import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Compass = (props) => {
  const { angle, angleAddition } = props;
  return (
    <Root>
      <img
        src={require('src/assets/images/oneshaughnessy/views/view-compass.svg')}
        alt="Compass"
        className="compass"
      />
      <img
        src={require('src/assets/images/oneshaughnessy/views/view-compass-handle.svg')}
        alt=""
        className="handle"
        style={{ transform: `rotate(${-angle + angleAddition}deg)` }}
      />
    </Root>
  );
};

Compass.defaultProps = {
  angleAddition: 0,
};

Compass.propTypes = {
  angle: PropTypes.number,
  angleAddition: PropTypes.number,
};

const Root = styled.div`
  width: ${vwMobile(91)};
  margin-left: ${vwMobile(51)};
  position: relative;
  img {
    display: block;
    width: 100%;
    height: auto;
  }
  .handle {
    position: absolute;
    top: 0;
    left: 0;
  }
  @media ${media.tablet} {
    width: ${vwTablet(120)};
    margin-left: 0;
    position: absolute;
    right: ${vwTablet(60)};
    bottom: ${vwTablet(440)};
    z-index: 5;
  }
  @media ${media.desktop} {
    width: ${vwDesktop(160)};
    right: ${vwDesktop(60)};
    bottom: ${vwDesktop(580)};
  }
`;

export default Compass;
