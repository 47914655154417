export default [
  {
    small: require('src/assets/images/home/kutak-xp-profile-1.jpg'),
    smallDescription: 'small',
    large: require('src/assets/images/home/kutak-xp-interior-1.jpg'),
    largeDescription: 'large',
  },
  {
    small: require('src/assets/images/home/kutak-xp-profile-2.jpg'),
    smallDescription:'small',
    large: require('src/assets/images/home/kutak-xp-interior-2.jpg'),
    largeDescription: 'large',
  },
]