import React from 'react';

import Overview from 'src/pages/Project/OneShaughnessy/Overview';
import Building from 'src/pages/Project/OneShaughnessy/Building';
import Community from 'src/pages/Project/OneShaughnessy/Community';
import Plans from 'src/pages/Project/OneShaughnessy/Plans';
import Views from 'src/pages/Project/OneShaughnessy/Views';
import Register from 'src/pages/Project/OneShaughnessy/Register';
import Thankyou from 'src/pages/Project/OneShaughnessy/Thankyou';

import planData from 'src/data/Floorplans/oneshaughnessy';

// nav element must match the key of the page to display it properly

export default {
  title: 'One Shaughnessy',
  slug: 'one-shaughnessy',
  nav: ['building', 'community', 'plans', 'views'],
  navTitle: (
    <>
      <span>One</span>Shaughnessy
    </>
  ),
  overview: <Overview />,
  building: <Building />,
  community: <Community />,
  plans: <Plans />,
  planData: planData,
  planLogo: require('src/assets/images/oneshaughnessy/one-shaughnessy-plain.svg'),
  views: <Views />,
  register: <Register />,
  thankyou: <Thankyou />,
};
