import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import vw from 'src/styles/utils';

export default function NotFound() {
  return (
    <Root>
      <Heading>
        <h1 className='corporate white'>404</h1>
      </Heading>
      <Body>
        <h3 className='corporate black'>We're sorry, this page cannot be found.</h3>
        <p className='body1 black'>
          Try going back to the <Link to='/' className='gold'>homepage</Link>, browse our <Link to='/homes' className='gold'>homes</Link>, or learn more <Link to='/team' className='gold'>about our team</Link>.
        </p>
      </Body>

    </Root>
  )
}

const Root = styled.div``;

const Heading = styled.div`
  ${vw('padding-top', 40, 80)}
  ${vw('padding-right', 16, 60)}
  ${vw('padding-bottom', 80, 80)}
  ${vw('padding-left', 16, 60)}
  ${vw('height', 164, 232)}
`;

const Body = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  ${vw('padding-top', 40, 60, 80)}
  ${vw('padding-right', 16, 60, 360)}
  ${vw('padding-bottom', 100, 300)}
  ${vw('padding-left', 16, 60, 360)}
  h3 {
    ${vw('margin-bottom', 16, 20)}
  }
`;
