import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import Button from 'components/Button';
import Lightbox from './elements/Lightbox';
import Plus from './elements/Plus';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const GridGallery = (props) => {
  const { title, subTitle, text, images } = props;
  const gridImages = images.filter((item) => item.grid);

  const [isLightboxActive, setIsLightboxActive] = useState(false);
  const [activeImage, setActiveImage] = useState(0);

  useEffect(() => {
    return () => clearAllBodyScrollLocks();
  }, []);

  const handleOpenLightbox = (index) => {
    setActiveImage(index);
    setIsLightboxActive(true);
    disableBodyScroll();
  };

  const handleCloseLightbox = () => {
    enableBodyScroll();
    setIsLightboxActive(false);
  };

  const handleNext = () => {
    if (activeImage === images.length - 1) {
      setActiveImage(0);
    } else setActiveImage(activeImage + 1);
  };

  const handlePrev = () => {
    if (activeImage === 0) {
      setActiveImage(images.length - 1);
    } else {
      setActiveImage(activeImage - 1);
    }
  };

  return (
    <Root>
      <Fade>
        <Grid>
          {gridImages.map((item, index) => (
            <Image
              image={item.img}
              index={index}
              key={index}
              large={item.large}
              onClick={() =>
                handleOpenLightbox(images.findIndex((i) => i.img === item.img))
              }
            >
              <div />
            </Image>
          ))}
        </Grid>
      </Fade>
      <Content>
        <div>
          <h5 className="global gold uppercase">{subTitle}</h5>
          <h3 className="corporate black">{title}</h3>
        </div>
        <div>
          <p className="body2 black">{text}</p>
          <Launch onClick={() => handleOpenLightbox(activeImage)}>
            <Plus />
            <Button
              type="text-link-gold"
              label="Launch Gallery"
              clickHandler={(f) => f}
            />
          </Launch>
        </div>
      </Content>
      <Lightbox
        active={isLightboxActive}
        handleClose={handleCloseLightbox}
        images={images}
        activeImage={activeImage}
        handleNext={handleNext}
        handlePrev={handlePrev}
      />
    </Root>
  );
};

GridGallery.propTypes = {
  images: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subTitle: PropTypes.string,
  text: PropTypes.string,
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.lightGrey};
  margin: 0 ${vwMobile(16)} ${vwMobile(48)};
  @media ${media.tablet} {
    margin: 0 ${vwTablet(60)} ${vwTablet(80)};
  }
  @media ${media.desktop} {
    display: flex;
    margin: 0 0 ${vwDesktop(100)} ${vwDesktop(60)};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${vwMobile(93)} ${vwMobile(93)} ${vwMobile(93)};
  grid-template-rows: ${vwMobile(93)} ${vwMobile(93)} ${vwMobile(93)};
  grid-gap: ${vwMobile(5)};
  @media ${media.tablet} {
    grid-template-columns: ${vwTablet(208)} ${vwTablet(208)} ${vwTablet(208)};
    grid-template-rows: ${vwTablet(208)} ${vwTablet(208)} ${vwTablet(208)};
    grid-gap: ${vwTablet(12)};
  }
  @media ${media.desktop} {
    grid-template-columns: ${vwDesktop(250)} ${vwDesktop(250)} ${vwDesktop(250)};
    grid-template-rows: ${vwDesktop(250)} ${vwDesktop(250)} ${vwDesktop(250)};
    grid-gap: ${vwDesktop(12)};
  }
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  grid-column: ${(props) => (props.large ? '2 / 4' : 'auto')};
  grid-row: ${(props) => (props.large ? '1 / 3' : 'auto')};
  > div {
    width: 100%;
    height: 100%;
    background-image: url(${(props) => props.image});
    background-size: cover;
    background-position: center;
  }
  @media ${media.desktop} {
    cursor: pointer;
    > div {
      transition: 0.7s ease-in-out;
    }
    &:hover > div {
      transform: scale(1.05);
    }
  }
`;

const Content = styled.div`
  padding: ${vwMobile(32)} ${vwMobile(16)} ${vwMobile(48)};
  h3 {
    margin: ${vwMobile(16)} 0;
  }
  p {
    margin-bottom: ${vwMobile(24)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(40)} ${vwTablet(40)} ${vwTablet(80)};
    display: flex;
    justify-content: space-between;
    h3 {
      margin: ${vwTablet(20)} 0 0;
    }
    p {
      margin: ${vwTablet(56)} 0 ${vwTablet(40)};
    }
    > div:first-of-type {
      width: ${vwTablet(260)};
    }
    > div:last-of-type {
      width: ${vwTablet(272)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(120)} ${vwDesktop(132)} ${vwDesktop(80)};
    display: block;
    h3 {
      margin: ${vwDesktop(20)} 0;
    }
    p {
      margin: 0 0 ${vwDesktop(40)};
    }
    > div:first-of-type {
      width: ${vwDesktop(300)};
    }
    > div:last-of-type {
      width: ${vwDesktop(300)};
    }
  }
`;

const Launch = styled.div`
  display: flex;
  align-items: center;
  svg {
    width: ${vwMobile(32)};
    height: ${vwMobile(32)};
    margin-right: ${vwMobile(12)};
  }
  path {
    fill: ${({ theme }) => theme.color.gold};
  }
  @media ${media.tablet} {
    svg {
      width: ${vwTablet(40)};
      height: ${vwTablet(40)};
      margin-right: ${vwTablet(12)};
    }
  }
  @media ${media.desktop} {
    cursor: pointer;
    svg {
      width: ${vwDesktop(40)};
      height: ${vwDesktop(40)};
      margin-right: ${vwDesktop(12)};
    }
    path {
      transition: 0.4s ease;
    }
    &:hover path {
      fill: ${({ theme }) => theme.color.black};
    }
    &:hover > button {
      color: ${({ theme }) => theme.color.black};
      border-bottom: 2px solid ${({ theme }) => theme.color.black};
    }
  }
`;

export default GridGallery;
