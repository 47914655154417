import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Card = (props) => {
  const {
    plan,
    activeBed,
    slug,
    configurator,
    handlePlan,
    handlePopup,
    activePlans,
    index,
    summary,
  } = props;

  const [isCardVisible, setIsCardVisible] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (activeBed === null) {
      setTimeout(() => setIsCardVisible(true), 400);
    } else if (activeBed === plan.bed) {
      setTimeout(() => setIsCardVisible(true), 400);
    } else {
      setTimeout(() => setIsCardVisible(false), 400);
    }
  }, [activeBed, plan.bed]);

  const handleClick = () => {
    history.push(`/homes/${slug}/plans/${plan.id}`, {
      activeFilter: activeBed,
    });
  };

  const handleCardClick = () => {
    if (configurator) {
      if (window.innerWidth < 576) {
        return handlePlan(plan.id);
      } else {
        return handlePopup(index);
      }
    } else if (plan.soldout) {
      return (f) => f;
    } else {
      return handleClick();
    }
  };

  return (
    <Root
      active={isCardVisible}
      onClick={handleCardClick}
      soldOut={plan.soldout}
      summary={summary}
      config={configurator}
    >
      <Image>
        <img src={plan.plan} alt={`Floorplan ${plan.id}`} />
      </Image>
      <Div>
        <div>
          <Wrapper margin>
            <h3 className="floorplan-label uppercase">{plan.id}</h3>
            <div>
              <p className="plan-type black">
                {plan.bed === 0 ? 'Studio' : `${plan.bed} Bed`}{' '}
                {plan.den && '+ Den'}
              </p>
              <p className="plan-type black">{plan.bath} Bathroom</p>
            </div>
          </Wrapper>
          {configurator && (
            <Mobile>
              <Wrapper>
                <p className="plan-details black uppercase">Interior</p>
                <p className="plan-details black uppercase">
                  {plan.interior} sqft
                </p>
              </Wrapper>
              <Wrapper>
                <p className="plan-details black uppercase">Exterior</p>
                <p className="plan-details black uppercase">
                  {plan.exterior} sqft
                </p>
              </Wrapper>
            </Mobile>
          )}
          <Wrapper>
            <p className="plan-details black uppercase">Total</p>
            <p className="plan-details black uppercase">{plan.total} sqft</p>
          </Wrapper>
        </div>
        {configurator && (
          <>
            <Fav
              src={
                activePlans.includes(plan.id)
                  ? require('src/assets/images/global/icon/action/favourites-active.svg')
                  : require('src/assets/images/global/icon/action/favourites-inactive.svg')
              }
              alt="Favourite"
              onClick={
                window.innerWidth > 576 ? () => handlePlan(plan.id) : (f) => f
              }
            />
            <Border
              style={{ opacity: activePlans.includes(plan.id) ? 1 : 0 }}
            />
          </>
        )}
      </Div>
      <Overlay soldOut={plan.soldout} noShow={configurator || summary}>
        <h3 className="project white uppercase">Sold Out</h3>
      </Overlay>
    </Root>
  );
};

Card.propTypes = {
  plan: PropTypes.object,
  activeBed: PropTypes.number,
  index: PropTypes.number,
  slug: PropTypes.string,
  configurator: PropTypes.bool,
  summary: PropTypes.bool,
  handlePlan: PropTypes.func,
  handlePopup: PropTypes.func,
  activePlans: PropTypes.array,
};

const Root = styled.div`
  width: 100%;
  height: ${(props) => (props.config ? vwMobile(447) : vwMobile(415))};
  background-color: white;
  padding: ${vwMobile(20)} ${vwMobile(32)};
  margin-bottom: ${vwMobile(12)};
  position: relative;
  display: ${(props) => (props.active ? 'block' : 'none')};
  color: ${({ theme }) => theme.color.black};
  @media ${media.tablet} {
    height: ${vwTablet(462)};
    padding: ${vwTablet(32)} ${vwTablet(32)} ${vwTablet(24)};
    margin-bottom: 0;
  }
  @media ${media.desktop} {
    width: ${vwDesktop(346)};
    /* height: ${vwDesktop(511)}; */
    height: auto;
    padding: ${vwDesktop(32)} ${vwDesktop(32)} ${vwDesktop(24)};
    margin-bottom: 0;
    cursor: ${(props) => {
      if (props.summary) {
        return 'auto';
      } else if (props.soldOut && !props.config) {
        return 'auto';
      } else {
        return 'pointer';
      }
    }};
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${(props) => (props.margin ? vwMobile(17) : 0)};
  > div {
    text-align: right;
  }
  @media ${media.tablet} {
    margin-bottom: ${(props) => (props.margin ? vwTablet(24) : 0)};
  }
  @media ${media.desktop} {
    margin-bottom: ${(props) => (props.margin ? vwDesktop(24) : 0)};
  }
`;

const Image = styled.div`
  width: 100%;
  height: ${vwMobile(248)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${vwMobile(24)};
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
  @media ${media.tablet} {
    height: ${vwTablet(254)};
    margin-bottom: ${vwTablet(24)};
  }
  @media ${media.desktop} {
    height: ${vwDesktop(282)};
    margin-bottom: ${vwDesktop(24)};
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(34, 34, 34, 0.85);
  opacity: ${(props) => (props.soldOut ? 1 : 0)};
  opacity: ${(props) => {
    if (props.noShow) {
      return 0;
    } else if (props.soldOut) {
      return 1;
    } else {
      return 0;
    }
  }};
`;

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  > div:first-of-type {
    width: 100%;
  }
`;

const Fav = styled.img`
  width: ${vwMobile(22)};
  margin-bottom: 3px;
  margin-left: ${vwMobile(22)};
  z-index: 2;
  position: relative;
  @media ${media.tablet} {
    width: ${vwTablet(22)};
    margin-left: ${vwTablet(32)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(22)};
    margin-left: ${vwDesktop(20)};
    margin-bottom: 5px;
  }
`;

const Border = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  border: ${vwMobile(4)} solid ${({ theme }) => theme.color.gold};
  transition: 0.3s ease;
  @media ${media.tablet} {
    border: ${vwTablet(4)} solid ${({ theme }) => theme.color.gold};
  }
  @media ${media.desktop} {
    border: ${vwDesktop(4)} solid ${({ theme }) => theme.color.gold};
  }
`;

const Mobile = styled.div`
  @media ${media.tablet} {
    display: none;
  }
`;

export default Card;
