import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ArrowButton = (props) => {
  const { to, text } = props;

  return (
    <Link to={to}>
      <Root className="configurator caption gold">
        <img
          src={require('src/assets/images/global/icon/action/chevron-right-gold.svg')}
          alt="Description"
        />
        {text}
      </Root>
    </Link>
  );
};

ArrowButton.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
};

const Root = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: ${vwMobile(12)};
    margin-right: ${vwMobile(4)};
    display: block;
    transform: scaleX(-1);
  }
  @media ${media.tablet} {
    img {
      width: ${vwTablet(12)};
      margin-right: ${vwTablet(4)};
    }
  }
  @media ${media.desktop} {
    img {
      width: ${vwDesktop(12)};
      margin-right: ${vwDesktop(4)};
    }
  }
`;

export default ArrowButton;
