import React from 'react';
import styled from 'styled-components';

import Members from './sections/Members';
import HeroCorporateText from 'components/HeroCorporateText';
import data from 'src/data/Components/Hero';

export default function Team() {
  return (
    <Root>
      <HeroCorporateText 
        data={data.team}
        badge={data.team.badge}
      />
      <Members />
    </Root>

  )
}

const Root = styled.div`

`;