import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Upgrades = (props) => {
  const { data } = props;
  return (
    <Root>
      <h4 className="global black uppercase">selected upgrades</h4>
      <Container>
        {data.map((item, index) => (
          <Card key={index}>
            <h4 className="global black uppercase">{item}</h4>
            <img
              src={require('src/assets/images/global/icon/action/multi-select-active-black.svg')}
              alt="Checkmark"
            />
          </Card>
        ))}
      </Container>
    </Root>
  );
};

Upgrades.propTypes = {
  data: PropTypes.array,
};

const Root = styled.div`
  padding: ${vwMobile(60)} ${vwMobile(16)} 0;
  @media ${media.tablet} {
    padding: 0;
    padding-top: 80px;
  }
  @media print {
    padding: 80px 0 0 0;
    break-inside: avoid;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-gap: ${vwMobile(12)};
  margin-top: ${vwMobile(16)};
  @media ${media.tablet} {
    grid-template-columns: ${vwTablet(240)} ${vwTablet(240)};
    grid-gap: ${vwTablet(32)};
    margin-top: ${vwTablet(24)};
  }
  @media ${media.desktop} {
    grid-template-columns: ${vwDesktop(240)} ${vwDesktop(240)} ${vwDesktop(240)} ${vwDesktop(
        240
      )};
    grid-gap: ${vwDesktop(32)};
    margin-top: ${vwDesktop(24)};
  }
  @media print {
    grid-template-columns: 240px 240px 240px;
    grid-gap: 32px;
    margin-top: 24px;
  }
`;

const Card = styled.div`
  width: 100%;
  height: ${vwMobile(160)};
  border: 4px solid ${({ theme }) => theme.color.black};
  padding: 20px;
  position: relative;
  img {
    width: 22px;
    height: 22px;
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  @media ${media.tablet} {
    width: 100%;
    height: 240px;
  }
  @media ${media.desktop} {
    height: ${vwDesktop(240)};
    border: ${vwDesktop(4)} solid ${({ theme }) => theme.color.black};
    padding: ${vwDesktop(20)};
    img {
      width: ${vwDesktop(22)};
      height: ${vwDesktop(22)};
      bottom: ${vwDesktop(20)};
      right: ${vwDesktop(20)};
    }
  }
  @media print {
    height: 240px;
    border: 4px solid ${({ theme }) => theme.color.black};
    padding: 20px;
    img {
      width: 22px;
      height: 22px;
      bottom: 20px;
      right: 20px;
    }
  }
`;

export default Upgrades;
