export default {
  corporate: {
    address1: 'Suite 2007 - 1177 West Hastings St. ',
    address2: 'Vancouver, BC V6E 2K3',
    email: 'info@kutakdevelopment.com ',
    phone: '604.670.8845',
    instagram: 'https://www.instagram.com/kutakdevelopment/',
    facebook: 'https://www.facebook.com/kutaktogether/',
    linkedin: 'https://www.linkedin.com/company/kutak-development/',
    twitter: 'https://twitter.com/kutakdev/',
  },
  os: {
    visitHeading: 'Visit the presentation centre',
    locationHeading: 'Location',
    address1: 'Unit 119 - 2540 Shaughnessy St.',
    address2: 'Port Coquitlam, BC V3C 3W4',
    tel: '604.670.8745',
    hoursHeading: 'Hours',
    weekdays: '12-5pm',
    weekends: 'Saturday, Sunday and Monday',
    map: require('src/assets/images/oneshaughnessy/one-shaughnessey-stick-map.jpg'),
    mapDescription: 'one shaughnessy stick map',
  },
};
