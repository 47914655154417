import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setProject, clearProject } from 'src/redux/project';

import vw from 'src/styles/utils';

import data from 'src/data/Projects';

const Subpage = () => {
  const [activePage, setActivePage] = useState(null);
  const [noPage, setNoPage] = useState(false);

  const activeProject = useSelector((state) => state.project.activeProject);

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.id && !noPage) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].slug === params.id) {
          dispatch(setProject(data[i].slug));
          if (params.subpage) {
            if (data[i][params.subpage]) {
              setActivePage(data[i][params.subpage]);
              break;
            } else {
              setNoPage(true);
              return history.replace('/404');
            }
          }
          break;
        }

        if (i === data.length - 1 && data[i].slug !== params.id) {
          setNoPage(true);
          return history.replace('/404');
        }
      }
    }
    return () => dispatch(clearProject());
  }, [params, dispatch, history, activeProject, noPage]);

  return <Root>{activePage && activePage}</Root>;
};

export default Subpage;

const Root = styled.div`
  ${vw('margin-top', 60, 82, 100)}
`;
