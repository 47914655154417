import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Menu from '../elements/Menu';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Membership = (props) => {
  const [ loggedIn, setLoggedIn ] = useState((localStorage.getItem('token') !== null));
  const { isMembership, location } = props;
  const history = useHistory();

  useEffect(() => {
    setLoggedIn((localStorage.getItem('token') !== null));
  })

  const Logout = () => {
    localStorage.removeItem('token');
    setLoggedIn(false);
    history.push('/membership');
  }

  return (
    <>
      <Root isMembership={isMembership} loggedIn={loggedIn}>
        <Heading>
          <ProjectName className='project-nav'>
            <p><span>One</span>Shaughnessy</p>
            <p>Membership</p>
          </ProjectName>
        </Heading>
        <img
          src={require('src/assets/images/global/icon/kutak/kutak-me.svg')}
          alt="Kutak"
        />
      </Root>
      <Menu loggedIn={loggedIn} location={location} Logout={Logout}/>
      <Spacing loggedIn={loggedIn && location.includes('membership')} />
    </>
  );
};

Membership.propTypes = {
  isMembership: PropTypes.bool,
  location: PropTypes.string,
  data: PropTypes.array,
};

const Root = styled.div`
  display: ${props => props.isMembership ? 'flex' : 'none'};
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.color.black};
  ${vw('height', 80)}
  ${vw('padding-right', 16, 30, 60)}
  ${vw('padding-left', 16, 30, 60)}
  img {
    ${vw('width', 56, 60)}
    ${vw('height', 56, 60)}
  }

  @media ${media.desktop} {
    justify-content: flex-end;
    img {
      margin-left: ${vwDesktop(30)};
    }
  }
`;

const Heading = styled.div`
  display: flex;
`;

const ProjectName = styled.div`
  display: flex;
  flex-direction: column;
  text-transform: uppercase;
  p {
    &:first-of-type {
      color: ${({ theme }) => theme.color.gold};
      span {
        font-weight: 600;
      }
    }
    &:last-of-type {
      color: ${({ theme }) => theme.color.white};
      ${vw('margin-left', 0, 49)}
    }
  }

  @media ${media.tablet} {
    flex-direction: row;
    p {
      &:last-of-type {
        color: ${({ theme }) => theme.color.gold};
        position: relative;
        &:before {
          content: '';
          position: absolute;
          width: 1px;
          height: ${vwTablet(22)};
          left: ${vwTablet(-25)};
          top: ${vwTablet(-1)};
          background-color: ${({ theme }) => theme.color.gold};
        }
      }
    }
  }

  @media ${media.desktop} {
    p {
      &:last-of-type {
        &:before {
          height: ${vwDesktop(22)};
          left: ${vwDesktop(-25)};
          top: ${vwDesktop(-1)};
        }
      }
    }
  }
`;

const Spacing = styled.div`
  display: none;
  @media ${media.desktop} {
    display: ${props => props.loggedIn ? 'block' : 'none'};
    background-color: ${({ theme }) => theme.color.black};
    height: ${vwDesktop(120)};
  }
`;

export default Membership;
