import React from 'react';
import styled from 'styled-components';

import HeroCorporateText from 'components/HeroCorporateText';
import Map from './elements/Map';
import data from 'src/data/Components/Hero';

export default function Contact() {
  return (
    <Root>
      <HeroCorporateText 
        data={data.contact}
      />
      <Map />
    </Root>
  )
}

const Root = styled.div``;