import React, { useState } from 'react';
import styled from 'styled-components';
import LCrypt from 'lcrypt';
import { useSelector, useDispatch } from 'react-redux';
import { setEmail } from 'src/redux/configurator';
import appConfig from 'src/config/app.conf';

import Button from 'components/Button';
import ArrowButton from 'components/Configurator/ArrowButton';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import { getSearchEmail, updateRegistrantNotes } from 'src/utils/sendLasso';
import oneshaughnessyToLassoData from 'src/data/Configurator/oneshaughnessy';

const ResetPassword = () => {
  const [hasSent, setHasSent] = useState(false);

  const config = useSelector((state) => state.configurator);

  const dispatch = useDispatch();

  let lcrypt = new LCrypt(appConfig.designMailer);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let param = {
      login: '',
    };

    const data = JSON.stringify(param);
    const response = await fetch(
      'https://bam-cors-anywhere.herokuapp.com/http://wp.bamdigital.com/forgot_password.php',
      {
        method: 'POST',
        body: param,
      }
    )

    // const responseJson = await response.json();

    // console.log(response)
    
  };

  return (
    <Root>
      <ArrowButton to="/membership" text="Back to Membership" />
      <h1 className="configurator black">
        Reset your password by emailing a copy to yourself.
      </h1>
      <form onSubmit={handleSubmit}>
        <h5 className="global black uppercase">
          <label htmlFor="email">Enter your Email</label>
        </h5>
        <input
          type="email"
          name="email"
          id="email"
          required
          onChange={(e) => dispatch(setEmail(e.target.value))}
          className="body2 black"
          defaultValue={config.email !== null ? config.email : ''}
          disabled={hasSent}
        />
        <Wrapper>
          <Button
            type="primary-arrow"
            label="send me this link"
            disabled={hasSent}
          />
          <Sent style={{ opacity: hasSent ? 1 : 0 }}>
            <img
              src={require('src/assets/images/global/icon/action/multi-select-active-black.svg')}
              alt="Sent"
            />
            <p className="body2 black">Email successfully sent! </p>
          </Sent>
        </Wrapper>
      </form>
      <Button
        type="text-link-gold"
        label="Back to One Shaughnessy"
        to="/homes/one-shaughnessy"
      />
    </Root>
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: ${vwMobile(60)} ${vwMobile(16)} ${vwMobile(40)};
  input {
    width: 100%;
    border: 1px solid ${({ theme }) => theme.color.black};
    border-radius: 0;
    margin: ${vwMobile(4)} 0 ${vwMobile(16)};
    padding: ${vwMobile(9)} ${vwMobile(12)};
    transition: 0.3s ease;
  }
  input:focus {
    outline: none;
    border-color: ${({ theme }) => theme.color.gold};
  }
  h1 {
    margin: ${vwMobile(24)} 0 ${vwMobile(40)};
  }
  form {
    margin-bottom: ${vwMobile(106)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(80)} ${vwTablet(60)};
    h1 {
      max-width: ${vwTablet(400)};
      margin: ${vwTablet(24)} 0 ${vwTablet(60)};
    }
    input {
      width: ${vwTablet(400)};
      margin: ${vwTablet(4)} 0 ${vwTablet(16)};
      padding: ${vwTablet(11)} ${vwTablet(12)};
    }
    form {
      margin-bottom: ${vwTablet(160)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(80)} ${vwDesktop(60)};
    min-height: 84vh;
    h1 {
      max-width: ${vwDesktop(400)};
      margin: ${vwDesktop(24)} 0 ${vwDesktop(60)};
    }
    input {
      width: ${vwDesktop(400)};
      margin: ${vwDesktop(4)} 0 ${vwDesktop(16)};
      padding: ${vwDesktop(11)} ${vwDesktop(12)};
    }
    form {
      margin-bottom: ${vwDesktop(160)};
    }
  }
`;

const Wrapper = styled.div`
  position: relative;
  @media ${media.tablet} {
    display: flex;
  }
`;

const Sent = styled.div`
  transition: 0.3s ease;
  position: absolute;
  top: ${`calc(100% + ${vwMobile(24)})`};
  display: flex;
  align-items: center;
  img {
    width: ${vwMobile(22)};
    margin-right: ${vwMobile(8)};
    display: block;
  }
  @media ${media.tablet} {
    position: relative;
    top: auto;
    margin-left: ${vwTablet(40)};
    img {
      width: ${vwTablet(22)};
      margin-right: ${vwTablet(8)};
    }
  }
  @media ${media.desktop} {
    margin-left: ${vwDesktop(40)};
    img {
      width: ${vwDesktop(22)};
      margin-right: ${vwDesktop(8)};
    }
  }
`;

export default ResetPassword;
