import React from 'react';

export default {
  title: 'Foster Martin',
  slug: 'foster-martin',
  hero: {
    badge: require('src/assets/images/global/icon/kutak/kutak-kis.svg'),
    heading: <>Three impressive towers in White Rock.</>,
    bodyTitle: '',
    body: [
    ]
  },
  infographic: [
    {
      label: 'Size',
      text: '3 towers; 24-25 storeys; 577,000 SF; 5.4 FSR',
    },
    {
      label: 'Developer',
      text: 'Landmark Premiere Properties Ltd.',
    },
    {
      label: 'Address',
      text: '1484 Martin St., White Rock, BC',
    },
    {
      label: 'Type',
      text: '350 residential homes; 56,000 SF of commercial uses, including retail, restaurant, office, and daycare; 884 parking stalls',
    },
  ],
  content: {
    projectName: 'Foster Martin',
    featuredImage: require('src/assets/images/philosophy/kis-case-study/kutak-foster-martin-feature.jpg'),
    bodyTitle: 'Designing luxury and lifestyle by the seaside.',
    bodyText: 
    <>
    Comprised of three iconic residential towers, Foster Martin is the single largest development in the idyllic White Rock community. In additional to residential, the project features commercial office space, public plaza shops and services, resort-style amenities for residents, and public parking. 
    <br/><br/>
    As Owner’s Representative and Development Manager, Kutak oversaw the site acquisition, design development, municipal affairs, financing, accounting, tendering, marketing, and public relations. During pre-sales, Foster Martin set new sales records for this market. 
    <br/><br/>
    Our contract agreement mandated that we lead the project to construction, at which point, a general contractor would take over. Construction is well underway, with completion of the first tower scheduled for 2021.
    </>,
    stats1Percentage: '34%',
    stats1Label: 'Lorem isum dolor sit amet',
    stats2Percentage: '18%',
    stats2Label: 'Lorem isum dolor sit amet',
  }
}