import React from 'react';
import styled from 'styled-components';

import Floorplans from 'components/Project/Plans/Floorplans';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/Floorplans/oneshaughnessy';
import pageData from 'src/data/Projects/OneShaughnessy';

const Plans = () => {
  return (
    <Root>
      <Hero>
        <h1 className='project black'>
          Home Fits
          <br />
          Your Life
        </h1>
        <img
          src={require('src/assets/images/oneshaughnessy/building-graphic.jpg')}
          alt='One Shaughnessy'
        />
      </Hero>
      <Floorplans
        data={data}
        slug={pageData.slug}
        filters={[
          {
            text: 'Studio',
            bed: 0,
          },
          {
            text: '1 Bed',
            bed: 1,
          },
          {
            text: '2 Bed',
            bed: 2,
          },
          {
            text: '3 Bed',
            bed: 3,
          },
        ]}
      />
    </Root>
  );
};

export default Plans;

const Root = styled.div``;

const Hero = styled.div`
  background-color: white;
  padding: ${vwMobile(48)} ${vwMobile(16)};
  img {
    width: ${vwMobile(252)};
    margin-top: ${vwMobile(40)};
    display: block;
  }
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${vwTablet(80)} ${vwTablet(60)};
    img {
      width: ${vwTablet(198)};
      margin-top: 0;
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(80)} ${vwDesktop(60)} ${vwDesktop(52)};
    img {
      width: ${vwDesktop(300)};
    }
  }
`;
