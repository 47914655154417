import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const UpgradeCard = (props) => {
  const { item, active, handleClick, summary } = props;
  return (
    <Root
      active={active}
      summary={summary}
      onClick={summary ? (f) => f : () => handleClick(item)}
    >
      <h4 className="global gold uppercase">{item}</h4>
      <Check>
        <div />
        <img
          src={require('src/assets/images/global/icon/action/multi-select-active-gold.svg')}
          alt="Selected"
          style={{ opacity: active ? 1 : 0 }}
        />
      </Check>
      <Border style={{ opacity: active ? 1 : 0 }} />
    </Root>
  );
};

UpgradeCard.propTypes = {
  item: PropTypes.string,
  active: PropTypes.bool,
  summary: PropTypes.bool,
  handleClick: PropTypes.func,
};

const Root = styled.div`
  width: 100%;
  height: ${vwMobile(160)};
  padding: ${vwMobile(20)};
  background-color: ${({ theme }) => theme.color.white};
  border: solid ${(props) => (props.summary ? 0 : '1px')} #d7d7d7;
  margin-bottom: ${vwMobile(16)};
  position: relative;
  transition: 0.3s ease;
  @media ${media.tablet} {
    width: ${vwTablet(240)};
    height: ${vwTablet(230)};
    padding: ${vwTablet(20)};
    margin-bottom: 0;
  }
  @media ${media.desktop} {
    width: ${vwDesktop(240)};
    height: ${vwDesktop(230)};
    padding: ${vwDesktop(20)};
    cursor: ${(props) => (props.summary ? 'auto' : 'pointer')};
  }
`;

const Border = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: ${vwMobile(4)} solid ${({ theme }) => theme.color.gold};
  transition: 0.3s ease;
  pointer-events: none;
  @media ${media.tablet} {
    border: ${vwTablet(4)} solid ${({ theme }) => theme.color.gold};
  }
  @media ${media.desktop} {
    border: ${vwDesktop(4)} solid ${({ theme }) => theme.color.gold};
  }
`;

const Check = styled.div`
  width: ${vwMobile(22)};
  height: ${vwMobile(22)};
  position: absolute;
  bottom: ${vwMobile(20)};
  right: ${vwMobile(20)};
  > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: solid 1px #d7d7d7;
    border-radius: 50%;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease;
  }
  @media ${media.tablet} {
    width: ${vwTablet(22)};
    height: ${vwTablet(22)};
    bottom: ${vwTablet(20)};
    right: ${vwTablet(20)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(22)};
    height: ${vwDesktop(22)};
    bottom: ${vwDesktop(20)};
    right: ${vwDesktop(20)};
  }
`;

export default UpgradeCard;
