import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ContentBlock = (props) => {
  const {
    title,
    id,
    secondAttribute,
    paletteIndex,
    labelRight,
    image,
    palette,
    secondAttributeImage,
    text,
    small,
  } = props;
  return (
    <Root>
      <h4 className="global black uppercase">{title}</h4>
      <Content small={small}>
        <Image src={image} alt="title" />
        <div>
          <h5 className="global black uppercase">Palette</h5>
          <Palette>
            <img src={palette} alt="Palette" className="palette" />
            <Label right={labelRight}>
              <h3 className="configurator white">
                {title}
                <br />
                {id}
                {paletteIndex + 1}
              </h3>
            </Label>
          </Palette>
          <h5 className="global black uppercase">{secondAttribute}</h5>
          <img
            src={secondAttributeImage}
            alt={secondAttribute}
            className="second"
          />
          <p className="configurator caption grey">{text}</p>
        </div>
      </Content>
    </Root>
  );
};

ContentBlock.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string,
  secondAttribute: PropTypes.string,
  image: PropTypes.string,
  palette: PropTypes.string,
  secondAttributeImage: PropTypes.string,
  paletteIndex: PropTypes.number,
  labelRight: PropTypes.number,
  text: PropTypes.object,
  small: PropTypes.bool,
};

const Root = styled.div`
  margin-bottom: ${vwMobile(60)};
  h4 {
    margin-left: ${vwMobile(16)};
    margin-bottom: ${vwMobile(16)};
  }
  h5 {
    margin-bottom: ${vwMobile(8)};
    margin-top: ${vwMobile(24)};
  }
  @media ${media.tablet} {
    margin-bottom: ${vwTablet(80)};
    h4 {
      margin-left: 0;
      margin-bottom: ${vwTablet(24)};
    }
    h5 {
      margin-bottom: ${vwTablet(12)};
      margin-top: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    margin-bottom: ${vwDesktop(80)};
    h4 {
      margin-bottom: ${vwDesktop(24)};
    }
    h5 {
      margin-bottom: ${vwDesktop(12)};
      margin-top: ${vwDesktop(40)};
    }
  }
  @media print {
    margin-bottom: 80px;
    break-inside: avoid;
    /* display: inline-block; */
    h3 {
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0.86px;
      margin-bottom: 12px;
    }
    h4 {
      margin-left: 0;
      margin-bottom: 24px;
    }
    h5 {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 3.56px;
      margin-bottom: 12px;
      margin-top: 40px;
    }
    .caption {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 1px;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  display: block;
  @media ${media.tablet} {
    width: 100%;
  }
  @media ${media.desktop} {
    width: ${vwDesktop(960)};
  }
  @media print {
    width: 62.5%;
    max-height: 57.5%;
  }
`;

const Content = styled.div`
  .palette {
    width: ${vwMobile(272)};
    height: ${vwMobile(68)};
  }
  .second {
    width: ${(props) => (props.small ? vwMobile(40) : vwMobile(53))};
    height: ${vwMobile(80)};
  }
  .caption {
    margin-top: ${vwMobile(24)};
  }
  > div {
    padding: 0 ${vwMobile(16)};
  }
  @media ${media.tablet} {
    > div {
      padding: 0;
    }
    .palette {
      width: ${vwTablet(300)};
      height: ${vwTablet(80)};
    }
    .second {
      width: ${(props) => (props.small ? vwTablet(44) : vwTablet(60))};
      height: ${vwTablet(80)};
    }
    .caption {
      margin-top: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    .palette {
      width: ${vwDesktop(300)};
      height: ${vwDesktop(80)};
    }
    .second {
      width: ${(props) => (props.small ? vwDesktop(44) : vwDesktop(60))};
      height: ${vwDesktop(80)};
    }
    .caption {
      margin-top: ${vwDesktop(40)};
    }
  }
  @media print {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .palette {
      width: 275px;
      height: 73.33px;
    }
    .second {
      width: ${(props) => (props.small ? '44px' : '60px')};
      height: 80px;
    }
    .caption {
      margin-top: 40px;
    }
  }
`;

const Palette = styled.div`
  position: relative;
  width: fit-content;
`;

const Label = styled.div`
  position: absolute;
  top: 14px;
  right: ${(props) => `${props.right}px`};
  @media ${media.tablet} {
    top: ${vwTablet(14)};
    right: ${(props) => vwTablet(props.right)};
  }
  @media ${media.desktop} {
    top: ${vwDesktop(14)};
    right: ${(props) => vwDesktop(props.right)};
  }
  @media print {
    top: 14px;
    right: ${(props) => `${props.right - 5}px`};
  }
`;

export default ContentBlock;
