import React, { Component } from 'react';
import Script from 'react-load-script';
import TagManager from 'react-gtm-module';

import { analytics } from 'src/config/app.conf';

export default class GTM extends Component {
  state = {
    isLoaded: false,
  };

  

  componentDidMount() {
    if (window.__prerendering) return;
    if (analytics.gtmId) {
      const tagManagerArgs = {
        gtmId: analytics.gtmId,
      };

      TagManager.initialize(tagManagerArgs);

  
    }
  }

  render() {
   
      return null;
  }
}
