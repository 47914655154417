import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

export default function Button(props) {
  const {
    label,
    clickHandler,
    type,
    to,
    mobileLabel,
    small,
    xSmall,
    disabled,
    target,
  } = props;

  const handleClick = () => {
    if (typeof clickHandler !== 'undefined') {
      clickHandler();
    }
  };

  return (
    <Root
      type={type}
      onClick={to ? (f) => f : handleClick}
      as={to ? Link : 'button'}
      to={to}
      mobileLabel={mobileLabel}
      small={small}
      xSmall={xSmall}
      disabled={disabled}
      target={target}
    >
      <img
        src={require('src/assets/images/global/icon/action/chevron-left-gold.svg')}
        alt="chevron right white"
        className="chevron-left"
      />
      {mobileLabel && <span className="mobile">{mobileLabel}</span>}
      <span className="desktop">{label}</span>
      <img
        src={require('src/assets/images/global/icon/action/chevron-right-white.svg')}
        alt="chevron right white"
        className="chevron-right"
      />
    </Root>
  );
}

Button.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  mobileLabel: PropTypes.string,
  small: PropTypes.bool,
  xSmall: PropTypes.bool,
  clickHandler: PropTypes.func,
};

const TextLinkWhite = css`
  font-family: 'futura-pt';
  font-weight: 500;
  color: ${({ theme }) => theme.color.white};
  border-bottom: 2px solid ${({ theme }) => theme.color.white};
  ${vw('font-size', 18, 18)}
  ${vw('line-height', 22, 22)}

  &:hover {
    color: ${({ theme }) => theme.color.gold};
    border-bottom: 2px solid ${({ theme }) => theme.color.gold};
  }
`;

const TextLinkBlack = css`
  font-family: 'futura-pt';
  font-weight: 500;
  color: ${({ theme }) => theme.color.black};
  border-bottom: 2px solid ${({ theme }) => theme.color.black};
  ${vw('font-size', 18, 18)}
  ${vw('line-height', 22, 22)}

  &:hover {
    color: ${({ theme }) => theme.color.gold};
    border-bottom: 2px solid ${({ theme }) => theme.color.gold};
  }
`;

const TextLinkGold = css`
  font-family: 'futura-pt';
  font-weight: 500;
  color: ${({ theme }) => theme.color.gold};
  border-bottom: 2px solid ${({ theme }) => theme.color.gold};
  width: fit-content;
  display: table;
  ${vw('font-size', 18, 18)}
  ${vw('line-height', 22, 22)}

  &:hover {
    color: ${({ theme }) => theme.color.black};
    border-bottom: 2px solid ${({ theme }) => theme.color.black};
  }
`;

const TextLinkDisabled = css`
  font-family: 'futura-pt';
  font-weight: 500;
  color: ${({ theme }) => theme.color.disabled};
  border-bottom: 2px solid ${({ theme }) => theme.color.disabled};
  width: fit-content;
  cursor: auto;
  ${vw('font-size', 18, 18)}
  ${vw('line-height', 22, 22)}
`;

const SecondaryGold = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.gold};
  font-family: 'futura-pt';
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${({ theme }) => theme.color.gold};
  ${vw('padding-top', 10, 10)}
  ${vw('padding-left', 12, 12)}
  ${vw('padding-bottom', 10, 10)}
  ${vw('padding-right', 12, 12)}
  ${vw('font-size', 16, 16)}
  ${vw('line-height', 20, 20)}
  ${vw('letter-spacing', 3, 3)}

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.color.gold};
    color: ${({ theme }) => theme.color.white};
  }
`;

const PrimaryGold = css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color.gold};
  font-family: 'futura-pt';
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.white};
  ${vw('padding-top', 10, 10)}
  ${vw('padding-left', 12, 12)}
  ${vw('padding-bottom', 10, 10)}
  ${vw('padding-right', 12, 12)}
  ${vw('font-size', 16, 16)}
  ${vw('line-height', 20, 20)}
  ${vw('letter-spacing', 3, 3)}

  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.color.black};
  }
`;

const PrimaryArrow = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.disabled ? props.theme.color.disabled : props.theme.color.gold};
  font-family: 'futura-pt';
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.white};
  ${vw('padding-top', 10, 10)}
  ${vw('padding-left', 10, 10)}
  ${vw('padding-bottom', 10, 10)}
  ${vw('padding-right', 10, 10)}
  ${vw('font-size', 16, 16)}
  ${vw('line-height', 20, 20)}
  ${vw('letter-spacing', 3, 3)}
  ${(props) => {
    if (props.xSmall) {
      return vw('width', 200, 200);
    } else if (props.small) {
      return vw('width', 112, 112, 240);
    } else {
      return vw('width', 288, 240);
    }
  }}
  
  &:hover,
  &:active {
    background-color: ${(props) =>
      props.disabled ? props.theme.color.disabled : props.theme.color.black};
  }

  .chevron-right {
    display: block;
    /* ${vw('margin-left', 90, 90)} */
    ${vw('width', 14, 14)}
    ${vw('height', 14, 14)}
  }

  .desktop {
    display: ${(props) => (props.mobileLabel ? 'none' : 'block')};
    @media ${media.desktop} {
      display: block;
    }
  }

  .mobile {
    display: ${(props) => (props.mobileLabel ? 'block' : 'none')};
    @media ${media.desktop} {
      display: none;
    }
  }

`;

const LevelSelectInactive = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.color.gold};
  border-radius: 50%;
  font-family: 'acumin-pro-extra-condensed';
  font-weight: 600;
  color: ${({ theme }) => theme.color.gold};
  padding-top: 1px;
  ${vw('padding-bottom', 6, 6)}
  ${vw('width', 32, 32)};
  ${vw('height', 32, 32)};
  ${vw('font-size', 22, 22)}
  ${vw('line-height', 22, 22)}
  ${vw('letter-spacing', 0, 0)}
`;

const LevelSelectActive = css`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: 'acumin-pro-extra-condensed';
  font-weight: 600;
  color: ${({ theme }) => theme.color.black};
  background-color: ${({ theme }) => theme.color.gold};
  padding-top: 1px;
  ${vw('padding-bottom', 6, 6)}
  ${vw('width', 32, 32)};
  ${vw('height', 32, 32)};
  ${vw('font-size', 22, 22)}
  ${vw('line-height', 22, 22)}
  ${vw('letter-spacing', 0, 0)}
`;

const FormSubmit = css`
  border: 1px solid ${({ theme }) => theme.color.gold};
  font-family: 'acumin-pro-extra-condensed';
  font-weight: 500;
  text-align: left;
  color: ${({ theme }) => theme.color.gold};
  text-transform: uppercase;
  position: relative;
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  width: fit-content;
  ${vw('height', 50, 50)};
  ${vw('padding-top', 14, 14)}
  ${vw('padding-left', 12, 12)}
  ${vw('padding-bottom', 14, 14)}
  ${vw('padding-right', 12, 12)}
  ${vw('font-size', 18, 18)}
  ${vw('line-height', 18, 18)}
  ${vw('letter-spacing', 2.5, 2.5)}
  &:after {
    content: '';
    background-color: ${({ theme }) => theme.color.gold};
    display: block;
    /* position: absolute;
    top: 50%; */
    transition: 0.3s ease;
    ${vw('margin-left', 20, 45, 45)}
    ${vw('width', 40, 40)}
    ${vw('height', 1, 1)}
    ${vw('right', 12, 12)}
  }
  &:hover {
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.gold};
    &::after {
      background-color: ${({ theme }) => theme.color.white};
    }
  }
`;

const Back = css`
  display: flex;
  align-items: center;
  font-family: 'futura-pt';
  ${vw('font-size', 12, 14)}
  ${vw('letter-spacing', 3, 4)}
  font-weight: 500;
  color: ${({ theme }) => theme.color.gold};
  text-transform: uppercase;
  .chevron-left {
    display: block;
    ${vw('margin-right', 8, 8)}
    ${vw('width', 10, 12)}
    ${vw('height', 10, 12)}
  }
`;

const ToggleBlackInactive = css`
  border: 1px solid ${({ theme }) => theme.color.black};
  font-family: 'futura-pt';
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.black};
  ${vw('width', 75, 75)}
  ${vw('height', 36, 36)}
  ${vw('padding-top', 8, 8)}
  ${vw('padding-left', 10, 10)}
  ${vw('padding-bottom', 8, 8)}
  ${vw('padding-right', 10, 10)}
  ${vw('font-size', 16, 16)}
  ${vw('line-height', 20, 20)}
`;

const ToggleBlackActive = css`
  font-family: 'futura-pt';
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.black};
  ${vw('width', 75, 75)}
  ${vw('height', 36, 36)}
  ${vw('padding-top', 8, 8)}
  ${vw('padding-left', 10, 10)}
  ${vw('padding-bottom', 8, 8)}
  ${vw('padding-right', 10, 10)}
  ${vw('font-size', 16, 16)}
  ${vw('line-height', 20, 20)}
`;

const ToggleGoldInactive = css`
  border: 1px solid ${({ theme }) => theme.color.gold};
  font-family: 'futura-pt';
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.gold};
  ${vw('width', 75, 75)}
  ${vw('height', 36, 36)}
  ${vw('padding-top', 8, 8)}
  ${vw('padding-left', 10, 10)}
  ${vw('padding-bottom', 8, 8)}
  ${vw('padding-right', 10, 10)}
  ${vw('font-size', 16, 16)}
  ${vw('line-height', 20, 20)}
`;

const ToggleGoldActive = css`
  font-family: 'futura-pt';
  font-weight: 500;
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.gold};
  ${vw('width', 75, 75)}
  ${vw('height', 36, 36)}
  ${vw('padding-top', 8, 8)}
  ${vw('padding-left', 10, 10)}
  ${vw('padding-bottom', 8, 8)}
  ${vw('padding-right', 10, 10)}
  ${vw('font-size', 16, 16)}
  ${vw('line-height', 20, 20)}
`;

const Root = styled.button`
  cursor: pointer;
  transition: 0.3s ease-in-out;
  display: block;

  img {
    display: none;
  }
  ${(props) => {
    switch (props.type) {
      case 'text-link-white':
        return TextLinkWhite;
      case 'text-link-black':
        return TextLinkBlack;
      case 'text-link-gold':
        return TextLinkGold;
      case 'text-link-disabled':
        return TextLinkDisabled;
      case 'secondary-gold':
        return SecondaryGold;
      case 'primary-gold':
        return PrimaryGold;
      case 'primary-arrow':
        return PrimaryArrow;
      case 'level-select-inactive':
        return LevelSelectInactive;
      case 'level-select-active':
        return LevelSelectActive;
      case 'submit':
        return FormSubmit;
      case 'back':
        return Back;
      case 'toggle-black-inactive':
        return ToggleBlackInactive;
      case 'toggle-black-active':
        return ToggleBlackActive;
      case 'toggle-gold-inactive':
        return ToggleGoldInactive;
      case 'toggle-gold-active':
        return ToggleGoldActive;
      default:
        break;
    }
  }}
`;
