export default {
  color: {
    black: '#222222',
    gold: '#b18b6e',
    skyBlue: '#a9c5d9',
    lightGrey: '#ebebec',
    white: '#ffffff',
    disabled: '#C1C1C1',
    textGrey: '#696969',
  },
};
