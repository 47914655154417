import React from 'react';
import styled from 'styled-components';

import ContentBlock from 'components/Project/ContentBlock';
import HeaderText from 'components/Project/HeaderText';
import HomeConfigCTA from 'components/HomeConfigCTA';
import Hero from 'components/Project/Hero';

import data from 'src/data/Projects/OneShaughnessy';

const Overview = () => {
  return (
    <Root>
      <Hero
        image={require('src/assets/images/oneshaughnessy/one-shaugnessey-aerial.jpg')}
        text="Coming Soon To Port Coquitlam"
        banner={require('src/assets/images/oneshaughnessy/one-shaughnessy-lockup.svg')}
      />
      <HeaderText
        title={
          <>
            Thoughtful Living
            <br />
            is a Statement
          </>
        }
        text="In a welcoming Port Coquitlam community abundant with trees and local conveniences, One Shaughnessy will introduce an unmatched living experience in 33 modern condominiums."
        to={`/homes/${data.slug}/plans`}
        linkText="View Plans"
      />
      <ContentBlock
        title={
          <>
            Spotlight on
            <br />
            Innovative Design
          </>
        }
        subtitle="Building"
        text="This collection of homes defies ordinary, with details and features that speak to your personal style."
        linkText="View Building Features"
        to={`/homes/${data.slug}/building`}
        image={require('src/assets/images/oneshaughnessy/one-shaughnessy-kitchen.jpeg')}
        config
      />
      <ContentBlock
        title={
          <>
            A Walkable
            <br />
            Neighbourhood
          </>
        }
        subtitle="Community"
        text="Live just down the street from shops and restaurants, sprawling riverside parks, and even a West Coast Express station."
        linkText="Explore the Community"
        to={`/homes/${data.slug}/community`}
        image={require('src/assets/images/oneshaughnessy/one-shaughnessy-community.jpeg')}
        leftImage
      />
      <ContentBlock
        title="A Refreshing Outlook"
        subtitle="Views"
        text="Whether gazing out to lush greenspaces, a bustling community, or majestic mountaintops, your view is captivating from day to night."
        linkText="See Views"
        to={`/homes/${data.slug}/views`}
        image={require('src/assets/images/oneshaughnessy/one-shaughnessy-view.jpg')}
      />
      <HomeConfigCTA
        subHeading="Design Your Home"
        heading="Make it Yours"
        body="We put home design in the hands of the homeowner, and each home can be personalized from a selection of 27 different finishes and options."
        dark
      />
    </Root>
  );
};

export default Overview;

const Root = styled.div``;
