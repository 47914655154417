import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import media from 'src/styles/media';
import vw, { vwTablet, vwDesktop } from 'src/styles/utils';

export default function HeroCorporateText(props) {
  const { data, badge, badgeLarge, isHome } = props;

  return (
    <Root isHome={isHome} isEmptyBody={typeof data.body === 'undefined' || data.body.length === 0}>
      {typeof badge !== 'undefined' && 
        <Badge src={badge} large={badgeLarge} alt='kutak badge'/>
      }
      <h1 className='corporate white'>{data.heading}</h1>
      <Body isHome={isHome}>
        <h5 className='global gold uppercase'>{data.bodyTitle}</h5>
        {
          data.body.map((content, key) => {
            return(
              <p className='body2 white' key={key}>{content.text}</p>
            )
          })
        }
        <CTA isHome={isHome}>
          <img src={require('src/assets/images/global/icon/kutak/kutak-xp-me-kis-horizontal.svg')} alt='kutak xp me kis' />
          <Link to='/philosophy'>
            <Button label='Learn More' type='text-link-white' />
          </Link>
        </CTA> 
      </Body>
    </Root>
  )
}

HeroCorporateText.propTypes = {
  data: PropTypes.object,
  badge: PropTypes.string,
  badgeLarge: PropTypes.bool,
  isHome: PropTypes.bool,
}

const HomeCSS = css`
  h5 {
    ${vw('margin-bottom', 12, 16)}
  }
  p {
    ${vw('width', 288, 340)}
    ${vw('margin-bottom', 32, 40)}
  }
  @media ${media.desktop} {
    /* position: absolute;
    bottom: ${vwDesktop(150)};
    right: ${vwDesktop(320)}; */
    margin: ${vwDesktop(120)} 0 0 ${vwDesktop(220)};
  }
`;
const PageCSS = css`
  ${vw('width', 288, 608, 640)}
  h5 {
    ${vw('margin-bottom', 12, 16)}
  }
  p {
    ${vw('width', 288, 272, 280)}
    &:first-of-type {
      ${vw('margin-bottom', 20, 0)}
      ${vw('margin-right', 0, 64, 80)}
    }
  }
  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    h5 {
      width: 100%;
    }
  }
  @media ${media.desktop} {
    /* position: absolute;
    top: ${vwDesktop(200)};
    right: ${vwDesktop(140)}; */
    margin: ${vwDesktop(160)} 0 0 ${vwDesktop(100)};
  }
`;

const BadgeDefaultCSS = css`
  ${vw('width', 80, 100)}
  ${vw('height', 80, 100)}
`;

const BadgeLargeCSS = css`
  ${vw('width', 288, 340)}
  ${vw('height', 59, 70)}
`;

const Root = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.black};
  ${vw('padding-top', 40, 80, 80)}
  ${vw('padding-left', 16, 60)}
  ${props => props.isHome ? vw('padding-bottom', 80, 160, 150) : vw('padding-bottom', 80, 100, 100)}
  ${vw('padding-right', 16, 60)}
  h1 {
    ${vw('width', 288, 424, 500)}
    ${props => props.isEmptyBody ? '' : vw('margin-bottom', 48, 80, 0)}
  }
  @media ${media.desktop} {
    /* height: ${props => props.isHome ? vwDesktop(668) : vwDesktop(580)}; */
    display: flex;
  }
`;

const Body = styled.div`
  ${props => {
    switch(props.isHome) {
      case true:
        return HomeCSS;
      default:
        return PageCSS;
    }
  }}
`;

const CTA = styled.div`
  display: ${props => props.isHome ? 'block' : 'none'};
  img {
    ${vw('width', 288, 340)}
    ${vw('height', 60, 70)}
    ${vw('margin-bottom',  32, 40)}
  }
`;

const Badge = styled.img`
  ${props => {
    switch(props.large) {
      case true:
        return BadgeLargeCSS;
      default:
        return BadgeDefaultCSS;
    }
  }}
  ${vw('margin-bottom', 21, 0, 0)}

  @media ${media.tablet} {
    position: absolute;
    top: ${vwTablet(60)};
    right: ${vwTablet(60)};
  }
  @media ${media.desktop} {
    top: ${vwDesktop(60)};
    right: ${vwDesktop(60)};
  }
`;