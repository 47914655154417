// Constants
export const SET__PROJECT = 'SET::PROJECT';
export const CLEAR__PROJECT = 'CLEAR::PROJECT';

// Actions
export const setProject = (project) => ({
  type: SET__PROJECT,
  payload: project,
});

export const clearProject = () => ({
  type: CLEAR__PROJECT,
});

// Initial State
const initialState = {
  activeProject: null,
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET__PROJECT:
      return {
        ...state,
        activeProject: action.payload,
      };
    case CLEAR__PROJECT:
      return {
        ...state,
        activeProject: null,
      };
    default:
      return state;
  }
};
