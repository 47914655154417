import React from 'react';

const Plus = () => {
  return (
    <svg
      width="40px"
      height="40px"
      viewBox="0 0 40 40"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="icon/nav/circle-expand"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <path
          d="M20,0 C31.045695,0 40,8.954305 40,20 C40,31.045695 31.045695,40 20,40 C8.954305,40 0,31.045695 0,20 C0,8.954305 8.954305,0 20,0 Z M20,2 C10.0588745,2 2,10.0588745 2,20 C2,29.9411255 10.0588745,38 20,38 C29.9411255,38 38,29.9411255 38,20 C38,10.0588745 29.9411255,2 20,2 Z M21,9 L21,19 L31,19 L31,21 L21,21 L21,31 L19,31 L19,21 L9,21 L9,19 L19,19 L19,9 L21,9 Z"
          id="Combined-Shape"
        ></path>
      </g>
    </svg>
  );
};

export default Plus;
