import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Infographic from './sections/Infographic';
import HeroCorporateText from 'components/HeroCorporateText';
import CaseStudyCards from 'components/CaseStudyCards';
import Button from 'components/Button';
import data from 'src/data/Components/Hero';

import vw from 'src/styles/utils';

export default function KIS() {
  return (
    <Root>
      <Back>
        <Link to={'/philosophy'}>
          <Button label={`Back to Philosophy`} type='back'/>
        </Link>
      </Back>
      <HeroCorporateText 
        data={data.kis}
        badge={data.kis.badge}
      />
      <Infographic />
      <CaseStudyCards />
    </Root>
  )
}

const Root = styled.div``;

const Back = styled.div`
  background-color: ${({ theme}) => theme.color.black};
  width: 100%;
  ${vw('height', 36, 54)}
  ${vw('padding-top', 12, 24)}
  ${vw('padding-right', 16, 30, 60)}
  ${vw('padding-bottom', 8, 12, 24)}
  ${vw('padding-left', 16, 30, 60)}
`;