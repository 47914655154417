import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Hero = (props) => {
  const { image, text, banner } = props;
  return (
    <Root>
      {banner && (
        <Banner>
          <img src={banner} alt="" />
        </Banner>
      )}
      <Background image={image}>
        {text && <h3 className="small white global">{text}</h3>}
      </Background>
    </Root>
  );
};

Hero.propTypes = {
  image: PropTypes.string,
  text: PropTypes.string,
  banner: PropTypes.string,
};

const Root = styled.div`
  position: relative;
`;

const Background = styled.div`
  width: 100%;
  height: ${vwMobile(240)};
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  padding: ${vwMobile(12)} ${vwMobile(16)};
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  h3 {
    max-width: ${vwMobile(120)};
    text-align: right;
  }
  @media ${media.tablet} {
    height: ${vwTablet(500)};
    padding: ${vwTablet(40)} ${vwTablet(60)};
    h3 {
      max-width: ${vwTablet(172)};
    }
  }
  @media ${media.desktop} {
    height: ${vwDesktop(720)};
    padding: ${vwDesktop(40)} ${vwDesktop(60)};
    h3 {
      max-width: ${vwDesktop(172)};
    }
  }
`;

const Banner = styled.div`
  background-color: ${({ theme }) => theme.color.skyBlue};
  padding: ${vwMobile(32)};
  img {
    width: 100%;
  }
  @media ${media.tablet} {
    padding: ${vwTablet(30)};
    position: absolute;
    top: 0;
    left: ${vwTablet(60)};
    width: ${vwTablet(200)};
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(40)};
    left: ${vwDesktop(60)};
    width: ${vwDesktop(300)};
  }
`;

export default Hero;
