import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Project/Hero';
import Parking from 'components/Project/Parking/Parking';
import HeaderText from 'components/Project/HeaderText';
import Rooftop from 'components/Project/Building/sections/Rooftop';
import Kitchen from 'components/Project/Building/sections/Kitchen';
import Bathroom from 'components/Project/Building/sections/Bathroom';
import Features from 'components/Project/Building/sections/Features';

import featuresData from 'src/data/Features/OneShaughnessyFeatures';

const Building = () => {
  return (
    <Root>
      <Hero
        image={require('src/assets/images/oneshaughnessy/one-shaughnessy-building-street.jpeg')}
      />
      <HeaderText
        title={
          <>
            Create Your
            <br />
            Dream Life
          </>
        }
        text="Live in a home uniquely designed to reflect your individuality – featuring signature architecture, unbelievable rooftop patios, and stunning views. Throughout, innovation and personal style are top of mind."
      />
      <Rooftop />
      <Kitchen />
      <Bathroom />
      <Parking />
      <Features
        data={featuresData}
        pdf="/pdf/oneshaughnessy/one-shaughnessy-feature-sheet.pdf"
      />
    </Root>
  );
};

export default Building;

const Root = styled.div`
  background-color: white;
`;
