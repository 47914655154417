import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Application = (props) => {
  const { isApp, isMembership } = props;

  const renderDisclaimer = () => {
    switch(isMembership) {
      case true:
        return (
          <>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas varius, tempor nibh finibus et. Aenean eu enim justo. Vestibulum aliquam hendrerit.
          </>
        )
        break;
      default:
        return (
          <>
            This is not an offering for sale as any such offering can only be made
            by way of disclosure statement. Square footages are based on preliminary
            measurements and are approximate. Renderings are conceptual only and may
            differ from the final product. The developer reserves the right to make
            modifications to building design, specifications and floor plans.
            E&amp;O.E
          </>
        )
    }
  }
  return (
    <Root isApp={isApp}>
      <img
        src={require('src/assets/images/global/logo/kutak-white.svg')}
        alt="Kutak"
      />
      <p className="footer disclaimer">
        {renderDisclaimer()}
      </p>
    </Root>
  );
};

Application.propTypes = {
  isApp: PropTypes.bool,
  isMembership: PropTypes.bool,
};

const Root = styled.div`
  display: ${(props) => (props.isApp ? 'block' : 'block')};
  background-color: ${({ theme }) => theme.color.black};
  padding: ${vwMobile(60)} ${vwMobile(16)} ${vwMobile(32)};
  img {
    width: ${vwMobile(134)};
    margin-bottom: ${vwMobile(24)};
    display: block;
  }
  p {
    color: rgba(255, 255, 255, 0.5);
  }
  @media ${media.tablet} {
    display: ${(props) => (props.isApp ? 'flex' : 'none')};
    align-items: flex-start;
    justify-content: space-between;
    padding: ${vwTablet(40)} ${vwTablet(30)} ${vwTablet(32)};
    img {
      width: ${vwTablet(161)};
      margin-bottom: 0;
    }
    p {
      text-align: right;
      width: ${vwTablet(400)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(40)} ${vwDesktop(60)} ${vwDesktop(32)};
    img {
      width: ${vwDesktop(161)};
    }
    p {
      width: ${vwDesktop(920)};
    }
  }
  @media print {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px 60px 32px;
    img {
      width: 161px;
    }
    .footer.disclaimer {
      max-width: 70%;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.34px;
    }
  }
`;

export default Application;
