import React from 'react';

export default [
  {
    title: 'Designed for Experience',
    list: [
      'Situated in Port Coquitlam’s vibrant downtown core',
      'Personally designed by acclaimed architect John Bingham of Bingham Hill Architects',
      'The building is a blend of traditional European design and West Coast contemporary',
      'Step inside an inviting lobby, with deep blue elements and double-height ceilings',
      'An automated parking system makes parking easy, and gives you confidence knowing your vehicle is secured',
      'EV charging is available',
    ],
  },
  {
    title: 'Feels like Home',
    list: [
      "9' ceilings in all homes",
      'Luxury vinyl tile flooring throughout',
      'Homes with both private and shared outdoor spaces',
      'Select homes have private rooftop deck space with private staircase access',
      'Quality roller shades',
      <>
        Smart home upgrade:
        <ul>
          <li>Smart control hub</li>
          <li>Programmable lighting</li>
          <li>Motorized window coverings</li>
          <li>Integrated speakers and music system</li>
          <li>Security system</li>
          <li>Telus PureFibre network</li>
        </ul>
      </>,
    ],
  },
  {
    title: 'A Legacy of Artistry and Craftsmanship',
    list: [
      'As a homeowner, our Kutak Membership (ME) gives you access to timely and accurate updates on the progress of your home’s construction. Learn all about your new neighbourhood, local businesses, nearby parks, and schools in your catchment.',
      'Once you move in, information on your home, including product and warranty details, will be easily available.',
      'You will have the ability to choose from a curated selection of flooring and cabinetry types, giving you a completely new home ownership experience.',
      'Under our ME philosophy, Kutak will always be connected to the homes, caring about their value and wellbeing.',
    ],
  },
  {
    title: 'Delve into the World of Gourmet',
    list: [
      <>
        High-end appliance package, including:
        <ul>
          <li>
            Fisher Paykel 36” integrated panel refrigerator (32” in Studios)
          </li>
          <li>GE Profile 30" induction range</li>
          <li>GE Profile 24” dishwasher with integrated panel</li>
          <li>Slim telescopic hood fan</li>
        </ul>
      </>,
      'Quality kitchen cabinetry, extending to the ceiling, locally sourced and tailored to your personality',
      'Integrated under-cabinet lighting',
      'Soft-close drawers',
      'Engineered quartz stone countertop and backsplash',
      'Kohler plumbing fixtures',
    ],
  },
  {
    title: 'Look Forward to the Start and End of Your Day',
    list: [
      'Large-format porcelain tiles',
      'Tiled ceiling above all showers and bathtubs',
      'Kohler plumbing fixtures',
      'Quartz stone countertop',
      'Large mirrors and medicine cabinet with integrated shelf',
    ],
  },
  {
    title: 'Sitting on Top of the World',
    list: [
      'Sky Lounge - a 2,100 SF outdoor oasis',
      'Community dining area with BBQ',
      'Community gardens',
      'Expansive lounging and seating areas',
      '360° views of the Fraser Valley',
      'Elevator access to the rooftop deck',
    ],
  },
];
