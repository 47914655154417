import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import vw from 'src/styles/utils';

export default function ConfigCallout() {
  return (
    <Link to="/design" target="_blank">
      <Root>
        <div>
          <img
            src={require('src/assets/images/global/icon/kutak/kutak-xp.svg')}
            alt="kutak xp"
          />
          <div>
            <h3 className="project gold">Make it Yours</h3>
            <Link to="/design" className="text-links gold" target="_blank">
              Launch Home Configurator
              <img
                src={require('src/assets/images/global/icon/action/chevron-right-gold.svg')}
                alt="chevron right gold"
                className="arrow"
              />
            </Link>
          </div>
        </div>
      </Root>
    </Link>
  );
}

const Root = styled.div`
  ${vw('width', 320, 768, 480)}
  ${vw('height', 116, 160, 180)}
  ${vw('padding-top', 24, 30, 40)}
  ${vw('padding-left', 12, 60, 66)}
  ${vw('padding-bottom', 24, 30, 40)}
  background-color: ${({ theme }) => theme.color.black};
  cursor: pointer;

  &:hover {
        .arrow {
          transform: translateX(6px);
        }
      }

  >div {
    display: flex;
    img {
      ${vw('width', 68, 100)}
      ${vw('height', 68, 100)} 
    }
    >div {
      ${vw('margin-left', 10, 12)}
    }
    h3 {
      ${vw('margin-bottom', 6, 10)}
    }

    a {
      display: flex;
      align-items: center;
      img {
        position: relative;
        margin-left: 4px;
        transition: 0.3s ease-in-out;
        ${vw('width', 12, 12)}
        ${vw('height', 12, 12)}
      }
    }
  }
`;
