import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import media from 'src/styles/media';
import vw, { vwMobile, vwDesktop } from 'src/styles/utils';

const Features = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const { data, pdf } = props;

  const handleClick = (index) => {
    setActiveIndex(index);
  };
  return (
    <Root>
      <Wrapper>
        <h2 className="global gold uppercase">Features and Finishes</h2>
        <h3 className="project white">
          Features that
          <br />
          Elevate Everything
        </h3>
      </Wrapper>
      <Content>
        {data.map((feature, key) => {
          return (
            <Feature
              onClick={() => handleClick(key)}
              key={key}
              isActive={activeIndex === key}
            >
              <Title>
                <h5 className="global white uppercase">{feature.title}</h5>
                <img
                  src={require('src/assets/images/global/icon/action/chevron-up-gold.svg')}
                  alt="chevron"
                  style={{
                    transform: activeIndex === key ? 'scaleY(1)' : 'scaleY(-1)',
                  }}
                />
              </Title>
              <ul>
                {feature.list.map((item, itemKey) => {
                  return (
                    <li key={itemKey} className="body3 white">
                      <span>{item}</span>
                    </li>
                  );
                })}
              </ul>
            </Feature>
          );
        })}
      </Content>
      <Download href={pdf} target="_blank" rel="noopener noreferrer">
        <Button label="Download Feature Sheet" type="secondary-gold" />
      </Download>
    </Root>
  );
};

Features.propTypes = {
  data: PropTypes.array,
  pdf: PropTypes.string,
};

export default Features;

const Root = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.black};
  ${vw('padding-top', 60, 80, 100)}
  ${vw('padding-right', 16, 60, 120)}
  ${vw('padding-bottom', 60, 80, 60)}
  ${vw('padding-left', 16, 60)}
  h2 {
    ${vw('margin-bottom', 16, 20)}
  }
  h3 {
    ${vw('margin-bottom', 49, 65)}
  }
  @media ${media.desktop} {
    display: grid;
    grid-template-rows: ${vwDesktop(148)} auto;
    grid-template-columns: ${vwDesktop(300)} auto;
    grid-column-gap: ${vwDesktop(180)};
  }
`;

const Wrapper = styled.div`
  @media ${media.desktop} {
    grid-row: 1;
    grid-column: 1;
  }
`;

const Feature = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.gold};
  &:last-of-type {
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.color.gold};
    ${vw('margin-bottom', 57, 85)}
  }
  ul {
    margin-left: -25px;
    padding-left: 50px;
    overflow: hidden;
    height: ${(props) => (props.isActive ? 'auto' : '0')};
    ${(props) => (props.isActive ? vw('max-height', 408) : vw('max-height', 0))}
    ${(props) => (props.isActive ? vw('margin-bottom', 24, 40) : '')}
    transition-duration: 0.3s;
    transition: all 0.3s ease;
    ${vw('margin-left', -25, -25, 0)}
    ${vw('padding-left', 50, 50, 0)}
    li {
      position: relative;
      list-style: none;
      &:before {
        content: '';
        border-radius: 50%;
        position: absolute;
        background-color: white;
        ${vw('top', 9)}
        ${vw('left', -20)}
        ${vw('width', 4)}
        ${vw('height', 4)}
      }
      &:not(:last-of-type) {
        ${vw('margin-bottom', 12)}
      }
    }
    ul {
      list-style-type: disc;
      ${vw('margin-top', 12)}
      ${vw('margin-bottom', 0)}
      ${vw('margin-left', -20)}
      ${vw('padding-left', 50)}
      li {
        &:before {
          content: '';
          border-radius: 0;
          position: absolute;
          background-color: white;
          ${vw('top', 9)}
          ${vw('left', -20)}
          ${vw('width', 4)}
          ${vw('height', 2)}
        }
        &:not(:last-of-type) {
          ${vw('margin-bottom', 12)}
        }
        &:last-of-type {
          ${vw('margin-bottom', 0)}
        }
      }
    }
  }
  @media ${media.desktop} {
    border-top: none;
    width: ${vwDesktop(316)};
    margin-bottom: ${vwDesktop(40)};
    &:nth-of-type(n+4) {
      margin-left: ${vwDesktop(110)};
    }
    &:last-of-type {
      border-bottom: none;
    }
    ul {
      height: auto;
      max-height: none;
      overflow: visible;
      margin: 0;
    }
  }
`;

const Title = styled.div`
  position: relative;
  h5 {
    width: 100%;
    width: ${vwMobile(260)};
    ${vw('padding-top', 16, 24)}
    ${vw('margin-bottom', 16, 24, 12)}
  }
  img {
    transition: all 0.3s ease;
    position: absolute;
    right: 0;
    ${vw('top', 20, 27)}
    ${vw('width', 12, 16)}
    ${vw('height', 12, 16)}
  }
  @media ${media.desktop} {
    h5 {
      width: auto;
    }
  }
  @media ${media.desktop} {
    h5 {
      padding: 0;
    }
    img {
      display: none;
    }
  }
`;

const Content = styled.div`
  @media ${media.desktop} {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: ${vwDesktop(742)};
    height: ${vwDesktop(1463)};

    margin-top: ${vwDesktop(56)};
    grid-row: 1 / 3;
    grid-column: 2;
  }
`;

const Download = styled.a`
  @media ${media.desktop} {
    grid-row: 2;
    grid-column: 1;
    margin-top: ${vwDesktop(40)};
  }
`;
