import React from 'react';
import styled from 'styled-components';

import Container from 'components/Project/Views/Container';

const Views = () => {
  return (
    <Root>
      <Container
        data={[
          {
            level: '2',
            src: require('src/assets/images/oneshaughnessy/views/kutak-view-level-2.jpg'),
          },
          {
            level: '3',
            src: require('src/assets/images/oneshaughnessy/views/kutak-view-level-3.jpg'),
          },
          {
            level: '4',
            src: require('src/assets/images/oneshaughnessy/views/kutak-view-level-4.jpg'),
          },
          {
            level: '5',
            src: require('src/assets/images/oneshaughnessy/views/kutak-view-level-5.jpg'),
          },
          {
            level: '6',
            src: require('src/assets/images/oneshaughnessy/views/kutak-view-level-6.jpg'),
          },
          {
            level: 'R',
            src: require('src/assets/images/oneshaughnessy/views/kutak-view-rooftop.jpg'),
          },
        ]}
        buildingImage={require('src/assets/images/oneshaughnessy/views/building-graphic.png')}
        buildingSvg={{
          width: '200px',
          height: '140px',
          viewBox: '0 0 200 140',
          levels: [
            <polygon
              id="level-2"
              fill-opacity="0.8"
              fill="#B18B6E"
              points="21.1128708 108.311547 21.1128708 100.289125 27.8152679 100.289125 27.8152679 92.9628709 24.8928612 92.9628709 24.8928612 91.5676125 170.114589 91.5676125 170.114589 99.93958 175.91567 99.93958 175.91567 105.540844 176.43526 105.540844 176.43526 108.311547"
            ></polygon>,
            <polygon
              id="level-3"
              fill-opacity="0.8"
              fill="#B18B6E"
              points="170.121253 74.7187725 170.121253 83.7585453 175.91567 83.7585453 175.91567 91.5676125 20.4464629 91.5676125 20.4464629 83.7585453 27.8077708 83.7585453 27.8077708 76.213722 24.8131005 76.213722 24.8131005 74.6695527"
            ></polygon>,
            <polygon
              id="level-4"
              fill-opacity="0.8"
              fill="#B18B6E"
              points="170.065858 57.9489764 170.065858 67.0331721 175.91567 67.0331721 175.91567 74.7187725 20.4464629 74.7187725 20.4464629 67.0331721 27.6944815 67.0331721 27.6944815 59.4677016 24.8936942 59.4677016 24.8936942 57.9489764"
            ></polygon>,
            <polygon
              id="level-5"
              fill-opacity="0.8"
              fill="#B18B6E"
              points="20.4464629 57.9489764 20.4464629 50.2512795 27.7092674 50.2512795 27.7092674 39.9080143 43.7957286 39.9080143 43.7957286 36.6736844 78.4726785 36.6736844 78.4726785 40.3361855 170.131666 40.3361855 170.131666 50.2512795 175.91567 50.2512795 175.91567 57.9489764"
            ></polygon>,
            <polygon
              id="level-6"
              fill="#B18B6E"
              opacity="0.795549665"
              points="27.2538193 34.2495222 27.2538193 36.0467466 27.7499329 36.0467466 27.7499329 39.9090571 43.7882972 39.9090571 43.7882972 36.6828183 78.5711626 36.6828183 78.5711626 40.3361855 170.131666 40.3361855 170.131666 36.4692541 168.358412 36.4692541 168.358412 32.1414664 158.64734 32.1414664 158.64734 24.6619747 163.339506 24.6619747 163.339506 22.9045827 158.275461 22.9045827 158.275461 19.2909597 153.196094 19.2909597 153.196094 17.4848147 98.1927051 17.4848147 98.1927051 23.0470195 29.9037414 23.0470195 29.9037414 24.4484105 34.5087768 24.4484105 34.5087768 32.1414664 29.2774693 32.1414664 29.2774693 34.2495222"
            ></polygon>,
            <polygon
              id="level-r"
              fill-opacity="0.8"
              fill="#B18B6E"
              points="35.0599545 23.0633455 35.0599545 19.364138 36.1074643 19.364138 36.1074643 15.4106974 36.6174746 15.4106974 36.6174746 12.0641628 41.9839322 12.0641628 41.9839322 6.87188289 67.5585836 6.87188289 67.5585836 12.0641628 76.993253 12.0641628 76.993253 4.53831845 115.12282 4.53831845 115.12282 15.1404052 157.143585 15.1404052 157.143585 19.364138 153.27238 19.364138 153.27238 17.4848147 98.1927051 17.4848147 98.1927051 23.0470195"
            ></polygon>,
          ],
        }}
        angleAddition={80}
        defaultLevel={5}
        defaultAngle={213}
      />
    </Root>
  );
};

const Root = styled.div``;

export default Views;
