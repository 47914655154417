import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import View360 from './elements/View360';
import LevelSelect from './elements/LevelSelect';
import Building from './elements/Building';
import Compass from './elements/Compass';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Container = (props) => {
  const [activeLevel, setActiveLevel] = useState(0);
  const [angle, setAngle] = useState(180);
  const [hasDragged, setHasDragged] = useState(false);

  const {
    data,
    buildingImage,
    angleAddition,
    defaultAngle,
    buildingSvg,
    defaultLevel,
  } = props;

  useEffect(() => {
    if (defaultLevel) {
      setActiveLevel(defaultLevel);
    }
  }, [defaultLevel]);

  const handleLevel = (index) => {
    setActiveLevel(index);
  };

  const handleDrag = () => {
    setTimeout(() => setHasDragged(true), 400);
  };

  return (
    <Root>
      <LevelSelect
        data={data}
        activeLevel={activeLevel}
        handleLevel={handleLevel}
      />
      <Wrapper>
        <View360
          src={data[activeLevel].src}
          onUpdate={(angle) => setAngle(angle)}
          defaultAngle={defaultAngle}
          onStart={handleDrag}
        />
        <div className="drag" style={{ opacity: hasDragged ? 0 : 1 }}>
          {/* <img
            src={require('src/assets/images/global/icon/nav/circle-arrow.svg')}
            alt="Drag"
          /> */}
          <h5 className="global white uppercase">drag to pan the view</h5>
          {/* <img
            src={require('src/assets/images/global/icon/nav/circle-arrow.svg')}
            alt="Drag"
          /> */}
        </div>
      </Wrapper>
      <Div>
        <h5 className="global white uppercase desktop">Select a Level</h5>
        <Building
          image={buildingImage}
          buildingSvg={buildingSvg}
          activeLevel={activeLevel}
        />
        <Compass angle={angle} angleAddition={angleAddition} />
      </Div>
    </Root>
  );
};

Container.propTypes = {
  data: PropTypes.array,
  buildingImage: PropTypes.string,
  angleAddition: PropTypes.number,
  defaultAngle: PropTypes.number,
  defaultLevel: PropTypes.number,
  buildingSvg: PropTypes.object,
};

const Root = styled.div`
  padding: ${vwMobile(40)} 0;
  @media ${media.tablet} {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    position: relative;
    padding: ${vwTablet(80)} 0;
  }
  @media ${media.desktop} {
    display: grid;
    grid-template-columns: ${vwDesktop(124)} ${vwDesktop(1316)};
    grid-template-rows: ${vwDesktop(220)} ${vwDesktop(680)};
    padding: ${vwDesktop(40)} 0 ${vwDesktop(100)};
    /* align-items: flex-start; */
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: ${vwMobile(200)};
  position: relative;
  .drag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease;
    pointer-events: none;
    text-align: center;
    img {
      display: none;
    }
  }
  @media ${media.tablet} {
    height: ${vwTablet(500)};
    order: 3;
    .drag {
      justify-content: space-between;
      padding: 0 ${vwTablet(60)};
      img {
        display: block;
        width: ${vwTablet(40)};
      }
      img:last-of-type {
        transform: scaleX(-1);
      }
    }
    h5 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  @media ${media.desktop} {
    width: ${vwDesktop(1316)};
    height: ${vwDesktop(680)};
    grid-row: 2;
    grid-column: 2;
    .drag {
      padding: 0 ${vwDesktop(60)};
      img {
        width: ${vwDesktop(40)};
      }
    }
  }
`;

const Div = styled.div`
  display: flex;
  margin: ${vwMobile(24)} ${vwMobile(16)};
  .desktop {
    display: none;
  }
  @media ${media.tablet} {
    order: 2;
    margin: 0 0 ${vwTablet(40)} ${vwTablet(176)};
  }
  @media ${media.desktop} {
    margin: 0 0 ${vwDesktop(40)};
    padding-right: ${vwDesktop(60)};
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    order: 1;
    width: 100%;
    grid-row: 1;
    grid-column: 2;
    .desktop {
      display: block;
    }
  }
`;

export default Container;
