import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';

function Input(props) {
  const { inputRef, label, required, name, size, maxLength } = props;

  return (
    <Root>
      <label>
        {label} {required && '*'}
      </label>
      <input
        type="text"
        name={name}
        size={size}
        maxLength={maxLength}
        ref={inputRef && inputRef}
        required={required}
      />
    </Root>
  );
}

Input.propTypes = {
  inputRef: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  size: PropTypes.string,
  maxLength: PropTypes.string,
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${vw('margin-top', 23, 24)};

  label {
    font-family: 'acumin-pro-extra-condensed';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.gold};
    ${vw('font-size', 16, 18)};
    ${vw('letter-spacing', 2.31, 2.6)};
    ${vw('margin-bottom', 4)};
  }

  input {
    appearance: none;
    background: none;
    outline: none;
    font-family: futura-pt;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: white;
    border: solid 1px ${({ theme }) => theme.color.gold};
    ${vw('height', 46, 50)};
    ${vw('font-size', 16, 18)};
    ${vw('line-height', 24)};
    ${vw('letter-spacing', 0, 0.5)};
    ${vw('padding-left', 12, 17)};

    &:focus {
      border: solid 1px white;
    }
  }
`;

export default Input;
