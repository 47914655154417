import { createGlobalStyle } from 'styled-components';
import media from 'src/styles/media';
import vw from 'src/styles/utils';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  body {
    font-family: 'Roboto';
  }

  a,
  button {
    display: inline-block;
    outline: none;
    border: none;
    background: transparent;
    text-decoration: none;
    color: ${({ theme }) => theme.color.primary};
  }

  main {
    text-align: center;
    position: absolute;
    top: 120px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  @font-face {
    font-family: 'Roboto';
    src: url('/assets/fonts/Roboto-Regular.woff2') format('woff2'),
      url('/assets/fonts/Roboto-Regular.woff') format('woff');
  }

  /* 
    Kutak Corporate Typography 
  */

  .white {
    color: ${({ theme }) => theme.color.white};
  }
  .black {
    color: ${({ theme }) => theme.color.black};
  }
  .gold {
    color: ${({ theme }) => theme.color.gold};
  }
  .grey {
    color: ${({ theme }) => theme.color.textGrey};
  }
  .uppercase {
    text-transform: uppercase;
  }
  .bold {
    font-weight: 500;
  }
  .hoverGold {
    transition: all 0.3s ease;
    g {
      transition: all 0.3s ease;
    }
    &:hover {
      color: ${({ theme }) => theme.color.gold};
      g {
        fill: ${({ theme }) => theme.color.gold};
      }
    }
  }
  .hoverWhite {
    transition: all 0.3s ease;
    g {
      transition: all 0.3s ease;
    }
    &:hover {
      color: ${({ theme }) => theme.color.white};
      g {
        fill: ${({ theme }) => theme.color.white};
      }
    }
  }
  .hoverOpacity {
    transition: all 0.3s ease;
    &:hover {
      opacity: 0.8;
    }
  }
  h1 {
    &.corporate {
      font-family: 'acumin-pro-extra-condensed';
      font-weight: 300;
      ${vw('font-size', 44, 72)}
      ${vw('line-height', 44, 72)}
      ${vw('letter-spacing', 2, 3)}
    }

    &.project {
      font-family: 'acumin-pro-extra-condensed';
      font-weight: 300;
      ${vw('font-size', 44, 66)}
      ${vw('line-height', 44, 66)}
      ${vw('letter-spacing', 1, 2)}
    }

    &.configurator {
      font-family: 'acumin-pro-extra-condensed';
      font-weight: 300;
      line-height: 1;
      ${vw('font-size', 34, 46)};
      ${vw('letter-spacing', 1.33, 1.8)} 
    }
  }

  h2 {
    &.global {
        font-family: 'futura-pt';
        font-weight: 500;
        ${vw('font-size', 16, 18)}
        ${vw('line-height', 22, 24)}
        ${vw('letter-spacing', 3.56, 4)}
    }
  }

  h3 {
    &.corporate {
      font-family: 'acumin-pro-extra-condensed';
      font-weight: 300;
      ${vw('font-size', 36, 56)}
      ${vw('line-height', 36, 56)}
      ${vw('letter-spacing', 0.64, 1)}
    }
    &.small {
      font-family: 'acumin-pro-extra-condensed';
      font-weight: 500;
      ${vw('font-size', 20, 30)}
      ${vw('line-height', 22, 36)}
      ${vw('letter-spacing', 0.83, 1)}
    }
    &.project {
      font-family: 'acumin-pro-extra-condensed';
      font-weight: 300;
      ${vw('font-size', 38, 52)}
      ${vw('line-height', 38, 52)}
      ${vw('letter-spacing', 0.5, 1)}
    }
    &.configurator {
      font-family: 'acumin-pro-extra-condensed';
      font-weight: 300;
      ${vw('font-size', 20, 24)}
      ${vw('line-height', 20, 24)}
      ${vw('letter-spacing', 0.71, 0.86)}
    }
  }

  h4 {
    &.global {
      font-family: 'acumin-pro-extra-condensed';
      font-weight: normal;
      ${vw('font-size', 30, 36)}
      ${vw('line-height', 30, 38)}
      ${vw('letter-spacing', 0.83, 1)}
    }
  }

  h5 {
    &.global {
      font-family: 'futura-pt';
      font-weight: 500;
      ${vw('font-size', 14, 16)}
      ${vw('line-height', 20, 22)}
      ${vw('letter-spacing', 3.11, 3.56)}
    }
  }


  /* Body */

  .highlight {
    font-family: 'futura-pt';
    font-weight: 500;
    ${vw('font-size', 20, 20, 26)}
    ${vw('line-height', 28, 28, 34)}
    ${vw('letter-spacing', 0, 0)}
  }
  .intro {
    font-family: 'futura-pt';
    font-weight: normal;
    ${vw('font-size', 18, 26)}
    ${vw('line-height', 26, 36)}
    ${vw('letter-spacing', 0, 1)}
  }
  .body1 {
    font-family: 'futura-pt';
    font-weight: normal;
    ${vw('font-size', 18, 20)}
    ${vw('line-height', 26, 32)}
    ${vw('letter-spacing', 0, 0)}
  }
  .body2{
    font-family: 'futura-pt';
    font-weight: normal;
    ${vw('font-size', 16, 18)}
    ${vw('line-height', 22, 26)}
    ${vw('letter-spacing', 0.44, 0.5)} 
  }
  .body3 {
    font-family: 'futura-pt';
    font-weight: normal;
    ${vw('font-size', 14, 16)}
    ${vw('line-height', 20, 22)}
    ${vw('letter-spacing', 0.4, 0.8)}
  }

  .body4 {
    font-family: 'futura-pt';
    font-weight: normal;
    ${vw('font-size', 14, 14)}
    ${vw('line-height', 20, 20)}
    ${vw('letter-spacing', 0.4, 0.4)}
  }

  .body-intro {
    font-family: 'futura-pt';
    font-weight: normal;
    ${vw('font-size', 18, 26, 26)}
    ${vw('line-height', 26, 36, 36)}
    ${vw('letter-spacing', 0, 1, 1)}
  }

  /* Globals */

  .infographic.heading {
    font-family: 'acumin-pro-extra-condensed';
    font-weight: normal;
    color: ${({ theme }) => theme.color.gold};
    ${vw('font-size', 100, 100)}
    ${vw('line-height', 100, 100)}
    ${vw('letter-spacing', 2.78, 2.78)}
  }
  .infographic.subheading {
    font-family: 'acumin-pro-extra-condensed';
    font-weight: 500;
    font-size: 22px;
    ${vw('font-size', 22, 22)}
    ${vw('line-height', 28, 28)}
    ${vw('letter-spacing', 2, 2)}
  }
  .infographic.subheading.gold {
    color: ${({ theme }) => theme.color.gold};
  }
  .infographic.subheading.black {
    color: ${({ theme }) => theme.color.black};
  }

  .text-links {
    font-family: 'futura-pt';
    font-weight: 500;
    ${vw('font-size', 18, 18)}
    ${vw('line-height', 22, 22)}
    ${vw('letter-spacing', 0, 0)};
  }

  .button {
    font-family: 'futura-pt';
    font-weight: 500;
    ${vw('font-size', 16, 16)}
    ${vw('line-height', 20, 20)}
    ${vw('letter-spacing', 3, 3)}
  }

  .project-nav {
    font-family: 'futura-pt';
    font-weight: 500;
    ${vw('font-size', 15, 15)}
    ${vw('letter-spacing', 3, 3)}
  }

  .footer.body {
    font-family: 'futura-pt';
    font-weight: 500;
    ${vw('font-size', 14, 14)}
    ${vw('line-height', 14, 14)}
    ${vw('letter-spacing', 2, 2)}
  }

  .footer.footnote {
    font-family: 'futura-pt';
    font-weight: 500;
    ${vw('font-size', 12, 12)}
    ${vw('letter-spacing', 3, 3)}
  }

  .footer.disclaimer {
    font-family: 'futura-pt';
    font-weight: normal;
    color: rgba(255, 255, 255, 0.5);
    ${vw('font-size', 12, 12)}
    ${vw('line-height', 14, 14)}
    ${vw('letter-spacing', 0.34, 0.34)};
  }

  .nav {
    font-family: 'futura-pt';
    font-weight: 500;
    ${vw('font-size', 18, 22, 14)}
    ${vw('letter-spacing', 5.18, 6.29, 4)}
    &.breadcrumb {
      ${vw('font-size', 12, 14)}
      ${vw('letter-spacing', 3, 4)}
    }
    &.configurator {
      font-family: 'acumin-pro-extra-condensed';
      font-weight: 500;
      ${vw('font-size', 20, 24)}
      ${vw('letter-spacing', 0.83, 1)}
      ${vw('line-height', 20, 24)}
    }
    @media ${media.tablet} {
      &.configurator {
        line-height: normal;
      }
      &.corporate {
        ${vw('font-size', 14, 14)}
        ${vw('letter-spacing', 4, 4)}
      }
    }
  }

  .floorplan-label {
    font-family: 'acumin-pro-extra-condensed';
    font-weight: 200;
    ${vw('font-size', 78, 98, 114)}
    ${vw('line-height', 70, 84, 104)}
    ${vw('letter-spacing', 4, 5, 5.82)}
  }

  .plan-type {
    font-family: 'acumin-pro-extra-condensed';
    font-weight: 200; 
    ${vw('font-size', 30, 36, 44)}
    ${vw('line-height', 30, 36, 44)}
    ${vw('letter-spacing', 0.5, 0.63, 0.77)}
  }

  .plan-details {
    font-family: 'futura-pt';
    font-weight: 500; 
    ${vw('font-size', 12, 14, 16)}
    ${vw('line-height', 16, 18, 20)}
    ${vw('letter-spacing', 1.2, 1.4, 1.6)}
  }

  .configurator {
    .nav {
      /*
      TODO: Mobile
      font-family: 'acumin-pro-extra-condensed';
      font-weight: 500;
      ${vw('font-size', 24)}
      ${vw('letter-spacing', 1)}
      */
    }
    .nav-active {
      font-family: 'acumin-pro-extra-condensed';
      font-weight: 500;
      color: #696969;
      text-align: center;
      ${vw('font-size', 24, 24)}
      ${vw('letter-spacing', 1, 1)}

      @media ${media.tablet} {
        text-align: left;
      }
    }
    .active {
      /*
      TODO: Mobile
      font-family: 'acumin-pro-extra-condensed';
      font-weight: normal;
      ${vw('font-size', 24)}
      ${vw('letter-spacing', 4)}
      color: #000000
      */
    }
    .prompt {
      /*
      TODO: Mobile
      font-family: 'futura-pt';
      font-weight: 500;
      ${vw('font-size', 15)}
      ${vw('letter-spacing', 3.33)}
      */
    }
    &.caption {
      font-family: 'futura-pt';
      font-weight: 600;
      ${vw('font-size', 12)}
      ${vw('line-height', 16)}
      ${vw('letter-spacing', 1)}
     
    }
  }
  .symbol-organizer {
    .group-title {
      font-family: 'SFProText';
      font-weight: bold;
      color: #000000;
      ${vw('font-size', 20, 20)}
      ${vw('line-height', 24, 24)}
    }
  }

  .map-number {
    font-family: 'futura-pt';
    font-weight: 500;
    ${vw('font-size', 12)}
    ${vw('letter-spacing', 0)}
    text-align: center;     
   
  }

  .level-select {
    font-family: 'acumin-pro-extra-condensed';
    font-weight: 600;
    ${vw('font-size', 22, 22)}
    ${vw('line-height', 22, 22)}
    ${vw('letter-spacing', 0, 0)}
  }

  .form-label {
    font-family: 'acumin-pro-extra-condensed';
    font-weight: 500;
    ${vw('font-size', 18, 18)}
    ${vw('line-height', 18, 18)}
    ${vw('letter-spacing', 2.5, 2.5)}
  }
`;
