import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Hero from 'components/Project/Hero';
import Input from 'components/Project/Form/Input';
import Select from 'components/Project/Form/Select';
import Button from 'components/Button';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';
import Insight from 'src/plugins/insight';
import { getSearchEmail, getRegistrant } from 'src/utils/sendLasso';
import { result } from 'lodash';
import oneshaughnessyToLassoData from 'src/data/Configurator/oneshaughnessy';

const Register = () => {
  const activeProject = useSelector((state) => state.project.activeProject);

  const $firstName = React.createRef();
  const $lastName = React.createRef();
  const $email = React.createRef();
  // const $phone = React.createRef();
  const $zipCode = React.createRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = $firstName.current.value + ' ' + $lastName.current.value;

    const email = $email.current.value;
    const pageName = 'One - New Registrant - ' + $zipCode.current.value;

    await Insight({ name: name, email: email, pageName: pageName });
    const notes = await sendNoteToLasso(email);
    document.getElementById('note').value = await notes;
    await document.getElementById('form').submit();
  };

  const sendNoteToLasso = async (email) => {
    let param = {
      email: email,
    };
    const data = JSON.stringify(param);

    const postToDB = await fetch(
      'https://bam-cors-anywhere.herokuapp.com/https://i.bamcommunications.ca/server/project/public/api/registrant/config/email',
      {
        method: 'POST',
        body: data,
      }
    );

    try {      
      let postToDBJson = await postToDB.json();
      let tmpIDValue = 0;
      let tmpIndex = 0;
      postToDBJson.map( (post, index) => {
        if (parseInt(post.id) > tmpIDValue) {
          tmpIndex = index;
          tmpIDValue = parseInt(post.id);
        }
      })

      return covertStringNote(postToDBJson[tmpIndex]);
    } catch (e) {}
  };

  const covertStringNote = (object) => {
    let result = `KITCHEN: ${
      oneshaughnessyToLassoData.kitchen.palette[object.kitchen_palette].name
    } + ${
      oneshaughnessyToLassoData.kitchen.flooring[object.kitchen_flooring].name
    }
BATH: ${oneshaughnessyToLassoData.bath.palette[object.bath_palette].name} + ${
      oneshaughnessyToLassoData.bath.ceiling[object.bath_ceiling].name
    }
PLANS: ${object.plans}
UPGRADES: ${object.upgrade_features}
LINK: https://www.kutakdevelopment.com/design?id=${object.query_string}`;

    return result;
  };

  const handleClick = async () => {
    const data = JSON.stringify({});

    const postToDB = await fetch(
      'https://bam-cors-anywhere.herokuapp.com/https://i.bamcommunications.ca/server/project/public/api/registrant/config/all',
      {
        method: 'POST',
        body: data,
      }
    );

    try {
      let postToDBJson = await postToDB.json();
      postToDBJson.reverse().map(async (postJson) => {
        const result = await getSearchEmail(postJson.email);

        if (result.items.length !== 0) {
          // const update = await updateRegistrantNotes(result.items[0].registrantId, covertStringNote(postJson));
        }
      })
    } catch(e) {
    }
  }

  return (
    <Root>
      <Hero
        image={require('src/assets/images/oneshaughnessy/one-shaugnessey-aerial.jpg')}
        text="Coming Soon To Port Coquitlam"
        banner={require('src/assets/images/oneshaughnessy/one-shaughnessy-lockup.svg')}
      />
      <Container>
        <Intro>
          <p className="body-intro white">
            Live in a home uniquely designed to reflect your individuality –
            featuring signature architecture, unbelievable rooftop patios, and
            stunning views.
          </p>
          <Vertical>
            <img
              src={require('src/assets/images/global/icon/kutak/kutak-xp-me-kis-vertical.svg')}
              alt=""
            />
            <img
              src={require('src/assets/images/global/icon/kutak/intelligent-personal-text.svg')}
              alt=""
            />
          </Vertical>
        </Intro>
        <h1 className="project white">
          Register To
          <br /> Find Out More
        </h1>
        <form
          method="POST"
          action="http://app.lassocrm.com/registrant_signup/"
          id="form"
          onSubmit={handleSubmit}
        >
          <Fields>
            <Input
              label="First name"
              name="FirstName"
              id="first_name"
              inputRef={$firstName}
              required
            />
            <Input
              label="Last name"
              name="LastName"
              id="last_name"
              inputRef={$lastName}
              required
            />
            <Input
              label="Email address"
              name="Emails[Primary]"
              id="email"
              inputRef={$email}
              required
            />
            <Input
              label="Phone number"
              name="Phones[Home]"
              id="phone"
              required
            />
            <Input
              label="Postal code"
              name="PostalCode"
              size="20"
              maxLength="20"
              inputRef={$zipCode}
              required
            />
            <Select
              label="how did you hear about us?"
              name="Questions[85418]"
              options={[
                { title: 'Online Search', value: '268570' },
                { title: 'Facebook/Instagram', value: '268571' },
                { title: 'Friend/Referral', value: '268572' },
                { title: 'Realtor', value: '268573' },
                { title: 'Signage', value: '268574' },
                { title: 'Banner Ad', value: '268575' },
                { title: 'MLS', value: '268576' },
                { title: 'Magazine', value: '268577' },
                { title: 'Newspaper', value: '268578' },
                { title: 'Email', value: '268579' },
                { title: 'Other', value: '268580' },
              ]}
            />
            <Select
              label="are you working with a realtor?"
              name="Questions[53829]"
              options={[
                { title: 'Yes', value: '226113' },
                { title: 'No', value: '226114' },
              ]}
            />
            <Select
              label="are you a realtor?"
              name="Questions[85450]"
              options={[
                { title: 'Yes', value: '268615' },
                { title: 'No', value: '268616' },
              ]}
            />
          </Fields>

          <p className="body4 white">
            This project is marketed by Momentum Realty Inc. for the project’s
            developer. By submitting this form, you agree that Momentum Realty
            Inc. and its affiliated companies may contact you with information
            about this project and other projects that may be of interest.
          </p>

          <Button type="submit" label="Submit" />
          
          <textarea name="Comments" cols="X" rows="Y" id="note"></textarea>
          <input type="hidden" name="LassoUID" value="BeLnU7iJcZ" />
          <input type="hidden" name="ClientID" value="1496" />
          <input type="hidden" name="ProjectID" value="12852" />
          <input
            type="hidden"
            name="SignupThankyouLink"
            value={window.location.origin + `/homes/${activeProject}/thankyou`}
          />
          <input type="hidden" name="SignupEmailLink" value="" />
          <input type="hidden" name="SignupEmailSubject" value="" />
          <input type="hidden" name="RatingID" value="15591683" tag="N" />
          <input type="hidden" name="SecondarySourceTypeID" value="222560" />
          <input
            type="hidden"
            name="SourceTypeID"
            value=" 7211593"
            tag="Online Registration"
          />
          <input type="hidden" name="domainAccountId" value="LAS-239091-08" />
          <input type="hidden" name="guid" value="" />
        </form>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  #note {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }
  @media ${media.desktop} {
    br {
      display: none;
    }
  }
`;

const Container = styled.div`
  padding: ${vwMobile(40)} ${vwMobile(16)} ${vwMobile(80)};
  .body4 {
    margin: ${vwMobile(24)} 0 ${vwMobile(40)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(60)} ${vwTablet(60)} ${vwTablet(120)};
    form {
      width: ${vwTablet(500)};
    }
    .body4 {
      margin: ${vwTablet(32)} 0 ${vwTablet(60)};
    }
    h1 {
      margin-bottom: ${vwTablet(36)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(60)} ${vwDesktop(60)} ${vwDesktop(120)};
    form {
      width: ${vwDesktop(1040)};
    }
    .body4 {
      margin: ${vwDesktop(32)} 0 ${vwDesktop(60)};
    }
    h1 {
      margin-bottom: ${vwDesktop(36)};
    }
  }
`;

const Intro = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: ${vwMobile(40)};
  @media ${media.tablet} {
    margin-bottom: ${vwTablet(120)};
    p {
      max-width: ${vwTablet(424)};
    }
  }
  @media ${media.desktop} {
    margin-bottom: ${vwDesktop(120)};
    p {
      max-width: ${vwDesktop(640)};
    }
  }
`;

const Vertical = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  @media ${media.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    img:first-of-type {
      width: ${vwTablet(50)};
      margin-bottom: ${vwTablet(60)};
    }
    img:last-of-type {
      width: ${vwTablet(22)};
    }
  }
  @media ${media.desktop} {
    img:first-of-type {
      width: ${vwDesktop(50)};
      margin-bottom: ${vwDesktop(60)};
    }
    img:last-of-type {
      width: ${vwDesktop(22)};
    }
  }
`;

const Fields = styled.div`
  @media ${media.desktop} {
    display: flex;
    flex-wrap: wrap;

    > * {
      width: ${vwDesktop(500)};
    }

    > *:nth-child(2n) {
      margin-left: ${vwDesktop(40)};
    }
  }
`;

export default Register;
