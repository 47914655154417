import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';

import CaseStudyTemplate from 'components/CaseStudyTemplate';
import data from 'src/data/CaseStudies';

export default function CaseStudies() {
  const [activeCaseStudy, setActiveCaseStudy] = useState(null);

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    if (params.id) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].slug === params.id) {
          setActiveCaseStudy(data[i]);
          break;
        } else if (i === data.length - 1 && data[i].slug !== params.id) {
          return history.replace('/404');
        }
      }
    }
  }, [params.id, history]);

  return (
    <Root>
      {activeCaseStudy !== null && <CaseStudyTemplate data={activeCaseStudy} />}
    </Root>
  );
}

const Root = styled.div`
  background-color: white;
`;
