// Constants
export const AUTH__LOGIN = 'AUTH::LOGIN';
export const AUTH__LOGOUT = 'AUTH::LOGOUT';

// Actions
export const setLogin = () => ({
  type: AUTH__LOGIN,
});

export const clearLogin = () => ({
  type: AUTH__LOGOUT,
});

// Initial State
const initialState = {
  isLoggedIn: false,
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case AUTH__LOGIN:
      return {
        ...state,
        isLoggedIn: true,
      };
    case AUTH__LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
