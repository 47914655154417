import appConfig from 'src/config/app.conf';
const { stage, mediaQuery } = appConfig;

export default function vw(property, wMobile, wTablet, wDesktop) {
  if (typeof wTablet === 'undefined') {
    wTablet = wMobile;
  }

  if (typeof wDesktop === 'undefined') {
    wDesktop = wTablet;
  }

  // if (property === 'font-size' ||
  //     property === 'line-height' ||
  //     property === 'letter-spacing') {
  //   return `${property}: min(${vwMobile(wMobile)}, ${wMobile * 1.2}px);
  //     @media (min-width: ${mediaQuery.tablet}px) {
  //       ${property}: ${vwTablet(wTablet)};
  //     }
  //     @media (min-width: ${mediaQuery.desktop}px) {
  //       ${property}: max(${vwDesktop(wDesktop)}, ${wDesktop * 0.9}px);
  //     }
  //   `
  // }else {
    return `${property}: ${vwMobile(wMobile)};
      @media (min-width: ${mediaQuery.tablet}px) {
        ${property}: ${vwTablet(wTablet)};
      }
      @media (min-width: ${mediaQuery.desktop}px) {
        ${property}: ${vwDesktop(wDesktop)};
      }
    `
  // }
}

export const vwMobile = width => {
  return `${(width / stage.mobile) * 100}vw`;
};

export const vwTablet = width => {
  return `${(width / stage.tablet) * 100}vw`;
};

export const vwDesktop = width => {
  return `${(width / stage.desktop) * 100}vw`;
};
