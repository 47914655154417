import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import vw, { vwDesktop, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/Projects';

export default function Project(props) {
  const [activeProject, setActiveProject] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activePage, setActivePage] = useState(null);

  const activeProjectSlug = useSelector((state) => state.project.activeProject);

  const location = useLocation();

  const { isProject, hasScrolledDown } = props;

  useEffect(() => {
    if (activeProjectSlug) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].slug === activeProjectSlug) {
          setActiveProject(data[i]);
          break;
        }
      }
    }
    const loc = location.pathname.split('/');
    if (loc[loc.length - 1] === '') {
      loc.splice(loc.length - 1, 1);
    }
    // check for individual plan page
    if (loc[loc.length - 2] === 'plans') {
      setActivePage(loc[loc.length - 2]);
    } else {
      setActivePage(loc[loc.length - 1]);
    }
  }, [activeProjectSlug, location.pathname]);

  const closeMenu = () => setIsMenuOpen(false);

  const handleActivePage = () => {
    if (activeProject) {
      if (activePage === activeProject.slug) {
        return activeProject.navTitle;
      } else if (activeProject.nav.includes(activePage)) {
        return activeProject.nav.find((item) => item === activePage);
      } else if (activePage === 'register') {
        return 'Register';
      } else if (activePage === 'thankyou') {
        return 'Register';
      }
    }
  };

  return (
    <Root isProject={isProject} hasScrolledDown={hasScrolledDown}>
      {activeProject && (
        <>
          <Mobile isMenuOpen={isMenuOpen}>
            <div onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <p className="project-nav gold uppercase">{handleActivePage()}</p>
              <img
                src={require('src/assets/images/global/icon/action/chevron-up-gold.svg')}
                alt="Menu"
                style={{ transform: isMenuOpen ? 'scaleY(1)' : 'scaleY(-1)' }}
              />
            </div>
            <Button
              className="project-nav black uppercase"
              to={`/homes/${activeProject.slug}/register`}
              tablet
              onClick={closeMenu}
            >
              Register Today
            </Button>
          </Mobile>
          <Nav active={isMenuOpen}>
            <StyledLink
              to={`/homes/${activeProject.slug}`}
              className="project-nav uppercase"
              nomargin
              onClick={closeMenu}
              active={activePage === activeProject.slug}
            >
              {activeProject.navTitle}
            </StyledLink>
            <Line />
            {activeProject.nav.map((item, index) => (
              <StyledLink
                to={`/homes/${activeProject.slug}/${item}`}
                className="project-nav uppercase"
                active={activePage === item}
                onClick={closeMenu}
                navLink
              >
                {item}
              </StyledLink>
            ))}
            <StyledLink
              to={`/design`}
              className="project-nav uppercase"
              mobile
              last
              onClick={closeMenu}
              target="_blank"
            >
              Design Your Home
            </StyledLink>
            <Button
              className="project-nav black uppercase"
              to={`/homes/${activeProject.slug}/register`}
              mobile
              onClick={closeMenu}
            >
              Register Today
            </Button>
          </Nav>
          <Wrapper>
            <Button
              className="project-nav gold uppercase"
              to={`/design`}
              dark
              target="_blank"
            >
              Design Your Home
            </Button>
            <Button
              className="project-nav black uppercase"
              to={`/homes/${activeProject.slug}/register`}
              active={activePage === 'register'}
            >
              Register Today
            </Button>
          </Wrapper>
        </>
      )}
    </Root>
  );
}

Project.propTypes = {
  isProject: PropTypes.bool,
  hasScrolledDown: PropTypes.bool,
};

const Root = styled.div`
  display: ${(props) => (props.isProject ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.color.black};
  /* ${vw('height', 60, 82, 100)} */
  padding: 0 ${vwMobile(16)};
  position: relative;
  height: ${vwMobile(60)};
  transition: 0.3s ease;
  span {
    font-weight: 900;
  }
  @media ${media.tablet} {
    padding: 0 ${vwTablet(30)};
    height: ${vwTablet(82)};
  }
  @media ${media.desktop} {
    padding: 0 ${vwDesktop(60)};
    height: ${(props) =>
      props.hasScrolledDown ? vwDesktop(82) : vwDesktop(100)};
  }
`;

const Nav = styled.div`
  position: absolute;
  top: 100%;
  left: 0%;
  background-color: ${({ theme }) => theme.color.black};
  width: 100%;
  padding-top: ${(props) => (props.active ? vwMobile(16) : 0)};
  padding-left: ${vwMobile(16)};
  padding-right: ${vwMobile(16)};
  padding-bottom: ${(props) => (props.active ? vwMobile(40) : 0)};
  max-height: ${(props) => (props.active ? vwMobile(425) : 0)};
  transition: 0.4s ease-in-out;
  overflow: hidden;
  @media ${media.tablet} {
    padding-top: ${(props) => (props.active ? vwTablet(30) : 0)};
    padding-left: ${vwTablet(30)};
    padding-right: ${vwTablet(30)};
    padding-bottom: ${(props) => (props.active ? vwTablet(60) : 0)};
    max-height: ${(props) => (props.active ? vwTablet(480) : 0)};
  }
  @media ${media.desktop} {
    display: flex;
    position: relative;
    top: ${vwDesktop(2)};
    width: auto;
    padding: 0;
    max-height: none;
  }
`;

const StyledLink = styled(Link)`
  margin-right: ${(props) => (props.nomargin ? 0 : vwDesktop(32))};
  border-top: 1px solid #393939;
  border-bottom: ${(props) => (props.last ? '1px solid #393939' : 'none')};
  padding: ${vwMobile(16)} 0;
  color: ${(props) => (props.active ? 'white' : props.theme.color.gold)};
  transition: 0.3s ease;
  width: 100%;
  @media ${media.tablet} {
    padding: ${vwTablet(24)} 0;
    border-bottom: none;
  }
  @media ${media.desktop} {
    width: auto;
    border-top: none;
    padding: 0;
    display: ${(props) => (props.mobile ? 'none' : 'block')};
    color: ${({ theme }) => theme.color.gold};
    position: relative;
    overflow: visible;
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: ${({ theme }) => theme.color.gold};
      opacity: ${(props) => (props.active && props.navLink ? 1 : 0)};
      transition: 0.3s ease;
    }
    &:hover::after {
      opacity: ${(props) => props.navLink && 1};
    }
  }
`;

const Line = styled.div`
  width: 1px;
  height: ${vwDesktop(22)};
  margin: 0 ${vwDesktop(24)};
  background-color: ${({ theme }) => theme.color.gold};
  display: none;
  @media ${media.desktop} {
    display: block;
  }
`;

const Button = styled(Link)`
  background-color: ${(props) =>
    props.dark ? 'transparent' : props.theme.color.gold};
  border: 1px solid ${({ theme }) => theme.color.gold};
  padding: ${vwMobile(5)} ${vwMobile(10)} ${vwMobile(5)} ${vwMobile(12)};
  margin-top: ${vwMobile(16)};
  display: ${(props) => props.tablet && 'none'};
  @media ${media.tablet} {
    display: ${(props) => (props.tablet ? 'block' : 'none')};
    padding: ${vwTablet(5)} ${vwTablet(10)} ${vwTablet(5)} ${vwTablet(12)};
    margin-top: 0;
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(5)} ${vwDesktop(10)} ${vwDesktop(5)} ${vwDesktop(12)};
    margin-right: ${(props) => (props.dark ? vwDesktop(16) : 0)};
    display: ${(props) => (props.mobile ? 'none' : 'block')};
    transition: 0.3s ease;
    background-color: ${(props) =>
      props.active && !props.dark && props.theme.color.white};
    border-color: ${(props) =>
      props.active && !props.dark && props.theme.color.white};
    &:hover {
      background-color: ${(props) =>
        props.dark ? 'transparent' : props.theme.color.white};
      border: 1px solid ${({ theme }) => theme.color.white};
      color: ${(props) => props.dark && props.theme.color.white};
    }
  }
`;

const Wrapper = styled.div`
  display: none;
  @media ${media.desktop} {
    display: flex;
  }
`;

const Mobile = styled.div`
  width: 100%;
  img {
    width: ${vwMobile(16)};
    transition: 0.3s ease;
    margin-right: ${vwMobile(4)};
  }
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      justify-content: start;
      width: ${vwTablet(216)};
    }
    img {
      width: ${vwTablet(16)};
      margin-left: ${vwTablet(12)};
    }
  }
  @media ${media.desktop} {
    display: none;
  }
`;
