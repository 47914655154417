export default [
  {
    id: 'a1',
    bed: 1,
    bath: 1,
    den: false,
    interior: '721',
    exterior: '60-66',
    total: '781-787',
    plan: require('src/assets/images/oneshaughnessy/plans/plan-a1.png'),
    plate: require('src/assets/images/oneshaughnessy/plates/floorplate-a1.png'),
    pdf: '/pdf/oneshaughnessy/one-shaugnessy-floorplan-a1.pdf',
    smallWidth: false,
    soldout: false,
  },
  {
    id: 'b1',
    bed: 1,
    bath: 1,
    den: true,
    interior: '829',
    exterior: '184-194',
    total: '1,013-1,023',
    plan: require('src/assets/images/oneshaughnessy/plans/plan-b1.png'),
    plate: require('src/assets/images/oneshaughnessy/plates/floorplate-b1.png'),
    pdf: '/pdf/oneshaughnessy/one-shaugnessy-floorplan-b1.pdf',
    smallWidth: false,
    soldout: false,
  },
  {
    id: 'b2',
    bed: 1,
    bath: 2,
    den: true,
    interior: '1,033',
    exterior: '767',
    total: '1,800',
    plan: require('src/assets/images/oneshaughnessy/plans/plan-b2.png'),
    plate: require('src/assets/images/oneshaughnessy/plates/floorplate-b2.png'),
    pdf: '/pdf/oneshaughnessy/one-shaugnessy-floorplan-b2.pdf',
    smallWidth: false,
    soldout: false,
  },
  {
    id: 'c1',
    bed: 2,
    bath: 2,
    den: false,
    interior: '980',
    exterior: '105-255',
    total: '1,085-1,235',
    plan: require('src/assets/images/oneshaughnessy/plans/plan-c1.png'),
    plate: require('src/assets/images/oneshaughnessy/plates/floorplate-c1.png'),
    pdf: '/pdf/oneshaughnessy/one-shaugnessy-floorplan-c1.pdf',
    smallWidth: false,
    soldout: false,
  },
  {
    id: 'c2',
    bed: 2,
    bath: 2,
    den: false,
    interior: '1,152',
    exterior: '898',
    total: '2,050',
    plan: require('src/assets/images/oneshaughnessy/plans/plan-c2.png'),
    plate: require('src/assets/images/oneshaughnessy/plates/floorplate-c2.png'),
    pdf: '/pdf/oneshaughnessy/one-shaugnessy-floorplan-c2.pdf',
    smallWidth: false,
    soldout: false,
  },
  {
    id: 'c3',
    bed: 2,
    bath: 2,
    den: false,
    interior: '1,141',
    exterior: '755',
    total: '1,896',
    plan: require('src/assets/images/oneshaughnessy/plans/plan-c3.png'),
    plate: require('src/assets/images/oneshaughnessy/plates/floorplate-c3.png'),
    pdf: '/pdf/oneshaughnessy/one-shaugnessy-floorplan-c3.pdf',
    smallWidth: false,
    soldout: false,
  },
  {
    id: 'd1',
    bed: 2,
    bath: 2,
    den: true,
    interior: '1,055',
    exterior: '81-205',
    total: '1,136-1,260',
    plan: require('src/assets/images/oneshaughnessy/plans/plan-d1.png'),
    plate: require('src/assets/images/oneshaughnessy/plates/floorplate-d1.png'),
    pdf: '/pdf/oneshaughnessy/one-shaugnessy-floorplan-d1.pdf',
    smallWidth: false,
    soldout: false,
  },
  {
    id: 'd2',
    bed: 2,
    bath: 2,
    den: true,
    interior: '1,119',
    exterior: '678',
    total: '1,797',
    plan: require('src/assets/images/oneshaughnessy/plans/plan-d2.png'),
    plate: require('src/assets/images/oneshaughnessy/plates/floorplate-d2.png'),
    pdf: '/pdf/oneshaughnessy/one-shaugnessy-floorplan-d2.pdf',
    smallWidth: false,
    soldout: false,
  },
  {
    id: 'e1',
    bed: 3,
    bath: 2,
    den: false,
    interior: '1,173',
    exterior: '161',
    total: '1,334',
    plan: require('src/assets/images/oneshaughnessy/plans/plan-e1.png'),
    plate: require('src/assets/images/oneshaughnessy/plates/floorplate-e1.png'),
    pdf: '/pdf/oneshaughnessy/one-shaugnessy-floorplan-e1.pdf',
    smallWidth: false,
    soldout: false,
  },
  {
    id: 's1',
    bed: 0,
    bath: 1,
    den: false,
    interior: '506',
    exterior: '0-44',
    total: '506-550',
    plan: require('src/assets/images/oneshaughnessy/plans/plan-s1.png'),
    plate: require('src/assets/images/oneshaughnessy/plates/floorplate-s1.png'),
    pdf: '/pdf/oneshaughnessy/one-shaugnessy-floorplan-s1.pdf',
    smallWidth: true,
    soldout: false,
  },
  {
    id: 's2',
    bed: 0,
    bath: 1,
    den: false,
    interior: '474',
    exterior: '0-46',
    total: '474-520',
    plan: require('src/assets/images/oneshaughnessy/plans/plan-s2.png'),
    plate: require('src/assets/images/oneshaughnessy/plates/floorplate-s2.png'),
    pdf: '/pdf/oneshaughnessy/one-shaugnessy-floorplan-s2.pdf',
    smallWidth: true,
    soldout: false,
  },
  {
    id: 's3',
    bed: 0,
    bath: 1,
    den: false,
    interior: '441',
    exterior: '0',
    total: '441',
    plan: require('src/assets/images/oneshaughnessy/plans/plan-s3.png'),
    plate: require('src/assets/images/oneshaughnessy/plates/floorplate-s3.png'),
    pdf: '/pdf/oneshaughnessy/one-shaugnessy-floorplan-s3.pdf',
    smallWidth: true,
    soldout: false,
  },
];
