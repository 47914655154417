export default [
  {
    title: 'dining & grocery',
    points: [
      {
        text: 'Samz Neighbourhood Pub',
        left: '51.1%',
        top: '25.6%',
      },
      {
        text: 'Patina Brewing Co.',
        left: '36.9%',
        top: '49.8%',
      },
      {
        text: 'Me-n-Ed’s Pizza Parlours',
        left: '68.9%',
        top: '43.3%',
      },
      {
        text: 'Papa John’s Pizza',
        left: '45.2%',
        top: '44.4%',
      },
      {
        text: 'Starbucks',
        left: '54.2%',
        top: '42.4%',
      },
      {
        text: 'Europe Old Fashioned Bakery & Deli',
        left: '52.3%',
        top: '42.8%',
      },
      {
        text: 'PoCo Freshmart',
        left: '44%',
        top: '46.7%',
      },
      {
        text: 'City Avenue Market',
        left: '55.7%',
        top: '30.4%',
      },
      {
        text: 'Waves Coffee House',
        left: '54.2%',
        top: '32.4%',
      },
      {
        text: 'Asahi Sushi',
        left: '73.6%',
        top: '51.2%',
      },
      {
        text: 'Earls',
        left: '74.8%',
        top: '11.7%',
        bottomLabel: true,
      },
      {
        text: 'Tip Thai Restaurant',
        left: '57.4%',
        top: '36.2%',
      },
      {
        text: 'Safeway',
        left: '81.1%',
        top: '13.7%',
        bottomLabel: true,
      },
      {
        text: 'Dairy Queen',
        left: '49.4%',
        top: '46.9%',
      },
      {
        text: 'Subway',
        left: '48.1%',
        top: '49.3%',
      },
      {
        text: 'A&W',
        left: '46.4%',
        top: '52.7%',
      },
      {
        text: 'Pho T&T',
        left: '68.9%',
        top: '49.5%',
      },
      {
        text: 'Matteo’s Gelato',
        left: '71.2%',
        top: '44.8%',
      },
      {
        text: 'Sparta Empire Greek Taverna + Pizza',
        left: '65.8%',
        top: '46.8%',
      },
    ],
  },
  {
    title: 'Recreation & Entertainment',
    points: [
      {
        text: 'Carnoustie Golf Club',
        left: '93.8%',
        top: '37.5%',
        offMap: true,
        position: 'right',
        bottomLabel: true,
      },
      {
        text: 'PoCo Bowl',
        left: '61.3%',
        top: '37.7%',
      },
      {
        text: 'Giggle Dam Dinner Theatre',
        left: '58.5%',
        top: '33.8%',
      },
    ],
  },
  {
    title: 'Parks',
    points: [
      {
        text: 'Gates Park',
        left: '5.1%',
        top: '63.1%',
        rightTitle: true,
      },
      {
        text: 'Central Park',
        left: '34.2%',
        top: '94.8%',
      },
      {
        text: 'Elks Park',
        left: '57%',
        top: '80.5%',
      },
      {
        text: 'Veterans Park',
        left: '55.1%',
        top: '40.2%',
      },
      {
        text: 'Railside Skate Park',
        left: '61.4%',
        top: '12.7%',
        bottomLabel: true,
      },
      {
        text: 'Rowland Park',
        left: '67.1%',
        top: '58.6%',
      },
    ],
  },
  {
    title: 'Schools',
    points: [
      {
        text: 'Central Community Elementary School',
        left: '52.3%',
        top: '95.5%',
        offMap: true,
        position: 'bottom',
      },
      {
        text: 'École Pitt River Community Middle School',
        left: '56%',
        top: '95.5%',
        offMap: true,
        position: 'bottom-right',
      },
      {
        text: 'École Riverside Secondary School',
        left: '13.2%',
        top: '80.5%',
        offMap: true,
        position: 'bottom',
        rightTitle: true,
      },
      {
        text: 'Bramblewood Montessori Daycare',
        left: '58%',
        top: '53.8%',
      },
    ],
  },
  {
    title: 'Transit',
    points: [
      {
        text: 'West Coast Express',
        left: '96.1%',
        top: '48.8%',
      },
      {
        text: 'Port Coquitlam Bus Loop',
        left: '97%',
        top: '54.6%',
      },
      {
        text: 'Modo Car Share',
        left: '45.7%',
        top: '76%',
      },
      {
        text: 'U-Bicycle Station',
        left: '57.4%',
        top: '42.6%',
      },
      {
        text: 'U-Bicycle Station',
        left: '5.1%',
        top: '58.5%',
        rightTitle: true,
      },
      {
        text: 'U-Bicycle Station',
        left: '73.3%',
        top: '58.5%',
      },
    ],
  },
];
