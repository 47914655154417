import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import vw from 'src/styles/utils';

export default function Terms() {
  return (
    <Root>
      <Heading>
        <h1 className='corporate white'>Terms of Use</h1>
      </Heading>
      
      <Body>
        <p className='body1 black'>
          THESE TERMS OF USE CONTAIN LEGAL OBLIGATIONS. PLEASE READ THESE TERMS OF USE BEFORE USING THIS WEBSITE.
        </p>

        <p className='body1 black'>
          Last Updated: <span className='bold'>June 19, 2020</span>
        </p>

        <h4 className='global black uppercase'>Terms of Use</h4>
        <p className='body1 black'>
          Kutak Development Services Ltd. and its affiliated or related entities (collectively, "<span className='bold'>Kutak</span>", "<span className='bold'>us</span>", "<span className='bold'>our</span>" or "<span className='bold'>we</span>") uses this website, "<span className='bold'>https://www.kutakdevelopment.com/</span>", and its related webpages (collectively, the “Website”) to deliver information relating to our real estate services and developments. Any customer service or any related services provided to you through the Website are referred to in these terms of use (the "<span className='bold'>Terms of Use</span>") as the "<span className='bold'>Services</span>". We also use the Website to provide you with information about our products and Services.
          <br/><br/>
          Access to and use of the information provided through this Website and the Services (collectively referred to as the "<span className='bold'>Content</span>"), is subject to your acceptance of and compliance with these Terms of Use. By using the Website you indicate your acceptance of these Terms of Use. By using the Website you agree to be bound by and acknowledge your acceptance of these Terms of Use and our <Link to='/privacy-policy'>Privacy Policy</Link>. If you do not accept these Terms of Use or the Privacy Policy, please do not use the Website or any of its Content.

        </p>

        <h4 className='global black uppercase'>Modifications to this Agreement; Waiver</h4>
        <p className='body1 black'>
          We reserve the right to alter, add to, delete, modify or change these Terms of Use at any time. Your use of the Website after any amendments or updates of these Terms of Use shall signify your acceptance of such revised terms. The most current version of these Terms of Use will govern your use of the Website. We will post any changes to the Terms of Use on the Website and update the revision date at the beginning of these Terms of Use. You should periodically review the Terms of Use to see recent changes. Any changes to these Terms of Use or waiver of our rights hereunder shall not be valid or effective except in a written agreement bearing the physical signature of one of our directors or officers. No purported waiver or modification of these Terms of Use by us via telephone or email communications will be valid.
        </p>

        <h4 className='global black uppercase'>Restrictions on Use of the Website</h4>
        <p className='body1 black'>
          <span className='bold'>You acknowledge and agree that you are authorized to view and print the Content and to use the Website and the Content and that you shall view, print and use the Website and the Content for your own personal and non-commercial use only and otherwise only in accordance with these Terms of Use</span>. Except as otherwise set out in these Terms of Use, you acknowledge and agree that you will not, directly or indirectly, sell, assign, distribute, provide access to, transmit, exploit, copy, modify, publish, republish, reproduce, repackage, retransmit, resell, recompile, decompile, disassemble or otherwise disseminate, the Website or the Content, or any derivative works from the Content, or any portion thereof, in any medium or in any manner whatsoever.
          <br/><br/>
          In addition, you shall use the Website, links to other web sites from the Website (the “Linked Sites”) for lawful purposes only and you shall use the Content in accordance with all applicable laws. You may not: (i) attempt to gain unauthorized access to the Website, or computer systems or networks connected to the Website, through hacking, password or data mining, or any other means to circumvent security procedure; (ii) use the Website in any manner that could damage, disable, overburden, or impair the Website or any of the Services, or interfere with any other party's use and enjoyment of the Website; (iii) access the Website in a manner that utilizes the resources of the Website more heavily than would be the case for an individual person using a conventional web browser; (iv) use any robot, spider or other automatic device, software program or manual process to monitor, copy, “scrape” or interfere with any web pages or the content contained thereon on the Website; (v) use any device, software or routine to interfere or attempt to interfere with the proper working of the Website; or (vi) use the Website to execute denial of service attacks.
          <br/><br/>
          Under no circumstances will you use the Website or the Content, or any derivative works from the Content, or any portion thereof, in any manner that may infringe any proprietary or intellectual property rights or interests that we may have in the Website or the Content, or any derivative works from the Content, or any portion thereof. <span className='bold'>Without limiting the generality of the foregoing, you represent and warrant to us that you will not use the Website or Content or any material retrieved from the Content to create products or perform services which compete or interfere with our services</span>.
        </p>

        <h4 className='global black uppercase'>Accuracy of Information</h4>
        <p className='body1 black'>
          We work hard to make sure all information provided on the Website is accurate, up-to-date and complete. However, the information and materials on the Website may contain errors or inaccuracies, including both typographical and substantive errors. We reserve the right, in our sole discretion, without any obligation and without any notice requirement, to discontinue, change, improve or correct the information, materials and descriptions on the Website and to suspend or deny access to the Website for scheduled or unscheduled maintenance, upgrades, improvements or corrections. Any dated information is published as of its date only, and we have no responsibility to update or amend any such information.
          <br/><br/>
          Purchasers of residential or commercial units from Kutak should refer to their respective agreements of purchase and sale (the "<span className='bold'>Purchase Agreements</span>") for full details of the terms and provisions governing their respective transactions. These Purchase Agreements provide the sole and exclusive remedies to purchasers of such units arising from any cause or claim whatsoever, including any liability arising from any misrepresentation. There are no representations, warranties, conditions, or contracts or collateral representations, warranties, conditions, or contracts, express or implied, arising out of the Website or any content therein. Nothing contained on the Website shall be deemed or considered an offer by Kutak to sell a unit to you
        </p>

        <h4 className='global black uppercase'>Links to Third-Party Websites</h4>
        <p className='body1 black'>
          The Website may contain links to other Linked Sites that are not owned or controlled by us and we are not responsible for the content of any Linked Sites. The presence on the Website of a link to any other Linked Site does not imply that we endorse or accept any responsibility for the content or use of such Linked Site, and you hereby release us from all liability and/or damages that may arise from your use of such Linked Site.
        </p>

        <h4 className='global black uppercase'>Links to the Website</h4>
        <p className='body1 black'>
          While we encourage links to the Website, we reserve the right to prohibit or refuse to accept any link to the Website at any time. You agree to remove any link you may have to the Website upon our request.
        </p>

        <h4 className='global black uppercase'>Non-confidentiality and Privacy</h4>
        <p className='body1 black'>
          Any communications between you and Kutak, such as email or other correspondence, in which you offer suggestions or comments for improving or modifying the Website or the Content (“Feedback”) will be deemed by us to be non-confidential and non-proprietary, and you agree that (a) we are therefore not subject to any confidentiality obligations in respect to the Feedback, (b) the Feedback is not confidential or proprietary information of any third party and you have all of the necessary rights to disclose the Feedback to us, (c) we (including all of our affiliates, successors and assigns) may freely use, reproduce, publicize, and you irrevocably assign to us the right to license, distribute, and otherwise commercialize Feedback on the Website, and (d) you are not entitled to receive any compensation or re-imbursement of any kind from us or any of the users of the Website.
          <br/><br/>
          Our Privacy Policy explains how we collect, use and disclose information that pertains to your privacy. The Privacy Policy forms part of the Terms of Use with you and is incorporated herein. For full details, please refer to our <Link to='/privacy-policy'>Privacy Policy</Link>. By providing personal information via the Website you agree that we will collect, use and disclose your personal information as is necessary to operate the Website and to manage the Privacy Policy and these Terms of Use.
        </p>

        <h4 className='global black uppercase'>Security</h4>
        <p className='body1 black'>
          While we make commercially reasonable efforts to keep the Website secure and protect the data you transmit to us through the Website, information sent or received over the Internet is generally unsecure and we cannot and do not make any representation or warranty concerning security of any communication to or from the Website or any representation or warranty regarding the interception by third parties of personal or other information and as such cannot guarantee the security of the Website or such information and will not be liable for any unauthorized access to or use of the data transmitted via the Website.
        </p>

        <h4 className='global black uppercase'>Disclaimer of all Representations, Warranties and Conditions</h4>
        <p className='body1 black'>
          THE INFORMATION AND MATERIALS PROVIDED ON THE WEBSITE AND ACCESS TO THE CONTENT ARE PROVIDED ON AN "AS IS", AS-AVAILABLE BASIS. WE, OUR DIRECTORS, OFFICERS, EMPLOYEES, ADVISORS, AFFILIATES, LICENSEES AND AGENTS MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EITHER EXPRESS, IMPLIED, STATUTORY, BY USAGE OF TRADE, COURSE OF DEALING OR OTHERWISE WITH RESPECT TO THE ACCESSIBILITY OR CAPABILITY OF THE WEBSITE (INCLUDING WITHOUT LIMITATION WITH RESPECT TO ACCESS TO THE WEBSITE THROUGH ANY MOBILE DEVICE) OR THE ACCURACY OR THE COMPLETENESS OF THE DATA PROVIDED THEREON. ALL WARRANTIES OF ANY TYPE, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WITH RESPECT TO WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, TITLE, SATISFACTORY QUALITY OR FITNESS FOR A PARTICULAR PURPOSE ARE EXPRESSLY DISCLAIMED. ANY INFORMATION OR MATERIAL ACCESSED, VIEWED, DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE WEBSITE AND THE CONTENT BY ANY MEANS OF ACCESS WHATSOEVER IS AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM, LOSS OF DATA, FAILURE TO TRANSMIT DATA OR ANY OTHER LOSS THAT RESULTS FROM ACCESSING, VIEWING, DOWNLOADING, USING OR RELYING ON ANY SUCH MATERIAL. WE DO NOT WARRANT THAT THE CONTENT PROVIDED HEREIN WILL MEET ANY PARTICULAR CRITERIA OF PERFORMANCE OR QUALITY, OR THAT THE WEBSITE IS FREE OF OTHER HARMFUL COMPONENTS.
          <br/><br/>
          WE DO NOT WARRANT, ENDORSE, GUARANTEE, PROVIDE ANY CONDITIONS OR REPRESENTATIONS, OR ASSUME ANY RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY ANY THIRD PARTY THROUGH ANY WEBSITE THAT CAN BE REACHED FROM A LINK ON THE WEBSITE OR FEATURED IN ANY BANNER OR OTHER ADVERTISING ON THE WEBSITE, AND WE SHALL NOT BE A PARTY TO ANY TRANSACTION THAT YOU MAY ENTER INTO WITH ANY SUCH THIRD PARTY.
        </p>

        <h4 className='global black uppercase'>Limitation of Liability and Indemnification</h4>
        <p className='body1 black'>
          YOU EXPRESSLY AGREE THAT YOUR USE OF THIS WEBSITE AND ANY INFORMATION CONTAINED HEREIN IS AT YOUR SOLE RISK. WE, OUR DIRECTORS, OFFICERS, EMPLOYEES, ADVISORS, AFFILIATES, LICENSEES AND AGENTS ARE NOT LIABLE UNDER CONTRACT LAW, TORT LAW, OR OTHERWISE, FOR ANY DAMAGES OF ANY KIND ARISING FROM YOUR USE OF THIS WEBSITE OR ANY INFORMATION CONTAINED HEREIN. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, IN NO EVENT SHALL WE, OUR SUPPLIERS AND OUR AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR OTHER DAMAGES (INCLUDING, WITHOUT LIMITATION, ANY DAMAGES ARISING FROM OR IN CONNECTION WITH THE USE OF OR ACCESS TO, OR ANY INCONVENIENCE, DELAY OR LOSS OF USE OF OR ACCESS TO, THE WEBSITE, ANY CONTENT OR ANY LINKS OR FAILURE OF THE WEBSITE OR ANY LINKS OR DAMAGES SUFFERED AS A RESULT OF OMISSIONS OR INACCURACIES IN THE WEBSITE, ANY CONTENT OR ANY LINKS OR THE TRANSMISSION OF CONFIDENTIAL OR SENSITIVE INFORMATION TO OR FROM THE WEBSITE OR ANY LINKS). THESE LIMITATIONS SHALL APPLY EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THE FOREGOING LIMITATIONS SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW.
          <br/><br/>
          YOU ACKNOWLEDGE THAT WE SHALL NOT BE LIABLE FOR ANY DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT BY YOU AND THAT THE RISK OF HARM OR DAMAGE FROM THE FOREGOING RESTS ENTIRELY WITH YOU.
          <br/><br/>
          YOU SHALL INDEMNIFY AND HOLD US AND OUR SUBSIDIARIES, AFFILIATES, LICENSEES, DIRECTORS, OFFICERS, AGENTS, ADVISORS AND EMPLOYEES, HARMLESS FROM ALL CLAIMS, THREATS, ACTIONS, PROCEEDINGS, DEMANDS, DAMAGES, LOSSES, OBLIGATIONS, COSTS, AND EXPENSES INCLUDING REASONABLE ATTORNEYS' FEES, MADE BY ANY THIRD PARTY DUE TO OR ARISING OUT OF YOUR USE OF THE WEBSITE, ANY VIOLATION BY YOU OF THESE TERMS OF USE, OR ANY VIOLATION BY YOU OF ANY RIGHTS OF ANOTHER (INCLUDING, WITHOUT LIMITATION, ALL INTELLECTUAL PROPERTY RIGHTS AND RIGHTS OF PUBLICITY, PERSONALITY OR PRIVACY).
          <br/><br/>
          You expressly acknowledge that we are making the Website, Content and links available to you in reliance upon the limitations and exclusions of liability and the disclaimers set forth herein and that the same form an essential basis of the contract between you and us. You expressly agree that the limitations and exclusions of liability and the disclaimers set forth herein will survive, and continue to apply in the case of a fundamental breach or breaches of, the failure of essential purpose of contract, the failure of any exclusive remedy or the termination or suspension by us of your use of, or access to, the Website.
        </p>

        <h4 className='global black uppercase'>Ability to Accept Terms of Use</h4>
        <p className='body1 black'>
          You represent and warrant that: (i) you are an individual and over the age of majority in the jurisdiction in which you are resident; (ii) you are using the Website in a jurisdiction where access to, or use of, the Website (or any part thereof) is not prohibited or illegal; (iii) you are fully able and competent to enter into, and abide by, these Terms of Use.
        </p>

        <h4 className='global black uppercase'>Jurisdiction</h4>
        <p className='body1 black'>
          Those who choose to access the Website from outside Canada do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable. Notwithstanding the foregoing, and recognizing the global nature of the Internet, each viewer and user shall comply with all local rules regarding online conduct and submission of acceptable materials. These Terms of Use are governed and interpreted pursuant to the laws of the Province of British Columbia, Canada and the federal laws of Canada applicable therein, without regard to principles of conflicts of law that would impose the law of another jurisdiction, and you agree to submit to the exclusive jurisdiction of the courts located within the Province of British Columbia, Canada.
        </p>

        <h4 className='global black uppercase'>Termination</h4>
        <p className='body1 black'>
          We may, under certain circumstances and without prior notice, immediately terminate your ability to access the Website or portions thereof. You acknowledge and agree that all terminations may be made by us in our sole discretion and that we shall not be liable to you or any third-party for any termination of your access to the Website.
        </p>

        <h4 className='global black uppercase'>Website Availability</h4>
        <p className='body1 black'>
          We cannot guarantee that the Website will be available 100% of the time. Although we strive to provide the most reliable website possible, interruptions and delays in accessing the Website are unavoidable and we disclaim any liability for damages resulting from such problems.
        </p>

        <h4 className='global black uppercase'>Miscellaneous</h4>
        <p className='body1 black'>
          You may not use or export or re-export any Content or any copy or adaptation of such Content, or any product or service offered on the Website, in violation of any applicable laws or regulations, including without limitation Canadian export laws and regulations.
          <br/><br/>
          If any of the provisions of these Terms of Use are held by a court or other tribunal of competent jurisdiction to be void or unenforceable, such provisions shall be limited or eliminated to the minimum extent necessary and replaced with a valid provision that best embodies the intent of these Terms of Use, so that these Terms of Use shall remain in full force and effect. These Terms of Use constitute the entire agreement between you and Kutak with regard to your use of the Site, and any and all other written or oral agreements or understandings previously existing between you and Kutak with respect to such use are hereby superseded and cancelled. Other than as provided in a purchase agreement you enter into with Kutak, Kutak will not accept any counter-offers to these Terms of Use, and all such offers are hereby categorically rejected. Kutak’s failure to insist on or enforce strict performance of these Terms of Use shall not be construed as a waiver by Kutak of any provision or any right it has to enforce these Terms of Use, nor shall any course of conduct between Kutak and you or any other party be deemed to modify any provision of these Terms of Use. These Terms of Use shall not be interpreted or construed to confer any rights or remedies on any third parties.
        </p>
      </Body>
    </Root>
  )
}

const Root = styled.div``;

const Heading = styled.div`
  ${vw('padding-top', 40, 80)}
  ${vw('padding-right', 16, 60)}
  ${vw('padding-bottom', 80, 80)}
  ${vw('padding-left', 16, 60)}
  ${vw('height', 164, 232)}
`;

const Body = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  ${vw('padding-top', 40, 60, 80)}
  ${vw('padding-right', 16, 60, 360)}
  ${vw('padding-bottom', 40, 60, 80)}
  ${vw('padding-left', 16, 60, 360)}
  h3 {
    ${vw('margin-bottom', 16, 20)}
  }
  h4 {
    ${vw('margin-bottom', 12, 12)}
  }
  h5 {
    ${vw('margin-bottom', 12, 12)}
  }
  p {
    ${vw('margin-bottom', 32, 40)}
  }
  li {
    list-style: none;
    position: relative;
    &:not(:last-of-type) {
      ${vw('margin-bottom', 12, 12)}
    }
    ${vw('padding-left', 36)}
    &:before {
      content: '';
      position: absolute;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.black};
      ${vw('top', 14)}
      ${vw('left', 18)}
      ${vw('width', 6)}
      ${vw('height', 6)}
    }
  }
  a {
    color: black;
    text-decoration: underline;
  }
`;