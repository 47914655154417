import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useLocation, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import LCrypt from 'lcrypt';
import appConfig from 'src/config/app.conf';
import { setAll } from 'src/redux/configurator';

import FadeGallery from 'components/Gallery/FadeGallery';
import Button from 'components/Button';

import media from 'src/styles/media';
import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';

import data from 'src/data/Components/HomeConfigCTA';

const Landing = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  let lcrypt = new LCrypt(appConfig.designMailer);

  const renderLandingPage = () => {
    return (
      <Root>
        <Body>
          <div>
            <h1 className="configurator black">Make It Yours.</h1>
            <p className="body2 black">
              We created a homebuying experience like no other. This tailored
              program takes you on a journey to uniquely customize your home
              from a selection of carefully curated designs. Your home should be
              an expression of you.
            </p>
            <Button type="primary-arrow" label="Start" to="/design/kitchen" />
          </div>
          <Logo
            src={require('src/assets/images/global/icon/kutak/kutak-xp.svg')}
            alt="XP"
          />
        </Body>
        <FadeGallery data={data} />
      </Root>
    );
  };

  if (location.search) {
    const query = queryString.parse(location.search);
    if (query.id) {
      try {
        const configOptions = JSON.parse(lcrypt.decode(query.id));
        dispatch(setAll(configOptions));
        return <Redirect to="/design/summary" />;
      } catch (e) {
        return renderLandingPage();
      }
    } else {
      return renderLandingPage();
    }
  } else {
    return renderLandingPage();
  }
};

const Root = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  @media ${media.desktop} {
    display: flex;
  }
`;

const Body = styled.div`
  padding: ${vwMobile(48)} ${vwMobile(16)};
  p {
    width: ${vwMobile(288)};
    margin: ${vwMobile(16)} 0 ${vwMobile(24)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(80)} ${vwTablet(60)};
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    p {
      width: ${vwTablet(312)};
      margin: ${vwTablet(20)} 0 ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(120)} ${vwDesktop(60)};
    width: ${vwDesktop(540)};
    display: block;
    p {
      width: ${vwDesktop(300)};
      margin: ${vwDesktop(20)} 0 ${vwDesktop(40)};
    }
  }
`;

const Logo = styled.img`
  width: ${vwMobile(80)};
  margin-top: ${vwMobile(40)};
  display: block;
  @media ${media.tablet} {
    margin-top: 0;
    width: ${vwTablet(100)};
  }
  @media ${media.desktop} {
    margin-top: ${vwDesktop(60)};
    width: ${vwDesktop(100)};
  }
`;

export default Landing;
