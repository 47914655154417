import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';
import vw from 'src/styles/utils';

export default function Slideout(props) {
  const { body } = props;
  const [ isOpen, setIsOpen ] = useState(false);
  const handleClick = () => {
    setIsOpen(!isOpen);
  }
  return (
    <Root isOpen={isOpen}>
      <Tab onClick={handleClick} isOpen={isOpen}>
        <img src={require('src/assets/images/global/icon/kutak/kutak-kis.svg')} alt='kis' />
        <button className='button gold uppercase'>Intelligence</button>
        <span />
      </Tab>

      <Card isOpen={isOpen}>
        <div>
          <img src={require('src/assets/images/global/graphic/kis-intelligence.svg')} alt='intelligence graphic' />
          <h2 className='infographic subheading gold'>KIS Project Stat</h2>
          <p className='white'>{body}</p>
        </div>
      </Card>
    </Root>
  )
}

Slideout.propTypes = {
  body: PropTypes.string,
}

const Root = styled.div`
  position: absolute;
  right: 0;
  ${props => props.isOpen ? vw('height', 500, 500) : vw('height', 64, 500)}
  ${props => props.isOpen ? vw('width', 288, 349) : vw('width', 288, 79)}
  display: flex;
  flex-direction: column;
  transition: 0.3s ease-in-out;

  @media ${media.tablet} {
    flex-direction: row;
  }

`;

const Tab = styled.div`
  cursor: pointer;
  ${vw('width', 288, 79)}
  ${vw('height', 64, 300)}
  ${vw('padding-left', 8, 0)}
  ${vw('padding-top', 0, 10)}
  background-color: ${({ theme }) => theme.color.black};
  display: flex;
  align-items: center;
  position: relative;
  img {
    ${vw('width', 48, 68)}
    ${vw('height', 48, 68)}
  }
  button {
    cursor: pointer;
    ${vw('margin-left', 12, 0)}
    ${vw('margin-top', 0, 77)}
  }
  span {
    position: absolute;
    ${vw('right', 16, 32)}
    ${vw('bottom', 'auto', 26)}
    background-image: ${props => props.isOpen ? 
      `url(${require('src/assets/images/global/icon/action/action-close.svg')})` : 
      `url(${require('src/assets/images/global/icon/action/chevron-down-gold.svg')})` 
    };
    background-size: cover;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    transition: 0.3s ease-in-out;
  }

  @media ${media.tablet} {
    flex-direction: column;
    align-items: center;
    button {
      transform: rotate(-270deg);
    }
    span {
      background-image: ${props => props.isOpen ? 
        `url(${require('src/assets/images/global/icon/action/action-close.svg')})` : 
        `url(${require('src/assets/images/global/icon/action/chevron-left-gold.svg')})` 
      };
    }

    &:hover {
      span {
        transform: ${props => props.isOpen ? '': 'translateX(-6px)'};
      }
    }
  }
`;

const Card = styled.div`
  overflow: hidden;
  ${props => props.isOpen ? vw('height', 436, 500) : vw('height', 0, 500)}
  ${props => props.isOpen ? vw('width', 288, 270) : vw('width', 288, 0)}
  position: relative;
  background-color: ${({ theme }) => theme.color.black};
  transition: 0.3s ease-in-out;

  >div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    img {
      ${vw('width', 150, 150)}
      ${vw('height', 150, 150)}
      ${vw('margin-bottom', 32, 32)}
    }

    h2 {
      ${vw('margin-bottom', 16, 16)}
    }

    p {
      ${vw('width', 200, 190)}
      font-family: 'futura-pt';
      font-weight: normal;
      ${vw('font-size', 16, 16)}
      ${vw('line-height', 22, 22)}
      ${vw('letter-spacing', 0.44, 0.8)}
    }
  }
`;