import React from 'react';
import styled from 'styled-components';

import HeroCorporateText from 'components/HeroCorporateText';
import HeroCorporateImage from 'components/HeroCorporateImage';
import HomeConfigCTA from 'components/HomeConfigCTA';
import data from 'src/data/Components/Hero';

export default function Home() {
  return (
    <Root>
      <HeroCorporateText 
        data={data.home}
        isHome
      />
      <HeroCorporateImage
        backgroundColor={({ theme }) => theme.color.skyBlue}
        bodyImage={require('src/assets/images/global/logo/one-shaughnessy-lockup.svg')}
        bodyText='Live in a home designed to reflect your own individuality.'
        projectURL='/homes/one-shaughnessy'
        heroImage={require('src/assets/images/global/render/kutak-os-aerial-render.jpg')}
        heroText='Coming Soon to Port Coquitlam'
      />
      <HomeConfigCTA
        subHeading='Design your home'
        heading='Make It Yours'
        body='Tailor your home from our carefully curated design selections and express your unique sense of style.'
      />
    </Root>
  )
}

const Root = styled.div`

`;