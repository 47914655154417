import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';

const FadeGallery = (props) => {
  const { data } = props;

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const cycleImages = setTimeout(() => {
      if (index < data.length - 1) {
        setIndex(index + 1);
      } else {
        setIndex(0);
      }
    }, 5400);
    return () => {
      clearTimeout(cycleImages);
    };
  }, [index, data.length]);

  const handleClick = (i) => {
    setIndex(i);
  };

  return (
    <Root>
      {data.map((image, key) => {
        return (
          <ImageContainer isActive={key === index} key={key}>
            <SmallImage
              src={image.small}
              alt={image.smallDescription}
              isActive={key === index}
            />
            <LargeImage
              src={image.large}
              alt={image.largeDescription}
              isActive={key === index}
            />
          </ImageContainer>
        );
      })}
      <Dots>
        {data.map((image, key) => {
          return (
            <Dot
              isActive={key === index}
              key={key}
              onClick={() => handleClick(key)}
            />
          );
        })}
      </Dots>
    </Root>
  );
};

FadeGallery.propTypes = {
  data: PropTypes.array,
};

const Root = styled.div`
  overflow: hidden;
  position: relative;
  ${vw('width', 320, 768, 900)}
  ${vw('height', 280, 640, 800)}
  img {
    display: block;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  position: absolute;
  transition: all 0.3s ease;
`;
const SmallImage = styled.img`
  position: relative;
  opacity: ${(props) => (props.isActive ? '1' : '0')};
  transition: opacity 0.6s ease;
  /* transition-delay: 0.3s; */
  ${vw('width', 114, 276, 330)}
  ${vw('height', 160, 380, 480)}
  ${vw('margin-right', 6, 8, 10)}
  ${vw('top', 0, 0, 80)}
`;

const LargeImage = styled.img`
  opacity: ${(props) => (props.isActive ? '1' : '0')};
  transition: opacity 0.6s ease;
  ${vw('width', 200, 484, 560)}
  ${vw('height', 280, 640, 800)}
`;

const Dots = styled.div`
  display: flex;
  position: absolute;
  ${vw('width', 54, 54)};
  ${vw('left', 16, 60, 0)}
  ${vw('bottom', 20, 60)}
`;

const Dot = styled.span`
  cursor: pointer;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isActive ? props.theme.color.gold : '#c1c1c1'};
  ${vw('width', 6, 10)}
  ${vw('height', 6, 10)}
  &:not(:first-of-type) {
    ${vw('margin-left', 8, 12)}
  }
`;

export default FadeGallery;
