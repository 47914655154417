import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ArrowButton from 'components/Configurator/ArrowButton';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Title = (props) => {
  const { to, title } = props;

  return (
    <Root>
      <h4 className="global gold uppercase">{title}</h4>
      <ArrowButton to={to} text="Back to Edit" />
    </Root>
  );
};

Title.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
};

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 ${vwMobile(16)} ${vwMobile(16)};
  @media ${media.tablet} {
    padding: 0 ${vwTablet(60)} ${vwTablet(24)};
  }
  @media ${media.desktop} {
    padding: 0 ${vwDesktop(60)} ${vwDesktop(24)};
  }
`;

export default Title;
