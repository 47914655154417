import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const List = (props) => {
  const {
    data,
    activeCategory,
    handleCategory,
    handlePoint,
    activePoint,
  } = props;
  return (
    <Root>
      {data.map((category, index) => (
        <Category key={index} active={activeCategory === index}>
          <div className="title" onClick={() => handleCategory(index)}>
            <h4 className="global gold uppercase">{category.title}</h4>
            <img
              src={require('src/assets/images/global/icon/action/chevron-up-gold.svg')}
              alt=""
              style={{
                transform:
                  activeCategory === index ? 'scaleY(1)' : 'scaleY(-1)',
              }}
            />
          </div>
          <div className="points">
            {category.points.map((point, j) => (
              <Point
                className="body2"
                onClick={() => handlePoint(j)}
                active={activePoint === j}
              >
                <span>{j + 1}.</span>
                {point.text}
              </Point>
            ))}
          </div>
        </Category>
      ))}
    </Root>
  );
};

List.propTypes = {
  data: PropTypes.array,
  activeCategory: PropTypes.number,
  activePoint: PropTypes.number,
  handleCategory: PropTypes.func,
  handlePoint: PropTypes.func,
};

const Root = styled.div`
  margin-top: ${vwMobile(40)};
  @media ${media.tablet} {
    margin-top: ${vwTablet(60)};
  }
  @media ${media.desktop} {
    grid-column: 1;
    grid-row: 2;
    margin-top: 0;
  }
`;

const Category = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.color.gold};
  h4 {
    width: 100%;
  }
  .title {
    padding: ${vwMobile(8)} 0 ${vwMobile(16)};
    display: flex;
    justify-content: space-between;
  }
  .points {
    margin-bottom: ${(props) => (props.active ? vwMobile(16) : 0)};
    overflow-y: ${(props) => (props.active ? 'auto' : 'hidden')};
    max-height: ${(props) => (props.active ? vwMobile(240) : 0)};
    transition: 0.4s ease;
    /* width */
    ::-webkit-scrollbar {
      width: ${vwMobile(4)};
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.color.gold};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.color.white};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.color.white};
    }
  }
  img {
    width: ${vwMobile(16)};
    transition: 0.3s ease;
  }
  @media ${media.tablet} {
    .title {
      padding: ${vwTablet(8)} 0 ${vwTablet(16)};
    }
    .points {
      margin-bottom: ${(props) => (props.active ? vwTablet(24) : 0)};
      max-height: ${(props) => (props.active ? vwTablet(240) : 0)};
      /* width */
      ::-webkit-scrollbar {
        width: ${vwTablet(4)};
      }
    }
    img {
      width: ${vwTablet(16)};
    }
  }
  @media ${media.desktop} {
    .title {
      cursor: pointer;
      padding: ${vwDesktop(8)} 0 ${vwDesktop(16)};
    }
    .points {
      margin-bottom: ${(props) => (props.active ? vwDesktop(24) : 0)};
      max-height: ${(props) => (props.active ? vwDesktop(341) : 0)};
      /* width */
      ::-webkit-scrollbar {
        width: ${vwDesktop(4)};
      }
    }
    img {
      width: ${vwDesktop(16)};
    }
  }
`;

const Point = styled.p`
  color: ${(props) =>
    props.active ? props.theme.color.gold : props.theme.color.white};
  margin-bottom: ${vwMobile(5)};
  transition: 0.3s ease;
  display: flex;
  span {
    margin: 0 ${vwMobile(10)} 0 ${vwMobile(5)};
  }
  @media ${media.tablet} {
    margin-bottom: ${vwTablet(10)};
    span {
      margin: 0 ${vwTablet(10)} 0 ${vwTablet(5)};
    }
  }
  @media ${media.desktop} {
    margin-bottom: ${vwDesktop(10)};
    cursor: pointer;
    span {
      margin: 0 ${vwDesktop(10)} 0 ${vwDesktop(5)};
    }
  }
`;

export default List;
