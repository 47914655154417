export default {
  project: 'One Shaughnessy',
  kitchen: {
    palette: [
      {
        name: 'K1',
        cabinet: 'Gloss White',
      },
      {
        name: 'K2',
        cabinet: 'Dark Grey',
      },
      {
        name: 'K3',
        cabinet: 'Light Grey',
      },
    ],
    flooring: [
      {
        name: 'Maple LVT',
      },
      {
        name: 'Smoked Oak LVT',
      },
      {
        name: 'Honey Oak LVT',
      },
    ],
  },
  bath: {
    palette: [
      {
        name: 'B1',
        cabinet: 'Gloss White',
      },
      {
        name: 'B2',
        cabinet: 'Champagne',
      },
      {
        name: 'B3',
        cabinet: 'Walnut',
      },
    ],
    ceiling: [
      {
        name: 'Benjamin Moore Chantilly Lace',
      },
      {
        name: 'Benjamin Moore Natural Cream',
      },
      {
        name: 'Benjamin Moore Hale Navy',
      },
    ],
  },
  upgrades: [
    'add music to select rooms',
    'add smart lighting',
    'add motorized shading to your windows',
    'add security system',
    'kitchen organizer package',
    'add a wall bed',
    'add custom closet organizer',
    'add ev charging to your parking stall',
    'add an additional parking stall',
    'Add heated floors to your bathroom',
    'add towel warmer',
    'add wool carpeting to bedroom',
  ],
};
