import React from 'react';
import styled from 'styled-components';

import ImageCallout from 'components/Project/ImageCallout';

import media from 'src/styles/media';
import vw, { vwDesktop } from 'src/styles/utils';

const Bathroom = () => {
  return (
    <Root>
      <StyledImageCallout
        image={require('src/assets/images/oneshaughnessy/one-shaughnessy-bath.jpeg')}
        alt="Bathroom"
        points={[
          {
            top: '56.4%',
            left: '26.7%',
            text: 'Sleek Kohler plumbing fixtures',
          },
          {
            top: '61.9%',
            left: '40.7%',
            text:
              'Smooth quartz countertops sit on top of your choice of cabinetry colour',
          },
          {
            top: '43.6%',
            left: '59.6%',
            text:
              'Organize your toiletries in a discreet medicine cabinet or on floating shelves',
          },
          {
            top: '5.9%',
            left: '65.3%',
            text: 'Select your preferred ceiling colour',
            bottomLabel: true,
          },
          {
            top: '18.1%',
            left: '89.9%',
            tabletLeft: '85%',
            text: 'Shower walls and ceilings are fully tiled',
          },
        ]}
      />
      <Block className="bathroom">
        <BlockContent>
          <h5 className="global black uppercase">Bathrooms</h5>
          <p className="body2 black">
            Design a sanctuary that will relax you while soaking in a tub,
            standing under the spray of a shower, or getting ready for the day
            ahead.
          </p>
        </BlockContent>
      </Block>
    </Root>
  );
};

export default Bathroom;

const Root = styled.div`
  @media ${media.desktop} {
    display: flex;
    margin-bottom: ${vwDesktop(120)};
    padding: 0 ${vwDesktop(60)};
  }
`;

const StyledImageCallout = styled(ImageCallout)`
  @media ${media.desktop} {
    width: ${vwDesktop(752)};
    height: 100%;
  }
`;

const Block = styled.div`
  ${vw('padding-top', 32, 32)}
  ${vw('padding-right', 16, 60)}
  ${vw('padding-bottom', 60, 80, 120)}
  ${vw('padding-left', 16, 60)}
  @media ${media.desktop} {
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 0;
    margin-left: ${vwDesktop(40)};
  }
`;

const BlockContent = styled.div`
  ${vw('max-width', 312, 312)}
  h5 {
    ${vw('margin-bottom', 8, 8)}
  }
`;
