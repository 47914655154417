import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

export default function Privacy() {
  return (
    <Root>
      <Heading>
        <h1 className='corporate white'>Privacy Policy</h1>
      </Heading>
      
      <Body>
        <p className='body1 black'>
          This privacy policy (the "<span className='bold'>Privacy Policy</span>") sets out the privacy policies and practices for Kutak Development Services Ltd. and its affiliated or related entities (collectively, "<span className='bold'>Kutak</span>", "<span className='bold'>us</span>", "<span className='bold'>our</span>" or "<span className='bold'>we</span>") with respect to how Kutak collects, uses and discloses personal information. This Privacy Policy applies to information collected from you by Kutak via <span className='bold'>http://www.kutakdevelopment.com/</span> (the "<span className='bold'>Website</span>") and other places where or means by which we may collect personal information, including but not limited to email, social media platforms, telephone, in-person, questionnaires, assessment forms, and products and services provided by Kutak (collectively, the "<span className='bold'>Services</span>"). This Privacy Policy also sets out how you can access certain information that Kutak may collect about you. In this Privacy Policy, “personal information” means information or opinion about an individual whose identity is apparent or can be reasonably ascertained from the information or opinion as further defined under applicable privacy laws, including, but not limited to, other personal information collected through either or both the Website and Services.
          <br/><br/>
          Please note that the Website and Services may contain links to other third party websites that are not controlled or operated by Kutak. This Privacy Policy does not apply to such third party websites, and Kutak is not responsible for the content of such third party websites or the privacy practices of such third parties. Therefore, Kutak encourages you to request and review the privacy policies of any third parties before disclosing your personal information to such parties or when visiting such third party websites.
        </p>

        <h4 className='global black uppercase'>Consent</h4>
        <p className='body1 black'>
          BY SUBMITTING PERSONAL INFORMATION TO KUTAK OR ITS SERVICE PROVIDERS AND AGENTS, YOU AGREE THAT KUTAK MAY COLLECT YOUR PERSONAL INFORMATION AND YOU CONSENT TO THE USE, DISCLOSURE AND TRANSFER OF YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THIS PRIVACY POLICY AND AS PERMITTED OR REQUIRED BY LAW. Subject to legal and contractual requirements, you may refuse or withdraw your consent to certain of the purposes identified herein at any time by contacting Kutak at the address provided below. If you refuse or withdraw your consent, you acknowledge that Kutak may not be able to provide you or continue to provide you with certain services or information which may be of value to you.
        </p>

        <h4 className='global black uppercase'>Revisions to this Privacy Policy</h4>
        <p className='body1 black'>
          Kutak reserves the right, in Kutak’s sole discretion, to change, modify, add or remove portions of this Privacy Policy at any time and from time to time, without prior notice to you. However, Kutak will treat your continued use of either or both the Website and Services following such revision as your acceptance of the revised terms. All revisions will be posted to the Website and will apply to any personal information collected on or after the date posted. Kutak will obtain the necessary consents required under applicable privacy laws if it seeks to collect, use or disclose your personal information for purposes other than those to which consent has been obtained unless otherwise required or permitted by law.
          <br/><br/>
          This Privacy Policy was last updated June 19,2020.
        </p>

        <h4 className='global black uppercase'>Collection of your Information</h4>
        <p className='body1 black'>
          Kutak may collect personal information in connection with your use of any or all of the Website, the Services or the purchase of Kutak products and services, which may include information provided in connection with the following activities:
          <br/><br/>
          <ul>
            <li><span>Using and inputting information into either or both the Website and Services. This includes information you may provide when you sign up to receive Kutak e-mail newsletters or other services, submit information or questionnaires to Kutak;</span></li>
            <li><span>Providing feedback or making other submissions to Kutak;</span></li>
            <li><span>Requesting information or assistance from Kutak;</span></li>
            <li><span>Kutak may also keep a copy of any communications or correspondence you may send to it, including any e-mail communications;</span></li>
            <li><span>Using other features of either or both the Website and Services that may be offered from time to time, and may require such information in order to utilize the feature.</span></li>
          </ul>
          <br/>
          The personal information that Kutak may collect may include your name, address, email address, telephone number, age, date of birth, marital status, number of children, and any other personal information that you choose to submit to Kutak through either or both the Website and Services, or in other communications to Kutak. You represent and warrant that you have the right and authority or have obtained all necessary consents to provide any information, including any personal information of another individual, that is provided by you to Kutak.
          <br/><br/>
          Kutak may also collect information that may not be personal information, which may include, but is not limited to, the frequency of user visits to the Website, the routes by which users access the Website and use of any hyperlinks available on the Website. Kutak or its service providers and agents may process and use this aggregate data for various purposes including, but not limited to, analysis of this data for trends and statistics, development of the Website, and the Website’s features and offerings, assessing patterns of use, or planning and evaluating marketing initiatives. This information is gathered by certain tools and methods such as the collection of your IP address or the use of cookies and is maintained, used and disclosed in aggregate form unless otherwise noted.
        </p>
        
        <h4 className='global black uppercase'>Protection of your Information</h4>
        <p className='body1 black'>
          To prevent unauthorized access or disclosure, maintain data accuracy and facilitate the appropriate use of information, Kutak uses physical, technological and administrative procedures to protect the personal information that Kutak collects from loss, unauthorized access, modification, disclosure or other misuse.
        </p>

        <h4 className='global black uppercase'>Use of your Personal Information</h4>
        <p className='body1 black'>
          Upon Kutak’s collection of your personal information, Kutak may use such personal information internally, separately or in combination with pre-existing information, for the following purposes:
          <br/><br/>
          <ul>
            <li><span>To develop, enhance, market, sell or otherwise provide information, products or services;</span></li>
            <li><span>To contact you and to respond to any communications that you may have had with us;</span></li>
            <li><span>To troubleshoot problems with either or both the Website and Services;</span></li>
            <li><span>To develop and manage Kutak’s business and operations;</span></li>
            <li><span>To enforce Kutak’s Terms of Use or comply with this Privacy Policy;</span></li>
            <li><span>To detect and protect Kutak and other third parties against error, negligence, breach of contract, fraud, theft and other illegal activity, and to audit compliance with Kutak’s policies and contractual obligations;</span></li>
            <li><span>To engage in business transactions, including the purchase, sale, lease, merger, amalgamation or any other type of acquisition, disposal, securitization or financing involving Kutak, as permitted by, and to comply with, any legal or regulatory requirements or provisions; and</span></li>
            <li><span>For any other purpose to which you consent or that is otherwise permitted or required by law.</span></li>
          </ul>
        </p>
        
        <h4 className='global black uppercase'>Disclosure of your Information</h4>
        <p className='body1 black'>
          Kutak may disclose your personal information to its subsidiaries or affiliates and to third parties who Kutak hires to provide services on Kutak’s behalf, including, but not limited to, website services, delivering promotional materials and answering customer questions about Kutak’s products and services. These subsidiaries, affiliates and third parties may be located overseas. Kutak will only provide those third parties with the personal information that they need to deliver the services to Kutak or on Kutak’s behalf. Kutak will not disclose your personal information to third parties for their direct marketing purposes without obtaining prior affirmative consent. In the event that Kutak is involved in a business transaction, including the purchase, sale, lease, merger, amalgamation or any other type of acquisition, disposal, securitization or financing involving Kutak, you consent to your personal information and any other information Kutak may have collected being transferred to another party in connection with such transaction. You also consent to Kutak disclosing your personal information to legal, financial, insurance, or other advisors in connection with such business transactions or management of all or part of Kutak’s business or operations; as consented to by you from time to time, including to fulfill any other purposes that are identified when the personal information is collected; or as otherwise permitted or required by law.
        </p>

        <h4 className='global black uppercase'>Choice and Opt-Out Preferences</h4>
        <p className='body1 black'>
          Kutak does not collect your personal information unless you choose to provide it. If, at any time, you prefer not to receive further email communications from Kutak, you will have the ability to unsubscribe from such communications by contacting: <a href='mailto:sales@kutakdevelopment.com'>sales@kutakdevelopment.com</a>. 
        </p>

        <h4 className='global black uppercase'>Access and Correction</h4>
        <p className='body1 black'>
          Kutak will take reasonable steps to ensure the personal information it holds is accurate, complete and up to date. Upon your written request, subject to certain exceptions provided by law, Kutak will inform you of the existence, use and disclosure of your personal information and give you access to that information. Additionally, if you have any questions or enquiries relating to our privacy practices or procedures, or if you believe that the personal information that Kutak holds about you is not accurate, complete or up to date you may write to the Privacy Officer at the address provided below. If you are able to establish that the information is not accurate, complete or up to date, Kutak will take reasonable steps to correct the information so that it is accurate, complete and up to date.
        </p>

        <h4 className='global black uppercase'>Request for Access and Correction, Questions or Comments</h4>
        <p className='body1 black'>
          Kutak welcomes questions and comments about this Privacy Policy. Requests for access and correction of your personal information, and questions or comments should be directed to:
          <br/><br/>
          Kutak Development Services Ltd.
          <br/>
          2007-1177 West Hastings St.
          <br/>
          Vancouver, BC V6E 2K3
          <br/><br/>
          or
          <br/><br/>
          <a href='mailto:sales@kutakdevelopment.com'>sales@kutakdevelopment.com</a>
        </p>
      </Body>
    </Root>
  )
}

const Root = styled.div``;

const Heading = styled.div`
  ${vw('padding-top', 40, 80)}
  ${vw('padding-right', 16, 60)}
  ${vw('padding-bottom', 80, 80)}
  ${vw('padding-left', 16, 60)}
  ${vw('height', 164, 232)}
`;

const Body = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  ${vw('padding-top', 40, 60, 80)}
  ${vw('padding-right', 16, 60, 360)}
  ${vw('padding-bottom', 40, 60, 80)}
  ${vw('padding-left', 16, 60, 360)}
  h3 {
    ${vw('margin-bottom', 16, 20)}
  }
  h4 {
    ${vw('margin-bottom', 12, 12)}
  }
  h5 {
    ${vw('margin-bottom', 12, 12)}
  }
  p {
    ${vw('margin-bottom', 32, 40)}
  }
  li {
    list-style: none;
    position: relative;
    &:not(:last-of-type) {
      ${vw('margin-bottom', 12, 12)}
    }
    ${vw('padding-left', 36)}
    &:before {
      content: '';
      position: absolute;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.black};
      ${vw('top', 14)}
      ${vw('left', 18)}
      ${vw('width', 6)}
      ${vw('height', 6)}
    }
  }
  a {
    color: black;
    text-decoration: underline;
  }
`;