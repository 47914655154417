import appConfig from 'src/config/app.conf';
const PROXY = 'https://bam-cors-anywhere.herokuapp.com/';

export default async function request(url) {
  const response = await fetch(url);
  const result = await response.json();

  return result;
}

export const getRequest = async (project) => {
  const result = await request(`${appConfig.siteUrl}/${appConfig.wordpressAPIUrl}/${project}`);

  return result;
};

export const getRequestCategories = async () => {
  const result = await request(`${appConfig.siteUrl}/${appConfig.wordpressAPIUrl}/categories`);

  return result;
}