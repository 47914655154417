import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Description = (props) => {
  const { active, children, handleClose } = props;

  const $description = useRef();

  return (
    <Root
      style={{
        opacity: active ? 1 : 0,
        pointerEvents: active ? 'all' : 'none',
      }}
      active={active}
      ref={$description}
      height={$description.current && $description.current.scrollHeight}
    >
      <img
        src={require('src/assets/images/global/icon/action/action-close.svg')}
        alt="Close"
        onClick={handleClose}
      />
      {children}
    </Root>
  );
};

Description.propTypes = {
  active: PropTypes.bool,
  handleClose: PropTypes.func,
};

const Root = styled.div`
  transition: 0.4s ease;
  max-height: ${(props) => (props.active ? `${props.height}px` : 0)};
  margin-top: ${(props) => (props.active ? vwMobile(12) : 0)};
  overflow: hidden;
  img {
    display: none;
  }
  ul {
    margin-top: ${vwMobile(20)};
    margin-left: ${vwMobile(40)};
  }
  li {
    margin-bottom: ${vwMobile(5)};
  }
  .subpoint {
    margin-left: ${vwMobile(20)};
    list-style-type: circle;
  }
  @media ${media.tablet} {
    margin-top: ${(props) => (props.active ? vwTablet(12) : 0)};
    ul {
      margin-top: ${vwTablet(20)};
      margin-left: ${vwTablet(40)};
    }
    li {
      margin-bottom: ${vwTablet(5)};
    }
    .subpoint {
      margin-left: ${vwTablet(20)};
    }
  }
  @media ${media.desktop} {
    background-color: ${({ theme }) => theme.color.white};
    max-height: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: ${vwDesktop(104)} ${vwDesktop(60)};
    z-index: 5;
    margin-top: 0;
    img {
      display: block;
      position: absolute;
      top: ${vwDesktop(40)};
      right: ${vwDesktop(60)};
      width: ${vwDesktop(24)};
      cursor: pointer;
    }
    p,
    ul {
      max-width: ${vwDesktop(300)};
    }
    ul {
      margin-top: ${vwDesktop(20)};
      margin-left: ${vwDesktop(40)};
    }
    li {
      margin-bottom: ${vwDesktop(5)};
    }
    .subpoint {
      margin-left: ${vwDesktop(20)};
    }
  }
`;

export default Description;
