import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const HeaderText = (props) => {
  const { title, text, to, linkText } = props;
  return (
    <Root to={to}>
      <h1 className="project black">{title}</h1>
      <div>
        <p className="intro">{text}</p>
        {to && <Button to={to} label={linkText} type="text-link-gold" />}
      </div>
    </Root>
  );
};

HeaderText.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
};

export default HeaderText;

const Root = styled.div`
  margin: ${vwMobile(48)} ${vwMobile(16)};
  background-color: white;
  p {
    margin: ${vwMobile(20)} 0;
  }
  @media ${media.tablet} {
    margin: ${vwTablet(80)} ${vwTablet(60)};
    h1 {
      max-width: ${vwTablet(400)};
    }
    p {
      margin: ${vwTablet(40)} 0;
    }
  }
  @media ${media.desktop} {
    display: flex;
    padding-left: ${vwDesktop(60)};
    margin: ${vwDesktop(112)} 0 ${vwDesktop(102)};
    h1 {
      width: ${vwDesktop(413)};
      margin-right: ${vwDesktop(67)};
    }
    p {
      max-width: ${vwDesktop(726)};
      margin: 0;
      margin-bottom: ${(props) => (props.to ? vwDesktop(40) : 0)};
      margin-top: ${vwDesktop(12)};
    }
  }
`;
