import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { vwDesktop, vwMobile, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

// import data from 'src/data/Projects';

const Configurator = (props) => {
  const { isConfig, data, location } = props;

  // const [activeProject, setActiveProject] = useState(null);
  const [activePage, setActivePage] = useState(null);

  const activeProjectSlug = useSelector((state) => state.project.activeProject);

  const history = useHistory();

  useEffect(() => {
    // if (activeProjectSlug) {
    //   for (let i = 0; i < data.length; i++) {
    //     if (data[i].slug === activeProjectSlug) {
    //       setActiveProject(data[i]);
    //       break;
    //     }
    //   }
    // }
    if (location) {
      const activePage = data.filter((item) => location.includes(item.to));
      if (activePage[0]) {
        setActivePage(data.indexOf(activePage[0]));
      } else {
        setActivePage(null);
      }
    }
  }, [activeProjectSlug, location, data]);

  return (
    <>
      <Root isConfig={isConfig}>
        <div>
          <p className="project-nav uppercase gold">
            {/* {activeProject && activeProject.navTitle} */}
            <span>One</span>Shaughnessy
          </p>
          <p className="project-nav uppercase gold line">|</p>
          <p className="project-nav uppercase design">design your home</p>
        </div>
        <img
          src={require('src/assets/images/global/icon/kutak/kutak-xp.svg')}
          alt="Kutak"
        />
      </Root>
      <Subnav
        active={activePage !== null}
        style={{ position: '-webkit-sticky', top: 0 }}
      >
        <Arrow
          src={require('src/assets/images/global/icon/action/chevron-left-gold.svg')}
          alt="Previous"
          onClick={() =>
            activePage === 0
              ? history.push('/design')
              : history.push(
                  data[(activePage !== null ? activePage : 1) - 1].to
                )
          }
        />
        {data.map((item, index) => (
          <Item
            className="nav configurator"
            key={index}
            active={location.includes(item.to)}
            to={item.to}
          >
            <span>0{index + 1}</span>
            {item.label}
          </Item>
        ))}
        <Arrow
          src={require('src/assets/images/global/icon/action/chevron-right-gold.svg')}
          alt="Next"
          onClick={() =>
            activePage === data.length - 1
              ? history.push('/design/save')
              : history.push(
                  data[(activePage !== null ? activePage : 1) + 1].to
                )
          }
        />
      </Subnav>
    </>
  );
};

Configurator.propTypes = {
  isConfig: PropTypes.bool,
  location: PropTypes.string,
  data: PropTypes.array,
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.black};
  height: ${vwMobile(80)};
  padding: 0 ${vwMobile(16)};
  justify-content: space-between;
  align-items: center;
  display: ${(props) => (props.isConfig ? 'flex' : 'none')};
  img {
    width: ${vwMobile(56)};
    display: block;
  }
  span {
    font-weight: 900;
  }
  .design {
    color: ${({ theme }) => theme.color.white};
  }
  .line {
    display: none;
  }
  @media ${media.tablet} {
    height: ${vwTablet(80)};
    padding: 0 ${vwTablet(30)};
    img {
      width: ${vwTablet(60)};
    }
    > div {
      display: flex;
      align-items: center;
    }
    .design {
      color: ${({ theme }) => theme.color.gold};
    }
    .line {
      display: block;
      margin: 0 ${vwTablet(24)};
    }
  }
  @media ${media.desktop} {
    height: ${vwDesktop(80)};
    padding: 0 ${vwDesktop(60)};
    img {
      width: ${vwDesktop(60)};
    }
    .line {
      margin: 0 ${vwDesktop(24)};
    }
  }
  @media print {
    padding: 0 60px;
    height: 80px;
    > div {
      display: flex;
      align-items: center;
    }
    img {
      width: 60px;
    }
    .line {
      margin: 0 24px;
      display: block;
    }
    .project-nav {
      font-size: 15px;
      letter-spacing: 3px;
    }
  }
`;

const Subnav = styled.div`
  background-color: ${({ theme }) => theme.color.lightGrey};
  height: ${vwMobile(48)};
  padding: 0 ${vwMobile(16)};
  display: ${(props) => (props.active ? 'flex' : 'none')};
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  @media ${media.tablet} {
    padding: 0 ${vwTablet(30)};
    height: ${vwTablet(50)};
  }
  @media ${media.desktop} {
    justify-content: flex-start;
    height: ${vwDesktop(50)};
    padding: 0 ${vwDesktop(60)};
  }
`;

const Item = styled(Link)`
  color: ${({ theme }) => theme.color.gold};
  display: ${(props) => (props.active ? 'block' : 'none')};
  span {
    margin-right: ${vwMobile(10)};
  }
  @media ${media.tablet} {
    display: block;
    color: ${(props) =>
      props.active ? props.theme.color.gold : props.theme.color.textGrey};
    position: relative;
    margin-top: ${vwTablet(-2)};
    span {
      margin-right: ${vwTablet(10)};
    }
    &::after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: ${({ theme }) => theme.color.gold};
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      opacity: ${(props) => (props.active ? 1 : 0)};
      margin-top: -2px;
    }
  }
  @media ${media.desktop} {
    margin-right: ${vwDesktop(64)};
    transition: 0.3s ease;
    margin-top: ${vwDesktop(-2)};
    span {
      margin-right: ${vwDesktop(10)};
    }
  }
`;

const Arrow = styled.img`
  width: ${vwMobile(12)};
  @media ${media.tablet} {
    display: none;
  }
`;

export default Configurator;
