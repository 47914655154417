import React from 'react';

export default {
  title: 'The Stanton',
  slug: 'the-stanton',
  hero: {
    badge: require('src/assets/images/global/icon/kutak/kutak-kis.svg'),
    heading: <>Distinguished residences in Kerrisdale.</>,
    bodyTitle: '',
    body: [],
  },
  infographic: [
    {
      label: 'Size',
      text: '4-storey building; 30,000 SF; 2.5 FSR',
    },
    {
      label: 'Developer',
      text: 'Stanton Holdings (GP) Ltd.',
    },
    {
      label: 'Address',
      text: '2089 West 43rd Avenue, Vancouver, BC',
    },
    {
      label: 'Type',
      text:
        '17 residential homes; 4 commercial strata units; 38 residential parking stalls; 5 commercial parking stalls',
    },
  ],
  content: {
    projectName: 'The Stanton',
    featuredImage: require('src/assets/images/philosophy/kis-case-study/kutak-stanton-feature.jpg'),
    bodyTitle: 'Bringing classic luxury to life.',
    bodyText: (
      <>
        Kutak acted as the Owner’s Representative throughout the development of
        The Stanton, a collection of 17 homes and four retail units within a
        four-storey concrete building in Vancouver’s prestigious Kerrisdale
        neighbourhood.
        <br />
        <br />
        We skilfully and efficiently steered the project through acquisition,
        municipal approvals, marketing, tendering, construction, and closings.
        Thanks in part to our strategic and hands-on approach, the project
        achieved the highest selling price per square foot ever seen in a
        Kerrisdale multi-family building – amounting to over $1,600 per square
        foot.
        <br />
        <br />
        The completed project has won both a HAVAN Awards for Housing Excellence
        and Georgie Award Winner for Best Multi-Family Low Rise, as well as a
        Georgie Award Finalist for Best Kitchen – Multifamily and Multi-Family
        Home Builder of the Year.
      </>
    ),
    stats1Percentage: '34%',
    stats1Label: 'Lorem isum dolor sit amet',
    stats2Percentage: '18%',
    stats2Label: 'Lorem isum dolor sit amet',
  },
};
