export default [
  {
    img: require('src/assets/images/oneshaughnessy/community/1-one-shaughnessey-community-brewery.jpg'),
    grid: true,
    large: true,
  },
  {
    img: require('src/assets/images/oneshaughnessy/community/2-one-shaughnessey-community-market.jpg'),
    grid: false,
  },
  {
    img: require('src/assets/images/oneshaughnessy/community/11-one-shaughnessey-community-fields.jpg'),
    grid: true,
  },
  {
    img: require('src/assets/images/oneshaughnessy/community/6-one-shaughnessey-community-market-produce.jpg'),
    grid: true,
  },
  {
    img: require('src/assets/images/oneshaughnessy/community/4-one-shaughnessey-community-coffee-shop.jpg'),
    grid: true,
  },
  {
    img: require('src/assets/images/oneshaughnessy/community/3-one-shaughnessey-community-transit.jpg'),
    grid: true,
  },
  {
    img: require('src/assets/images/oneshaughnessy/community/5-one-shaughnessey-community-trails.jpg'),
    grid: false,
  },
  {
    img: require('src/assets/images/oneshaughnessy/community/7-one-shaughnessey-community-streetscape.jpg'),
    grid: false,
  },
  {
    img: require('src/assets/images/oneshaughnessy/community/8-one-shaughnessey-community-golf-course.jpg'),
    grid: true,
  },
  {
    img: require('src/assets/images/oneshaughnessy/community/9-one-shaughnessey-community-earls.jpg'),
    grid: false,
  },
  {
    img: require('src/assets/images/oneshaughnessy/community/10-one-shaughnessey-community-bowling.jpg'),
    grid: false,
  },
  {
    img: require('src/assets/images/oneshaughnessy/community/12-one-shaughnessey-community-city-centre.jpg'),
    grid: false,
  },
  {
    img: require('src/assets/images/oneshaughnessy/community/13-one-shaughnessey-community-plaza.jpg'),
    grid: false,
  },
];
