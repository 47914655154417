// Constants
export const KITCHEN__SETPALETTE = 'KITCHEN::SETPALETTE';
export const KITCHEN__SETFLOORING = 'KITCHEN::SETFLOORING';
export const BATH__SETPALETTE = 'BATH::SETPALETTE';
export const BATH__SETCEILING = 'BATH::SETCEILING';
export const PLANS__SELECT = 'PLANS::SELECT';
export const UPGRADES__SELECT = 'UPGRADE::SELECT';
export const EMAIL__SET = 'EMAIL::SET';
export const PROJECT__SET = 'EMAIL::SET';
export const SET__ALL = 'SET::ALL';

// Actions
export const setKitchenPalette = (index) => ({
  type: KITCHEN__SETPALETTE,
  palette: index,
});

export const setKitchenFlooring = (index) => ({
  type: KITCHEN__SETFLOORING,
  flooring: index,
});

export const setBathPalette = (index) => ({
  type: BATH__SETPALETTE,
  palette: index,
});

export const setBathCeiling = (index) => ({
  type: BATH__SETCEILING,
  ceiling: index,
});

export const selectPlan = (id) => ({
  type: PLANS__SELECT,
  plan: id,
});

export const selectUpgrade = (upgrade) => ({
  type: UPGRADES__SELECT,
  upgrade,
});

export const setEmail = (email) => ({
  type: EMAIL__SET,
  email,
});

export const setProject = (project) => ({
  type: PROJECT__SET,
  project,
});

export const setAll = (object) => ({
  type: SET__ALL,
  object,
});

// Initial State
const initialState = {
  email: null,
  project: 'One Shaughnessy',
  kitchen: {
    palette: 0,
    flooring: 0,
  },
  bath: {
    palette: 0,
    ceiling: 0,
  },
  plans: [],
  upgrades: [],
};

// Reducer
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case KITCHEN__SETPALETTE:
      return {
        ...state,
        kitchen: {
          palette: action.palette,
          flooring: state.kitchen.flooring,
        },
      };
    case KITCHEN__SETFLOORING:
      return {
        ...state,
        kitchen: {
          palette: state.kitchen.palette,
          flooring: action.flooring,
        },
      };
    case BATH__SETPALETTE:
      return {
        ...state,
        bath: {
          palette: action.palette,
          ceiling: state.bath.ceiling,
        },
      };
    case BATH__SETCEILING:
      return {
        ...state,
        bath: {
          palette: state.bath.palette,
          ceiling: action.ceiling,
        },
      };
    case PLANS__SELECT:
      const plansCopy = state.plans;
      if (state.plans.includes(action.plan)) {
        plansCopy.splice(state.plans.indexOf(action.plan), 1);
        return {
          ...state,
          plans: plansCopy,
        };
      } else {
        plansCopy.push(action.plan);
        return {
          ...state,
          plans: plansCopy,
        };
      }
    case UPGRADES__SELECT:
      const upgradesCopy = state.upgrades;
      if (state.upgrades.includes(action.upgrade)) {
        upgradesCopy.splice(state.upgrades.indexOf(action.upgrade), 1);
        return {
          ...state,
          upgrades: upgradesCopy,
        };
      } else {
        upgradesCopy.push(action.upgrade);
        return {
          ...state,
          upgrades: upgradesCopy,
        };
      }
    case EMAIL__SET:
      return {
        ...state,
        email: action.email,
      };
    case PROJECT__SET:
      return {
        ...state,
        project: action.project,
      };
    case SET__ALL:
      return {
        ...action.object,
      };
    default:
      return state;
  }
};
