import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Corporate from './sections/Corporate';
import Project from './sections/Project';
import Configurator from './sections/Configurator';
import Membership from './sections/Membership';

import data from 'src/data/Components/NavLinks';

export default function Header(props) {
  const [hasScrolledDown, setHasScrolledDown] = useState(false);

  const location = useLocation();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = (e) => {
    if (e.target.scrollingElement.scrollTop > 150) {
      setHasScrolledDown(true);
    } else {
      setHasScrolledDown(false);
    }
  };

  return (
    <>
      <Root isConfig={location.pathname.includes('design')}>
        <Corporate
          navLinks={data.corporate}
          isProject={location.pathname.includes('/homes/')}
          isConfig={location.pathname.includes('design')}
          isMembership={location.pathname.includes('membership')}
          hasScrolledDown={hasScrolledDown}
        />
        <Project
          isProject={location.pathname.includes('/homes/')}
          hasScrolledDown={hasScrolledDown}
        />
      </Root>
      <Configurator
        isConfig={location.pathname.includes('design')}
        data={data.configurator}
        location={location.pathname}
      />
      <Membership
        isMembership={location.pathname.includes('membership')}
        location={location.pathname}
      />
    </>
  );
}

Header.propTypes = {
  isProject: PropTypes.bool,
};

const Root = styled.header`
  position: ${(props) => (props.isConfig ? 'static' : 'fixed')};
  display: ${(props) => (props.isConfig ? 'unset' : 'block')};
  width: 100%;
  z-index: 9999;
`;
