import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';

function Select(props) {
  const { label, required, options, name, id } = props;

  return (
    <Root>
      <label>
        {label} {required && '*'}
      </label>
      <Wrapper>
        <select name={name} id={id} defaultValue="default">
          <option disabled value="default">
            - Select One -
          </option>
          {options.map((option) => (
            <option value={option.value} key={label + option.title}>
              {option.title}
            </option>
          ))}
        </select>
      </Wrapper>
    </Root>
  );
}

Select.propTypes = {
  label: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.array,
  name: PropTypes.string,
  id: PropTypes.string,
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  ${vw('margin-top', 23, 24)};

  label {
    font-family: 'acumin-pro-extra-condensed';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.gold};
    ${vw('font-size', 16, 18)};
    ${vw('letter-spacing', 2.31, 2.6)};
    ${vw('margin-bottom', 4)};
  }
`;

const Wrapper = styled.div`
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    ${vw('right', 16, 17)};
    ${vw('width', 16, 16)};
    ${vw('height', 16, 16)};
    background: url(${require('src/assets/images/global/icon/action/chevron-down-gold.svg')});
    background-size: cover;
    pointer-events: none;
  }

  select {
    appearance: none;
    background: none;
    outline: none;
    width: 100%;
    font-family: futura-pt;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: white;
    border: solid 1px ${({ theme }) => theme.color.gold};
    border-radius: 0;
    ${vw('height', 46, 50)};
    ${vw('font-size', 16, 18)};
    ${vw('line-height', 24)};
    ${vw('letter-spacing', 0, 0.5)};
    ${vw('padding-left', 12, 17)};

    &:focus {
      border: solid 1px white;
    }
  }
`;

export default Select;
