import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Title from './Title';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const SummaryBlock = (props) => {
  const {
    title,
    toEdit,
    id,
    secondAttribute,
    text,
    image,
    palette,
    secondAttributeImage,
    paletteIndex,
    labelRight,
    small,
  } = props;

  return (
    <Root>
      <Title title={title} to={toEdit} />
      <Content small={small}>
        <Image src={image} />
        <div>
          <h5 className="global gold uppercase">Palette</h5>
          <Palette>
            <img src={palette} alt="Palette" className="palette" />
            <Label right={labelRight}>
              <h3 className="configurator white">
                {title}
                <br />
                {id}
                {paletteIndex + 1}
              </h3>
            </Label>
          </Palette>
          <h5 className="global gold uppercase">{secondAttribute}</h5>
          <img
            src={secondAttributeImage}
            alt={secondAttribute}
            className="second-image"
          />
          <p className="configurator caption grey">{text}</p>
        </div>
      </Content>
    </Root>
  );
};

SummaryBlock.propTypes = {
  title: PropTypes.string,
  toEdit: PropTypes.string,
  id: PropTypes.string,
  secondAttribute: PropTypes.string,
  secondAttributeImage: PropTypes.string,
  image: PropTypes.string,
  palette: PropTypes.string,
  text: PropTypes.object,
  paletteIndex: PropTypes.number,
  labelRight: PropTypes.number,
  small: PropTypes.bool,
};

const Root = styled.div`
  margin-bottom: ${vwMobile(60)};
  @media ${media.tablet} {
    margin-bottom: ${vwTablet(60)};
  }
`;

const Content = styled.div`
  > div {
    padding: 0 ${vwMobile(16)};
  }
  .palette {
    width: ${vwMobile(272)};
    margin-bottom: ${vwMobile(24)};
  }
  .second-image {
    width: ${(props) => (props.small ? vwMobile(40) : vwMobile(54))};
  }
  h5 {
    margin-bottom: ${vwMobile(8)};
  }
  p {
    margin-top: ${vwMobile(24)};
  }
  @media ${media.tablet} {
    > div {
      padding: 0 ${vwTablet(60)};
    }
    .palette {
      width: ${vwTablet(300)};
    }
    .second-image {
      width: ${(props) => (props.small ? vwTablet(44) : vwTablet(54))};
    }
    h5 {
      margin-bottom: ${vwTablet(12)};
    }
    p {
      margin-top: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    display: flex;
    > div {
      padding: 0;
    }
    .palette {
      width: ${vwDesktop(300)};
      margin-bottom: 0;
    }
    .second-image {
      width: ${(props) => (props.small ? vwDesktop(44) : vwDesktop(60))};
    }
    h5 {
      margin: ${vwDesktop(40)} 0 ${vwDesktop(12)};
    }
    p {
      margin-top: ${vwDesktop(40)};
    }
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
  height: ${vwMobile(276)};
  margin-bottom: ${vwMobile(24)};
  @media ${media.tablet} {
    height: ${vwTablet(662)};
    margin-bottom: ${vwTablet(40)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(1020)};
    height: ${vwDesktop(880)};
    margin-bottom: 0;
    margin-right: ${vwDesktop(60)};
  }
`;

const Palette = styled.div`
  position: relative;
  width: fit-content;
`;

const Label = styled.div`
  position: absolute;
  top: ${vwMobile(14)};
  right: ${(props) => vwMobile(props.right)};
  @media ${media.tablet} {
    top: ${vwTablet(14)};
    right: ${(props) => vwTablet(props.right)};
  }
  @media ${media.desktop} {
    top: ${vwDesktop(14)};
    right: ${(props) => vwDesktop(props.right)};
  }
`;

export default SummaryBlock;
