import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Button from 'components/Button';
import ConfigCallout from 'components/ConfigCallout';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ContentBlock = (props) => {
  const {
    title,
    subtitle,
    text,
    to,
    linkText,
    image,
    leftImage,
    config,
  } = props;
  return (
    <Root>
      <Content leftImage={leftImage}>
        <div>
          <h2 className="global gold uppercase">{subtitle}</h2>
          <h3 className="project black">{title}</h3>
        </div>
        <div>
          <p className="body2 black">{text}</p>
          <Button type="text-link-gold" label={linkText} to={to} />
        </div>
      </Content>
      <Image leftImage={leftImage}>
        <Fade><img src={image} alt="" /></Fade>
      </Image>
      {config && (
        <Config>
          <ConfigCallout />
        </Config>
      )}
    </Root>
  );
};

ContentBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  to: PropTypes.string,
  linkText: PropTypes.string,
  image: PropTypes.string,
  leftImage: PropTypes.bool,
  config: PropTypes.bool,
};

export default ContentBlock;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  @media ${media.desktop} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-left: ${vwDesktop(60)};
    margin: ${vwDesktop(160)} 0;
  }
`;

const Content = styled.div`
  order: 3;
  margin: ${vwMobile(32)} ${vwMobile(16)} ${vwMobile(60)};
  h3 {
    margin: ${vwMobile(16)} 0;
  }
  p {
    margin-bottom: ${vwMobile(24)};
  }
  @media ${media.tablet} {
    display: flex;
    margin: ${vwTablet(40)} ${vwTablet(60)} ${vwTablet(80)};
    h3 {
      margin: ${vwTablet(20)} 0 0;
    }
    p {
      margin-top: ${vwTablet(56)};
      margin-bottom: ${vwTablet(40)};
    }
    > div {
      width: ${vwTablet(300)};
    }
    > div:first-of-type {
      margin-right: ${vwTablet(36)};
    }
  }
  @media ${media.desktop} {
    display: block;
    max-width: ${vwDesktop(300)};
    order: ${(props) => (props.leftImage ? 2 : 1)};
    margin: 0;
    margin-right: ${(props) => (props.leftImage ? vwDesktop(174) : 0)};
    h3 {
      margin: ${vwDesktop(20)} 0;
    }
    p {
      margin-top: 0;
      margin-bottom: ${vwDesktop(40)};
    }
    > div {
      width: auto;
    }
    > div:first-of-type {
      margin-right: 0;
    }
  }
`;

const Image = styled.div`
  order: 1;
  width: 100%;
  @media ${media.desktop} {
    width: ${(props) => (props.leftImage ? vwDesktop(752) : vwDesktop(900))};
    order: ${(props) => (props.leftImage ? 1 : 2)};
  }
`;

const Config = styled.div`
  order: 2;
  @media ${media.desktop} {
    width: 100%;
    order: 3;
    display: flex;
    justify-content: flex-end;
  }
`;
