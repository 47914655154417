import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Filters from './sections/Filters';
import Card from './elements/Card';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Floorplans = (props) => {
  const {
    data,
    filters,
    slug,
    configurator,
    handlePopup,
    handlePlan,
    activePlans,
  } = props;

  const [activeFilter, setActiveFilter] = useState(null);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (
      history.location.state &&
      history.location.state.activeFilter !== null
    ) {
      handleFilter(history.location.state.activeFilter);
    }
  }, [history.location.state]);

  const handleFilter = (index) => {
    setIsTransitioning(true);
    setActiveFilter(index);
    setTimeout(() => {
      setIsTransitioning(false);
    }, 500);
  };

  return (
    <Root>
      <Filters
        filters={filters}
        activeFilter={activeFilter}
        handleFilter={handleFilter}
      />
      <Container style={{ opacity: isTransitioning ? 0 : 1 }}>
        {data.map((item, index) => (
          <Card
            plan={item}
            key={index}
            index={index}
            activeBed={activeFilter !== null ? filters[activeFilter].bed : null}
            slug={slug}
            configurator={configurator}
            handlePlan={handlePlan}
            handlePopup={handlePopup}
            activePlans={activePlans}
          />
        ))}
      </Container>
    </Root>
  );
};

Floorplans.propTypes = {
  data: PropTypes.array,
  filters: PropTypes.array,
  activePlans: PropTypes.array,
  slug: PropTypes.string,
  configurator: PropTypes.bool,
  handlePopup: PropTypes.func,
  handlePlan: PropTypes.func,
};

const Root = styled.div`
  padding: ${vwMobile(40)} ${vwMobile(16)} ${vwMobile(48)};
  background-color: ${({ theme }) => theme.color.skyBlue};
  @media ${media.tablet} {
    padding: ${vwTablet(60)} ${vwTablet(60)} ${vwTablet(80)};
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(80)} ${vwDesktop(60)};
    display: flex;
    justify-content: space-between;
  }
`;

const Container = styled.div`
  transition: 0.5s ease;
  min-height: 400px;
  @media ${media.tablet} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${vwTablet(12)};
  }
  @media ${media.desktop} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: ${vwDesktop(12)};
  }
`;

export default Floorplans;
