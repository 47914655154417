import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import Button from 'components/Button';
import data from 'src/data/Philosophy/data';

import media from 'src/styles/media';
import vw, { vwDesktop } from 'src/styles/utils';

export default function Membership() {
  return (
    <Root>
      <Body>
        <h4 className="global gold uppercase">{data.membership.heading}</h4>
        <p className="body2 black">{data.membership.body}</p>
        <Link to='/membership'>
          <Button
            label="Membership Portal Coming Soon"
            type="text-link-gold"
          />
        </Link>
        <Badge src={data.membership.badge} alt={data.membership.badgeAlt} />
      </Body>
      <TransitionGroup>
        <List>
          {data.membership.list.map((item, key) => {
            return (
              <Item key={key}>
                <Fade delay={key * 200} duration={300}>
                  <h5 className="infographic heading">0{key + 1}</h5>
                  <p className="body2 black">{item}</p>
                </Fade>
              </Item>
            );
          })}
        </List>
      </TransitionGroup>
    </Root>
  );
}

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.lightGrey};
  position: relative;
  ${vw('padding-top', 48, 80, 120)}
  ${vw('padding-right', 16, 60)}
  ${vw('padding-bottom', 48, 80, 120)}
  ${vw('padding-left', 16, 60)}
  @media ${media.desktop} {
    height: ${vwDesktop(718)};
  }

`;

const Body = styled.div`
  position: relative;
  ${vw('margin-bottom', 40, 80, 0)}
  h4 {
    ${vw('margin-bottom', 24, 40)}
  }
  p {
    ${vw('width', 288, 312, 300)}
    ${vw('margin-bottom', 24, 40)}
  }
`;

const Badge = styled.img`
  ${vw('width', 80, 100)};
  ${vw('height', 80, 100)};
  ${vw('margin-top', 40, 0, 80)};
  @media ${media.tablet} {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  @media ${media.desktop} {
    position: static;
  }
`;

const List = styled.div`
  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
  }
  @media ${media.desktop} {
    position: absolute;
    width: ${vwDesktop(640)};
    right: ${vwDesktop(140)};
    top: 50%;
    transform: translateY(-50%);
  }
`;

const Item = styled.div`
  ${vw('width', 200, 272, 280)}
  ${vw('margin-bottom', 0, 40, 60)}
  &:nth-of-type(even) {
    ${vw('margin-left', 0, 64, 80)}
  }
  &:nth-of-type(-n + 2) {
    p {
      ${vw('margin-bottom', 0, 40, 60)}
    }
  }
  &:not(:last-of-type) {
    ${vw('margin-bottom', 24, 0)}
  }
  h5 {
    ${vw('margin-bottom', 12, 12)}
  }
`;
