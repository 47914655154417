import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectUpgrade } from 'src/redux/configurator';

import UpgradeCard from 'components/Configurator/UpgradeCard';
import Button from 'components/Button';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/Configurator/oneshaughnessy';

const Upgrades = () => {
  const dispatch = useDispatch();
  const config = useSelector((state) => state.configurator);

  return (
    <Root>
      <h1 className="configurator black">Select Your Upgrades</h1>
      <Wrapper>
        {data.upgrades.map((item, index) => (
          <UpgradeCard
            item={item}
            key={index}
            active={config.upgrades.includes(item)}
            handleClick={(item) => dispatch(selectUpgrade(item))}
          />
        ))}
      </Wrapper>
      <Container>
        <Button type="primary-arrow" label="Next" to="/design/summary" />
      </Container>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  padding: ${vwMobile(32)} ${vwMobile(16)} ${vwMobile(40)};
  h1 {
    margin-bottom: ${vwMobile(32)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(40)} ${vwTablet(60)};
    h1 {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(40)} ${vwDesktop(60)};
    h1 {
      margin-bottom: ${vwDesktop(40)};
    }
  }
`;

const Wrapper = styled.div`
  @media ${media.tablet} {
    display: grid;
    grid-template-columns: ${vwTablet(240)} ${vwTablet(240)};
    grid-template-rows: auto;
    grid-gap: ${vwTablet(32)};
  }
  @media ${media.desktop} {
    grid-template-columns: ${vwDesktop(240)} ${vwDesktop(240)} ${vwDesktop(240)} ${vwDesktop(
        240
      )};
    grid-gap: ${vwDesktop(32)};
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${vwDesktop(40)};
  @media ${media.tablet} {
    margin-top: ${vwTablet(60)};
  }
  @media ${media.desktop} {
    margin-top: ${vwDesktop(60)};
  }
`;

export default Upgrades;
