const flatten = require('lodash/flatten');
const cloneDeep = require('lodash/cloneDeep');
const appConfig = require('./config/app.conf');

const routes = [
  {
    path: '/',
    component: 'pages/Home/Home',
  },
  {
    path: '/home',
    component: 'pages/Home/Home',
  },
  {
    path: '/homes',
    component: 'pages/Homes/Homes',
  },
  {
    path: '/philosophy',
    component: 'pages/Philosophy/Philosophy',
  },
  {
    path: '/philosophy/kis',
    component: 'pages/KIS/KIS',
  },
  {
    path: '/philosophy/kis/case-study/:id',
    component: 'pages/CaseStudies/CaseStudies',
  },
  {
    path: '/team',
    component: 'pages/Team/Team',
  },
  {
    path: '/contact',
    component: 'pages/Contact/Contact',
  },
  {
    path: '/privacy-policy',
    component: 'pages/Legal/Privacy',
  },
  {
    path: '/terms-of-use',
    component: 'pages/Legal/Terms',
  },
  {
    path: '/homes/:id',
    component: 'pages/Project/Project',
  },
  {
    path: '/homes/one-shaughnessy',
    component: 'pages/Project/Project',
  },
  {
    path: '/homes/:id/:subpage',
    component: 'pages/Project/Subpage',
  },
  {
    path: '/homes/:id/plans/:floorplan',
    component: 'pages/Project/SinglePlan',
  },
  {
    path: '/one',
    component: 'pages/Project/One',
  },
  {
    path: '/design',
    component: 'pages/Design/Landing',
  },
  {
    path: '/design/save',
    component: 'pages/Design/Save',
  },
  {
    path: '/design/kitchen',
    component: 'pages/Design/Kitchen',
  },
  {
    path: '/design/bathroom',
    component: 'pages/Design/Bathroom',
  },
  {
    path: '/design/plans',
    component: 'pages/Design/Plans',
  },
  {
    path: '/design/upgrades',
    component: 'pages/Design/Upgrades',
  },
  {
    path: '/design/summary',
    component: 'pages/Design/Summary',
  },
  {
    path: '/design/print',
    component: 'pages/Design/Print',
  },
  {
    path: '/membership',
    component: 'pages/Membership/Login',
  },
  {
    path: '/membership/resources',
    component: 'pages/Membership/Resources',
  },
  {
    path: '/membership/updates',
    component: 'pages/Membership/Updates',
  },
  {
    path: '/membership/updates/:id',
    component: 'pages/Membership/templates/Post',
  },
  {
    path: '/membership/reset-password',
    component: 'pages/Membership/ResetPassword',
  },
];

const generateRoutes = function () {
  let generatedRoutes = routes;
  let { locales } = appConfig;
  // If there is only one locale, that is the default and therefore does not
  // need to be considered.
  if (locales.length <= 1) {
    locales = [];
  }
  // Strip the default locale from the locale array. We don't need
  // to generate a prerendered path prefixed with the default locale.
  else {
    locales = locales.filter((locale) => locale !== appConfig.defaultLocale);
  }

  // Generate localized routes for each supported locale if there are multiple
  // supported locales.
  if (locales.length > 0) {
    let localizedRoutes = locales.map((locale) => {
      return cloneDeep(routes).map((route) => {
        route.path = `/${locale}${route.path}`;
        return route;
      });
    });
    // Merge the base routes and the localized routes.
    generatedRoutes = flatten(generatedRoutes.concat(localizedRoutes));
  }

  // Finally, add the wildcard route at the end to redirect to 404 page.
  generatedRoutes.push({
    path: '*',
    component: 'pages/NotFound',
  });

  return generatedRoutes;
};

module.exports = generateRoutes();
