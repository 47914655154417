import React from 'react';
import styled from 'styled-components';

import Experience from './sections/Experience';
import HeroCorporateText from 'components/HeroCorporateText';
import HeroCorporateImage from 'components/HeroCorporateImage';
import CaseStudyCards from 'components/CaseStudyCards';
import data from 'src/data/Components/Hero';

export default function Homes() {
  return (
    <Root>
      <HeroCorporateText
        data={data.homes}
        badge={data.homes.badge}
      />
      <HeroCorporateImage
        backgroundColor={({ theme }) => theme.color.skyBlue}
        bodyImage={require('src/assets/images/global/logo/one-shaughnessy-lockup.svg')}
        bodyText='Live in a home designed to reflect your own individuality.'
        projectURL=''
        heroImage={require('src/assets/images/global/render/kutak-os-aerial-render.jpg')}
        heroText='Coming Soon to Port Coquitlam'
      />
      <Experience />
      <CaseStudyCards />
    </Root>
  )
}

const Root = styled.div`

`;