import React from 'react';
import styled from 'styled-components';

import Hero from 'components/Project/Hero';
import HeaderText from 'components/Project/HeaderText';
import Map from 'components/Project/Map/Map';
import GridGallery from 'components/Project/GridGallery/GridGallery';

import mapData from 'src/data/Maps/oneshaughnessey';
import galleryData from 'src/data/Gallery/oneshaughnessey';

const Community = () => {
  return (
    <Root>
      <Hero
        image={require('src/assets/images/oneshaughnessy/one-shaughnessy-community.jpeg')}
      />
      <HeaderText
        title={
          <>
            Community
            <br />
            at the Heart
          </>
        }
        text="Port Coquitlam is rich with warm community energy, lush nature, and urban conveniences. One Shaughnessy’s central location encourages a lifestyle outdoors, where you can walk everywhere."
      />
      <GridGallery
        title={
          <>
            A Snapshot
            <br /> of Home
          </>
        }
        subTitle="Gallery"
        text="From shopping for groceries to sampling craft beers, scenic trail walks to adventure playgrounds, see what life in Port Coquitlam looks like."
        images={galleryData}
      />
      <Map
        image={require('src/assets/images/oneshaughnessy/one-shaughnessey-community-map.png')}
        data={mapData}
      />
    </Root>
  );
};

export default Community;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.white};
`;
