import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';

import media from 'src/styles/media';
import vw, { vwTablet, vwDesktop } from 'src/styles/utils';

export default function Header(props) {
  const { isProject, navLinks, hasScrolledDown, isConfig, isMembership } = props;
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  // const browser = useSelector((state) => state.browser);

  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <Root
      hasScrolled={hasScrolledDown}
      isProject={isProject}
      isConfig={isConfig}
      isMembership={isMembership}
    >
      <Link to="/" onClick={closeMenu}>
        <Logo
          src={require('src/assets/images/global/logo/kutak-white.svg')}
          hasScrolled={hasScrolledDown}
        />
      </Link>
      <NavContainer isProject={isProject}>
        <ProjectLabel
          isProject={isProject}
          isOpen={isOpen}
          className="nav corporate white uppercase"
          to="/homes"
        >
          Homes
        </ProjectLabel>
        <Nav isOpen={isOpen} isProject={isProject}>
          <ul>
            {navLinks.map((link, key) => {
              return (
                <List
                  key={key}
                  index={key}
                  isOpen={isOpen}
                  isProject={isProject}
                  isActive={location.pathname === link.route}
                >
                  <StyledLink
                    className="nav white uppercase"
                    to={link.route}
                    onClick={closeMenu}
                    isActive={location.pathname.includes(link.route)}
                  >
                    {link.label}
                  </StyledLink>
                </List>
              );
            })}
          </ul>
        </Nav>
        <Menu isOpen={isOpen} isProject={isProject} onClick={handleMenu}>
          <span />
        </Menu>
      </NavContainer>
    </Root>
  );
}

Header.propTypes = {
  isProject: PropTypes.bool,
  isConfig: PropTypes.bool,
  isMembership: PropTypes.bool,
  hasScrolledDown: PropTypes.bool,
  navLinks: PropTypes.array,
};

const Root = styled.div`
  width: 100%;
  display: ${(props) => ((props.isConfig || props.isMembership) ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.color.black};
  transition: all 0.3s ease;
  height: ${(props) => (props.hasScrolled && props.isProject ? 0 : '60px')};

  ${vw('padding-left', 16, 30, 60)}
  ${vw('padding-right', 16, 30, 60)}
  z-index: 9999;
  @media ${media.tablet} {
    height: ${(props) => (props.hasScrolled && props.isProject ? 0 : '100px')};
  }
  @media ${media.desktop} {
    overflow: hidden;
    height: ${(props) => {
      if (props.hasScrolled && props.isProject) {
        return 0;
      } else if (props.hasScrolled) {
        return vwDesktop(54);
      } else {
        return vwDesktop(100);
      }
    }};
  }
`;

const Logo = styled.img`
  width: 162px;
  height: 24px;
  transition: all 0.3s ease;
  @media ${media.tablet} {
    width: 189px;
    height: 28px;
  }
  @media ${media.desktop} {
    width: ${(props) =>
      props.hasScrolled ? vwDesktop(134.5) : vwDesktop(235)};
    height: ${(props) => (props.hasScrolled ? vwDesktop(20) : vwDesktop(33))};
  }
`;

const NavContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Nav = styled.nav`
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  width: 100vw;
  position: absolute;
  top: 60px;
  left: 0;
  background-color: ${({ theme }) => theme.color.black};
  height: ${`calc(100vh - 60px)`};
  z-index: 9999;
  transition: all 0.3s ease;
  ul {
    list-style: none;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    li {
      ${vw('margin-bottom', 40, 60, 0)}
    }
  }

  @media ${media.tablet} {
    top: 100px;
    height: ${`calc(100vh - 100px )`};
  }
  @media ${media.desktop} {
    position: static;
    visibility: visible;
    opacity: 1;
    height: auto;
    width: auto;
    top: auto;
    left: auto;
    ul {
      text-align: left;
      position: static;
      top: auto;
      left: auto;
      transform: none;
      display: flex;
      transition: all 0.3s ease;
      overflow: hidden;
      li {
        &:not(:first-of-type) {
          margin-left: ${vwDesktop(44)};
        }
      }
    }
    ${(props) =>
      props.isProject
        ? props.isOpen
          ? `
        ul {
          width: ${vwDesktop(600)};
          margin-right: ${vwDesktop(40)};
        }
      `
          : `
        ul {
          width: ${vwDesktop(106)};
        }
        `
        : ``}
  }
`;

const List = styled.li`
  transition: all 0.3s ease;
  transition-delay: ${(props) =>
    props.isOpen ? `calc(${props.index} * 0.1s)` : '0s'};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  &:first-child {
    span {
      transition: all 0.3s ease;
      &:after {
        opacity: ${(props) =>
          (props.isProject && props.isOpen) || props.isActive ? '1' : '0'};
      }
    }
  }
  @media ${media.desktop} {
    opacity: 1;
    transition-delay: 0;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    span {
      &:after {
        opacity: 1;
      }
    }
  }
  span {
    position: relative;
    transition: all 0.3s ease;
    &:after {
      content: '';
      opacity: ${(props) => (props.isActive ? '1' : '0')};
      width: 96.25%;
      position: absolute;
      left: 0;
      background-color: ${({ theme }) => theme.color.white};
      ${vw('height', 1, 1)}
      ${vw('bottom', -3, -3)}
    }
  }
  img {
    display: inline-block;
    ${vw('width', 16, 16, 12)}
    ${vw('height', 16, 16, 12)}
    ${vw('margin-left', 4, 4, 2)}
  }
  @media ${media.desktop} {
    height: ${vwDesktop(24)};
  }
`;

const ProjectLabel = styled(Link)`
  display: none;
  position: relative;
  @media ${media.tablet} {
    display: ${(props) => (props.isProject ? 'block' : 'none')};
    margin-right: ${vwTablet(30)};
    &:after {
      content: '';
      width: 96.25%;
      opacity: ${(props) => (props.isOpen ? '1' : '0')};
      position: absolute;
      left: 0;
      background-color: ${({ theme }) => theme.color.white};
      transition: all 0.3s ease;
      ${vw('height', 1, 1)}
      ${vw('bottom', -3, -3)}
    }
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const Menu = styled.span`
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;

  span {
    opacity: ${(props) => (props.isOpen ? '0' : '1')};
    background-color: ${({ theme }) => theme.color.lightGrey};
    width: 100%;
    height: 1px;
    position: absolute;
    top: calc(50% - 1px);
    transition: all 0.4s ease;
  }

  &:after,
  &:before {
    content: '';
    display: block;
    background-color: ${({ theme }) => theme.color.white};
    width: 100%;
    height: 1px;
    position: absolute;
    transition: all 0.4s ease;
  }

  &:before {
    top: ${(props) => (props.isOpen ? `calc(50% - 1px)` : '4px')};
    transform: ${(props) => (props.isOpen ? `rotate(-45deg)` : '')};
  }
  &:after {
    bottom: ${(props) => (props.isOpen ? `50%` : '4px')};
    transform: ${(props) => (props.isOpen ? `rotate(45deg)` : '')};
  }
  @media ${media.tablet} {
    width: 32px;
    height: 32px;
    ${(props) =>
      props.isProject
        ? `
        margin-left: ${vwTablet(30)}
      `
        : `
      `}
    span {
      top: calc(50% - 1px);
    }
    &:before {
      top: ${(props) => (props.isOpen ? 'calc(50% - 1px)' : '5px')};
    }

    &:after {
      bottom: ${(props) => (props.isOpen ? '50%' : '5px')};
    }
  }

  @media ${media.desktop} {
    ${(props) =>
      props.isProject
        ? `
        margin-left: ${vwDesktop(40)}
      `
        : `
        display: none;
      `}
    span {
      top: calc(50% - 1px);
    }
    &:before {
      top: ${(props) => (props.isOpen ? 'calc(50% - 1px)' : vwDesktop(5))};
    }

    &:after {
      bottom: ${(props) => (props.isOpen ? '50%' : vwDesktop(5))};
    }
  }
`;
