import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from 'components/Button';
import FadeGallery from 'components/Gallery/FadeGallery';

import media from 'src/styles/media';
import vw, { vwTablet } from 'src/styles/utils';

import data from 'src/data/Components/HomeConfigCTA';

export default function HomeConfigCTA(props) {
  const { subHeading, heading, body, dark } = props;

  return (
    <Root subHeading={subHeading} dark={dark}>
      <Body subHeading={subHeading}>
        {typeof subHeading !== 'undefined' && (
          <>
            <h5 className="global gold uppercase">{subHeading}</h5>
            <h3 className="corporate black">{heading}</h3>
          </>
        )}

        {typeof subHeading === 'undefined' && (
          <h4 className="global gold uppercase">{heading}</h4>
        )}

        <p className="body2 black">{body}</p>
        <Link to="/design" target="_blank">
          <Button
            label="Launch Home Configurator"
            type={dark ? 'text-link-white' : 'text-link-gold'}
            // label="Home Configurator Coming Soon"
            // type="text-link-disabled"
          />
        </Link>
        <Badge
          src={require('src/assets/images/global/icon/kutak/kutak-xp.svg')}
        />
      </Body>
      <FadeGallery data={data} />
    </Root>
  );
}

HomeConfigCTA.propTypes = {
  subHeading: PropTypes.string,
  heading: PropTypes.string,
  body: PropTypes.string,
  dark: PropTypes.bool,
};

const Root = styled.div`
  overflow: hidden;
  background-color: ${(props) =>
    props.dark ? props.theme.color.black : props.theme.color.white};
  width: 100%;
  height: auto;
  h3,
  h4,
  p {
    color: ${(props) =>
      props.dark ? props.theme.color.white : props.theme.color.black};
  }
  @media ${media.desktop} {
    display: flex;
  }
`;

const Body = styled.div`
  position: relative;
  height: auto;
  ${vw('width', 320, 768, 540)}
  ${vw('padding-top', 48, 80, 120)}
  ${vw('padding-right', 16, 60)}
  ${vw('padding-bottom', 48, 80, 120)}
  ${vw('padding-left', 16, 60)}
  h5 {
    ${vw('margin-bottom', 24, 40)}
  }
  h4 {
    ${vw('margin-bottom', 24, 40)}
  }
  h3 {
    ${vw('margin-bottom', 16, 20)}
  }
  p {
    ${vw('width', 288, 312, 300)}
    ${vw('margin-bottom', 24, 40)}
  }
`;

const Badge = styled.img`
  ${vw('width', 80, 100)}
  ${vw('height', 80, 100)}
  ${vw('margin-top', 40, 0, 80)}
  @media ${media.tablet} {
    position: absolute;
    bottom: ${vwTablet(80)};
    right: ${vwTablet(60)}
  }
  @media ${media.desktop} {
    position: static;
    bottom: auto;
    right: auto;
  }
`;
