import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile } from 'src/styles/utils';
import media from 'src/styles/media';

const MobileSelect = (props) => {
  const { options, activeOption, handleOption } = props;
  return (
    <Root>
      {options.map((item, index) => (
        <Option
          key={index}
          onClick={() => handleOption(index)}
          active={index === activeOption}
        >
          <h5 className="global gold uppercase">{item}</h5>
        </Option>
      ))}
    </Root>
  );
};

MobileSelect.propTypes = {
  options: PropTypes.array,
  activeOption: PropTypes.number,
  handleOption: PropTypes.func,
};

const Root = styled.div`
  display: flex;
  width: 100%;
  @media ${media.tablet} {
    display: none;
  }
`;

const Option = styled.div`
  width: 50%;
  height: ${vwMobile(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.active ? props.theme.color.white : props.theme.color.lightGrey};
  transition: 0.3s ease;
`;

export default MobileSelect;
