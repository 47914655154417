import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Lightbox = (props) => {
  const {
    active,
    handleClose,
    images,
    activeImage,
    handleNext,
    handlePrev,
  } = props;

  return (
    <Root
      style={{
        opacity: active ? 1 : 0,
        pointerEvents: active ? 'all' : 'none',
      }}
    >
      <Close
        src={require('src/assets/images/global/icon/action/close-white.svg')}
        alt="Close"
        onClick={handleClose}
      />
      <Container>
        <Image>
          {images.map((item, index) => (
            <img
              src={item.img}
              alt=""
              className="gallery-image"
              style={{ opacity: activeImage === index ? 1 : 0 }}
            />
          ))}
        </Image>
        <Buttons>
          <img
            src={require('src/assets/images/global/icon/nav/circle-arrow.svg')}
            alt="Prev"
            onClick={handlePrev}
          />
          <img
            src={require('src/assets/images/global/icon/nav/circle-arrow.svg')}
            alt="Next"
            onClick={handleNext}
          />
        </Buttons>
      </Container>
    </Root>
  );
};

Lightbox.propTypes = {
  active: PropTypes.bool,
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
  images: PropTypes.array,
  activeImage: PropTypes.number,
};

const Root = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => theme.color.black};
  transition: 0.4s ease;
  z-index: 99999;
  display: flex;
  align-items: center;
`;

const Close = styled.img`
  position: absolute;
  width: ${vwMobile(32)};
  height: ${vwMobile(32)};
  top: ${vwMobile(16)};
  right: ${vwMobile(16)};
  cursor: pointer;
  @media ${media.tablet} {
    width: ${vwTablet(32)};
    height: ${vwTablet(32)};
    top: ${vwTablet(32)};
    right: ${vwTablet(32)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(32)};
    height: ${vwDesktop(32)};
    top: ${vwDesktop(40)};
    right: ${vwDesktop(60)};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Image = styled.div`
  position: relative;
  width: 100%;
  height: ${vwMobile(230)};
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: 0.3s ease;
  }
  .gallery-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  @media ${media.tablet} {
    height: ${vwTablet(555)};
    max-height: 80vh;
  }
  @media ${media.desktop} {
    height: ${vwDesktop(624)};
    width: ${vwDesktop(864)};
    max-height: 90vh;
  }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${vwMobile(76)};
  margin-top: ${vwMobile(24)};
  img {
    width: ${vwMobile(32)};
    height: ${vwMobile(32)};
  }
  img:last-of-type {
    transform: scaleX(-1);
  }
  @media ${media.tablet} {
    width: ${vwTablet(96)};
    margin-top: ${vwTablet(40)};
    img {
      width: ${vwTablet(40)};
      height: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    margin: 0;
    padding: 0 ${vwDesktop(60)};
    img {
      width: ${vwDesktop(40)};
      height: ${vwDesktop(40)};
      cursor: pointer;
    }
  }
`;

export default Lightbox;
