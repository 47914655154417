import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const PlanPopup = (props) => {
  const {
    active,
    activePlans,
    handleClose,
    handleNext,
    handlePrev,
    handlePlan,
    data,
    activePlan,
  } = props;
  return (
    <Root
      style={{
        opacity: active ? 1 : 0,
        pointerEvents: active ? 'all' : 'none',
      }}
    >
      <Close
        src={require('src/assets/images/global/icon/action/close-white.svg')}
        alt="Close Popup"
        onClick={handleClose}
      />
      <Arrow
        src={require('src/assets/images/global/icon/nav/circle-arrow.svg')}
        alt="Previous"
        onClick={handlePrev}
      />
      <Container>
        {data.map((item, index) => (
          <Plan
            key={index}
            style={{
              opacity: activePlan === index ? 1 : 0,
              zIndex: activePlan === index ? 2 : 1,
            }}
          >
            <Image>
              <img src={item.plan} alt={`Plan ${item.id}`} />
            </Image>
            <Details>
              <Label>
                <Desktop>
                  <Line />
                  <img
                    src={require('src/assets/images/global/logo/one-shaughnessy-plain.svg')}
                    alt="One Shaughnessy"
                  />
                </Desktop>
                <Line />
                <Flex largeMargin>
                  <h3 className="floorplan-label uppercase">{item.id}</h3>
                  <div>
                    <p className="plan-type black">
                      {item.bed === 0 ? 'Studio' : `${item.bed} Bed`}{' '}
                      {item.den && '+ Den'}
                    </p>
                    <p className="plan-type black">{item.bath} Bathroom</p>
                  </div>
                </Flex>
                <Flex>
                  <p className="plan-details uppercase black">Interior</p>
                  <p className="plan-details uppercase black">
                    {item.interior} sqft
                  </p>
                </Flex>
                <Flex>
                  <p className="plan-details uppercase black">Exterior</p>
                  <p className="plan-details uppercase black">
                    {item.exterior} sqft
                  </p>
                </Flex>
                <Flex mediumMargin>
                  <p className="plan-details uppercase black">Total</p>
                  <p className="plan-details uppercase black">
                    {item.total} sqft
                  </p>
                </Flex>
                <Line />
              </Label>
              <Fav onClick={() => handlePlan(item.id)}>
                <p className="configurator caption grey">
                  Add to
                  <br />
                  Favourites
                </p>
                <img
                  src={
                    activePlans.includes(item.id)
                      ? require('src/assets/images/global/icon/action/favourites-active.svg')
                      : require('src/assets/images/global/icon/action/favourites-inactive.svg')
                  }
                  alt="Favourite"
                />
              </Fav>
            </Details>
          </Plan>
        ))}
      </Container>
      <Arrow
        src={require('src/assets/images/global/icon/nav/circle-arrow.svg')}
        alt="Next"
        onClick={handleNext}
        right
      />
    </Root>
  );
};

PlanPopup.propTypes = {
  active: PropTypes.bool,
  handleClose: PropTypes.func,
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
  handlePlan: PropTypes.func,
  data: PropTypes.array,
  activePlans: PropTypes.array,
  activePlan: PropTypes.number,
};

const Root = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  transition: 0.4s ease-in-out;
  z-index: 99999;
  display: none;
  @media ${media.tablet} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const Close = styled.img`
  width: ${vwDesktop(32)};
  position: absolute;
  top: ${vwDesktop(40)};
  right: ${vwDesktop(60)};
  cursor: pointer;
  @media ${media.tablet} {
    width: ${vwTablet(32)};
    top: ${vwTablet(32)};
    right: ${vwTablet(32)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(32)};
    top: ${vwDesktop(40)};
    right: ${vwDesktop(60)};
  }
`;

const Container = styled.div`
  width: ${vwDesktop(1000)};
  height: ${vwDesktop(652)};
  position: relative;
  background-color: white;
  @media ${media.tablet} {
    width: ${vwTablet(584)};
    height: ${vwTablet(810)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(1000)};
    height: ${vwDesktop(652)};
    max-height: 90vh;
  }
`;

const Plan = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: ${vwTablet(40)};
  transition: 0.4s ease-in-out;
  @media ${media.desktop} {
    display: flex;
    align-items: flex-start;
    padding: ${vwDesktop(40)};
  }
`;

const Image = styled.div`
  width: ${vwDesktop(572)};
  height: ${vwDesktop(572)};
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    image-rendering: -webkit-optimize-contrast;
  }
  @media ${media.tablet} {
    width: 100%;
    height: ${vwTablet(504)};
  }
  @media ${media.desktop} {
    order: 2;
    margin-right: ${vwDesktop(40)};
    width: ${vwDesktop(572)};
    height: ${vwDesktop(572)};
    max-height: 100%;
  }
`;

const Details = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: ${vwTablet(40)};
  p {
    text-align: right;
    white-space: nowrap;
  }
  @media ${media.desktop} {
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    margin-top: 0;
    order: 1;
    > div {
      margin-left: 0;
    }
  }
`;

const Fav = styled.div`
  margin-bottom: 5px;
  display: flex;
  cursor: pointer;
  position: absolute;
  bottom: ${vwDesktop(40)};
  right: ${vwDesktop(40)};
  > img {
    width: ${vwDesktop(32)};
  }
  @media ${media.tablet} {
    bottom: ${vwTablet(40)};
    right: ${vwTablet(40)};
    > img {
      width: ${vwTablet(32)};
      margin-left: ${vwTablet(8)};
    }
  }
  @media ${media.desktop} {
    bottom: ${vwDesktop(40)};
    right: ${vwDesktop(40)};
    > img {
      width: ${vwDesktop(32)};
      margin-left: ${vwDesktop(8)};
    }
  }
`;

const Arrow = styled.img`
  width: ${vwDesktop(40)};
  height: ${vwDesktop(40)};
  position: absolute;
  top: 50%;
  left: ${(props) => (props.right ? 'auto' : vwDesktop(60))};
  right: ${(props) => (props.right ? vwDesktop(60) : 'auto')};
  transform: translateY(-50%) ${(props) => props.right && 'scaleX(-1)'};
  cursor: pointer;
  @media ${media.tablet} {
    width: ${vwTablet(40)};
    height: ${vwTablet(40)};
    left: ${(props) => (props.right ? 'auto' : vwTablet(32))};
    right: ${(props) => (props.right ? vwTablet(32) : 'auto')};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(40)};
    height: ${vwDesktop(40)};
    left: ${(props) => (props.right ? 'auto' : vwDesktop(60))};
    right: ${(props) => (props.right ? vwDesktop(60) : 'auto')};
  }
`;

const Label = styled.div`
  width: ${vwTablet(200)};
  @media ${media.desktop} {
    width: ${vwDesktop(240)};
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${(props) => {
    if (props.largeMargin) {
      return vwTablet(16);
    } else if (props.mediumMargin) {
      return vwTablet(12);
    } else {
      return vwTablet(4);
    }
  }};
  @media ${media.desktop} {
    margin-bottom: ${(props) => {
      if (props.largeMargin) {
        return vwDesktop(16);
      } else if (props.mediumMargin) {
        return vwDesktop(12);
      } else {
        return vwDesktop(4);
      }
    }};
  }
`;

const Line = styled.div`
  width: 100%;
  height: ${vwTablet(5)};
  background-color: ${({ theme }) => theme.color.black};
  @media ${media.desktop} {
    height: ${vwDesktop(5)};
  }
`;

const Desktop = styled.div`
  display: none;
  @media ${media.desktop} {
    display: block;
    padding-bottom: ${vwDesktop(12)};
    img {
      width: 100%;
      display: block;
      margin-top: ${vwDesktop(12)};
    }
  }
`;

export default PlanPopup;
