import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';

function CommentForm(props) {
  const [isEmpty, setIsEmpty] = useState(true);
  const refComment = useRef(null);
  const { postID, name, email, parent } = props;

  const handleSubmitComment = async (evt) => {
    evt.preventDefault();

    if (!isEmpty) {

      let tmpEmail = typeof email === 'undefined' ? 'unknow@email.com' : email;
      let parentID = typeof parent === 'undefined' ? 0 : parent;

      let data = JSON.stringify({
        post: postID,
        parent: parentID,
        author_name: name,
        author_email: tmpEmail,
        content: refComment.current.value
      });;

      console.log(parentID)

      // if (typeof parent === 'undefined') {
      //   JSON.stringify({
      //     post: postID,
      //     author_name: name,
      //     author_email: email,
      //     content: refComment.current.value
      //   });
      // } else {
      //   JSON.stringify({
      //     post: postID,
      //     parent: parentID,
      //     author_name: name,
      //     author_email: email,
      //     content: refComment.current.value
      //   });
      // }

      // if (typeof email === 'undefined') {
      //   data = JSON.stringify({
      //     post: postID,
      //     author_name: name,
      //     author_email: tmpEmail,
      //     content: refComment.current.value
      //   });
      // } else {
      //   data = JSON.stringify({
      //     post: postID,
      //     author_name: name,
      //     author_email: email,
      //     content: refComment.current.value
      //   });
      // }

      const ACTION_URL = "https://kutakdevelopment.com/cms/wp-json/wp/v2/comments"
      const response = await fetch(ACTION_URL, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          "accept": "application/json",
        },
        body: data,
      })

      refComment.current.value = '';

      // const responseJson = await response.json();
    }
  }

  const handleChange = () => {
    if (refComment.current.value === '') {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }

  return (
    <Root onSubmit={handleSubmitComment} {...props}>
      <input type="hidden" id="postId" value={postID} />
      <textarea
        ref={refComment}
        required
        onChange={handleChange}
      />
      <SubmitButton type="submit" value="Comment" halfOpacity={isEmpty} />
    </Root>
  )
}

CommentForm.propTypes = {
  postID: PropTypes.number.isRequired
}

const Root = styled.form`
  display: flex;
  flex-direction: column;

  > textarea {
    border: solid 1px #d7d7d7;
    font-family: 'futura-pt';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    color: ${({ theme }) => theme.color.black};
    ${vw('font-size', 18)};
    ${vw('letter-spacing', 0.5)};
    ${vw('width', 288, 648, 640)};
    ${vw('height', 160, 120)};
    ${vw('margin-top', 32, 40)};
    ${vw('margin-bottom', 12)};
    ${vw('padding-left', 4)};
  }
`;

const SubmitButton = styled.input`
  border: solid 1px ${({ theme }) => theme.color.black}; /* opacity 0.5 */
  opacity: ${props => props.halfOpacity ? '0.5' : '1'};
  font-family: 'futura-pt';
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  color: ${({ theme }) => theme.color.black};
  text-transform: uppercase;
  ${vw('font-size', 16)};
  ${vw('letter-spacing', 3)};
  ${vw('width', 121)};
  ${vw('height', 40)};
`;

export default CommentForm

