import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

export default function Resource(props) {
  const { title, description, btnLabel, url } = props;

  return (
    <Root>
      <Section>
        <h4 className='global gold'>{title}</h4>
        <p className='body2 black'>{description}</p>
      </Section>
      <a href={url} target='_blank' rel='noopener noreferrer' className='button gold'>
        {btnLabel}
        <span />
      </a>
    </Root>
  )
}

Resource.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  btnLabel: PropTypes.string,
  url: PropTypes.string,
}

const Root = styled.div`
  border-top: 1px solid #d7d7d7;
  ${vw('padding-top', 16, 16, 17)}
  ${vw('margin-bottom', 32, 40)}
  
  h4 {
    text-transform: uppercase;
    ${vw('margin-bottom', 12, 12, 0)}
  }
  p {
    ${vw('margin-bottom', 24, 0)}
  }
  a {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    transition: all 0.3s ease;
    ${vw('height', 20)}
    span {
      position: relative;
      ${vw('width', 16)}
      ${vw('height', 16)}
      ${vw('margin-left', 8)}

      &:before {
        content: '';
        position: absolute;
        left: 0;
        background: ${`url(${require('src/assets/images/global/icon/action/chevron-right-gold.svg')})`} center / cover no-repeat;
        transition: all 0.3s ease;
        ${vw('width', 16)}
        ${vw('height', 16)}
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        background: ${`url(${require('src/assets/images/global/icon/action/chevron-right-black.svg')})`} center / cover no-repeat;
        transition: all 0.3s ease;
        opacity: 0;
        ${vw('width', 16)}
        ${vw('height', 16)}
      }
    }
    &:hover {
      color: ${({ theme }) => theme.color.black};
      span {
        &:before {
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }

  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const Section = styled.div`
  @media ${media.tablet} {
    width: ${vwTablet(480)};
  }
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    h4 {
      width: ${vwDesktop(300)};
    }
    p {
      width: ${vwDesktop(480)};
    }
  }
`;