import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const LevelSelect = (props) => {
  const { data, activeLevel, handleLevel } = props;
  return (
    <Root>
      <h5 className="global white uppercase mobile">Select a Level</h5>
      <Wrapper>
        {data.map((item, index) => (
          <Button
            key={index}
            type={
              index === activeLevel
                ? 'level-select-active'
                : 'level-select-inactive'
            }
            label={item.level}
            clickHandler={() => handleLevel(index)}
          >
            {item.level}
          </Button>
        ))}
      </Wrapper>
    </Root>
  );
};

LevelSelect.propTypes = {
  data: PropTypes.array,
  activeLevel: PropTypes.number,
};

const Root = styled.div`
  padding: 0 ${vwMobile(16)} ${vwMobile(16)};
  @media ${media.tablet} {
    padding: 0 0 ${vwTablet(40)} ${vwTablet(60)};
  }
  @media ${media.desktop} {
    padding: 0;
    grid-column: 1;
    grid-row: 2;
    align-self: flex-start;
    .mobile {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${vwMobile(24)};
  @media ${media.tablet} {
    width: ${vwTablet(272)};
    margin-top: ${vwTablet(40)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(124)};
    padding-left: ${vwDesktop(60)};
    margin-top: 0;
    flex-direction: column-reverse;
    order: 3;
    > button {
      margin-bottom: ${vwDesktop(16)};
    }
  }
`;

export default LevelSelect;
