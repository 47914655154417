import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Fade from 'react-reveal/Fade';

import data from 'src/data/Team/data';

import media from 'src/styles/media';
import vw, { vwDesktop } from 'src/styles/utils';

export default function Members() {
  const [index, setIndex] = useState(window.innerWidth >= 992 ? 2 : 0);
  const [team, setTeam] = useState(
    window.innerWidth >= 992 ? data.desktop : data.mobileTablet
  );
  const [headerOffset, setHeaderOffset] = useState(
    window.innerWidth >= 992
      ? 3.75 * 1440
      : window.innerWidth >= 576
      ? 13.020833333333334 * 768
      : 18.75 * 320
  );
  const browser = useSelector((state) => state.browser);
  const selectRef = useRef(null);
  const tabRef = useRef(null);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [browser]);

  const handleResize = () => {
    setTeam(window.innerWidth >= 992 ? data.desktop : data.mobileTablet);
    // setIndex(window.innerWidth >= 992 ? 2 : 0);
    setHeaderOffset(
      window.innerWidth >= 992
        ? 3.75 * 1440
        : window.innerWidth >= 576
        ? 13.020833333333334 * 768
        : 18.75 * 320
    );
  };

  const handleChange = (e) => {
    setIndex(parseInt(e.target.value));
    window.scrollTo({
      top: selectRef.current.offsetTop - headerOffset / 100,
      left: 0,
      behavior: 'smooth',
    });
  };

  const handleSelect = (key) => {
    setIndex(key);
    window.scrollTo({
      top: tabRef.current.offsetTop - headerOffset / 100,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Root>
      <TeamPhoto src={data.teamPhoto} alt="Kutak Team Photo " />
      <Select ref={selectRef}>
        <label htmlFor="members">
          <h5 className="global black uppercase">Select a Bio</h5>
        </label>
        <select
          name="members"
          id="members"
          className="body2 black"
          onChange={(e) => handleChange(e)}
        >
          {team.members.map((member, key) => {
            return (
              <option
                selected={index === key ? 'selected' : ''}
                key={key}
                value={key}
                className="body2 black"
              >
                {member.name}
              </option>
            );
          })}
        </select>
      </Select>

      <Tabs ref={tabRef}>
        {team.members.map((member, key) => {
          return (
            <Tab
              key={key}
              isActive={index === key}
              onClick={() => handleSelect(key)}
            >
              <h4 className="global gold uppercase">{member.name}</h4>
              <p className="body3 black uppercase">{member.position}</p>
            </Tab>
          );
        })}
      </Tabs>

      <Bio>
        {team.members.map((member, key) => {
          return (
            <Member key={key} isActive={index === key}>
              <Fade>
                <Photo src={member.photo} alt={`${member.name} headshot`} />
              </Fade>
              <Info>
                <Name className="global black uppercase">{member.name}</Name>
                <Position className="body3 black uppercase">
                  {member.position}
                </Position>
                <Description className="body2 black">
                  {member.description}
                </Description>
                {typeof member.linkedin !== 'undefined' && (
                  <LinkedIn
                    href={member.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        stroke="none"
                        strokeWidth="1"
                      >
                        <g fill="#222" fillRule="nonzero" className="fill">
                          <path d="M18.743 18.748h-3.258v-5.105c0-1.218-.024-2.784-1.697-2.784-1.699 0-1.958 1.324-1.958 2.694v5.195H8.572V8.25h3.13v1.43h.041c.438-.824 1.501-1.695 3.09-1.695 3.3 0 3.911 2.173 3.911 5v5.763zM4.893 6.814A1.89 1.89 0 013 4.92a1.892 1.892 0 111.891 1.893zm1.633 11.934H3.259V8.25h3.267v10.498zM20.373 0H1.623C.726 0 0 .71 0 1.585v18.83C0 21.291.726 22 1.623 22H20.37c.897 0 1.63-.709 1.63-1.585V1.585C22 .71 21.267 0 20.37 0h.003z"></path>
                        </g>
                      </g>
                    </svg>
                  </LinkedIn>
                )}
              </Info>
            </Member>
          );
        })}
      </Bio>
    </Root>
  );
}

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.white};
`;

const TeamPhoto = styled.img`
  width: 100%;
`;

const Select = styled.div`
  ${vw('padding-top', 32, 40, 0)}
  ${vw('padding-right', 16, 60, 0)}
  ${vw('padding-bottom', 32, 40, 0)}
  ${vw('padding-left', 16, 60, 0)}
  label {
    h5 {
      ${vw('margin-bottom', 4, 4, 0)}
    }
  }
  select {
    position: relative;
    appearance: none;
    border: 1px solid ${({ theme }) => theme.color.black};
    border-radius: 0;
    outline: none;
    background-image: url(${require('src/assets/images/global/icon/action/chevron-down-black.svg')});
    background-repeat: no-repeat;
    background-color: ${({ theme }) => theme.color.white};
    ${vw('background-size', 16, 16)}
    ${vw('background-position', 260, 328, 0)}
    ${vw('width', 288, 360, 0)}
    ${vw('height', 40, 48, 0)}
    ${vw('padding-top', 9, 11, 0)}
    ${vw('padding-right', 12, 12, 0)}
    ${vw('padding-bottom', 9, 11, 0)}
    ${vw('padding-left', 12, 12, 0)}
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const Tabs = styled.div`
  display: none;
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    padding: ${vwDesktop(48)} ${vwDesktop(60)};
  }
`;

const Tab = styled.div`
  @media ${media.desktop} {
    cursor: pointer;
    position: relative;
    width: ${vwDesktop(240)};
    h4 {
      margin-bottom: ${vwDesktop(8)};
    }
    p {
      height: ${vwDesktop(44)};
    }
    &:after {
      content: '';
      width: ${vwDesktop(80)};
      height: ${vwDesktop(2)};
      background-color: ${({ theme }) => theme.color.gold};
      position: absolute;
      bottom: ${vwDesktop(-12)};
      visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
    }
  }
`;

const Member = styled.div`
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  opacity: ${(props) => (props.isActive ? '1' : '0')};
  transition: all 0.3s ease;
  @media ${media.desktop} {
    display: ${(props) => (props.isActive ? 'flex' : 'none')};
    flex-direction: row-reverse;
  }
`;

const Bio = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${({ theme }) => theme.color.lightGrey};
  ${vw('padding-top', 0, 0, 0)}
  ${vw('padding-right', 0, 60, 0)}
  ${vw('padding-bottom', 0, 60, 0)}
  ${vw('padding-left', 0, 60, 0)}
`;

const Photo = styled.img`
  height: 100%;
  ${vw('width', 320, 360, 600)}
  ${vw('margin-bottom', 0, 32, 0)}
`;

const Info = styled.div`
  ${vw('width', 320, 768, 840)}
  ${vw('padding-top', 24, 0, 80)}
  ${vw('padding-right', 16, 0, 60)}
  ${vw('padding-bottom', 40, 0, 120)}
  ${vw('padding-left', 16, 0, 60)}
`;

const Name = styled.h4`
  ${vw('margin-bottom', 8, 8)}
`;

const Position = styled.p`
  ${vw('margin-bottom', 16, 16)}
`;

const Description = styled.p`
  ${vw('margin-bottom', 32, 32)}
  span {
    display: block;
    ${vw('margin-bottom', 16, 16)}
  }
  ${vw('width', 288, 648, 640)}
`;

const LinkedIn = styled.a`
  svg {
    .fill {
      transition: all 0.3s ease;
    }
    &:hover {
      .fill {
        fill: ${({ theme }) => theme.color.gold};
      }
    }
    ${vw('width', 26, 26)};
    ${vw('height', 26, 26)};
  }
`;
