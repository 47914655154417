const LASSO_API_KEY = 'eyJhbGciOiJSUzI1NiJ9.eyJleHAiOjcyMjY1ODI0MDAsInRva2VuRGF0YSI6IntcbiAgXCJwcm9qZWN0SWRcIiA6IDEyODUyLFxuICBcImNsaWVudElkXCIgOiAxNDk2LFxuICBcIm5hbWVcIiA6IFwibGFzc29MZWFkQ2FwdHVyZVwiXG59IiwiaXNzIjoiTGFzc29Ub2tlbiIsImF1ZCI6Ikxhc3NvIiwidG9rZW5JZCI6MjE1OTJ9.bFXZhENQS2h1aulGeRlxv1dDT4y-6mIyPT21H7278Rm-dnxk9QB8s2vspFihVwhZiQc3TCclihMR02DxybOLqiqLdIt-bdW_W6KQYLIpJ7tKGuA-YTPhuX2-wTvMvAu1g3CAmTfkS50M_kbexaZA7XY6Mh1LkX6odyp-GXDksQmPEZUglGnDuy4K7QfgHfaASQftZqKetJ8crptLj0_HVX0_5k2x6XnsK2t9GwW2XtdLF0Md6akCn6udxq4d72yuRPQDRG2ZwGfApobzDnxvlconFzFxWvQ5uGUzsLn5u98jrAEmwwnrwsOdJiSYqH6CdkFhOHm7gIGX0lOH6yaz1g';
const PROXY = 'https://bam-cors-anywhere.herokuapp.com/';
const BASE_URL = 'https://api.lassocrm.com/v1';
const SEARCH_EMAIL = '/registrants/search?email=';
const REGISTRANTS = '/registrants';
const HEADERS = {
  "Content-Type": "application/json",
  "accept": "application/json",
  "Authorization": `Bearer ${LASSO_API_KEY}`
};

export async function getSearchEmail(email) {
  const response = await fetch(PROXY + BASE_URL + SEARCH_EMAIL + email, {
    headers: HEADERS
  });
  const responseJson = await response.json();

  return responseJson;
}

export async function getExtendRegistrant(registrantId) {
  const response = await fetch(PROXY + BASE_URL + REGISTRANTS + `/${registrantId}`, {
    headers: HEADERS,
  });
  const responseJson = await response.json();

  return responseJson;
}

export async function setHistoy(registrantId, type) {
  const dataBody = {
    type: type,
    subject: "Register at Sales Centre"
  }
  const response = await fetch(PROXY + BASE_URL + REGISTRANTS + `/${registrantId}/histories`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(dataBody)
  });
  const responseJson = await response.json();

  return responseJson;
}

export async function updateHistoy(registrantId, historyId, type) {
  const dataBody = {
    type: type,
    subject: "Register at Sales Centre"
  }
  const response = await fetch(PROXY + BASE_URL + REGISTRANTS + `/${registrantId}/histories/${historyId}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify(dataBody)
  });
  const responseJson = await response.json();

  return responseJson;
}

export async function getRegistrant(registrantId) {
  const response = await fetch(PROXY + BASE_URL + REGISTRANTS + `/${registrantId}`, {
    headers: HEADERS,
  });
  const responseJson = await response.json();

  return responseJson;
}

export async function getLatestRegistrant() {
  const response = await fetch(PROXY + BASE_URL + REGISTRANTS, {
    headers: HEADERS,
  });
  const responseJson = await response.json();

  return responseJson;
}

export default async function createARegistrant() {
  const firstName = document.getElementById('firstName').value;
  const lastName = document.getElementById('lastName').value;
  const email = document.getElementById('email').value;
  const phone = document.getElementById('phone').value;
  const postal = document.getElementById('postal').value;

  const priceRange = document.getElementById('pricerange');
  const priceRangeValue = priceRange.options[priceRange.selectedIndex].value;

  const realtor = document.getElementById('realtor');
  const realtorValue = realtor.options[realtor.selectedIndex].value;

  const howHeard = document.getElementById('HowHeard');
  const howHeardValue = howHeard.options[howHeard.selectedIndex].value;


  const typeofhome = document.getElementById('typeofhome');
  const typeofhomeValue = typeofhome.options[typeofhome.selectedIndex].value;

  const dataBody = {
    person: {
      firstName: firstName,
      lastName: lastName
    },
    emails: [
      {
        email: email,
        primary: true
      }
    ],
    phones: [
      {
        phone: phone,
        primary: true
      }
    ],
    addresses: [
      {
        zipCode: postal,
        primary: true
      }
    ],
    questions: [{
      questionId: "63561",
      name: "Home Type",
      answers: [{
        answer: typeofhomeValue
      }]
    }, {
      questionId: "63562",
      name: "How Heard",
      answers: [{
        answer: howHeardValue
      }]
    }, {
      questionId: "63563",
      name: "Realtor",
      answers: [{
        answer: realtorValue
      }]
    }, {
      questionId: "63564",
      name: "Consent",
      answers: [{
        answer: "Yes"
      }]
    },
    {
      questionId: "81523",
      name: "pricerange",
      answers: [{
        answer: priceRangeValue
      }]
    }],
    history: [
      {
        type: "Sales Centre",
        subject: "Register at Sales Centre"
      }
    ],
  };

  const response = await fetch(PROXY + BASE_URL + REGISTRANTS, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(dataBody)
  });

  const responseJson = await response.json();

  return responseJson;
}

export async function updateRegistrantContactInformation(registrantId) {
  const firstName = document.getElementById('firstName').value;
  const lastName = document.getElementById('lastName').value;
  const email = document.getElementById('email').value;
  const phone = document.getElementById('phone').value;
  const postal = document.getElementById('postal').value;

  const dataBody = {
    firstName: firstName,
    lastName: lastName,
    emails: [
      {
        email: email,
        primary: true
      }
    ],
    phones: [
      {
        phone: phone,
        primary: true
      }
    ],
    addresses: [
      {
        zipCode: postal,
        primary: true
      }
    ]
  };
  const response = await fetch(PROXY + BASE_URL + REGISTRANTS + `/${registrantId}/person/contact-information`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify(dataBody)
  });
  const responseJson = await response.json();

  return responseJson;
}

export async function updateRegistrantQuestionAnwsers(registrantId, questionId, questionDOMValue) {
  const dataBody = {
    answers: [
      {
        answer: questionDOMValue
      }
    ]
  };
  const response = await fetch(PROXY + BASE_URL + REGISTRANTS + `/${registrantId}/questions/${questionId}`, {
    method: 'PUT',
    headers: HEADERS,
    body: JSON.stringify(dataBody)
  });
  const responseJson = await response.json();

  return responseJson;
}

export async function updateRegistrantNotes(registrantId, notes) {
  const dataBody = {
    note: notes
  };
  const response = await fetch(PROXY + BASE_URL + REGISTRANTS + `/${registrantId}/notes`, {
    method: 'POST',
    headers: HEADERS,
    body: JSON.stringify(dataBody)
  });
  const responseJson = await response.json();

  return responseJson;
}