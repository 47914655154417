import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProject, clearProject } from 'src/redux/project';

import Button from 'components/Button';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/Projects';

const SinglePlan = () => {
  const [activePage, setActivePage] = useState(null);
  const [activePlan, setActivePlan] = useState(null);

  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (params.id) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].slug === params.id) {
          setActivePage(data[i]);
          dispatch(setProject(data[i].slug));
          if (params.floorplan) {
            for (let j = 0; j < data[i].planData.length; j++) {
              if (data[i].planData[j].id === params.floorplan) {
                setActivePlan(data[i].planData[j]);
                break;
              }
            }
          }
          break;
        }
      }
    }
    return () => dispatch(clearProject());
  }, [params, dispatch]);

  const handleGoBack = () => {
    history.push(`/homes/${activePage.slug}/plans`, {
      activeFilter: history.location.state.activeFilter,
    });
  };

  // TODO: break into components

  return (
    <Root>
      {activePlan && (
        <Wrapper>
          <Button label="back" type="back" clickHandler={handleGoBack} />
          <Div>
            <Label>
              <Line />
              <img src={activePage.planLogo} alt={activePage.title} />
              <Line />
              <Flex margin>
                <h3 className="floorplan-label black uppercase">
                  {activePlan.id}
                </h3>
                <div>
                  <p className="plan-type black">
                    {activePlan.bed === 0 ? 'Studio' : `${activePlan.bed} Bed`}{' '}
                    {activePlan.den && '+ Den'}
                  </p>
                  <p className="plan-type black">{activePlan.bath} Bathroom</p>
                </div>
              </Flex>
              <Flex>
                <p className="plan-details black uppercase">Interior</p>
                <p className="plan-details black uppercase">
                  {activePlan.interior} sqft
                </p>
              </Flex>
              <Flex>
                <p className="plan-details black uppercase">Exterior</p>
                <p className="plan-details black uppercase">
                  {activePlan.exterior} sqft
                </p>
              </Flex>
              <Flex>
                <p className="plan-details black uppercase">Total</p>
                <p className="plan-details black uppercase">
                  {activePlan.total} sqft
                </p>
              </Flex>
              <Line margin />
            </Label>
            <Plan smallWidth={activePlan.smallWidth}>
              <img src={activePlan.plan} alt={`Floorplan ${activePlan.id}`} />
            </Plan>
            <Details>
              <div className="image">
                <img
                  src={activePlan.plate}
                  alt={`Floorplan ${activePlan.id}`}
                />
              </div>
              <div className="text">
                <Download
                  href={activePlan.pdf}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p className="button black uppercase">Download PDF</p>
                </Download>
                <p className="body4 black">
                  Dimensions, sizes, specifications, layouts and materials are
                  approximate only and subject to change without notice.
                  E.&amp;O.E.
                </p>
              </div>
            </Details>
          </Div>
        </Wrapper>
      )}
    </Root>
  );
};

const Root = styled.div`
  background-color: white;
  ${vw('margin-top', 60, 82, 100)}
`;

const Wrapper = styled.div`
  padding: ${vwMobile(24)} ${vwMobile(16)} ${vwMobile(48)};
  @media ${media.tablet} {
    padding: ${vwTablet(60)} ${vwTablet(60)} ${vwTablet(80)};
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(60)} ${vwDesktop(60)} ${vwDesktop(80)};
  }
`;

const Label = styled.div`
  width: ${vwMobile(160)};
  img {
    width: 100%;
    display: block;
    margin: ${vwMobile(10)} 0;
  }
  @media ${media.tablet} {
    width: ${vwTablet(200)};
    img {
      margin: ${vwTablet(12)} 0;
    }
  }
  @media ${media.desktop} {
    width: 100%;
    grid-column: 1;
    img {
      margin: ${vwDesktop(12)} 0;
    }
  }
`;

const Line = styled.div`
  width: 100%;
  height: ${vwMobile(4)};
  background-color: ${({ theme }) => theme.color.black};
  margin-top: ${(props) => (props.margin ? vwMobile(10) : 0)};
  @media ${media.tablet} {
    height: ${vwTablet(5)};
    margin-top: ${(props) => (props.margin ? vwTablet(12) : 0)};
  }
  @media ${media.desktop} {
    height: ${vwDesktop(5)};
    margin-top: ${(props) => (props.margin ? vwDesktop(12) : 0)};
  }
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${(props) => (props.margin ? vwMobile(10) : vwMobile(2))};
  > div {
    text-align: right;
  }
  @media ${media.tablet} {
    margin-bottom: ${(props) => (props.margin ? vwTablet(17) : vwTablet(4))};
  }
  @media ${media.desktop} {
    margin-bottom: ${(props) => (props.margin ? vwDesktop(21) : vwDesktop(4))};
  }
`;

const Div = styled.div`
  margin-top: ${vwMobile(32)};
  @media ${media.tablet} {
    margin-top: ${vwTablet(60)};
  }
  @media ${media.desktop} {
    margin-top: ${vwDesktop(60)};
    display: grid;
    grid-template-columns: ${vwDesktop(300)} ${vwDesktop(980)};
    grid-column-gap: ${vwDesktop(40)};
  }
  @media (min-width: 1205px) {
    grid-template-columns: ${vwDesktop(240)} ${vwDesktop(980)};
    grid-column-gap: ${vwDesktop(100)};
  }
`;

const Plan = styled.div`
  width: ${(props) => (props.smallWidth ? vwMobile(200) : vwMobile(240))};
  margin: ${vwMobile(40)} 0;
  img {
    width: 100%;
  }
  @media ${media.tablet} {
    width: ${(props) => (props.smallWidth ? vwTablet(424) : vwTablet(536))};
    margin: ${vwTablet(60)} 0;
  }
  @media ${media.desktop} {
    grid-column: 2;
    grid-row: 1 / 3;
    width: 100%;
    min-height: ${vwDesktop(882)};
    height: ${vwDesktop(882)};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    img {
      max-height: 100%;
      height: 100%;
      max-width: 100%;
      width: auto;
    }
  }
`;

const Details = styled.div`
  img {
    width: ${vwMobile(160)};
  }
  @media ${media.tablet} {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    img {
      width: ${vwTablet(200)};
    }
    .body4 {
      max-width: ${vwTablet(240)};
    }
    .text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
  @media ${media.desktop} {
    display: block;
    grid-column: 1;
    grid-row: 2;
    margin-top: ${vwDesktop(32)};
    .text {
      display: block;
    }
    .image {
      height: ${vwDesktop(318)};
    }
    img {
      width: ${vwDesktop(240)};
    }
    .body4 {
      max-width: ${vwDesktop(240)};
    }
  }
`;

const Download = styled.a`
  border: 1px solid ${({ theme }) => theme.color.black};
  height: ${vwMobile(40)};
  padding: 0 ${vwMobile(12)};
  margin: ${vwMobile(32)} 0 ${vwMobile(20)};
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  @media ${media.tablet} {
    height: ${vwTablet(40)};
    padding: 0 ${vwTablet(12)};
    margin: 0 0 ${vwTablet(24)};
  }
  @media ${media.desktop} {
    height: ${vwDesktop(40)};
    padding: 0 ${vwDesktop(12)};
    margin: 0 0 ${vwDesktop(32)};
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.color.black};
      p {
        color: white;
      }
    }
  }
`;

export default SinglePlan;
