import React from 'react';
import styled from 'styled-components';

import ThankyouMessage from 'components/Project/Form/ThankyouMessage';

const Thankyou = () => {
  return (
    <Root>
      <ThankyouMessage
        title="Thank you for registering for
        ONE Shaughnessy"
        text="With your registration, you will be among the first to receive updates on this brand new community. You will receive an email confirmation shortly."
        buttonLabel="back to one shaughnessy"
      />
    </Root>
  );
};

const Root = styled.div``;

export default Thankyou;
