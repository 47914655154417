export default {
  membership: {
    heading: 'Homeowner Membership',
    body: 'When you buy a home from Kutak, you become part of a family. Your personal membership invites you to participate in your community and offers exclusive benefits. Here, you will belong to the long legacy of a distinguished home.',
    badge: require('src/assets/images/global/icon/kutak/kutak-me.svg'),
    badgeDescription: 'me',
    list: [
      'Follow the progress of your home’s construction via an online homeowner platform.',
      'Receive updates on local happenings in your new neighbourhood.',
      'Access information about your home, such as product manuals and warranty.',
      'Discover member benefits and belong to a community.',
    ]
  },
  intelligence: {
    heading: 'Kutak Intelligence',
    body: 'We created a unique system that combines intelligence, connection, legacy, and innovation to drive a project forward. For homeowners, this means quality homes, accurate and timely information, and peace of mind.',
  }
}