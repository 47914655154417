import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setLogin } from 'src/redux/membership';

import vw from 'src/styles/utils';

const Membership = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const dispatch = useDispatch();

  dispatch(setLogin(true));
  return(
    <Root> 
      <h1>Not Logged In!</h1>
    </Root>
  )
};

export default Membership;

const Root = styled.div`
  background-color: white;
  ${vw('margin-top', 60, 82, 100)}
`;