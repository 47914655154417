import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Title from './Title';
import UpgradeCard from '../UpgradeCard';
import Button from 'components/Button';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Upgrades = (props) => {
  const { data, handlePrint } = props;

  return (
    <Root>
      <Title to="/design/upgrades" title="selected upgrades" />
      <Wrapper>
        {data.length === 0 && (
          <p className="body2 white noupgrades">
            No upgrades have been selected
          </p>
        )}
        {data.map((item, index) => (
          <UpgradeCard
            item={item}
            key={index}
            active={true}
            handleClick={(f) => f}
            summary
          />
        ))}
      </Wrapper>
      <Container>
        <Button
          type="primary-arrow"
          label="Save preferences"
          mobileLabel="Save"
          to="/design/save"
          small
        />
        <Button
          type="primary-arrow"
          label="Print Preview"
          clickHandler={handlePrint}
          xSmall
        />
      </Container>
    </Root>
  );
};

Upgrades.propTypes = {
  data: PropTypes.array,
  handlePrint: PropTypes.func,
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.black};
  padding: ${vwMobile(60)} 0 ${vwMobile(40)};
  @media ${media.tablet} {
    padding: ${vwTablet(70)} 0 ${vwTablet(40)};
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(70)} 0 ${vwDesktop(40)};
  }
`;

const Wrapper = styled.div`
  padding: 0 ${vwMobile(16)};
  .noupgrades {
    white-space: nowrap;
  }
  @media ${media.tablet} {
    padding: 0 ${vwTablet(60)};
    display: grid;
    grid-template-columns: ${vwTablet(240)} ${vwTablet(240)};
    grid-template-rows: auto;
    grid-gap: ${vwTablet(32)};
  }
  @media ${media.desktop} {
    padding: 0 ${vwDesktop(60)};
    grid-template-columns: ${vwDesktop(240)} ${vwDesktop(240)} ${vwDesktop(240)} ${vwDesktop(
        240
      )};
    grid-gap: ${vwDesktop(32)};
  }
`;

const Container = styled.div`
  padding: ${vwMobile(24)} ${vwMobile(16)} 0;
  > a:first-of-type {
    margin-bottom: ${vwMobile(12)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(60)} ${vwTablet(60)} 0;
    display: flex;
    justify-content: flex-end;
    > a:first-of-type {
      margin-right: ${vwTablet(12)};
      margin-bottom: 0;
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(60)} ${vwDesktop(60)} 0;
    > a:first-of-type {
      margin-right: ${vwDesktop(12)};
    }
  }
`;

export default Upgrades;
