import React, { useState } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import Resource from './components/Resource';

import vw from 'src/styles/utils';

export default function Resources() {
  const [loggedIn, setLoggedIn] = useState((localStorage.getItem('token') !== null));

  return (
    <>
      {
        !loggedIn ? 
        <Redirect to='/membership' /> :
        <Root>
          <h1 className='corporate black'>Resources</h1>
          <Resource
            title='Your Personal Communication Portal'
            description=''
            btnLabel='Login'
            url='https://kutakdevelopment.com'
          />

          <Resource
            title='Deficiency Tool'
            description='Coming Soon'
            btnLabel='Login'
            url='https://kutakdevelopment.com'
          />

          <Resource
            title='Product Manuals & Warranties'
            description='Coming Soon'
            btnLabel='View'
            url='https://kutakdevelopment.com'
          />

          <Resource
            title='Strata'
            description='Coming Soon'
            btnLabel='View'
            url='https://kutakdevelopment.com'
          />
        </Root>
      }
    </>
  )
}

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  ${vw('padding-top', 40, 80, 100)}
  ${vw('padding-right', 16, 60 ,172)}
  ${vw('padding-bottom', 38, 80)}
  ${vw('padding-left', 16, 60 ,172)}

  h1 {
    ${vw('margin-bottom', 40, 60)}
  }
`;