import React from 'react';
import styled from 'styled-components';

import ImageCallout from 'components/Project/ImageCallout';
import Button from 'components/Button';

import media from 'src/styles/media';
import vw, { vwDesktop } from 'src/styles/utils';

const Rooftop = () => {
  return (
    <Root>
      <StyledImageCallout
        image={require('src/assets/images/oneshaughnessy/one-shaughnessy-rooftop-patio.jpeg')}
        alt="Rooftop Patio"
        points={[
          {
            top: '17.2%',
            left: '49%',
            text:
              'Admire views that extend across the city, all the way to the mountains',
          },
          {
            top: '83.3%',
            left: '77.3%',
            text:
              'Nurture herbs, flowers, or other plants in the community garden',
          },
          {
            top: '51.3%',
            left: '59.1%',
            text:
              'Lounge in the seating area with a book, a beverage, or simply your thoughts',
          },
          {
            top: '34.1%',
            left: '27.2%',
            text:
              'The rooftop terrace is available for enjoyment day and night',
          },
        ]}
      />
      <Block>
        <BlockContent>
          <h5 className="global black uppercase">Rooftop Terrace</h5>
          <p className="body2 black">
            See the community from a higher perspective on the rooftop terrace
            amenity, where you can socialize, watch the sunset, or relax under a
            blue sky.
          </p>
        </BlockContent>
        <Download
          href="/pdf/oneshaughnessy/one-shaughnessy-feature-sheet.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <StyledButton label="Download Feature Sheet" type="secondary-gold" />
        </Download>
      </Block>
    </Root>
  );
};

export default Rooftop;

const Root = styled.div``;

const StyledImageCallout = styled(ImageCallout)`
  width: 100%;
  height: ${vwDesktop(750)};
`;

const Block = styled.div`
  ${vw('padding-top', 32, 32)}
  ${vw('padding-right', 16, 60)}
  ${vw('padding-bottom', 60, 80, 120)}
  ${vw('padding-left', 16, 60)}
  @media ${media.desktop} {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
`;

const BlockContent = styled.div`
  ${vw('max-width', 312, 312)}
  h5 {
    ${vw('margin-bottom', 8, 8)}
  }
  p {
    ${vw('margin-bottom', 24, 24, 0)}
  }
`;

const Download = styled.a``;

const StyledButton = styled(Button)`
  border: 5px solid red;
`;
