import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setKitchenPalette, setKitchenFlooring } from 'src/redux/configurator';
import { animateScroll } from 'react-scroll';

import ColorSelect from 'components/Configurator/ColorSelect';
import Button from 'components/Button';
import Description from 'components/Configurator/Description';
import MobileSelect from 'components/Configurator/MobileSelect';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/Configurator/oneshaughnessy';

const Kitchen = () => {
  const [isDescriptionActive, setIsDescriptionActive] = useState(false);
  const [activeOption, setActiveOption] = useState(0);

  const config = useSelector((state) => state.configurator);

  const dispatch = useDispatch();

  const $root = React.useRef();

  const handleDescription = () => {
    setIsDescriptionActive(!isDescriptionActive);
    if (window.innerWidth > 992) {
      animateScroll.scrollTo($root.current.offsetTop - 50, {
        smooth: true,
      });
    }
  };

  return (
    <Root ref={$root}>
      <Image>
        {data.kitchen.palette.map((palette, i) =>
          data.kitchen.flooring.map((floor, j) => (
            <img
              src={require(`src/assets/images/config/oneshaughnessy/renders/kitchen/kitchen-palette-k${
                i + 1
              }-flooring-${j + 1}.jpeg`)}
              alt=""
              key={`img-${j}-${i}`}
              style={{
                opacity:
                  i === config.kitchen.palette && j === config.kitchen.flooring
                    ? 1
                    : 0,
              }}
            />
          ))
        )}
      </Image>
      <MobileSelect
        options={['palette', 'flooring']}
        activeOption={activeOption}
        handleOption={setActiveOption}
      />
      <Content>
        <Div>
          <ColorSelect
            title="Select Palette"
            options={data.kitchen.palette}
            handleClick={(index) => dispatch(setKitchenPalette(index))}
            activeOption={config.kitchen.palette}
            project="oneshaughnessy"
            swatchName="palette-k"
            mobileActive={activeOption === 0}
            labelRight={48}
            labelName="Kitchen"
            labelId="K"
          />
          <ColorSelect
            title="Select Flooring"
            options={data.kitchen.flooring}
            handleClick={(index) => dispatch(setKitchenFlooring(index))}
            activeOption={config.kitchen.flooring}
            project="oneshaughnessy"
            swatchName="k-flooring-"
            small
            mobileActive={activeOption === 1}
          />
        </Div>
        <Flex>
          <div>
            <p className="configurator caption grey">
              Upper Cabinet: Gloss White
            </p>
            <p className="configurator caption grey">
              Countertop &amp; Backsplash: Composite{' '}
            </p>
            <p className="configurator caption grey">
              Base Cabinet:{' '}
              {data.kitchen.palette[config.kitchen.palette].cabinet}
            </p>
            <p className="configurator caption grey">Fixture Brand: Kohler</p>
            <p className="configurator caption grey">
              Flooring: {data.kitchen.flooring[config.kitchen.flooring].name}
            </p>
          </div>
          <ArrowButton
            className="configurator caption gold"
            onClick={handleDescription}
            active={isDescriptionActive}
          >
            Full Description{' '}
            <img
              src={require('src/assets/images/global/icon/action/chevron-right-gold.svg')}
              alt="Description"
            />
          </ArrowButton>
        </Flex>
        <Description
          active={isDescriptionActive}
          handleClose={() => setIsDescriptionActive(false)}
        >
          <p className="body3 black">
            Appeal to all your tastes in a highly functional kitchen that can be
            personalized from a selection of cabinetry.
          </p>
          <ul className="body3 black">
            <li>High-end appliance package, including:</li>
            <li className="subpoint">
              Fisher Paykel 36” integrated panel refrigerator (32” in Studios)
            </li>
            <li className="subpoint">GE Profile 30” induction range</li>
            <li className="subpoint">
              GE Profile 24” dishwasher with integrated panel
            </li>
            <li className="subpoint">Slim telescopic hood fan</li>
            <li>
              Quality kitchen cabinetry, extending to the ceiling, locally
              sourced and tailored to your personality
            </li>
            <li>Integrated under-cabinet lighting</li>
            <li>Soft-close drawers</li>
            <li>Engineered quartz stone countertop and backsplash</li>
            <li>Kohler plumbing fixtures</li>
          </ul>
        </Description>
        <Wrapper>
          <Button type="primary-arrow" label="Next" to="/design/bathroom" />
        </Wrapper>
      </Content>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  @media ${media.tablet} {
  }
  @media ${media.desktop} {
    display: flex;
  }
`;

const Image = styled.div`
  width: 100%;
  height: ${vwMobile(276)};
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
    object-position: center;
    transition: 0.3s ease;
  }
  @media ${media.tablet} {
    height: ${vwTablet(662)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(816)};
    height: ${vwDesktop(704)};
  }
`;

const Content = styled.div`
  position: relative;
  padding: ${vwMobile(32)} ${vwMobile(16)} ${vwMobile(40)};
  @media ${media.tablet} {
    padding: ${vwTablet(40)} ${vwTablet(60)};
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(40)} ${vwDesktop(60)};
    width: ${vwDesktop(624)};
  }
`;

const ArrowButton = styled.button`
  margin: ${vwMobile(24)} 0 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    width: ${vwMobile(12)};
    margin-left: ${vwMobile(4)};
    display: block;
    transform: ${(props) =>
      props.active ? 'scaleY(-1) rotate(90deg)' : 'rotate(90deg)'};
    transition: 0.3s ease;
  }
  @media ${media.tablet} {
    margin: ${vwTablet(24)} 0 0;
    img {
      width: ${vwTablet(12)};
      margin-left: ${vwTablet(4)};
    }
  }
  @media ${media.desktop} {
    margin: 0;
    img {
      width: ${vwDesktop(12)};
      margin-left: ${vwDesktop(4)};
      display: block;
      transform: none;
    }
  }
`;

const Wrapper = styled.div`
  margin-top: ${vwMobile(40)};
  @media ${media.tablet} {
    margin-top: ${vwTablet(40)};
    display: flex;
    justify-content: flex-end;
  }
  @media ${media.desktop} {
    position: absolute;
    bottom: ${vwDesktop(40)};
    right: ${vwDesktop(60)};
    margin-top: 0;
    display: block;
  }
`;

const Div = styled.div`
  position: relative;
`;

const Flex = styled.div`
  @media ${media.desktop} {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
`;

export default Kitchen;
