import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import data from 'src/data/Membership/data';
import { Redirect } from 'react-router-dom';

import vw, { vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

export default function Login() {
  const [username, setUsername] = useState('');
  const [userNiceName, setUserNiceName] = useState('');
  const [password, setPassword] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [token, setToken] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = ( event ) => {
    let input = event.target.value;
    switch (event.target.name) {
      case 'username':
        setUsername(input);
        return;
      case 'password':
        setPassword(input);
        return;
      default:
        return;
    }
  }

  const onFormSubmit = () => {
    event.preventDefault();

     const siteUrl = 'https://kutakdevelopment.com/cms/wp-json/jwt-auth/v1/token';
     const loginData = {
       username: username,
       password: password,
     }

     setLoading(true);
     axios.post(siteUrl, loginData)
      .then( res => {
        if ( res.data.token === undefined ) {
          setError(data.message);
          setLoading(false);
          return;
        }
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('username', res.data.nice_nicename);
        localStorage.setItem('email', res.data.user_email);
        setLoading(false);
        setToken(res.data.token);
        setUserNiceName(res.data.user_nicename);
        setUserEmail(res.data.user_email);
        setLoggedIn(true);
        setError('');
      }) 
      .catch(err => {
        setError(err.response.data);
      })
  }
  return (
    <Root>
      {
        (loggedIn || localStorage.getItem('token')) &&
        <Redirect to='/membership/updates' />
      }
      <Graphic>
        <img src={require('src/assets/images/global/logo/one-shaughnessy-lockup.svg')} alt='graphic' />
      </Graphic>

      <MembershipLogin>
        <Body>
          <img
            src={require('src/assets/images/global/icon/kutak/kutak-me.svg')}
            alt="Kutak"
          />
          <h3 className='corporate gold'>{data.login.bodyTitle}</h3>
          <p className='body2 black'>{data.login.body}</p>
        </Body>

        <form onSubmit={onFormSubmit}>
          <label className='form-label gold'>Member id</label>
          <input
            type='text'
            name='username'
            value={username}
            className='body2 black'
            onChange={handleChange}
          />
          <label className='form-label gold'>Password</label>
          <input
            type='password'
            name='password'
            value={password}
            className='body2 black'
            onChange={handleChange}
          />

          <ForgotPassword 
            className='body4 black' 
            href="https://kutakdevelopment.com/cms/wp-login.php?action=lostpassword"
          >
            Forgot Password?
          </ForgotPassword>

          <button type='submit'>
            <span>Login</span>
            <img
              src={require('src/assets/images/global/icon/action/chevron-right-white.svg')}
              alt="chevron right white"
              className="chevron-right"
            />
          </button>
          <InvalidCredentials className='body4' isInvalid={error !== ''}>Invalid Member ID/Password. Please try again.</InvalidCredentials>
        </form>
      </MembershipLogin>
    </Root>
  )
}

const Root = styled.div`
  @media ${media.desktop} {
    display: flex;
  }
`;

const Body = styled.div`
  ${vw('margin-bottom', 32, 0)}
  img {
    ${vw('width', 100)}
    ${vw('height', 100)}
    ${vw('margin-bottom', 24, 40)}
  }
  h3 {
    ${vw('margin-bottom', 16, 20)}
  }
`;
const MembershipLogin = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  ${vw('padding-top', 48, 60, 120)}
  ${vw('padding-right', 16, 120)}
  ${vw('padding-bottom', 60, 154)}
  ${vw('padding-left', 16, 60)}
  ${vw('width', 320, 768, 840)}
  /* ${vw('height', 384, 470, 700)} */
  form {
    position: relative;
    label {
      display: block;
      text-transform: uppercase;
      ${vw('margin-bottom', 8)}
    }
    input {
      outline: none;
      border: 1px solid ${({ theme }) => theme.color.gold};
      ${vw('width', 288, 312, 300)}
      ${vw('height', 44, 50)}
      ${vw('padding-top', 12)}
      ${vw('padding-right', 12)}
      ${vw('padding-bottom', 12)}
      ${vw('padding-left', 12)}
      &:first-of-type {
        ${vw('margin-bottom', 24)}
      }
      &:last-of-type {
        ${vw('margin-bottom', 12)}
      }
    }

    button {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: ${({ theme }) => theme.color.gold};
      transition: all 0.3s ease;
      ${vw('width', 140)}
      ${vw('height', 44)}
      ${vw('padding-left', 12)}
      ${vw('padding-right', 12)}
      &:hover,
      &:active {
        background-color: ${(props) =>
          props.disabled ? props.theme.color.disabled : props.theme.color.black};
      }
      span {
        font-family: 'futura-pt';
        color: ${({ theme }) => theme.color.white};
        text-transform: uppercase;
        font-weight: 500;
        ${vw('font-size', 16)}
        ${vw('line-height', 20)}
        ${vw('letter-spacing', 3)}
      }
      .chevron-right {
        display: block;
        ${vw('width', 14, 14)}
        ${vw('height', 14, 14)}
      }
    }
  }

  @media ${media.tablet} {
    display: flex;
    form {
      padding-top: ${vwTablet(150)};
      margin-left: ${vwTablet(36)};
    }
  }
  @media ${media.desktop} {
    form {
      padding-top: ${vwDesktop(150)};
      margin-left: ${vwDesktop(60)};
    }
  }
`;

const Graphic = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.skyBlue};
  ${vw('width', 320, 768, 600)}
  ${vw('height', 384, 470, 700)}
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    ${vw('width', 256, 280, 400)}
    ${vw('height', 320, 350, 500)}

    @media ${media.tablet} {
      left: ${vwTablet(60)};
      transform: translate(0, -50%)
    }
    @media ${media.desktop} {
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const ForgotPassword = styled.a`
  ${vw('margin-bottom', 24)};
`;

const InvalidCredentials = styled.p`
  position: absolute;
  ${vw('bottom', -38)};
  visibility: ${props => props.isInvalid ? 'visible' : 'hidden'};
  opacity: ${props => props.isInvalid ? '1' : '0'};
  color: red;
  transition: all 0.3s ease;
`;