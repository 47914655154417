import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Image = (props) => {
  const { image, data, activeCategory, activePoint, handlePoint } = props;
  return (
    <Root>
      <Fade>
        <img src={image} alt="Map" className="map" />
      </Fade>
      {data.map((category, index) => (
        <Category
          key={index}
          active={activeCategory === index}
          style={{
            opacity: activeCategory === index ? 1 : 0,
            pointerEvents: activeCategory === index ? 'all' : 'none',
          }}
        >
          {category.points.map((point, j) => (
            <Point
              top={point.top}
              left={point.left}
              active={activePoint === j}
              style={{ zIndex: activePoint === j ? 2 : 1 }}
              onClick={() => handlePoint(j)}
              rightTitle={point.rightTitle}
              bottomLabel={point.bottomLabel}
            >
              <p className="map-number white">{j < 9 ? `0${j + 1}` : j + 1}</p>
              <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  id="Symbols"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="icon/map/map-point/black"
                    fill={activePoint === j ? '#b18b6e' : '#222222'}
                  >
                    <path
                      d="M11.9998266,24 C11.9547331,24 11.9092927,23.9897824 11.8669742,23.968995 L2.48787711,19.33762 C2.40497448,19.2967498 2.34461859,19.2199419 2.32449996,19.1283361 L0.00738879929,8.72262838 C-0.0127298306,8.63137497 0.00877629101,8.53624593 0.0653165784,8.46296133 L6.55600282,0.117325817 C6.6139306,0.043336563 6.70168945,0 6.7946514,0 L17.2053486,0 C17.2983105,0 17.3860694,0.043336563 17.4439972,0.117325817 L23.9346834,8.46296133 C23.9912237,8.53624593 24.0127298,8.63137497 23.9926112,8.72262838 L21.6755,19.1283361 C21.6550345,19.2199419 21.5950255,19.2967498 21.511776,19.33762 L12.1330258,23.968995 C12.0907073,23.9897824 12.0452669,24 11.9998266,24 L11.9998266,24 Z"
                      id="map-point-inactive"
                    ></path>
                  </g>
                </g>
              </svg>
              <div style={{ opacity: activePoint === j ? 1 : 0 }}>
                <p className="body3 white">{point.text}</p>
              </div>
              {point.offMap && (
                <Arrow
                  src={require('src/assets/images/global/icon/map/small-map-arrow.svg')}
                  alt="Off the Map"
                  position={point.position}
                />
              )}
            </Point>
          ))}
        </Category>
      ))}
    </Root>
  );
};

Image.propTypes = {
  image: PropTypes.string,
  data: PropTypes.array,
  activeCategory: PropTypes.number,
  activePoint: PropTypes.number,
  handlePoint: PropTypes.func,
};

const Root = styled.div`
  width: ${vwMobile(288)};
  height: ${vwMobile(288)};
  position: relative;
  .map {
    width: 100%;
    height: 100%;
    image-rendering: -webkit-optimize-contrast;
  }
  @media ${media.tablet} {
    width: ${vwTablet(648)};
    height: ${vwTablet(648)};
  }
  @media ${media.desktop} {
    grid-row: 1 / 3;
    grid-column: 2;
    width: ${vwDesktop(840)};
    height: ${vwDesktop(840)};
  }
`;

const Category = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s ease;
`;

const Point = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  width: ${vwMobile(8)};
  height: ${vwMobile(8)};
  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.3s ease;
    transform: ${(props) => (props.active ? 'scale(2)' : 'scale(1)')};
    transform-origin: 50% 50%;
  }
  p,
  > div {
    display: none;
  }
  @media ${media.tablet} {
    width: ${vwTablet(20)};
    height: ${vwTablet(20)};
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      transform: scale(1);
    }
    p {
      display: block;
      position: relative;
      z-index: 2;
    }
    > div {
      display: block;
      position: absolute;
      bottom: ${(props) =>
        props.bottomLabel ? 'auto' : `calc(100% + ${vwTablet(60)})`};
      top: ${(props) =>
        props.bottomLabel ? `calc(99% + ${vwTablet(60)})` : 'auto'};
      left: 50%;
      transform: ${(props) =>
        props.rightTitle ? 'none' : 'translateX(calc(-100% + 0.5px))'};
      background-color: #000000;
      padding: ${vwTablet(8)};
      white-space: nowrap;
      transition: 0.4s ease;
      &::after {
        content: '';
        display: block;
        width: 2px;
        height: ${vwTablet(60)};
        background-color: black;
        position: absolute;
        right: ${(props) => (props.rightTitle ? 'auto' : 0)};
        left: ${(props) => (props.rightTitle ? 0 : 'auto')};
        top: ${(props) => (props.bottomLabel ? 'auto' : '100%')};
        bottom: ${(props) => (props.bottomLabel ? '100%' : 'auto')};
      }
    }
  }
  @media ${media.desktop} {
    width: ${vwDesktop(20)};
    height: ${vwDesktop(20)};
    cursor: pointer;
    > div {
      bottom: ${(props) =>
        props.bottomLabel ? 'auto' : `calc(100% + ${vwDesktop(60)})`};
      top: ${(props) =>
        props.bottomLabel ? `calc(99% + ${vwDesktop(60)})` : 'auto'};
      padding: ${vwDesktop(8)};
      pointer-events: none;
      &::after {
        height: ${vwDesktop(60)};
      }
    }
  }
`;

const Arrow = styled.img`
  width: ${vwMobile(4)};
  position: absolute;
  top: ${(props) => {
    switch (props.position) {
      case 'right':
        return '50%';
      case 'bottom-right':
        return '80%';
      default:
        return '100%';
    }
  }};
  left: ${(props) => {
    switch (props.position) {
      case 'right':
        return '100%';
      case 'bottom-right':
        return '50%';
      default:
        return '25%';
    }
  }};
  transform: ${(props) => {
    switch (props.position) {
      case 'right':
        return 'translateY(-50%) rotate(-90deg)';
      case 'bottom-right':
        return 'rotate(-25deg)';
      default:
        return 'none';
    }
  }};
  @media ${media.tablet} {
    width: ${vwTablet(6)};
    top: ${(props) => {
      switch (props.position) {
        case 'right':
          return '50%';
        case 'bottom-right':
          return '85%';
        default:
          return '100%';
      }
    }};
    left: ${(props) => {
      switch (props.position) {
        case 'right':
          return '100%';
        case 'bottom-right':
          return '60%';
        default:
          return '50%';
      }
    }};
    transform: ${(props) => {
      switch (props.position) {
        case 'right':
          return 'translateY(-50%) rotate(-90deg)';
        case 'bottom-right':
          return 'rotate(-25deg)';
        default:
          return 'translateX(-50%)';
      }
    }};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(6)};
  }
`;

export default Image;
