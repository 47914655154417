import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

import Button from 'components/Button';
import data from 'src/data/CaseStudies';

import media from 'src/styles/media';
import vw, { vwDesktop } from 'src/styles/utils';

export default function CaseStudyCards(props) {
  const { currentProject, originalLocation } = props;
  const location = useLocation();
  return (
    <Root>
      <h2 className="global gold uppercase">Case Studies</h2>
      {data.map((study, key) => {
        return (
          <Clickable
            to={{
              pathname: `/philosophy/kis/case-study/${study.slug}`,
              state: {
                prevLocation: originalLocation
                  ? originalLocation
                  : location.pathname,
              },
            }}
          >
            <Fade>
              <Card key={key} hideProject={currentProject === study.slug}>
                <FeaturedImage>
                  <img
                    src={study.content.featuredImage}
                    alt={`${study.content.projectName}`}
                  />
                  <div className="infographic">
                    {study.infographic.map((info, i) => {
                      return (
                        <Info key={i}>
                          <h5 className="global gold uppercase">
                            {info.label}
                          </h5>
                          <p className="body3 white">{info.text}</p>
                        </Info>
                      );
                    })}
                  </div>
                </FeaturedImage>
                <Content>
                  <h4 className="global gold uppercase">{study.title}</h4>
                  <h3 className="corporate black">{study.hero.heading}</h3>
                  <Link
                    to={{
                      pathname: `/philosophy/kis/case-study/${study.slug}`,
                      state: {
                        prevLocation: originalLocation
                          ? originalLocation
                          : location.pathname,
                      },
                    }}
                  >
                    <Button label="View Case Study" type="primary-gold" />
                  </Link>
                </Content>
              </Card>
            </Fade>
          </Clickable>
        );
      })}
    </Root>
  );
}

CaseStudyCards.propTypes = {
  currentProject: PropTypes.string,
  originalLocation: PropTypes.string,
};

const Root = styled.div`
    background-color: ${({ theme }) => theme.color.lightGrey};
    width: 100%;
    ${vw('padding-top', 57, 80, 100)}
    ${vw('padding-right', 16, 60)}
    ${vw('padding-bottom', 48, 80, 100)}
    ${vw('padding-left', 16, 60)}
    h2 {
      ${vw('margin-bottom', 24, 40)}
    }
`;
const Clickable = styled(Link)`
  display: block;
  &:not(:last-of-type) {
    ${vw('margin-bottom', 24, 40)}
  }
`;
const Card = styled.div`
  display: ${(props) => (props.hideProject ? 'none' : 'block')};
  background-color: ${({ theme }) => theme.color.white};
  ${vw('height', 362, 670, 360)}
  @media ${media.desktop} {
    display: ${(props) => (props.hideProject ? 'none' : 'flex')};
    flex-direction: row-reverse;
    &:hover {
      .infographic {
        opacity: 1;
      }
    }
  }
`;

const FeaturedImage = styled.div`
  position: relative;
  overflow: hidden;
  ${vw('width', 288, 648, 720)}
  ${vw('height', 144, 324, 360)}
  .infographic {
    display: none;
  }
  @media ${media.desktop} {
    .infographic {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: ${vwDesktop(60)};
      position: absolute;
      opacity: ${(props) => (props.ishovered ? '1' : '0')};
      top: 0;
      right: 0;
      z-index: 1;
      background-color: rgba(34, 34, 34, 0.85);
      transition: all 0.6s ease;
    }
  }
`;

const Info = styled.div`
  @media ${media.desktop} {
    width: ${vwDesktop(280)};
    margin-bottom: ${vwDesktop(40)};
    h5 {
      margin-bottom: ${vwDesktop(4)};
    }
  }
`;

const Content = styled.div`
  ${vw('width', 288, 648, 600)}
  ${vw('padding-top', 16, 40)}
  ${vw('padding-right', 16, 40)}
  ${vw('padding-bottom', 16, 40)}
  ${vw('padding-left', 16, 40)}
  h4 {
    ${vw('margin-bottom', 12, 16, 16)}
  }
  h3 {
    ${vw('width', 256, 568, 500)}
    ${vw('margin-bottom', 32, 60, 74)}
  }
`;
