import React from 'react';

export default {
  title: 'Grandview Woodland',
  slug: 'grandview-woodland',
  hero: {
    badge: require('src/assets/images/global/icon/kutak/kutak-kis.svg'),
    heading: <>Developing contemporary housing solutions.</>,
    bodyTitle: '',
    body: [
    ]
  },
  infographic: [
    {
      label: 'Size',
      text: '2 mid-rise buildings; 10 storeys; 165,000 SF; 3.6 FSR',
    },
    {
      label: 'Developer',
      text: 'Confidential',
    },
    {
      label: 'Address',
      text: 'Confidential',
    },
    {
      label: 'Type',
      text: 'Approximately 180 to 190 residential homes; 160 to 180 parking stalls',
    },
  ],
  content: {
    projectName: 'Grandview Woodland',
    featuredImage: require('src/assets/images/philosophy/kis-case-study/kutak-woodland-feature.jpg'),
    bodyTitle: 'A municipal collaboration to build community.',
    bodyText: 
    <>
    Under the roles of Owner’s Representative and Development Manager, Kutak has been working closely with the City of Vancouver to replace two aging rental buildings which are beyond their lifespan. This will create modern living spaces across two new buildings, while increasing density, doubling the supply of rental units, and providing strata homes.
    <br/><br/>
    We are also acting as the project’s Tenant Relocation Specialist to formalize responsible and thoughtful relocation strategies for tenants in existing site structures. We will be mediating between the tenants and landowners to find a position that works for each party. As this is likely to be a two-phase project, with the market rental homes developed first, we hope to welcome some tenants into the new project with minimal disruption.
    <br/><br/>
    Kutak continues to manage a pre-zoning process, with a draft rezoning application being prepared for formal submission. It is the land owner’s preference to have this submitted in 2021.
    </>,
    stats1Percentage: '34%',
    stats1Label: 'Lorem isum dolor sit amet',
    stats2Percentage: '18%',
    stats2Label: 'Lorem isum dolor sit amet',
  }
}