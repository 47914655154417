import React from 'react';
import styled from 'styled-components';

import data from 'src/data/Homes/data';

import media from 'src/styles/media';
import vw, { vwDesktop } from 'src/styles/utils';

export default function Experience() {
  return (
    <Root>
      <h2 className='global gold uppercase'>{data.experience.subHeading}</h2>
      <Body>
        <h3 className='corporate black'>{data.experience.heading}</h3>
        <p className='body1 black'>{data.experience.body}</p>
      </Body>

    </Root>
  )
}

const Root = styled.div`
    background-color: white;
    ${vw('padding-top', 48, 80, 100)}
    ${vw('padding-right', 16, 60)}
    ${vw('padding-bottom', 48, 80, 100)}
    ${vw('padding-left', 16, 60)}
`;

const Body = styled.div`
  ${vw('margin-top', 24, 40)}
  h3 {
    ${vw('width', 288, 591, 312)}
    ${vw('margin-bottom', 16, 20, 0)}
  }
  @media ${media.desktop} {
    display: flex;
    p {
      width: ${vwDesktop(640)};
      margin-top: ${vwDesktop(12)};
      margin-left: ${vwDesktop(288)}
    }
  }
`;