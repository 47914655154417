import React, { useState } from 'react';
import styled from 'styled-components';

import { vwMobile} from 'src/styles/utils';
import media from 'src/styles/media';

export default function FiltersMobile(props) {
  const { activeFilterName, categories, handleFilterChange } = props;
  const [ isDropdownOpen, setIsDropdownOpen ] = useState(false);

  const handleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  }

  return (
    <Root>
      <h5 className="global black uppercase">Filter By</h5>
      <DropdownSelected className='body2 black' onClick={handleDropdown} isDropdownOpen={isDropdownOpen}>
        {activeFilterName}
        <img
          src={require('src/assets/images/global/icon/action/chevron-down-black.svg')}
          alt='Filters'
          className='mobile'
        />
      </DropdownSelected>
      <Dropdown isDropdownOpen={isDropdownOpen}>
        <DropdownItem className='button black uppercase' onClick={() => {handleFilterChange(0, 'All'); handleDropdown();}}>
          All
        </DropdownItem>
      {categories !== null && categories.map((category, i) =>
        category.name.toLowerCase() !== 'uncategorized' &&
        <DropdownItem
          key={i}
          value={i}
          className="button black uppercase"
          onClick={() => { handleFilterChange(category.id, category.name); handleDropdown(); }}
        >
          {category.name}
        </DropdownItem>
      )}
      </Dropdown>
    </Root>
  )
}

const Root = styled.div`
  margin-bottom: ${vwMobile(40)};
  h5 {
    margin-bottom: ${vwMobile(4)};
  }
  @media ${media.tablet} {
    display: none;
  }
`;

const Dropdown = styled.div`
  position: absolute;
  overflow: hidden;
  max-height: ${props => props.isDropdownOpen ? vwMobile(160) : '0'};
  opacity: ${props => props.isDropdownOpen ? 1 : 0};
  transition: all 0.3s ease;
  z-index: 9999;
  h5 {
    width: ${vwMobile(288)};
    height: ${vwMobile(40)};
    padding: ${vwMobile()}
  }
`;

const DropdownSelected = styled.div`
  position: relative;
  width: ${vwMobile(288)};
  height: ${vwMobile(40)};
  padding: ${vwMobile(9)} ${vwMobile(12)};
  background-color: ${({ theme }) => theme.color.white};
  border: 1px solid black;
  img {
    position: absolute;
    top: ${vwMobile(12)};
    right: ${vwMobile(12)};
    width: ${vwMobile(16)};
    height: ${vwMobile(16)};
    transform: ${props => props.isDropdownOpen ? 'scaleY(-1)' : 'scaleY(1)'};
    transition: 0.3s ease;
  }
`;

const DropdownItem = styled.div`
  width: ${vwMobile(288)};
  height: ${vwMobile(40)};
  padding: ${vwMobile(9)} ${vwMobile(12)};
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  border-left: 1px solid black;
  text-transform: uppercase;
`;