import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import HeroCorporateText from 'components/HeroCorporateText';
import CaseStudyCards from 'components/CaseStudyCards';
import Button from 'components/Button';

import media from 'src/styles/media';
import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';

export default function CaseStudyTemplate(props) {
  const { data } = props;
  const location = useLocation();

  const handleBack = () => {
    if (typeof location.state !== 'undefined') {
      switch (location.state.prevLocation) {
        case '/homes':
          return 'Homes';
        case '/philosophy':
          return 'Philosophy';
        default:
          return 'KIS';
      }
    } else {
      return 'KIS';
    }
  };

  return (
    <Root>
      <Back>
        <Link
          to={
            typeof location.state !== 'undefined'
              ? location.state.prevLocation
              : '/philosophy/kis'
          }
        >
          <Button label={`Back to ${handleBack()}`} type="back" />
        </Link>
      </Back>
      <HeroCorporateText data={data.hero} badge={data.hero.badge} />
      <Content>
        <FeaturedImage>
          <img
            src={data.content.featuredImage}
            alt={`${data.content.projectName}`}
          />
        </FeaturedImage>
        <Info>
          <h4 className="global gold uppercase">{data.content.projectName}</h4>
          <InfoBlocks>
            {data.infographic.map((info, key) => {
              return (
                <InfoBlock key={key}>
                  <h5 className="global black uppercase">{info.label}</h5>
                  <p className="body4 black">{info.text}</p>
                </InfoBlock>
              );
            })}
          </InfoBlocks>
        </Info>
        <Body>
          {/* <Stats>
            <Stat>
              <h4 className='infographic heading gold'>{data.content.stats1Percentage}</h4>
              <h5 className='infographic subheading black'>{data.content.stats1Label}</h5>
            </Stat>
            <Stat>
              <h4 className='infographic heading gold'>{data.content.stats2Percentage}</h4>
              <h5 className='infographic subheading black'>{data.content.stats2Label}</h5>
            </Stat>
          </Stats> */}
          <Text>
            <h3 className="corporate black">{data.content.bodyTitle}</h3>
            <p className="body1 black">{data.content.bodyText}</p>
          </Text>
        </Body>
      </Content>
      <CaseStudyCards
        currentProject={data.slug}
        originalLocation={
          typeof location.state !== 'undefined'
            ? location.state.prevLocation
            : '/philosophy/kis'
        }
      />
    </Root>
  );
}

CaseStudyTemplate.propTypes = {
  data: PropTypes.object,
};

const Root = styled.div``;

const Back = styled.div`
  background-color: ${({ theme }) => theme.color.black};
  width: 100%;
  ${vw('height', 36, 54)}
  ${vw('padding-top', 12, 24)}
  ${vw('padding-right', 16, 30, 60)}
  ${vw('padding-bottom', 8, 12, 24)}
  ${vw('padding-left', 16, 30, 60)}
`;

const Content = styled.div``;

const FeaturedImage = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  ${vw('height', 240, 500, 720)}
  img {
    height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media ${media.desktop} {
    img {
      width: 100%;
      height: auto;
    }
  }
`;

const Info = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.lightGrey};
  ${vw('padding-top', 24, 48)}
  ${vw('padding-right', 16, 60, 172)}
  ${vw('padding-bottom', 24, 60, 40)}
  ${vw('padding-left', 16, 60, 172)}
  h4 {
    ${vw('margin-bottom', 24, 40, 0)}
  }
  @media ${media.desktop} {
    display: flex;
    h4 {
      width: ${vwDesktop(240)};
      margin-right: ${vwDesktop(100)};
    }
  }
`;

const InfoBlocks = styled.div`
  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
  }
  @media ${media.desktop} {
    width: ${vwDesktop(640)};
  }
`;

const InfoBlock = styled.div`
  ${vw('width', 272, 300)}
  &:not(:last-of-type) {
    margin-bottom: ${vwMobile(24)};
  }
  &:nth-of-type(even) {
    ${vw('margin-left', 0, 48, 40)}
  }
  h5 {
    ${vw('margin-bottom', 4, 4)}
  }
  @media ${media.tablet} {
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
    &:nth-of-type(-n + 2) {
      margin-bottom: ${vwTablet(32)};
    }
  }
  @media ${media.desktop} {
    &:nth-of-type(-n + 2) {
      margin-bottom: ${vwDesktop(32)};
    }
  }
`;

const Body = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  /* ${vw('padding-top', 40, 60, 80)}
  ${vw('padding-right', 16, 60, 172)}
  ${vw('padding-bottom', 40, 60, 80)}
  ${vw('padding-left', 16, 60, 172)} */
  ${vw('padding-top', 40, 60, 80)}
  ${vw('padding-right', 16, 60, 360)}
  ${vw('padding-bottom', 40, 60, 80)}
  ${vw('padding-left', 16, 60, 360)}
  @media ${media.desktop} {
    display: flex;
  }
`;

// const Stats = styled.div`
//   @media ${media.tablet} {
//     display: flex;
//     margin-bottom: ${vwTablet(60)};
//   }
//   @media ${media.desktop} {
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 0;
//   }
// `;

// const Stat = styled.div`
//   ${vw('width', 288, 300, 188)}
//   ${vw('margin-bottom', 40, 0, 80)}
//   &:last-of-type {
//     ${vw('margin-bottom', 60, 0)}
//     ${vw('margin-left', 0, 48, 0)}
//   }
//   h4 {
//     ${vw('margin-bottom', 8, 8, 12)}
//   }
// `;

const Text = styled.div`
  h3 {
    ${vw('margin-bottom', 16, 20)}
  }
  @media ${media.desktop} {
    /* width: ${vwDesktop(640)};
    margin-left: ${vwDesktop(152)}; */
  }
`;
