import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Button from 'components/Button';

import media from 'src/styles/media';
import vw from 'src/styles/utils';

export default function HeroCorporateImage(props) {
  const { 
    backgroundColor, 
    bodyImage, 
    bodyText, 
    projectURL, 
    heroImage, 
    heroText
  } = props;
  const history = useHistory();
  const handleClick = () => {
    history.push('/homes/one-shaughnessy')
  }
  return (
    <Root onClick={handleClick}>
      <Body backgroundColor={backgroundColor}>
        <img src={bodyImage} alt='kutak project' />
        <p className='highlight white'>{bodyText}</p>
        <Link to={projectURL}>
          <Button label='Learn more' type='text-link-black' />
        </Link>
      </Body>
      <Hero>
        <HeroImage imageURL={heroImage} alt='kutak project' />
        <h3 className='small white'>{heroText}</h3>
      </Hero>
    </Root>
  )
}

HeroCorporateImage.propTypes = {
  backgroundColor: PropTypes.func,
  bodyImage: PropTypes.string,
  bodyText: PropTypes.string,
  projectURL: PropTypes.string,
  heroImage: PropTypes.string,
  heroText: PropTypes.string,
}

const Root = styled.div`
  overflow: hidden;
  cursor: pointer;
  @media ${media.tablet} {
    display: flex;
  }
`;

const Body = styled.div`
  background-color: ${props => props.backgroundColor};
  ${vw('height', 564, 580, 720)}
  ${vw('padding-top', 48, 60, 100)}
  ${vw('padding-right', 24, 30, 60)}
  ${vw('padding-bottom', 48, 60, 100)}
  ${vw('padding-left', 24, 30, 60)}
  img {
    width: 100%;
    ${vw('margin-bottom', 24, 40)}
  }
  p {
    ${vw('width', 240, 240)}
    ${vw('margin-bottom', 24, 40)}
  }
`;

const Hero = styled.div`
  position: relative;
  h3 {
    position: absolute;
    text-align: right;
    ${vw('width', 120, 142)}
    ${vw('top', 12, 24, 40)}
    ${vw('right', 16, 30, 60)}
  }
`;

const HeroImage = styled.div`
  background-image: url(${props => props.imageURL});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  ${vw('width', 320, 468, 1080)}
  ${vw('height', 240, 580, 720)}
`;
