import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';

import Button from 'components/Button';
import data from 'src/data/Philosophy/data';

import media from 'src/styles/media';
import vw, { vwDesktop } from 'src/styles/utils';

export default function Intelligence() {    
  return (
    <Root>
      <Body>
        <h4 className='global gold uppercase'>{data.intelligence.heading}</h4>
        <p className='body2 white'>{data.intelligence.body}</p>
        <Link to='/philosophy/kis'>
          <Button label='Learn More' type='text-link-white' />
        </Link>
        <Badge src={require('src/assets/images/global/icon/kutak/kutak-kis.svg')} alt='kis'/>
      </Body>
      <Graphic>
        <Fade>
          <img src={require('src/assets/images/global/graphic/kis-intelligence.svg')} alt='kis intelligence' />
        </Fade>
      </Graphic>
    </Root>
  )
}

const Root = styled.div`
  position: relative;
  ${vw('padding-top', 48, 80, 120)}
  ${vw('padding-right', 16, 60)}
  ${vw('padding-bottom', 48, 80, 120)}
  ${vw('padding-left', 16, 60)}
  @media ${media.desktop} {
    height: ${vwDesktop(720)};
  }

`;

const Body = styled.div`
  position: relative;
  ${vw('margin-bottom', 40, 80, 0)}
  h4 {
    ${vw('margin-bottom', 24, 40)}
  }
  p {
    ${vw('width', 288, 312, 300)}
    ${vw('margin-bottom', 24, 40)}
  }
`;

const Badge = styled.img`
  ${vw('width', 80, 100)};
  ${vw('height', 80, 100)};
  ${vw('margin-top', 40, 0, 80)};
  @media ${media.tablet} {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  @media ${media.desktop} {
    position: static;
  }
`;

const Graphic = styled.div`
  ${vw('width', 240, 480)}
  ${vw('height', 240, 480)}
  img {
    width: 100%;
    height: auto;
  }
  @media ${media.desktop} {
    position: absolute;
    top: ${vwDesktop(120)};
    right: ${vwDesktop(300)};
  }
`;