import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import Button from 'components/Button';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ThankyouMessage = (props) => {
  const { title, text, buttonLabel } = props;

  const activeProject = useSelector((state) => state.project.activeProject);

  return (
    <Root>
      <h1 className="project white">{title}</h1>
      <p className="body-intro white">{text}</p>
      <Button
        label={buttonLabel}
        to={`/homes/${activeProject}`}
        type="submit"
      />
    </Root>
  );
};

ThankyouMessage.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  buttonLabel: PropTypes.string,
};

const Root = styled.div`
  padding: ${vwMobile(48)} ${vwMobile(16)};
  p {
    margin: ${vwMobile(20)} 0 ${vwMobile(40)};
  }
  h1 {
    max-width: ${vwMobile(220)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(80)} ${vwTablet(60)} ${vwTablet(120)};
    p {
      margin: ${vwTablet(40)} 0 ${vwTablet(60)};
    }
    h1 {
      max-width: ${vwTablet(550)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(80)} ${vwDesktop(60)} ${vwDesktop(120)};
    p {
      margin: ${vwDesktop(40)} 0 ${vwDesktop(60)};
      max-width: ${vwDesktop(640)};
    }
    h1 {
      max-width: ${vwDesktop(500)};
    }
  }
`;

export default ThankyouMessage;
