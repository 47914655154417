import React from 'react';
import styled from 'styled-components';

import Membership from './sections/Membership';
import Intelligence from './sections/Intelligence';
import HeroCorporateText from 'components/HeroCorporateText';
import HomeConfigCTA from 'components/HomeConfigCTA';
import data from 'src/data/Components/Hero';

export default function Philosophy() {
  return (
    <Root>
      <HeroCorporateText 
        data={data.philosophy}
        badge={data.philosophy.badge}
        badgeLarge
      />
      <HomeConfigCTA
        heading='Kutak Experience'
        body='We created a homebuying experience like no other. This tailored program takes you on a journey to uniquely customize your home from a selection of carefully curated designs. Your home should be an expression of you. '
      />
      <Membership />
      <Intelligence />
    </Root>
  )
}

const Root = styled.div`

`;