import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAll } from 'src/redux/configurator';

import ContentBlock from 'components/Configurator/print/ContentBlock';
import Plans from 'components/Configurator/print/Plans';
import Upgrades from 'components/Configurator/print/Upgrades';
import Button from 'components/Button';

import data from 'src/data/Configurator/oneshaughnessy';
import planData from 'src/data/Floorplans/oneshaughnessy';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Print = () => {
  const config = useSelector((state) => state.configurator);
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.localStorage.summaryConfig) {
      dispatch(setAll(JSON.parse(window.localStorage.summaryConfig)));
      window.localStorage.removeItem('summaryConfig');
    }
  }, [dispatch]);

  return (
    <Root>
      <Container>
        <h1 className="configurator black">Your Summary</h1>
        <Wrapper>
          <Button
            type="primary-arrow"
            label="Print"
            clickHandler={() => window.print()}
            xSmall
          />
        </Wrapper>
      </Container>
      <ContentBlock
        title="Kitchen"
        id="K"
        secondAttribute="Flooring"
        paletteIndex={config.kitchen.palette}
        labelRight={48}
        image={require(`src/assets/images/config/oneshaughnessy/renders/kitchen/kitchen-palette-k${
          config.kitchen.palette + 1
        }-flooring-${config.kitchen.flooring + 1}.jpeg`)}
        palette={require(`src/assets/images/config/oneshaughnessy/swatches/palette-k${
          config.kitchen.palette + 1
        }.jpg`)}
        secondAttributeImage={require(`src/assets/images/config/oneshaughnessy/swatches/k-flooring-${
          config.kitchen.flooring + 1
        }.jpg`)}
        text={
          <>
            Upper Cabinet: Gloss White
            <br />
            Countertop &amp; Backsplash: Composite <br />
            Base Cabinet: {data.kitchen.palette[config.kitchen.palette].cabinet}
            <br />
            Fixture Brand: Kohler
            <br />
            Flooring: {data.kitchen.flooring[config.kitchen.flooring].name}
            <br />
          </>
        }
      />
      <ContentBlock
        title="Bathroom"
        id="B"
        secondAttribute="Ceiling"
        small
        paletteIndex={config.bath.palette}
        labelRight={33}
        image={require(`src/assets/images/config/oneshaughnessy/renders/bath/bathroom-palette-b${
          config.bath.palette + 1
        }-ceiling-${config.bath.ceiling + 1}.jpeg`)}
        palette={require(`src/assets/images/config/oneshaughnessy/swatches/palette-b${
          config.bath.palette + 1
        }.jpg`)}
        secondAttributeImage={require(`src/assets/images/config/oneshaughnessy/swatches/b-ceiling-${
          config.bath.ceiling + 1
        }.jpg`)}
        text={
          <>
            Wall Tile: White Gloss Tile
            <br />
            Countertop: Composite
            <br />
            Base Cabinet: {data.bath.palette[config.bath.palette].cabinet}
            <br />
            Floor Tile: Dark Grey Tile
            <br />
            Fixture Brand: Kohler
            <br />
            Ceiling: {data.bath.ceiling[config.bath.ceiling].name}
            <br />
          </>
        }
      />
      <Plans data={planData.filter((plan) => config.plans.includes(plan.id))} />
      <Upgrades data={config.upgrades} />
      <Container end>
        <Wrapper margin>
          <Button
            type="primary-arrow"
            label="Print"
            clickHandler={() => window.print()}
            xSmall
          />
        </Wrapper>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  background-color: white;
  padding: ${vwMobile(40)} 0 ${vwMobile(60)};
  h1 {
    margin-left: ${vwMobile(16)};
    /* margin-bottom: ${vwMobile(80)}; */
  }
  @media ${media.tablet} {
    padding: ${vwTablet(40)} ${vwTablet(60)} ${vwTablet(80)};
    h1 {
      margin-left: 0;
      /* margin-bottom: ${vwTablet(80)}; */
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(80)} ${vwDesktop(60)};
    /* h1 {
      margin-bottom: ${vwDesktop(80)};
    } */
  }
  @media print {
    padding: 40px 0px;
    h1 {
      margin-left: 0;
      font-size: 46px;
      letter-spacing: 1.8px;
      margin-bottom: 40px;
    }
    h4 {
      font-size: 36px;
      line-height: 38px;
      letter-spacing: 1px;
    }
  }
`;

const Wrapper = styled.div`
  margin-top: ${(props) => (props.margin ? vwMobile(40) : vwMobile(16))};
  margin-left: ${vwMobile(16)};
  @media ${media.tablet} {
    margin-left: 0;
    margin-top: ${(props) => (props.margin ? vwTablet(80) : 0)};
  }
  @media ${media.desktop} {
    margin-top: ${(props) => (props.margin ? vwDesktop(80) : 0)};
  }
  @media print {
    display: none;
  }
`;

const Container = styled.div`
  margin-bottom: ${vwMobile(80)};
  @media ${media.tablet} {
    display: flex;
    justify-content: ${(props) => (props.end ? 'flex-end' : 'space-between')};
    align-items: flex-end;
    margin-bottom: ${vwTablet(80)};
  }
  @media ${media.desktop} {
    margin-bottom: ${vwDesktop(80)};
  }
  @media print {
    margin-bottom: 40px;
  }
`;

export default Print;
