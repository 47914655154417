import React from 'react';
import styled from 'styled-components';
import GoogleMapReact from 'google-map-react';
import { useSelector } from 'react-redux';

import MapStyles from 'src/styles/map';

import vw from 'src/styles/utils';
export default function Map() {
  const browser = useSelector((state) => state.browser);

  return (
    <Root>
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBjffVYE4214dRYSEzO3bKSnqP3YAg8rZw' }}
        defaultCenter={{
          lat: 49.2896693,
          lng: -123.1219984,
        }}
        zoom={browser.is.mobile ? 15.925 : 15.925}
        options={{
          styles: MapStyles,
          streetViewControl: false,
          mapTypeControl: false,
          disableDoubleClickZoom: true,
          zoomControl: true,
          fullscreenControl: false,
          // minZoom: 15.125,
          // maxZoom: 15.9125,
        }}
      >
        <Marker
          lat={browser.is.mobile ? 49.2888693 : 49.2888693}
          lng={browser.is.mobile ? -123.1219984 : -123.1219984}
        />
      </GoogleMapReact>
    </Root>
  );
}

const Root = styled.div`
  width: 100%;
  ${vw('height', 360, 500, 720)}
`;

const Marker = styled.div`
  border-radius: 50%;
  background-color: white;
  ${vw('width', 6, 6)}
  ${vw('height', 6, 6)}
  position: relative;
  &:after {
    content: '';
    width: 1px;
    height: 50px;
    background-color: white;
    position: absolute;
    left: 3px;
    top: -49px;
  }
  &:before {
    content: '';
    width: 165px;
    height: 50px;
    border: 1px solid white;
    position: absolute;
    top: -100px;
    left: -77.5px;
    background-color: black;
    background-image: ${`url(${require('src/assets/images/global/logo/kutak-white.svg')})`};
    background-size: 75%;
    background-repeat: no-repeat;
    background-position: center;
  }
`;
