import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock';

import Button from 'components/Button';
import Popup from './sections/Popup';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Parking = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const $popup = React.useRef();
  const $video = React.useRef();

  useEffect(() => {
    return () => clearAllBodyScrollLocks();
  }, []);

  const handleOpenPopup = () => {
    disableBodyScroll($popup.current);
    setIsPopupOpen(true);
    playVideo();
  };

  const handleClosePopup = () => {
    enableBodyScroll($popup.current);
    setIsPopupOpen(false);
    pauseVideo();
  };

  const playVideo = () => {
    setIsVideoPlaying(true);
    $video.current.play();
  };

  const pauseVideo = () => {
    setIsVideoPlaying(false);
    $video.current.pause();
  };

  return (
    <Root>
      <Content>
        <div>
          <h5 className='global black uppercase'>Parking System</h5>
          <h3 className='project black'>
            A Way to
            <br />
            Live Differently
          </h3>
        </div>
        <div>
          <p className='body2 black'>
            A revolutionary mechanized parking system conveniently, safely, and
            securely stores your vehicle. It’s a better use of space and a
            reliable method of parking.
          </p>
          <Button
            label='Watch Demo'
            type='text-link-black'
            clickHandler={handleOpenPopup}
          />
        </div>
      </Content>
      <Fade>
        <Image
          src={require('src/assets/images/oneshaughnessy/klaus-car-park.png')}
          alt='Parking System'
        />
      </Fade>
      <Popup
        isPopupOpen={isPopupOpen}
        popupRef={$popup}
        videoRef={$video}
        handleClosePopup={handleClosePopup}
        pauseVideo={pauseVideo}
        playVideo={playVideo}
        isVideoPlaying={isVideoPlaying}
      />
    </Root>
  );
};

export default Parking;

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.lightGrey};
  padding: ${vwMobile(60)} ${vwMobile(16)};
  h3 {
    margin: ${vwMobile(16)} 0;
  }
  p {
    margin-bottom: ${vwMobile(24)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(80)} ${vwTablet(60)};
    h3 {
      margin: ${vwTablet(20)} 0 0;
    }
    p {
      margin-bottom: ${vwTablet(40)};
      margin-top: ${vwTablet(54)};
    }
  }
  @media ${media.desktop} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${vwDesktop(100)} ${vwDesktop(60)};
    h3 {
      margin: ${vwDesktop(20)} 0;
    }
    p {
      margin-bottom: ${vwDesktop(40)};
      margin-top: 0;
    }
  }
`;

const Image = styled.img`
  display: block;
  margin-top: ${vwMobile(40)};
  @media ${media.tablet} {
    margin-top: ${vwTablet(80)};
  }
  @media ${media.desktop} {
    margin-top: 0;
    width: ${vwDesktop(900)};
  }
`;

const Content = styled.div`
  @media ${media.tablet} {
    display: flex;
    > div {
      width: ${vwTablet(312)};
    }
  }
  @media ${media.desktop} {
    display: block;
    > div {
      width: ${vwDesktop(300)};
    }
  }
`;
