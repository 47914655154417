import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Redirect, useParams, useHistory, Link } from 'react-router-dom';

import CommentForm from './elements/CommentForm';

import request, { getRequest } from 'src/utils/request';
import vw from 'src/styles/utils';
import media from 'src/styles/media';

export default function Post() {
  const [loggedIn, setLoggedIn] = useState((localStorage.getItem('token') !== null));
  const [content, setContent] = useState(null);
  const [prevContent, setPrevContent] = useState(null);
  const [nextContent, setNextContent] = useState(null);
  const [activePost, setActivePost] = useState(null);
  const [author, setAuthor] = useState(null);
  const [comments, setComments] = useState(null);
  const [sortedComments, setSortedComments] = useState(null);
  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    if (loggedIn) requestContent();

    if (params.id && content !== null) {
      for (let i = 0; i < content.length; i++) {
        if (content[i].slug === params.id) {
          setActivePost(content[i]);
          if (i === 0) {
            setPrevContent(content[content.length - 1]);
          } else {
            setPrevContent(content[i - 1]);
          }
          if (i === content.length - 1) {
            setNextContent(content[0]);
          } else {
            setNextContent(content[i + 1]);
          }
          requestAuthor();
          requestComments();
          break;
        } else if (i === content.length - 1 && content[i].slug !== params.id) {
          return history.replace('/404');
        }
      }
    }
  }, [content], []);

  const requestContent = async () => {
    const result = await getRequest('one-shaughnessy');
    await setContent(result);
  }

  const requestComments = async () => {
    const result = activePost && await request(activePost._links.replies[0].href);
    const sortedResult = result && await commentsTreeSort(result);
    // console.log(result)
    // console.log(sortedResult)
    // console.log(result)
    await setComments(result);
    await setSortedComments(sortedResult);
  }

  const requestAuthor = async () => {
    const result = activePost && await request(activePost._links.author[0].href);
    await setAuthor(result);
  }

  const commentsTreeSort = (comments) => {
    const idMapping = comments.reduce((acc, el, i) => {
      acc[el.id] = i;
      return acc;
    }, {});

    let root;
    comments.forEach(el => {
      // Handle the root element
      if (el.parent === 0) {
        // root = el;
        root = [...(root || []), el];
        return;
      }
      // Use our mapping to locate the parent element in our comments array
      const parentEl = comments[idMapping[el.parent]];
      // console.log(parentEl)
      // Add our current el to its parent's `children` array
      // parentEl.children = [...(parentEl.children || []), el];
      if (typeof parentEl !== 'undefined') {
        parentEl.children = [...(parentEl.children || []), el];
      }
      // console.log(parentEl)
    });

    // console.log(root)

    return root;
  }

  const formattedDate = (data) => {
    const date = new Date(data);
    const month = date.toLocaleString('default', { month: 'short' });
    const day = date.getDate();
    const year = date.getFullYear();

    return (`${month} ${day}, ${year}`)
  }

  const handleClickReply = (e) => {
    e.target.parentNode.children[3].style.maxHeight = '1000px';
    e.target.parentNode.children[3].style.opacity = '1';
  }

  // console.log(activePost)

  return (
    <>
      {
        !loggedIn ?
          <Redirect to='/membership' /> :
          <Root>
            {/* {activePost !== null && activePost.title.rendered} */}
            <Container>
              <BackButton to="/membership/updates">
                <img src={require('src/assets/images/global/icon/action/chevron-left-gold.svg')} />
                <h2>Back to Update</h2>
              </BackButton>
              <h1>{activePost !== null && activePost.title.rendered}</h1>
            </Container>
            <img src={activePost !== null && activePost.acf.featured_image} alt="" />
            <Container>
              <Row>
                <ColumnLeft>
                  <div>
                    <h2>Project Updates</h2>
                    <h5>{activePost !== null && formattedDate(activePost.date)}</h5>
                    <p>Posted By: {author && author.name}</p>
                  </div>
                  <CommentAmount>
                    Comments: <p>{comments && comments.length}</p>
                  </CommentAmount>
                </ColumnLeft>
                <ColumnRight>
                  {comments && <div dangerouslySetInnerHTML={{ __html: activePost.acf.main_content }} />}
                  {
                    activePost && (
                      <FormWrapper>
                        <h5>Comments</h5>
                        <CommentForm postID={activePost.id} name={localStorage.username} email={localStorage.email} />
                      </FormWrapper>
                    )
                  }
                  <Comments>
                    {
                      sortedComments && sortedComments.map((comment) => (
                        comment.parent === 0 && (
                          <Comment id={`postID-${comment.id}`}>
                            <h5>{comment.author_name}</h5>
                            <div dangerouslySetInnerHTML={{ __html: comment.content.rendered }} />
                            <button onClick={handleClickReply}>Reply</button>
                            <ReplyCommentForm postID={activePost.id} parent={comment.id} name={localStorage.username} email={localStorage.email} />
                            {
                              comment.children && (
                                comment.children.map((childComment) => (
                                  <RepliedComment id={`postID-${childComment.id}`}>
                                    <h5>{childComment.author_name}</h5>
                                    <div dangerouslySetInnerHTML={{ __html: childComment.content.rendered }} />
                                    <button onClick={handleClickReply}>Reply</button>
                                    <ReplyCommentForm postID={activePost.id} parent={childComment.id} name={localStorage.username} email={localStorage.email} />
                                    {
                                      childComment.children && (
                                        childComment.children.map((secondLayerChildComment) => (
                                          <SecondLayerRepliedComment id={`postID-${secondLayerChildComment.id}`}>
                                            <h5>{secondLayerChildComment.author_name}</h5>
                                            <div dangerouslySetInnerHTML={{ __html: secondLayerChildComment.content.rendered }} />
                                          </SecondLayerRepliedComment>
                                        ))
                                      )
                                    }
                                  </RepliedComment>
                                ))
                              )
                            }
                          </Comment>
                        )
                      ))
                    }
                  </Comments>
                </ColumnRight>
              </Row>
            </Container>
            <NavBarBottom>
              {
                prevContent && nextContent && (
                  <>
                    <PreviusPost to={`/membership/updates/${prevContent.slug}`}>
                      <h5>Prev</h5>
                      <h4>{prevContent.title.rendered}</h4>
                    </PreviusPost>
                    <NextPost to={`/membership/updates/${nextContent.slug}`}>
                      <h5>Next</h5>
                      <h4>{nextContent.title.rendered}</h4>
                    </NextPost>
                  </>
                )
              }
            </NavBarBottom>
          </Root>
      }
    </>
  )
}

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.white};

  > img {
    ${vw('width', 320, 768, 1096)};
    height: auto;
    margin: 0 auto;
    /* ${vw('padding-right', 0, 0, 172)}; */
  }
`;

const Container = styled.div`
  ${vw('margin-left', 16, 60, 172)};
  ${vw('margin-right', 16, 60, 172)};

  > h1 {
    font-family: acumin-pro-extra-condensed;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    color: ${({ theme }) => theme.color.black};
    ${vw('font-size', 44, 72)};
    ${vw('letter-spacing', 2, 3)};
    ${vw('margin-top', 41, 60)};
    ${vw('margin-bottom', 21, 43)};
  }
`;

const BackButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  ${vw('margin-top', 21, 40, 60)};

  > img {
    ${vw('width', 13)};
    ${vw('height', 13)};
    ${vw('margin-right', 12)};
  }

  > h2 {
    font-family: 'futura-pt';
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #b18b6e;
    text-transform: uppercase;
    ${vw('font-size', 14)};
    ${vw('letter-spacing', 4)};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  ${vw('margin-top', 21, 61, 80)};

  @media ${media.desktop} {
    flex-direction: row;
  }
`;

const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  ${vw('width', 288, 648, 220)};

  @media ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }

  @media ${media.desktop} {
    flex-direction: column;
    justify-content: unset;
  }

  > div {
    > h2 {
      font-family: 'futura-pt';
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      color: #b18b6e;
      text-transform: uppercase;
      ${vw('font-size', 16, 18)};
      ${vw('letter-spacing', 3.56, 4)};
      ${vw('margin-bottom', 4)};
    }
    > h5 {
      font-family: 'futura-pt';
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.38;
      color: ${({ theme }) => theme.color.black};
      ${vw('font-size', 14, 16)};
      ${vw('letter-spacing', 3.11, 3.56)};
      ${vw('margin-bottom', 16, 24)};
    }
    > p {
      font-family: 'futura-pt';
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.44;
      color: ${({ theme }) => theme.color.black};
      ${vw('font-size', 16, 18)};
      ${vw('letter-spacing', 0.44, 0.5)};
      ${vw('margin-bottom', 60, 22)};
    }
  }
`;

const CommentAmount = styled.div`
  display: flex;
  font-family: 'futura-pt';
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  color: ${({ theme }) => theme.color.black};
  ${vw('font-size', 14, 16)};
  ${vw('letter-spacing', 0.4, 0.8)};

  > p {
    font-family: 'futura-pt';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    color: ${({ theme }) => theme.color.black};
    position: relative;
    ${vw('font-size', 14, 16)};
    ${vw('letter-spacing', 0.4, 0.8)};
    ${vw('margin-left', 18, 20, 14)};
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      background-image: url(${require('src/assets/images/global/icon/icon-comment.svg')});
      background-size: cover;
      ${vw('left', -11)};
      ${vw('width', 32)};
      ${vw('height', 32)};
    }
  }
`;

const ColumnRight = styled.div`
  ${vw('width', 288, 648, 640)};
  ${vw('margin-left', 0, 0, 112)};
  ${vw('margin-right', 16, 60, 112)};

  > div {
    > h3 {
    font-family: 'acumin-pro-extra-condensed';
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    color: ${({ theme }) => theme.color.black};
    ${vw('font-size', 36, 56)};
    ${vw('letter-spacing', 0.64, 1)};
    ${vw('margin-bottom', 16, 20)};
  }

  > p {
    font-family: 'futura-pt';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.6;
    letter-spacing: normal;
    color: ${({ theme }) => theme.color.black};
    ${vw('font-size', 18, 20)};
  }

  > h4 {
    font-family: 'acumin-pro-extra-condensed';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    color: ${({ theme }) => theme.color.black};
    text-transform: uppercase;
    ${vw('font-size', 30, 36)};
    ${vw('letter-spacing', 0.83, 1)};
    ${vw('margin-top', 32, 48)};
    ${vw('margin-bottom', 12, 20)};
  }

  > h5 {
    font-family: 'futura-pt';
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    color: ${({ theme }) => theme.color.black};
    text-transform: uppercase;
    ${vw('font-size', 14, 16)};
    ${vw('letter-spacing', 3.11, 3.56)};
    ${vw('margin-top', 32, 48)};
    ${vw('margin-bottom', 12, 20)};
  }

  li {
    font-family: 'futura-pt';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    color: ${({ theme }) => theme.color.black};
    ${vw('font-size', 18, 20)};
    ${vw('margin-left', 24)};
  }
  }
`;

const FormWrapper = styled.div`
  ${vw('margin-top', 120)};

  > h5 {
    font-family: 'futura-pt';
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    color: ${({ theme }) => theme.color.black};
    text-transform: uppercase;
    border-bottom: 1px solid ${({ theme }) => theme.color.black};
    ${vw('font-size', 14, 16)};
    ${vw('letter-spacing', 3.11, 3.56)};
    ${vw('padding-bottom', 8)};
  }
`;

const Comments = styled.div``;

const Comment = styled.div`
  h5 {
    font-family: 'futura-pt';
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    color: #b18b6e;
    text-transform: uppercase;  
    ${vw('font-size', 14, 16)};
    ${vw('letter-spacing', 3.11, 3.56)};
    ${vw('margin-top', 24, 32)};
    ${vw('margin-bottom', 4)};
    ${vw('padding-top', 24, 32)};
  }

  p {
    font-family: 'futura-pt';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    color: ${({ theme }) => theme.color.black};
    ${vw('font-size', 18)};
    ${vw('letter-spacing', 0.5)};
  }

  button {
    opacity: 0.7;
    font-family: 'futura-pt';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    color: ${({ theme }) => theme.color.black};
    ${vw('font-size', 14, 16)};
    ${vw('letter-spacing', 0.4, 0.8)};
    ${vw('margin-top', 4)};
  }

  > h5 {
    border-top: 1px solid #d7d7d7;
  }
`;

const RepliedComment = styled.div`
  ${vw('margin-left', 32, 60)};
`;

const SecondLayerRepliedComment = styled.div`
  ${vw('margin-left', 44, 120)};
`;

const SeparateLine = styled.p`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.black};
`;

const SeparateLineType2 = styled.p`
  width: 100%;
  height: 1px;
  background-color: #d7d7d7;
`;

const SubmitButton = styled.button`
  border: solid 1px ${({ theme }) => theme.color.black}; /* opacity 0.5 */
  opacity: 0.5;
  font-family: 'futura-pt';
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  color: ${({ theme }) => theme.color.black};
  ${vw('font-size', 16)};
  ${vw('letter-spacing', 3)};
`;

const NavBarBottom = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.color.lightGrey};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${vw('height', 60, 190, 152)};
  ${vw('margin-top', 60, 120)};

  h5 {
    font-family: 'futura-pt';
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    color: ${({ theme }) => theme.color.black};
    ${vw('font-size', 14, 16)};
    ${vw('letter-spacing', 3.11, 3.56)};
  }

  h4 {
    font-family: 'acumin-pro-extra-condensed';
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    color: #b18b6e;
    ${vw('font-size', 0, 36, 36)};
    ${vw('letter-spacing', 0, 1, 1)};
  }
`;

const PreviusPost = styled(Link)`
  ${vw('margin-left', 16, 27, 60)};
`;

const NextPost = styled(Link)`
  text-align: right;
  ${vw('margin-right', 16, 27, 60)};
`;

const ReplyCommentForm = styled(CommentForm)`
  max-height: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out;
`;