import React from 'react';

export default {
  teamPhoto: require('src/assets/images/team/kutak-team.jpg'),
  mobileTablet: {
    members: [
      {
        name: 'Patrik Kutak',
        position: 'CEO & Founder',
        description: (
          <>
            <div className="bold">Bringing homes to Life. Together</div>
            <span />
            These words hold tremendous meaning for me – it is how I run the
            company I founded in 2015. It is also how I deliver homes: with
            essence, enthusiasm and connection always at the forefront.
            <span />
            I come from humble beginnings. An immigrant to Canada, I saw that
            hard work and making a difference in one’s life was the strongest
            way to stand out. I am blessed to have worked with some of the
            legends in our real estate industry, and seen how the best craftsmen
            of a generation build and refine a legacy. Now, it is our turn to
            bring fresh artistry and innovation to the spaces which we all call
            home. <span />
            Today, I surround myself with a few of the finest people in real
            estate. Their ideas and their passions further fuel my desire to
            deliver an exceptional experience. I work tirelessly to honour the
            Kutak name and the legacy that I aspire to create. I treat every
            home as if it were my own. After all, each one carries my name.
          </>
        ),
        linkedin: 'https://www.linkedin.com/in/patrik-kutak-a6887775/',
        photo: require('src/assets/images/team/kutak-headshot-kutak.jpg'),
      },
      {
        name: 'Michael Kenchington',
        position: 'Project Manager',
        description: (
          <>
            I am driven to create attainable homes that exude collective appeal.
            Early in my career, I worked in new home construction, with an
            emphasis on large, luxury homes in BC, Europe and Asia. It was here
            where I developed my fanatical attention to detail, passion for
            innovation and, most of all, desire to deliver a finished space that
            I am proud of.
            <span />
            Later in my career, I had the privilege of working directly with the
            top development and construction firms in BC, where I honed my
            understanding of all facets of real estate. I saw an opportunity in
            providing meaningful homes that truly define lifestyle, while
            focusing on the importance of collaboration and transparency.
            <span />
            In 2016, I joined Kutak. Under Patrik Kutak’s leadership, we have
            delivered award-winning projects by designing communities that
            reimagine the home of tomorrow. I wake up every morning ready to
            make this world a better place – largely, by doing what I say and
            delivering on what I promise.
          </>
        ),
        linkedin: 'https://www.linkedin.com/in/mkenchington/',
        photo: require('src/assets/images/team/kutak-headshot-kenchington.jpg'),
      },
      {
        name: 'Geoff Jordan',
        position: 'Development Analyst',
        description: (
          <>
            In 2015, my family and I chose to move to BC from the UK – I had
            long appreciated the beauty and potential of the area. I brought
            with me my experience in European architecture, design and
            lifestyle. In the UK, I worked as an appraiser, providing advice to
            funders, equity providers and property companies.
            <span />
            I am involved very early on in every project at Kutak and appreciate
            my close working relationship with my colleagues, which gives me the
            opportunity to see the full lifecycle of our projects. I help to
            analyze the land, its viability, the local market and buyer
            demographics. I provide critical support across the development
            business, including feasibility studies, project budgeting and
            project finance management.
            <span />
            Living in the Lower Mainland is different to any other part of the
            world, and because of this, I believe that our homes need to reflect
            our individuality. Our homes need to take advantage of our
            lifestyles, our awe-inspiring views and our connection to our
            natural surroundings. This is paramount to how I approach every
            project site.
          </>
        ),
        photo: require('src/assets/images/team/kutak-headshot-jordan.jpg'),
      },
      {
        name: 'Sherri Young',
        position: 'Financial Accounting Manager',
        description: (
          <>
            Synergy is the combination of a great team seeking the same common
            goal and this synergy is what inspires me every day. I joined Kutak
            in 2017 as the Senior Financial Accounting Manager, and lead our
            company’s external financial reporting, corporate accounting and
            corporate internal controls.
            <span />
            Over the past two decades, I held a variety of senior financial
            management positions in both the public and private sectors. I bring
            a wealth of experience to my role, ensuring that Kutak’s financial
            health, and that of our projects and assets is always strong. I
            enjoy being part of the full project life cycle – from concept to
            completion to ongoing asset management.   I oversee our
            organizational policies along with governmental and finance
            requirements while safe-guarding the stakeholders’ interests. I
            strive to make each of our projects meaningful and successful.
            <span />
            When I’m not at work, I have a variety of hobbies that keep me
            invigorated. I am an avid traveler and enjoy hiking and exploring
            the beauty of nature by foot. I have also recently taken up a new
            passion: gardening.
          </>
        ),
        photo: require('src/assets/images/team/kutak-headshot-young.jpg'),
      },
      {
        name: 'Jessica Burns',
        position: 'Office Coordinator',
        description: (
          <>
            Music, festivals, restaurants, ethnic diversity and heritage –
            essentially, arts and culture – fuel my soul. When I had an
            opportunity to express myself through design and architecture, I
            jumped at it.
            <span />
            My career in construction started in the resource sector, as an
            administrator to thousands of employees. The work was hard but
            rewarding, and the experience helped me create the ability to thrive
            in a dynamic environment.
            <span />I joined Kutak in 2018 to further my ambitions in real
            estate development. Currently, as the Customer Care and Office
            Administrator, I am a constant point of contact for tenants and
            residents, vendors and partners. I strive to provide a high level of
            customer service through a genuine approach and connection. I also
            run Kutak’s social media platform, where I tell our story and share
            our special moments with you. I believe we have something unique
            here at Kutak, and I hope that my actions will show you the
            distinctive experiences we offer.
          </>
        ),
        linkedin: 'https://www.linkedin.com/in/jessica-burns-44a03862/',
        photo: require('src/assets/images/team/kutak-headshot-burns.jpg'),
      },
    ],
  },
  desktop: {
    members: [
      {
        name: 'Geoff Jordan',
        position: 'Development Analyst',
        description: (
          <>
            In 2015, my family and I chose to move to BC from the UK – I had
            long appreciated the beauty and potential of the area. I brought
            with me my experience in European architecture, design and
            lifestyle. In the UK, I worked as an appraiser, providing advice to
            funders, equity providers and property companies.
            <span />
            I am involved very early on in every project at Kutak and appreciate
            my close working relationship with my colleagues, which gives me the
            opportunity to see the full lifecycle of our projects. I help to
            analyze the land, its viability, the local market and buyer
            demographics. I provide critical support across the development
            business, including feasibility studies, project budgeting and
            project finance management.
            <span />
            Living in the Lower Mainland is different to any other part of the
            world, and because of this, I believe that our homes need to reflect
            our individuality. Our homes need to take advantage of our
            lifestyles, our awe-inspiring views and our connection to our
            natural surroundings. This is paramount to how I approach every
            project site.
          </>
        ),
        photo: require('src/assets/images/team/kutak-headshot-jordan.jpg'),
      },
      {
        name: 'Jessica Burns',
        position: 'Office Coordinator',
        description: (
          <>
            Music, festivals, restaurants, ethnic diversity and heritage –
            essentially, arts and culture – fuel my soul. When I had an
            opportunity to express myself through design and architecture, I
            jumped at it.
            <span />
            My career in construction started in the resource sector, as an
            administrator to thousands of employees. The work was hard but
            rewarding, and the experience helped me create the ability to thrive
            in a dynamic environment.
            <span />I joined Kutak in 2018 to further my ambitions in real
            estate development. Currently, as the Customer Care and Office
            Administrator, I am a constant point of contact for tenants and
            residents, vendors and partners. I strive to provide a high level of
            customer service through a genuine approach and connection. I also
            run Kutak’s social media platform, where I tell our story and share
            our special moments with you. I believe we have something unique
            here at Kutak, and I hope that my actions will show you the
            distinctive experiences we offer.
          </>
        ),
        linkedin: 'https://www.linkedin.com/in/jessica-burns-44a03862/',
        photo: require('src/assets/images/team/kutak-headshot-burns.jpg'),
      },
      {
        name: 'Patrik Kutak',
        position: 'CEO & Founder',
        description: (
          <>
            <div className="bold">Bringing homes to Life. Together</div>
            <span />
            These words hold tremendous meaning for me – it is how I run the
            company I founded in 2015. It is also how I deliver homes: with
            essence, enthusiasm and connection always at the forefront.
            <span />
            I come from humble beginnings. An immigrant to Canada, I saw that
            hard work and making a difference in one’s life was the strongest
            way to stand out. I am blessed to have worked with some of the
            legends in our real estate industry, and seen how the best craftsmen
            of a generation build and refine a legacy. Now, it is our turn to
            bring fresh artistry and innovation to the spaces which we all call
            home. <span />
            Today, I surround myself with a few of the finest people in real
            estate. Their ideas and their passions further fuel my desire to
            deliver an exceptional experience. I work tirelessly to honour the
            Kutak name and the legacy that I aspire to create. I treat every
            home as if it were my own. After all, each one carries my name.
          </>
        ),
        linkedin: 'https://www.linkedin.com/in/patrik-kutak-a6887775/',
        photo: require('src/assets/images/team/kutak-headshot-kutak.jpg'),
      },
      {
        name: 'Sherri Young',
        position: 'Financial Accounting Manager',
        description: (
          <>
            Synergy is the combination of a great team seeking the same common
            goal and this synergy is what inspires me every day. I joined Kutak
            in 2017 as the Senior Financial Accounting Manager, and lead our
            company’s external financial reporting, corporate accounting and
            corporate internal controls.
            <span />
            Over the past two decades, I held a variety of senior financial
            management positions in both the public and private sectors. I bring
            a wealth of experience to my role, ensuring that Kutak’s financial
            health, and that of our projects and assets is always strong. I
            enjoy being part of the full project life cycle – from concept to
            completion to ongoing asset management.   I oversee our
            organizational policies along with governmental and finance
            requirements while safe-guarding the stakeholders’ interests. I
            strive to make each of our projects meaningful and successful.
            <span />
            When I’m not at work, I have a variety of hobbies that keep me
            invigorated. I am an avid traveler and enjoy hiking and exploring
            the beauty of nature by foot. I have also recently taken up a new
            passion: gardening.
          </>
        ),
        photo: require('src/assets/images/team/kutak-headshot-young.jpg'),
      },
      {
        name: 'Michael Kenchington',
        position: 'Project Manager',
        description: (
          <>
            I am driven to create attainable homes that exude collective appeal.
            Early in my career, I worked in new home construction, with an
            emphasis on large, luxury homes in BC, Europe and Asia. It was here
            where I developed my fanatical attention to detail, passion for
            innovation and, most of all, desire to deliver a finished space that
            I am proud of.
            <span />
            Later in my career, I had the privilege of working directly with the
            top development and construction firms in BC, where I honed my
            understanding of all facets of real estate. I saw an opportunity in
            providing meaningful homes that truly define lifestyle, while
            focusing on the importance of collaboration and transparency.
            <span />
            In 2016, I joined Kutak. Under Patrik Kutak’s leadership, we have
            delivered award-winning projects by designing communities that
            reimagine the home of tomorrow. I wake up every morning ready to
            make this world a better place – largely, by doing what I say and
            delivering on what I promise.
          </>
        ),
        linkedin: 'https://www.linkedin.com/in/mkenchington/',
        photo: require('src/assets/images/team/kutak-headshot-kenchington.jpg'),
      },
    ],
  },
};
