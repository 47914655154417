import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setProject, clearProject } from 'src/redux/project';

import vw from 'src/styles/utils';

import data from 'src/data/Projects';

const Project = () => {
  const [activeProject, setActiveProject] = useState(null);

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (params.id) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].slug === params.id) {
          setActiveProject(data[i]);
          dispatch(setProject(data[i].slug));
          break;
        }
        if (i === data.length - 1 && data[i].slug !== params.id) {
          history.replace('/404');
        }
      }
    }
    return () => dispatch(clearProject());
  }, [params.id, dispatch, activeProject, history]);

  return <Root>{activeProject && activeProject.overview}</Root>;
};

export default Project;

const Root = styled.div`
  background-color: white;
  ${vw('margin-top', 60, 82, 100)}
`;
