import React from 'react';

export default {
  corporate: [
    {
      label: <span>Homes</span>,
      route: '/homes',
    },
    {
      label: <span>Philosophy</span>,
      route: '/philosophy',
    },
    {
      label: <span>Team</span>,
      route: '/team',
    },
    {
      label: <span>Contact</span>,
      route: '/contact',
    },
    {
    	label: <><span>Members</span><img src={require('src/assets/images/global/icon/action/chevron-right-white.svg')} alt='chevron right white' /></>,
    	route: '/membership',
    },
  ],
  configurator: [
    {
      label: 'Kitchen',
      to: '/design/kitchen',
    },
    {
      label: 'Bathroom',
      to: '/design/bathroom',
    },
    {
      label: 'Plans',
      to: '/design/plans',
    },
    {
      label: 'Upgrades',
      to: '/design/upgrades',
    },
    {
      label: 'Summary',
      to: '/design/summary',
    },
  ],
};
