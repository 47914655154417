import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Popup = (props) => {
  const {
    handleClosePopup,
    isVideoPlaying,
    videoRef,
    pauseVideo,
    playVideo,
    isPopupOpen,
    popupRef,
  } = props;

  const $progress = useRef();
  const $progressBar = useRef();

  useEffect(() => {
    let supportsProgress = document.createElement('progress').max !== undefined;
    if (!supportsProgress) {
      $progress.current.setAttribute('data-state', 'fake');
    }
    $progress.current.addEventListener('click', function (e) {
      var pos =
        (e.pageX - (this.offsetLeft + this.offsetParent.offsetLeft)) /
        this.offsetWidth;
      videoRef.current.currentTime = pos * videoRef.current.duration;
    });
    videoRef.current.addEventListener('timeupdate', function () {
      if (!$progress.current.getAttribute('max'))
        $progress.current.setAttribute('max', videoRef.current.duration);
      $progress.current.value = videoRef.current.currentTime;
      $progressBar.current.style.width =
        Math.floor(
          (videoRef.current.currentTime / videoRef.current.duration) * 100
        ) + '%';
    });
  }, [videoRef]);

  return (
    <Root isOpen={isPopupOpen} ref={popupRef}>
      <Close
        src={require('src/assets/images/global/icon/action/close-white.svg')}
        alt="Close"
        onClick={handleClosePopup}
      />
      <Video
        ref={videoRef}
        src="https://player.vimeo.com/external/427551374.hd.mp4?s=c35b6118fe6cbce2f82bf5a5aeb904a76b360416&amp;profile_id=175"
        playsInline
      />
      <Controls>
        <img
          src={
            isVideoPlaying
              ? require('src/assets/images/global/icon/action/video-pause.svg')
              : require('src/assets/images/global/icon/action/video-play.svg')
          }
          alt="Play/Pause"
          onClick={isVideoPlaying ? pauseVideo : playVideo}
        />
        <div className="progress">
          <progress id="progress" value="0" min="0" ref={$progress}>
            <span id="progress-bar" ref={$progressBar}></span>
          </progress>
        </div>
      </Controls>
    </Root>
  );
};

Popup.propTypes = {
  isVideoPlaying: PropTypes.bool,
  isPopupOpen: PropTypes.bool,
  videoRef: PropTypes.object,
  popupRef: PropTypes.object,
  handleClosePopup: PropTypes.func,
  playVideo: PropTypes.func,
  pauseVideo: PropTypes.func,
};

const Root = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: rgba(0, 0, 0, 0.85);
  transition: 0.3s ease;
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  pointer-events: ${(props) => (props.isOpen ? 'all' : 'none')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${media.tablet} {
    display: block;
  }
`;

const Close = styled.img`
  position: absolute;
  display: block;
  top: ${vwMobile(16)};
  right: ${vwMobile(16)};
  width: ${vwMobile(32)};
  @media ${media.tablet} {
    top: ${vwTablet(32)};
    right: ${vwTablet(32)};
    width: ${vwTablet(32)};
  }
  @media ${media.desktop} {
    top: ${vwDesktop(40)};
    right: ${vwDesktop(60)};
    width: ${vwDesktop(32)};
    cursor: pointer;
  }
`;

const Video = styled.video`
  width: 100%;
  height: auto;
  display: block;
  @media ${media.tablet} {
    margin-top: ${vwTablet(200)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(1120)};
    max-height: 82%;
    margin: ${vwDesktop(40)} auto;
  }
`;

const Controls = styled.div`
  margin: ${vwMobile(20)} ${vwMobile(16)} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: ${vwMobile(32)};
  }
  .progress {
    width: ${vwMobile(244)};
  }
  progress {
    display: block;
    width: 100%;
    height: ${vwMobile(6)};
    margin-top: 0.125rem;
    border: none;
    color: ${({ theme }) => theme.color.gold};
    background-color: rgba(255, 255, 255, 0.2);
  }
  progress::-moz-progress-bar {
    background-color: ${({ theme }) => theme.color.gold};
  }

  progress::-webkit-progress-value {
    background-color: ${({ theme }) => theme.color.gold};
  }

  progress[data-state='fake'] {
    background: #e6e6e6;
    height: 65%;
  }
  progress span {
    width: 0%;
    height: 100%;
    display: inline-block;
    background-color: ${({ theme }) => theme.color.gold};
  }
  @media ${media.tablet} {
    margin: ${vwTablet(20)} ${vwTablet(60)} 0;
    .progress {
      width: ${vwTablet(592)};
    }
    progress {
      height: ${vwTablet(8)};
    }
    img {
      width: ${vwTablet(40)};
    }
  }
  @media ${media.desktop} {
    width: ${vwDesktop(1120)};
    margin: 0 auto;
    img {
      width: ${vwDesktop(40)};
      cursor: pointer;
    }
    .progress {
      width: ${vwDesktop(1064)};
    }
    progress {
      height: ${vwDesktop(8)};
      cursor: pointer;
    }
  }
`;

export default Popup;
