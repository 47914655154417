import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import data from 'src/data/KIS/data';

import media from 'src/styles/media';
import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';

export default function Infographic() {
  return (
    <TransitionGroup>
      <Root>

        {
          data.map((item, key) => {
            return(
                <Item key={key}>
                  <Fade duration={300} delay={key * 200}>
                  <img src={item.image} alt={`${item.label} graphic`}/>
                  <h4 className='global gold uppercase'>{item.label}</h4>
                  <p className='body2 white'>{item.text}</p>
                  </Fade>
                </Item>
            )
          })
        }
      </Root>
    </TransitionGroup>
  )
}

const Root = styled.div`
  ${vw('padding-top', 60, 120)}
  ${vw('padding-right', 60, 60)}
  ${vw('padding-bottom', 60, 80)}
  ${vw('padding-left', 60, 60)}

  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
  }
  @media ${media.desktop} {
    justify-content: space-between;
    flex-wrap: nowrap;
  }
`;

const Item = styled.div`
  width: ${vwMobile(200)};
  text-align: center;
  &:not(:last-of-type) {
    margin-bottom: ${vwMobile(60)};
  }
  img {
    margin: 0 auto ${vwMobile(20)};
    width: ${vwMobile(150)};
    height: ${vwMobile(150)};
  }
  h4 {
    margin-bottom: ${vwMobile(12)};
  }
  @media ${media.tablet} {
    width: ${vwTablet(272)};
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
    &:nth-of-type(-n+2){
      margin-bottom: ${vwTablet(60)};
    }
    &:nth-of-type(even) {
      margin-left: ${vwTablet(64)};
    }
    img {
      margin: 0 auto ${vwTablet(20)};
      width: ${vwTablet(150)};
      height: ${vwTablet(150)};  
    }
    h4 {
      margin-bottom: ${vwTablet(12)};
    }
    p {
      width: ${vwTablet(220)};
      margin: 0 auto;
    }
  }
  @media ${media.desktop} {
    width: ${vwDesktop(300)};
    img {
      margin: 0 auto ${vwDesktop(20)};
      width: ${vwDesktop(150)};
      height: ${vwDesktop(150)}; 
    }
    h4 {
      margin-bottom: ${vwDesktop(12)};
    }
    p {
      width: ${vwDesktop(220)};
    }
    &:not(:last-of-type) {
      margin-bottom: 0;
    }
    &:nth-of-type(-n+2){
      margin-bottom: 0;
    }
    &:nth-of-type(even) {
      margin-left: 0;
    }
  }
`;