import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import media from 'src/styles/media';
import vw, { vwMobile, vwDesktop } from 'src/styles/utils';

export default function Footer(props) {
  const { isProject, data, isApp } = props;
  return (
    <Root isApp={isApp}>
      <Body>
        <LogoWrapper>
          <Logo
            src={require('src/assets/images/global/logo/kutak-white.svg')}
            alt="kutak logo white"
          />
        </LogoWrapper>

        <MomentumLogo
          href="https://www.momentuminc.com/"
          target="_blank"
          rel="noopener noreferrer"
          isProject={isProject}
          className="hoverOpacity"
        >
          <img
            src={require('src/assets/images/global/logo/momentum.svg')}
            alt="momentum logo"
          />
        </MomentumLogo>

        <BodyContent>
          <p className="footer body white">
            <a
              href="https://goo.gl/maps/htdQMTCJst5Yqb1Q9"
              target="_blank"
              rel="noopener noreferrer"
              className="footer body white hoverGold"
            >
              {data.address1} <span />
              {data.address2}
            </a>
          </p>
        </BodyContent>

        <BodyContent>
          <p className="footer body white">
            <a
              href={`mailto:${data.email}`}
              className="footer body white hoverGold"
            >
              {data.email}
            </a>{' '}
            <span />
            <a
              href={`tel:${data.phone}`}
              className="footer body white hoverGold"
            >
              {data.phone}
            </a>
          </p>
        </BodyContent>
      </Body>

      <Footnote>
        <SocialMedia>
          <a href={data.instagram} target="_blank" rel="noopener noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              className="hoverGold"
            >
              <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#E9EAE8" fillRule="nonzero">
                  <path d="M11 0C8.012 0 7.639.014 6.465.066c-1.171.055-1.969.24-2.67.512a5.387 5.387 0 00-1.949 1.268A5.367 5.367 0 00.577 3.795C.305 4.496.12 5.294.066 6.465.011 7.64 0 8.012 0 11c0 2.988.014 3.361.066 4.535.055 1.17.24 1.969.512 2.67.28.722.657 1.337 1.268 1.949a5.38 5.38 0 001.949 1.268c.702.272 1.5.458 2.67.512C7.64 21.989 8.012 22 11 22c2.988 0 3.361-.014 4.535-.066 1.17-.055 1.969-.24 2.67-.512a5.407 5.407 0 001.949-1.268c.61-.612.989-1.224 1.268-1.949.272-.701.458-1.5.512-2.67C21.989 14.36 22 13.988 22 11c0-2.988-.014-3.361-.066-4.535-.055-1.17-.24-1.97-.512-2.67a5.399 5.399 0 00-1.268-1.949A5.36 5.36 0 0018.205.577c-.701-.272-1.5-.457-2.67-.511C14.36.011 13.988 0 11 0zm0 1.98c2.936 0 3.286.015 4.446.065 1.072.05 1.654.228 2.041.38.515.2.88.438 1.267.822.384.385.622.75.821 1.266.15.387.33.969.379 2.041.052 1.16.064 1.509.064 4.446s-.014 3.286-.068 4.446c-.056 1.072-.234 1.654-.386 2.041-.205.515-.439.88-.824 1.267a3.432 3.432 0 01-1.265.821c-.385.15-.976.33-2.048.379-1.168.052-1.512.064-4.454.064-2.944 0-3.288-.014-4.455-.068-1.073-.056-1.664-.234-2.05-.386a3.406 3.406 0 01-1.263-.824 3.34 3.34 0 01-.825-1.265c-.152-.385-.33-.976-.385-2.048-.042-1.155-.056-1.512-.056-4.44 0-2.93.014-3.288.056-4.457.056-1.072.233-1.663.385-2.048.192-.522.439-.88.825-1.265.384-.385.742-.632 1.264-.824.385-.152.963-.33 2.036-.385 1.168-.042 1.512-.056 4.454-.056L11 1.98zm0 3.371a5.648 5.648 0 100 11.297 5.648 5.648 0 000-11.296zm0 9.316A3.666 3.666 0 017.333 11 3.666 3.666 0 0111 7.333 3.666 3.666 0 0114.667 11 3.666 3.666 0 0111 14.667zm7.192-9.538a1.32 1.32 0 01-2.64 0 1.32 1.32 0 012.64 0z"></path>
                </g>
              </g>
            </svg>
          </a>
          <a href={data.facebook} target="_blank" rel="noopener noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              className="hoverGold"
            >
              <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#E9EAE8" fillRule="nonzero">
                  <path d="M22 11.067C22 4.955 17.075 0 11 0S0 4.955 0 11.067C0 16.591 4.023 21.17 9.281 22v-7.734H6.488v-3.199h2.793V8.63c0-2.774 1.642-4.306 4.155-4.306 1.204 0 2.462.216 2.462.216v2.724h-1.387c-1.366 0-1.792.853-1.792 1.728v2.076h3.05l-.487 3.2h-2.563V22C17.978 21.17 22 16.591 22 11.067z"></path>
                </g>
              </g>
            </svg>
          </a>
          <a href={data.linkedin} target="_blank" rel="noopener noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              className="hoverGold"
            >
              <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#E9EAE8" fillRule="nonzero">
                  <path d="M18.743 18.748h-3.258v-5.105c0-1.218-.024-2.784-1.697-2.784-1.699 0-1.958 1.324-1.958 2.694v5.195H8.572V8.25h3.13v1.43h.041c.438-.824 1.501-1.695 3.09-1.695 3.3 0 3.911 2.173 3.911 5v5.763zM4.893 6.814A1.89 1.89 0 013 4.92a1.892 1.892 0 111.891 1.893zm1.633 11.934H3.259V8.25h3.267v10.498zM20.373 0H1.623C.726 0 0 .71 0 1.585v18.83C0 21.291.726 22 1.623 22H20.37c.897 0 1.63-.709 1.63-1.585V1.585C22 .71 21.267 0 20.37 0h.003z"></path>
                </g>
              </g>
            </svg>
          </a>
          <a href={data.twitter} target="_blank" rel="noopener noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              viewBox="0 0 22 22"
              className="hoverGold"
            >
              <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#E9EAE8" fillRule="nonzero" transform="translate(0 2)">
                  <path d="M21.957 2.116a9.212 9.212 0 01-2.59.706A4.522 4.522 0 0021.35.342a9.352 9.352 0 01-2.866 1.078A4.504 4.504 0 0015.192 0c-2.49 0-4.51 2.007-4.51 4.479 0 .355.042.697.117 1.024A12.8 12.8 0 011.503.833a4.371 4.371 0 00-.61 2.255c0 1.558.797 2.927 2.005 3.731a4.517 4.517 0 01-2.042-.561v.056c0 2.172 1.552 3.984 3.617 4.397a4.606 4.606 0 01-2.027.077 4.522 4.522 0 004.22 3.113 9.081 9.081 0 01-5.594 1.917c-.357 0-.714-.02-1.072-.061a12.887 12.887 0 006.927 2.012c8.3 0 12.832-6.828 12.832-12.74 0-.19 0-.382-.014-.574A9.076 9.076 0 0022 2.135l-.043-.019z"></path>
                </g>
              </g>
            </svg>
          </a>
        </SocialMedia>
        <Legal>
          <Copyright className="footer footnote white uppercase">
            Copyright 2020 Kutak Development Services Ltd.
          </Copyright>
          <Privacy className="footer footnote white uppercase">
            <Link to="/privacy-policy" className="footer footnote white">
              Privacy Policy
            </Link>
            <Link to="/terms-of-use" className="footer footnote white">
              Terms of Use
            </Link>
          </Privacy>
        </Legal>
      </Footnote>
    </Root>
  );
}

Footer.propTypes = {
  isProject: PropTypes.bool,
  isApp: PropTypes.bool,
  data: PropTypes.object,
};

const Root = styled.div`
  position: relative;
  ${vw('padding-top', 32, 33, 32)}
  ${vw('padding-left', 16, 29, 60)}
  ${vw('padding-bottom', 40, 40, 32)}
  ${vw('padding-right', 0, 0, 60)}
  background-color: ${({ theme }) => theme.color.black};
  display: ${(props) => (props.isApp ? 'none' : 'block')};
`;

const LogoWrapper = styled.div`
  ${vw('margin-bottom', 40, 60, 0)}
  width: 100%;
  @media ${media.desktop} {
    width: auto;
  }
`;

const Logo = styled.img`
  ${vw('width', 148, 181, 189)}
  ${vw('height', 22, 26, 28)}
`;

const MomentumLogo = styled.a`
  display: ${(props) => (props.isProject ? 'initial' : 'none')};
  position: absolute;
  ${vw('top', 24, 25, 32)}
  ${vw('right', 20, 409, 60)}
  ${vw('width', 100, 100)}
  ${vw('height', 39, 39)}  
  transition: all 0.3s ease;
`;

const Body = styled.div`
  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    align-items: start;
  }

  @media ${media.desktop} {
    margin-bottom: ${vwDesktop(82)};
  }
`;

const BodyContent = styled.div`
  ${vw('margin-bottom', 24, 0)}
  ${vw('margin-left', 0, 0, 62)}
  &:last-of-type {
    ${vw('margin-bottom', 60, 80, 0)}
    ${vw('margin-left', 0, 91, 93)}
  }
  p {
    width: ${vwMobile(211)};
    ${vw('padding-left', 13, 12)}
    position: relative;
    &:before {
      content: '';
      ${vw('width', 1, 1)};
      height: 100%;
      position: absolute;
      left: 0;
      background-color: ${({ theme }) => theme.color.white};
    }
    span {
      display: block;
      ${vw('height', 8, 8)}
    }
  }
  @media ${media.tablet} {
    p {
      width: auto;
    }
  }
`;

const Footnote = styled.div`
  @media ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
  }
  @media ${media.desktop} {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  width: 100%;
  ${vw('margin-bottom', 20, 40, 0)}
  a:not(:first-of-type) {
    ${vw('margin-left', 16, 16)}
  }
  svg {
    ${vw('width', 22, 22)}
    ${vw('height', 22, 22)}
  }
  @media ${media.desktop} {
    width: auto;
  }
`;

const Legal = styled.div`
  @media ${media.tablet} {
    display: flex;
  }
`;

const Copyright = styled.div`
  ${vw('margin-bottom', 16, 0, 0)}
  ${vw('width', 262, 420)}
  @media ${media.tablet}{
    white-space: nowrap;
  }
`;

const Privacy = styled.div`
  ${vw('margin-left', 0, 20, 20)}
  a {
    transition: all 0.3s ease;
    &:hover {
      color: ${({ theme }) => theme.color.gold};
    }
    &:last-of-type {
      ${vw('margin-left', 30, 30)};
      position: relative;
      &:before {
        content: '';
        width: 1px;
        ${vw('height', 12, 12)};
        position: absolute;
        ${vw('left', -16, -16)};
        top: 50%;
        transform: translateY(-50%);
        background-color: white;
        transition: all 0.3s ease;
      }
    }
  }
`;
