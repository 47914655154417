import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import vw from 'src/styles/utils';

export default function PostCard(props) {
  const { data } = props;

  const formattedDate = () => {
    const date = new Date(data.date);
    const month = date.toLocaleString('default', {month: 'short'});
    const day = date.getDate();
    const year = date.getFullYear();
    // console.log(date, month, day)
    return (`${month} ${day}, ${year}`)
  }

  return (
    <Root>
      <Link to={`/membership/updates/${data.slug}`}><Image src={data.acf.featured_image} /></Link>
      <h5 className='global black uppercase'>{formattedDate()}</h5>
      <Link to={`/membership/updates/${data.slug}`}><h4 className='global gold uppercase'>{data.title.rendered}</h4></Link>
      <p className='body2 black'>{data.acf.tagline}</p>
      <StyledLink to={`/membership/updates/${data.slug}`} className='button gold uppercase'>Read More</StyledLink>
    </Root>
  )
}

PostCard.propTypes = {
  data: PropTypes.object,
  isActive: PropTypes.bool,
}

const Root = styled.div`
  display: inline-block;

  h5, h4 {
  }
  h5 {
    ${vw('margin-bottom', 4)}
  }
  h4, p {
    ${vw('margin-bottom', 16)}
  }
  a {
    display: block;
  }
`;

const Image = styled.img`
  width: 100%;
  ${vw('height', 200, 216, 286)}
  ${vw('margin-bottom', 12, 16)}
`;

const StyledLink = styled(Link)`
  display: inline-block;
`;
