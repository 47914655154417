import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlan } from 'src/redux/configurator';

import Floorplans from 'components/Project/Plans/Floorplans';
import Button from 'components/Button';
import PlanPopup from './sections/PlanPopup';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/Floorplans/oneshaughnessy';

const Plans = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [activePlan, setActivePlan] = useState(0);

  const dispatch = useDispatch();
  const config = useSelector((state) => state.configurator);

  const handlePopup = (index) => {
    setActivePlan(index);
    setIsPopupOpen(true);
  };

  const closePopup = () => setIsPopupOpen(false);

  const handlePlan = (id) => {
    dispatch(selectPlan(id));
    setTimeout(() => setIsPopupOpen(false), 0);
  };

  const handleNext = () => {
    if (activePlan === data.length - 1) {
      setActivePlan(0);
    } else {
      setActivePlan(activePlan + 1);
    }
  };

  const handlePrev = () => {
    if (activePlan === 0) {
      setActivePlan(data.length - 1);
    } else {
      setActivePlan(activePlan - 1);
    }
  };

  return (
    <Root>
      <h1 className="configurator black">Select Your Favourites</h1>
      <Floorplans
        data={data}
        slug=""
        configurator
        handlePopup={handlePopup}
        handlePlan={handlePlan}
        activePlans={config.plans}
        filters={[
          {
            text: 'Studio',
            bed: 0,
          },
          {
            text: '1 Bed',
            bed: 1,
          },
          {
            text: '2 Bed',
            bed: 2,
          },
          {
            text: '3 Bed',
            bed: 3,
          },
        ]}
      />
      <Wrapper>
        <Button type="primary-arrow" label="Next" to="/design/upgrades" />
      </Wrapper>
      <PlanPopup
        active={isPopupOpen}
        handleClose={closePopup}
        handlePlan={(id) => dispatch(selectPlan(id))}
        handleNext={handleNext}
        handlePrev={handlePrev}
        data={data}
        activePlan={activePlan}
        activePlans={config.plans}
      />
    </Root>
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.skyBlue};
  padding: ${vwMobile(32)} 0 ${vwMobile(40)};
  h1 {
    margin: 0 ${vwMobile(16)} ${vwMobile(-8)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(40)} 0;
    h1 {
      margin: 0 ${vwTablet(60)} ${vwTablet(-20)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(40)} 0;
    h1 {
      margin: 0 ${vwDesktop(60)} ${vwDesktop(-40)};
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 ${vwMobile(16)};
  @media ${media.tablet} {
    padding: 0 ${vwTablet(60)};
    display: flex;
    justify-content: flex-end;
  }
  @media ${media.desktop} {
    padding: 0 ${vwDesktop(60)};
  }
`;

export default Plans;
