import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';

import { vwDesktop, vwTablet } from 'src/styles/utils';
import media from 'src/styles/media';

const ImageCallout = (props) => {
  const { image, alt, points } = props;

  const [activePoint, setActivePoint] = useState(null);

  const handlePoint = (index) => {
    if (activePoint === index) {
      setActivePoint(null);
    } else {
      setActivePoint(index);
    }
  };

  return (
    <Root {...props}>
      <Fade>
        <img src={image} alt={alt} onClick={() => setActivePoint(null)} />
      </Fade>
      {points.map((item, index) => (
        <Point
          key={index}
          top={item.top}
          left={item.left}
          tabletLeft={item.tabletLeft}
          bottomLabel={item.bottomLabel}
          active={activePoint === index}
          onClick={() => handlePoint(index)}
        >
          <span />
          <div>
            <p className="body4 black">{item.text}</p>
          </div>
        </Point>
      ))}
    </Root>
  );
};

ImageCallout.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  points: PropTypes.array,
};

const Root = styled.div`
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const pulse = keyframes`
  0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 5px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
`;

const pulseCss = css`
  animation: ${pulse} 2s infinite;
`;

const Point = styled.div`
  position: absolute;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  z-index: 2;
  display: none;
  z-index: ${(props) => (props.active ? 3 : 2)};
  span {
    display: block;
    width: ${vwDesktop(28)};
    height: ${vwDesktop(28)};
    border: 1px solid white;
    border-radius: 50%;
    cursor: pointer;
    ${(props) => !props.active && pulseCss}
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: ${vwDesktop(14)};
      height: ${vwDesktop(14)};
      border-radius: 50%;
      background-color: white;
    }
  }
  > div {
    position: absolute;
    left: 50%;
    bottom: ${(props) =>
      props.bottomLabel ? 'auto' : `calc(100% + ${vwDesktop(36)})`};
    top: ${(props) =>
      props.bottomLabel ? `calc(100% + ${vwDesktop(36)})` : 'auto'};
    transform: translateX(-50%);
    background-color: white;
    padding: ${vwDesktop(12)} ${vwDesktop(16)};
    width: ${vwDesktop(240)};
    pointer-events: none;
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: 0.4s ease;
    border: 1px solid ${({ theme }) => theme.color.lightGrey};
    &::after {
      content: '';
      position: absolute;
      top: ${(props) => (props.bottomLabel ? 'auto' : '100%')};
      bottom: ${(props) => (props.bottomLabel ? '100%' : 'auto')};
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: ${vwDesktop(37)};
      background-color: white;
    }
  }
  @media ${media.tablet} {
    display: block;
    left: ${(props) => (props.tabletLeft ? props.tabletLeft : props.left)};
    span {
      width: ${vwTablet(28)};
      height: ${vwTablet(28)};
      &::after {
        width: ${vwTablet(14)};
        height: ${vwTablet(14)};
      }
    }
    > div {
      bottom: ${(props) =>
        props.bottomLabel ? 'auto' : `calc(100% + ${vwTablet(36)})`};
      top: ${(props) =>
        props.bottomLabel ? `calc(100% + ${vwTablet(36)})` : 'auto'};
      padding: ${vwTablet(16)};
      width: ${vwTablet(195)};
      &::after {
        height: ${vwTablet(37)};
      }
    }
  }
  @media ${media.desktop} {
    left: ${(props) => props.left};
    span {
      width: ${vwDesktop(28)};
      height: ${vwDesktop(28)};
      &::after {
        width: ${vwDesktop(14)};
        height: ${vwDesktop(14)};
      }
    }
    > div {
      bottom: ${(props) =>
        props.bottomLabel ? 'auto' : `calc(100% + ${vwDesktop(36)})`};
      top: ${(props) =>
        props.bottomLabel ? `calc(100% + ${vwDesktop(36)})` : 'auto'};
      padding: ${vwDesktop(12)} ${vwDesktop(16)};
      width: ${vwDesktop(240)};
      &::after {
        height: ${vwDesktop(37)};
      }
    }
  }
`;

export default ImageCallout;
