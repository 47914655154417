import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Filters = (props) => {
  const { activeFilter, filters, handleFilter } = props;

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleClickFilter = (index) => {
    setIsMenuOpen(false);
    handleFilter(index);
  };

  return (
    <Root>
      <h5 className='global black uppercase mobile'>Filter By</h5>
      <p className='body1 black desktop'>Filter By</p>
      <Select onClick={handleMenu} isOpen={isMenuOpen}>
        <p className='body2 black mobile'>
          {activeFilter === null ? 'All' : filters[activeFilter].text}
        </p>
        <img
          src={require('src/assets/images/global/icon/action/chevron-down-black.svg')}
          alt='Filters'
          className='mobile'
        />
        <Menu isOpen={isMenuOpen}>
          <Filter
            onClick={() => handleClickFilter(null)}
            active={activeFilter === null}
          >
            <p className='button uppercase black'>All</p>
          </Filter>
          {filters.map((item, index) => (
            <Filter
              key={index}
              bedroomFilter
              onClick={() => handleClickFilter(index)}
              active={activeFilter === index}
            >
              <p className='button uppercase black'>{item.text}</p>
            </Filter>
          ))}
        </Menu>
      </Select>
    </Root>
  );
};

Filters.propTypes = {
  activeFilter: PropTypes.number,
  filters: PropTypes.array,
  handleFilter: PropTypes.func,
};

const Root = styled.div`
  margin-bottom: ${vwMobile(40)};
  .desktop {
    display: none;
  }
  @media ${media.tablet} {
    margin-bottom: ${vwTablet(40)};
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
  @media ${media.desktop} {
    margin-bottom: none;
  }
`;

const Select = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${vwMobile(40)};
  padding: 0 ${vwMobile(12)};
  margin-top: ${vwMobile(4)};
  border: 1px solid ${({ theme }) => theme.color.black};
  position: relative;
  background-color: ${({ theme }) => theme.color.skyBlue};
  z-index: 3;
  img {
    width: ${vwMobile(16)};
    transform: ${(props) => (props.isOpen ? 'scaleY(-1)' : 'scaleY(1)')};
    transition: 0.4s ease;
  }
  @media ${media.tablet} {
    height: auto;
    padding: 0;
    margin-top: ${vwTablet(12)};
    border: none;
    background-color: transparent;
  }
  @media ${media.desktop} {
    margin-top: ${vwDesktop(12)};
  }
`;

const Menu = styled.div`
  border: 1px solid ${({ theme }) => theme.color.black};
  border-top: none;
  max-height: ${(props) => (props.isOpen ? vwMobile(202) : 0)};
  opacity: ${(props) => (props.isOpen ? 1 : 0)};
  overflow: hidden;
  transition: 0.4s ease;
  position: absolute;
  top: calc(100% + 1px);
  left: -1px;
  width: calc(100% + 2px);
  z-index: -1;
  @media ${media.tablet} {
    max-height: none;
    display: flex;
    opacity: 1;
    position: static;
    border: none;
  }
  @media ${media.desktop} {
    display: block;
  }
`;

const Filter = styled.div`
  height: ${vwMobile(40)};
  padding: 0 ${vwMobile(12)};
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.color.skyBlue};
  border-top: ${(props) =>
    props.bedroomFilter ? `1px solid ${props.theme.color.black}` : 'none'};
  @media ${media.tablet} {
    height: ${vwTablet(36)};
    padding: 0 ${vwTablet(10)};
    border: 1px solid ${({ theme }) => theme.color.black};
    margin-right: ${vwTablet(8)};
    border: 1px solid ${({ theme }) => theme.color.black};
    background-color: ${(props) =>
      props.active ? props.theme.color.black : props.theme.color.skyBlue};
    transition: 0.4s ease;
    p {
      color: ${(props) =>
        props.active ? props.theme.color.white : props.theme.color.black};
    }
  }
  @media ${media.desktop} {
    cursor: pointer;
    width: ${vwDesktop(160)};
    height: ${vwDesktop(36)};
    padding: 0 ${vwDesktop(10)};
    margin-right: 0;
    margin-bottom: ${vwDesktop(12)};
    transition: 0.3s ease;
    &:hover {
      background-color: ${({ theme }) => theme.color.black};
      p {
        color: ${({ theme }) => theme.color.white};
      }
    }
  }
`;

export default Filters;
