import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Button from 'components/Button';
import SummaryBlock from 'components/Configurator/summary/SummaryBlock';
import FavPlans from 'components/Configurator/summary/FavPlans';
import Upgrades from 'components/Configurator/summary/Upgrades';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import data from 'src/data/Configurator/oneshaughnessy';
import planData from 'src/data/Floorplans/oneshaughnessy';

const Summary = () => {
  const config = useSelector((state) => state.configurator);

  const handlePrint = () => {
    window.localStorage.setItem('summaryConfig', JSON.stringify(config));
    window.open(`${window.location.origin}/design/print`);
  };

  return (
    <Root>
      <Title>
        <h1 className="configurator black">Your Summary</h1>
        <Wrapper>
          <Button
            type="primary-arrow"
            label="Save preferences"
            mobileLabel="Save"
            to="/design/save"
            small
          />
          <Button
            type="primary-arrow"
            label="Print Preview"
            clickHandler={handlePrint}
            xSmall
          />
        </Wrapper>
      </Title>
      <SummaryBlock
        title="Kitchen"
        toEdit="/design/kitchen"
        id="K"
        secondAttribute="Flooring"
        paletteIndex={config.kitchen.palette}
        labelRight={48}
        image={require(`src/assets/images/config/oneshaughnessy/renders/kitchen/kitchen-palette-k${
          config.kitchen.palette + 1
        }-flooring-${config.kitchen.flooring + 1}.jpeg`)}
        palette={require(`src/assets/images/config/oneshaughnessy/swatches/palette-k${
          config.kitchen.palette + 1
        }.jpg`)}
        secondAttributeImage={require(`src/assets/images/config/oneshaughnessy/swatches/k-flooring-${
          config.kitchen.flooring + 1
        }.jpg`)}
        text={
          <>
            Upper Cabinet: Gloss White
            <br />
            Countertop &amp; Backsplash: Composite <br />
            Base Cabinet: {data.kitchen.palette[config.kitchen.palette].cabinet}
            <br />
            Fixture Brand: Kohler
            <br />
            Flooring: {data.kitchen.flooring[config.kitchen.flooring].name}
            <br />
          </>
        }
      />
      <SummaryBlock
        title="Bathroom"
        toEdit="/design/bathroom"
        id="B"
        secondAttribute="Ceiling"
        small
        paletteIndex={config.bath.palette}
        labelRight={33}
        image={require(`src/assets/images/config/oneshaughnessy/renders/bath/bathroom-palette-b${
          config.bath.palette + 1
        }-ceiling-${config.bath.ceiling + 1}.jpeg`)}
        palette={require(`src/assets/images/config/oneshaughnessy/swatches/palette-b${
          config.bath.palette + 1
        }.jpg`)}
        secondAttributeImage={require(`src/assets/images/config/oneshaughnessy/swatches/b-ceiling-${
          config.bath.ceiling + 1
        }.jpg`)}
        text={
          <>
            Wall Tile: White Gloss Tile
            <br />
            Countertop: Composite
            <br />
            Base Cabinet: {data.bath.palette[config.bath.palette].cabinet}
            <br />
            Floor Tile: Dark Grey Tile
            <br />
            Fixture Brand: Kohler
            <br />
            Ceiling: {data.bath.ceiling[config.bath.ceiling].name}
            <br />
          </>
        }
      />
      <FavPlans
        plans={planData.filter((plan) => config.plans.includes(plan.id))}
        activePlans={config.plans}
      />
      <Upgrades data={config.upgrades} handlePrint={handlePrint} />
    </Root>
  );
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  @media ${media.tablet} {
  }
  @media ${media.desktop} {
  }
`;

const Title = styled.div`
  padding: ${vwMobile(32)} ${vwMobile(16)} ${vwMobile(60)};
  h1 {
    margin-bottom: ${vwMobile(20)};
  }
  @media ${media.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${vwTablet(40)} ${vwTablet(60)} ${vwTablet(80)};
    h1 {
      margin-bottom: 0;
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(40)} ${vwDesktop(60)} ${vwDesktop(80)};
  }
`;

const Wrapper = styled.div`
  > a:first-of-type {
    margin-bottom: ${vwMobile(12)};
  }
  @media ${media.tablet} {
    display: flex;
    > a:first-of-type {
      margin-right: ${vwTablet(12)};
      margin-bottom: 0;
    }
  }
  @media ${media.desktop} {
    > a:first-of-type {
      margin-right: ${vwDesktop(12)};
    }
  }
`;

export default Summary;
