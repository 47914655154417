import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

export default function Menu(props) {
  const { loggedIn, location, Logout } = props;
  const [ isMenuOpen, setIsMenuOpen ] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  }

  const closeMenu = () => {
    setIsMenuOpen(false);
  }

  return (
    <Root isActive={loggedIn && location.includes('/membership/')}>
      <CurrentLocation className='project-nav black uppercase' onClick={toggleMenu}>
        {
          location.includes('/membership/updates') ? 
            'updates' : location.includes('/membership/resources') ?
              'resources' :'404'
        }

        <img
          src={require('src/assets/images/global/icon/action/chevron-up-gold.svg')}
          alt="Menu"
          style={{ transform: isMenuOpen ? 'scaleY(1)' : 'scaleY(-1)' }}
        />
      </CurrentLocation>
      <Logo>
        <Link to='/membership/updates'><img src={require('src/assets/images/global/logo/one-shaughnessy-lockup.svg')} alt='one shaughnessy logo' /></Link>
      </Logo>
      <Links isMenuOpen={isMenuOpen}>
        <li className='project-nav uppercase'><StyledLink to='/membership/updates' isActive={location.includes('/membership/updates')} onClick={toggleMenu}>Updates</StyledLink></li>
        <li className='project-nav uppercase'><StyledLink to='/membership/resources' isActive={location === '/membership/resources'} onClick={toggleMenu}>Resources</StyledLink></li>
        <li className='project-nav uppercase' onClick={() => {Logout(); closeMenu();}}>Log Out</li>
      </Links>
    </Root>
  )
}

Menu.propTypes = {
  loggedin: PropTypes.bool,
  location: PropTypes.string,
  Logout: PropTypes.func,
}

const Root = styled.div`
  display: ${props => props.isActive ? 'block' : 'none'};
  background-color: ${({ theme }) => theme.color.lightGrey };
  @media ${media.desktop} {
    background-color: transparent;
  }
`;

const CurrentLocation = styled.span`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${vwMobile(15)} ${vwMobile(16)};
  img {
    transition: all 0.3s ease;
    ${vw('width', 16)}
    ${vw('height', 16)}
  }
  @media ${media.tablet} {
    padding: ${vwTablet(15)} ${vwTablet(30)};
  }
  @media ${media.desktop} {
    display: none;
  }
`;

const Links = styled.ul`
  list-style: none;
  overflow: hidden;
  position: absolute;
  left: 0;
  width: 100%;
  max-height: ${props => props.isMenuOpen ? vwMobile(199) : 0};
  padding: ${props => props.isMenuOpen ? `${vwMobile(24)} ${vwMobile(16)} 0` : `0 ${vwMobile(16)} 0`};
  background-color: ${({ theme }) => theme.color.lightGrey};
  transition-duration: 0.3s;
  z-index: 9999;

  li {
    cursor: pointer;
    padding: ${vwMobile(16)} 0;
    border-top: 1px solid #7e7e7e;
    &:last-of-type {
      margin-bottom: ${vwMobile(24)};
    }
  }

  @media ${media.tablet} {
    max-height: ${props => props.isMenuOpen ? vwTablet(267) : 0};
    padding: ${props => props.isMenuOpen ? `${vwTablet(24)} ${vwTablet(30)} 0` : `0 ${vwTablet(30)} 0`};
    li {
      padding: ${vwTablet(24)} 0;
      &:last-of-type {
        margin-bottom: ${vwTablet(36)};
      }
    }
  }
  
  @media ${media.desktop} {
    overflow: visible;
    width: fit-content;
    max-height: none;
    padding: 0;
    border: none;
    display: flex;
    position: absolute;
    top: ${vwDesktop(30)};
    left: ${vwDesktop(286)};
    background-color: transparent;

    li {
      padding: 0;
      border: none;
      position: relative;
      &:last-of-type {
        margin-bottom: 0;
        color: ${props => props.theme.color.white};
        opacity: 0.5;
      }
      &:not(:first-of-type) {
        margin-left: ${vwDesktop(40)};
      }
    }
  }
`;

const Logo = styled.div`
  display: none;

  @media ${media.desktop} {
    display: block;
    position: absolute;
    top: 0;
    left: ${vwDesktop(60)};
    width: ${vwDesktop(186)};
    height: ${vwDesktop(220)};
    background-color: ${({ theme }) => theme.color.skyBlue};
    z-index: 9999;

    img {
      width: ${vwDesktop(138)};
      height: ${vwDesktop(173)};
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const StyledLink = styled(Link)`
  color: ${props => props.isActive ? props.theme.color.gold : props.theme.color.black};
  @media ${media.desktop} {
    color: ${props => props.theme.color.white};
    &:after {
      content: '';
      display: ${props => props.isActive ? 'block' : 'none'};
      width: 97%;
      height: 1px;
      position: absolute;
      bottom: ${vwDesktop(-2)};
      left: 0;
      background-color: ${({ theme }) => theme.color.white};
    }
    &:hover {
      &:after {
        display: block;
      }
    }
  }
`;