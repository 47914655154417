import React, { Component } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { hot } from 'react-hot-loader';
import smoothscroll from 'smoothscroll-polyfill';

import { prerenderStyles } from 'src/plugins/prerender';
import routes from 'src/routes';

import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';

import vw from 'src/styles/utils';
import Insight from 'src/plugins/insight';

class App extends Component {
  componentDidMount() {
    prerenderStyles();
    smoothscroll.polyfill();
  }

  generateRoutes() {
    Insight();
    return routes.map((route, index) => {
      const { path, component } = route;
      const Component = require(`src/${component}`).default;
      return <Route exact path={path} component={Component} key={index} />;
    });
  }

  render() {
    return (
      <Route
        render={({ location }) => (
          <Root>
            <Header />
            <Page isConfig={location.pathname.includes('design')} isMembership={location.pathname.includes('membership')}>
              <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={500}
                  classNames="fade"
                >
                  <Switch location={location}>{this.generateRoutes()}</Switch>
                </CSSTransition>
              </TransitionGroup>
            </Page>
            <Footer />
          </Root>
        )}
      />
    );
  }
}

const Root = styled.div`
  
  .fade-enter {
    opacity: 0;
  }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: all 0.5s;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit.fade-exit-active {
    opacity: 0;
    transition: all 0.5s;
  }
`;

const Page = styled.div`
  background-color: ${({ theme }) => theme.color.black};
  ${(props) =>
    (props.isConfig || props.isMembership
    ) ? vw('padding-top', 0) : vw('padding-top', 60, 100)}
`;

export default process.env.NODE_ENV === 'development' ? hot(module)(App) : App;
