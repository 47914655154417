import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Image from './elements/Image';
import List from './elements/List';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Map = (props) => {
  const [activeCategory, setActiveCategory] = useState(0);
  const [activePoint, setActivePoint] = useState(0);

  const { image, data } = props;

  const handleCategory = (index) => {
    setActivePoint(null);
    setActiveCategory(index);
  };

  const handlePoint = (index) => {
    setActivePoint(index);
    if (index === activePoint) {
      setActivePoint(null);
    }
  };

  return (
    <Root>
      <Title>
        <h5 className="global gold uppercase">COMMUNITY MAP</h5>
        <h3 className="corporate white">Explore Your World</h3>
      </Title>
      <Image
        image={image}
        data={data}
        activeCategory={activeCategory}
        activePoint={activePoint}
        handlePoint={handlePoint}
      />
      <List
        data={data}
        activeCategory={activeCategory}
        handleCategory={handleCategory}
        handlePoint={handlePoint}
        activePoint={activePoint}
      />
    </Root>
  );
};

Map.propTypes = {
  image: PropTypes.string,
  data: PropTypes.array,
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.black};
  padding: ${vwMobile(48)} ${vwMobile(16)};
  h3 {
    margin: ${vwMobile(16)} 0 ${vwMobile(40)};
  }
  @media ${media.tablet} {
    padding: ${vwTablet(80)} ${vwTablet(60)};
    h3 {
      margin: ${vwTablet(20)} 0 ${vwTablet(60)};
    }
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(140)} ${vwDesktop(60)} ${vwDesktop(120)};
    display: grid;
    grid-template-columns: ${vwDesktop(360)} ${vwDesktop(840)};
    grid-template-rows: ${vwDesktop(156)} auto;
    grid-column-gap: ${vwDesktop(120)};
    h3 {
      margin: ${vwDesktop(20)} 0 ${vwDesktop(60)};
    }
  }
`;

const Title = styled.div`
  grid-row: 1;
  grid-column: 1;
`;

export default Map;
