import React from 'react';
import styled from 'styled-components';

import ImageCallout from 'components/Project/ImageCallout';
import ConfigCallout from 'components/ConfigCallout';

import media from 'src/styles/media';
import vw, { vwDesktop } from 'src/styles/utils';

const Kitchen = () => {
  return (
    <Root>
      <StyledImageCallout
        image={require('src/assets/images/oneshaughnessy/one-shaughnessy-kitchen.jpeg')}
        alt="Rooftop Patio"
        points={[
          {
            top: '45.7%',
            left: '27.5%',
            text:
              'Integrated refrigerator and dishwasher complement cabinetry with a flush fit',
          },
          {
            top: '46.5%',
            left: '41.5%',
            text: 'Durable quartz countertops and matching backsplash',
          },
          {
            top: '62.5%',
            left: '44.5%',
            text: 'A Kohler faucet pairs with a stainless steel sink',
          },
          {
            top: '54.1%',
            left: '51.9%',
            text:
              'Induction range makes delicious meals with precise heating and easy clean up',
          },
          {
            top: '27.9%',
            left: '28.8%',
            text: 'Select a cabinetry colour that suits you',
          },
          {
            top: '56.3%',
            left: '60.6%',
            text: 'Enjoy the quiet of soft-close drawers',
          },
        ]}
      />
      <Wrapper>
        <Config>
          <ConfigCallout />
        </Config>
        <Block>
          <BlockContent>
            <h5 className="global black uppercase">Kitchen</h5>
            <p className="body2 black">
              While high-end integrated appliances and plenty of storage options
              come standard, you can play with how your kitchen looks depending
              on your appetite.{' '}
            </p>
          </BlockContent>
        </Block>
      </Wrapper>
    </Root>
  );
};

export default Kitchen;

const Root = styled.div``;

const StyledImageCallout = styled(ImageCallout)`
  width: 100%;
  height: ${vwDesktop(750)};
  img {
    object-position: center 30%;
  }
`;

const Block = styled.div`
  ${vw('padding-top', 32, 32)}
  ${vw('padding-right', 16, 60)}
  ${vw('padding-bottom', 60, 80, 120)}
  ${vw('padding-left', 16, 60)}
  @media ${media.desktop} {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const BlockContent = styled.div`
  ${vw('max-width', 312, 312)}
  h5 {
    ${vw('margin-bottom', 8, 8)}
  }
`;

const Wrapper = styled.div`
  position: relative;
`;

const Config = styled.div`
  @media ${media.desktop} {
    position: absolute;
    right: 0;
  }
`;
