import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const ColorSelect = (props) => {
  const {
    title,
    options,
    small,
    extraSmall,
    handleClick,
    activeOption,
    project,
    swatchName,
    mobileActive,
    labelRight,
    labelName,
    labelId,
  } = props;

  return (
    <Root small={small} active={mobileActive}>
      <h5 className="global gold uppercase">{title}</h5>
      <div>
        {options.map((item, index) => (
          <Option
            key={index}
            small={small}
            extraSmall={extraSmall}
            onClick={() => handleClick(index)}
            active={index === activeOption}
          >
            <img
              src={require(`src/assets/images/config/${project}/swatches/${swatchName}${
                index + 1
              }.jpg`)}
              alt=""
            />
            <div
              style={{ opacity: activeOption === index ? 1 : 0 }}
              className="border"
            />
            {labelName && (
              <Label right={labelRight}>
                <h3 className="configurator white">
                  {labelName}
                  <br />
                  {labelId}
                  {index + 1}
                </h3>
              </Label>
            )}
          </Option>
        ))}
      </div>
    </Root>
  );
};

ColorSelect.propTypes = {
  title: PropTypes.string,
  project: PropTypes.string,
  swatchName: PropTypes.string,
  options: PropTypes.array,
  small: PropTypes.bool,
  extraSmall: PropTypes.bool,
  mobileActive: PropTypes.bool,
  activeOption: PropTypes.number,
  handleClick: PropTypes.func,
};

const Root = styled.div`
  margin-bottom: ${vwMobile(40)};
  position: ${(props) => (props.active ? 'relative' : 'absolute')};
  top: 0;
  opacity: ${(props) => (props.active ? 1 : 0)};
  pointer-events: ${(props) => (props.active ? 'all' : 'none')};
  transition: 0.4s ease;
  margin-left: ${vwMobile(8)};
  > div {
    display: ${(props) => (props.small ? 'flex' : 'block')};
  }
  h5 {
    display: none;
  }
  @media ${media.tablet} {
    position: static;
    top: auto;
    opacity: 1;
    pointer-events: all;
    margin-left: 0;
    margin-bottom: ${vwTablet(40)};
    h5 {
      display: block;
      margin-bottom: ${vwTablet(20)};
    }
    > div {
      display: flex;
      flex-wrap: wrap;
    }
  }
  @media ${media.desktop} {
    margin-bottom: ${vwDesktop(40)};
    h5 {
      display: block;
      margin-bottom: ${vwDesktop(20)};
    }
    > div {
      display: ${(props) => (props.small ? 'flex' : 'block')};
    }
  }
`;

const Option = styled.div`
  width: ${(props) => {
    if (props.extraSmall) {
      return vwMobile(40);
    } else if (props.small) {
      return vwMobile(54);
    } else {
      return vwMobile(272);
    }
  }};
  height: ${vwMobile(72)};
  margin-bottom: ${(props) => (props.small ? 0 : vwMobile(20))};
  margin-right: ${(props) => (props.small ? vwMobile(20) : 0)};
  cursor: pointer;
  position: relative;
  &:last-of-type {
    margin-bottom: 0;
  }
  img {
    width: 100%;
    height: 100%;
  }
  .border {
    width: ${`calc(100% + ${vwMobile(16)})`};
    height: ${`calc(100% + ${vwMobile(16)})`};
    position: absolute;
    top: ${vwMobile(-8)};
    left: ${vwMobile(-8)};
    border: ${vwMobile(2)} solid ${({ theme }) => theme.color.gold};
    transition: 0.3s ease;
  }
  @media ${media.tablet} {
    width: ${(props) => {
      if (props.extraSmall) {
        return vwTablet(44);
      } else if (props.small) {
        return vwTablet(60);
      } else {
        return vwTablet(300);
      }
    }};
    height: ${vwTablet(80)};
    margin-bottom: ${(props) => (props.small ? 0 : vwTablet(20))};
    margin-right: ${(props) => (props.small ? vwTablet(20) : 0)};
    &:nth-of-type(1) {
      margin-right: ${(props) => (props.small ? vwTablet(20) : vwTablet(36))};
    }
    .border {
      width: ${`calc(100% + ${vwTablet(16)})`};
      height: ${`calc(100% + ${vwTablet(16)})`};
      top: ${vwTablet(-8)};
      left: ${vwTablet(-8)};
      border: ${vwTablet(2)} solid ${({ theme }) => theme.color.gold};
    }
  }
  @media ${media.desktop} {
    width: ${(props) => {
      if (props.extraSmall) {
        return vwDesktop(44);
      } else if (props.small) {
        return vwDesktop(60);
      } else {
        return vwDesktop(300);
      }
    }};
    height: ${vwDesktop(60)};
    margin-bottom: ${(props) => (props.small ? 0 : vwDesktop(20))};
    margin-right: ${(props) => (props.small ? vwDesktop(20) : 0)};
    &:nth-of-type(1) {
      margin-right: ${(props) => (props.small ? vwDesktop(20) : 0)};
    }
    .border {
      width: ${`calc(100% + ${vwDesktop(16)})`};
      height: ${`calc(100% + ${vwDesktop(16)})`};
      top: ${vwDesktop(-8)};
      left: ${vwDesktop(-8)};
      border: ${vwDesktop(2)} solid ${({ theme }) => theme.color.gold};
    }
  }
`;

const Label = styled.div`
  position: absolute;
  top: ${vwMobile(14)};
  right: ${(props) => vwMobile(props.right)};
  @media ${media.tablet} {
    top: ${vwTablet(14)};
    right: ${(props) => vwTablet(props.right)};
  }
  @media ${media.desktop} {
    top: ${vwDesktop(4)};
    right: ${(props) => vwDesktop(props.right)};
  }
`;

export default ColorSelect;
