module.exports = {
  defaultLocale: 'en',
  locales: ['en'],
  prerender: true,
  dbProject: 'Kutak',
  designMailer: 'aHUyb25wZzU4ZnQ2eG9xOHdmOXh0YWp2bnpmdXZkYWQ=',
  analytics: {
    gtag: '',
    gtmId: 'GTM-PT33ZF3',
    optimizeId: '',
    hotjarId: '',
  },
  seo: {
    title: 'Kutak Development',
  },
  stage: {
    mobile: 320,
    tablet: 768,
    desktop: 1440,
  },
  mediaQuery: {
    tablet: 576,
    desktop: 992,
  },
  siteUrl: 'https://kutakdevelopment.com',
  wordpressAPIUrl: 'cms/wp-json/wp/v2',
};
