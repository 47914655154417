import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Title from './Title';
import Card from 'components/Project/Plans/elements/Card';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const FavPlans = (props) => {
  const { plans, activePlans } = props;

  return (
    <Root>
      <Title to="/design/plans" title="Favourite Plans" />
      <Plans>
        {plans.length === 0 && (
          <p className="body2 black">No plans have been selected</p>
        )}
        {plans.map((plan, index) => (
          <Card
            key={index}
            plan={plan}
            activeBed={null}
            configurator
            summary
            activePlans={activePlans}
            handlePopup={(f) => f}
            handlePlan={(f) => f}
          />
        ))}
      </Plans>
    </Root>
  );
};

FavPlans.propTypes = {
  plans: PropTypes.array,
  activePlans: PropTypes.array,
};

const Root = styled.div`
  background-color: ${({ theme }) => theme.color.lightGrey};
  padding: ${vwMobile(60)} 0;
  @media ${media.tablet} {
    padding: ${vwTablet(80)} 0;
  }
  @media ${media.desktop} {
    padding: ${vwDesktop(80)} 0;
  }
`;

const Plans = styled.div`
  padding: 0 ${vwMobile(16)};
  @media ${media.tablet} {
    padding: 0 ${vwTablet(60)};
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: ${vwTablet(318)} ${vwTablet(318)};
    grid-gap: ${vwTablet(12)};
  }
  @media ${media.desktop} {
    padding: 0 ${vwDesktop(60)};
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: ${vwDesktop(346)} ${vwDesktop(346)} ${vwDesktop(346)};
    grid-gap: ${vwDesktop(12)};
  }
`;

export default FavPlans;
