import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Corporate from './sections/Corporate';
import Project from './sections/Project';
import Application from './sections/Application';

import data from 'src/data/Contact/data';

export default function Footer(props) {
  const location = useLocation();
  return (
    <Root>
      <Project
        isProject={location.pathname.includes('/homes/')}
        data={data.os}
        isConfig={location.pathname.includes('design')}
      />
      <Corporate
        isProject={location.pathname.includes('/homes/')}
        data={data.corporate}
        isApp={location.pathname.includes('design') || location.pathname.includes('membership') }
      />
      <Application 
        isApp={location.pathname.includes('design') || location.pathname.includes('membership')} 
        isMembership={location.pathname.includes('membership')}
      />
    </Root>
  );
}

Footer.propTypes = {
  isProject: PropTypes.bool,
};

const Root = styled.footer`
  width: 100%;
  z-index: 9999;
`;
