export default [
  {
    image: require('src/assets/images/global/graphic/kis-intelligence.svg'),
    label: 'Intelligence',
    text: '20 years of expertise built into homes',
  },
  {
    image: require('src/assets/images/global/graphic/kis-connection.svg'),
    label: 'Connection',
    text: 'To your community, your building, each other',
  },
  {
    image: require('src/assets/images/global/graphic/kis-legacy.svg'),
    label: 'Legacy',
    text: 'Quality and partnerships create an iconic impression',
  },
  {
    image: require('src/assets/images/global/graphic/kis-innovation.svg'),
    label: 'Innovation',
    text: 'An eye on what’s next',
  },
]