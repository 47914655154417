import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Plans = (props) => {
  const { data } = props;
  return (
    <Root>
      <h4 className="global black">Favourite Plans</h4>
      {data.map((item, index) => (
        <Plan key={index}>
          <div>
            <Label>
              <Line />
              <img
                src={require('src/assets/images/global/logo/one-shaughnessy-plain.svg')}
                alt="One Shaughnessy"
              />
              <Line />
              <Text large>
                <p className="floorplan-label black uppercase">{item.id}</p>
                <div>
                  <p className="plan-type black">
                    {item.bed === 0 ? 'Studio' : `${item.bed} Bed`}
                    {item.den && ' + Den'}
                  </p>
                  <p className="plan-type black">{item.bath} Bathroom</p>
                </div>
              </Text>
              <Text>
                <p className="plan-details uppercase black">Interior</p>
                <p className="plan-details uppercase black">
                  {item.interior} sqft
                </p>
              </Text>
              <Text>
                <p className="plan-details uppercase black">Exterior</p>
                <p className="plan-details uppercase black">
                  {item.exterior} sqft
                </p>
              </Text>
              <Text medium>
                <p className="plan-details uppercase black">Total</p>
                <p className="plan-details uppercase black">
                  {item.total} sqft
                </p>
              </Text>
              <Line />
            </Label>
            <Plate src={item.plate} alt={`Plate ${item.id}`} />
          </div>
          <Image>
            <img src={item.plan} alt={`Plan ${item.id}`} />
          </Image>
        </Plan>
      ))}
    </Root>
  );
};

Plans.propTypes = {
  data: PropTypes.array,
};

const Root = styled.div`
  padding: 0 ${vwMobile(16)};
  @media ${media.tablet} {
    padding: 0;
  }
  @media print {
    padding: 0;
    .floorplan-label {
      font-size: 114px;
      line-height: 104px;
      letter-spacing: 5.82px;
    }
    .plan-type {
      font-size: 44px;
      line-height: 44px;
      letter-spacing: 0.77px;
    }
    .plan-details {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 1.6px;
    }
  }
`;

const Plan = styled.div`
  padding: 60px 0;
  border-bottom: 4px solid ${({ theme }) => theme.color.black};
  display: flex;
  flex-direction: column;
  .plan-type {
    text-align: right;
  }
  @media ${media.tablet} {
  }
  @media ${media.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: ${vwDesktop(60)} 0;
    border-bottom: ${vwDesktop(4)} solid ${({ theme }) => theme.color.black};
  }
  @media print {
    flex-direction: row;
    justify-content: flex-start;
    break-inside: avoid;
    /* max-height: 100vh; */
    padding: 60px 0;
    border-bottom: 4px solid ${({ theme }) => theme.color.black};
    > div:first-of-type {
      margin-right: 100px;
    }
  }
`;

const Label = styled.div`
  width: ${vwMobile(160)};
  img {
    width: 100%;
    height: auto;
    display: block;
    margin: ${vwMobile(10)} 0;
  }
  @media ${media.tablet} {
    width: ${vwTablet(240)};
    img {
      margin: ${vwTablet(12)} 0;
    }
  }
  @media ${media.desktop} {
    width: ${vwDesktop(240)};
    img {
      margin: ${vwDesktop(12)} 0;
    }
  }
  @media print {
    width: 240px;
    img {
      margin: 12px 0;
    }
  }
`;

const Line = styled.div`
  width: 100%;
  height: ${vwMobile(5)};
  background-color: ${({ theme }) => theme.color.black};
  @media ${media.tablet} {
    height: ${vwTablet(5)};
  }
  @media ${media.desktop} {
    height: ${vwDesktop(5)};
  }
  @media print {
    height: 5px;
  }
`;

const Text = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: ${(props) => {
    if (props.large) {
      return vwMobile(13);
    } else if (props.medium) {
      return vwMobile(10);
    } else {
      return vwMobile(2);
    }
  }};
  @media ${media.tablet} {
    margin-bottom: ${(props) => {
      if (props.large) {
        return vwTablet(20);
      } else if (props.medium) {
        return vwTablet(12);
      } else {
        return vwTablet(4);
      }
    }};
  }
  @media ${media.desktop} {
    margin-bottom: ${(props) => {
      if (props.large) {
        return vwDesktop(20);
      } else if (props.medium) {
        return vwDesktop(12);
      } else {
        return vwDesktop(4);
      }
    }};
  }
  @media print {
    margin-bottom: ${(props) => {
      if (props.large) {
        return '20px';
      } else if (props.medium) {
        return '12px';
      } else {
        return '4px';
      }
    }};
  }
`;

const Image = styled.div`
  width: 100%;
  height: ${vwMobile(300)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${vwMobile(40)};
  img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
  @media ${media.tablet} {
    width: 100%;
    height: ${vwTablet(676)};
    margin-top: ${vwTablet(40)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(980)};
    height: ${vwDesktop(882)};
    margin-top: 0;
  }
  @media print {
    width: 980px;
    height: 882px;
    max-height: 90vh;
  }
`;

const Plate = styled.img`
  width: ${vwMobile(240)};
  height: auto;
  margin-top: ${vwMobile(32)};
  @media ${media.tablet} {
    width: ${vwTablet(240)};
    margin-top: ${vwTablet(32)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(240)};
    margin-top: ${vwDesktop(32)};
  }
  @media print {
    width: 240px;
    height: auto;
    margin-top: 32px;
  }
`;

export default Plans;
