import React from 'react';
import data from '../Contact/data';

export default {
  home: {
    heading: <>Introducing <br /> a Completely New Home Ownership Experience</>,
    bodyTitle: 'Philosophy',
    body: [
      {
        text: 'Our Kutak philosophy is guided by three innovative programs. Together, they present a buying and living experience that will change how people approach real estate.',
      },
    ],
  },
  homes: {
    badge: require('src/assets/images/global/icon/kutak/kutak-xp.svg'),
    heading: <>Personalized Homes <br /> for Higher Living</>,
    bodyTitle: '',
    body: [
    ],
  },
  philosophy: {
    badge: require('src/assets/images/global/icon/kutak/kutak-xp-me-kis-horizontal.svg'),
    heading: <>Intelligent. <br /> Personal.</>,
    bodyTitle: 'Philosophy',
    body: [
      {
        text: 'We are changing the standards of real estate to give homeowners a completely new, hands-on buying and living experience – homes, after all, are personal. To achieve this ambitious task, we defined three innovative programs:'
      },
      {
        text: 'Kutak Experience (XP), Kutak Membership (ME), and Kutak Intelligence (KIS). Each offers its own benefit to homeowners, but it’s their synergy that shifts the paradigm of home.'
      },
    ],
  },
  kis: {
    badge: require('src/assets/images/global/icon/kutak/kutak-kis.svg'),
    heading: 'Homes with an Intelligent Outlook',
    bodyTitle: 'Kutak Intelligence',
    body: [
      {
        text: 'Our Kutak Intelligence System of gathering expertise and data is deployed to all facets of real estate development, including land acquisition, design, marketing, construction, and customer service.'
      },
      {
        text: 'We’re able to take complex concepts and refine them to their simplest form, conveying an articulate message to purchasers, realtors, and investors. Our four pillars of KIS grow from this foundation.'
      },
    ],
  },
  team: {
    badge: require('src/assets/images/global/icon/kutak/kutak-me.svg'),
    heading: <>Welcome to the <br /> Kutak Family</>,
    bodyTitle: 'Team',
    body: [
      {
        text: 'The Kutak team is a small group of real estate professionals with decades of experience, with our roots planted in construction and development, locally and beyond.'
      },
      {
        text: 'Our intimate size means we’re nimble, approachable, and engaged in our community. Every day, we commit ourselves to delivering meaningful homes that offer choice to consumers and enrichment to communities.'
      },
    ],
  },
  contact: {
    heading: <>Get in Touch <br /> with Our Team</>,
    bodyTitle: 'Contact',
    body: [
      {
        text: <a href='https://goo.gl/maps/Ev8SyYtprRcGkQQr9' rel='noopener noreferrer' target='_blank' className='body2 white'>Kutak Development Services Ltd. <br /> <div className='hoverGold'>{data.corporate.address1} <br /> {data.corporate.address2}</div></a>
      },
      {
        text: <><a href={`tel:${data.corporate.phone}`} className='body2 white hoverGold'>{data.corporate.phone}</a> <br /> <a href={`mailto:${data.corporate.email}`} className='body2 white hoverGold'>info@kutakdevelopment.com</a></>
      }
    ]
  },
}