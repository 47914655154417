import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import media from 'src/styles/media';
import vw, { vwTablet, vwDesktop } from 'src/styles/utils';

export default function Project(props) {
  const { isProject, data } = props;
  return (
    <Root isProject={isProject}>
      <Body>
        <h2 className="global white uppercase">{data.visitHeading}</h2>

        <Info>
          <InfoBlock>
            {/* <h3 className="footer body gold uppercase">
              {data.locationHeading}
            </h3> */}
            <a
              href="https://www.google.com/maps/search/119-2540+Shaughnessy+St,+Port+Coquitlam,+BC+V3C+3W4/@49.2614953,-122.7841898,17z/data=!3m1!4b1"
              target="_blank"
              rel="noreferrer noopener"
            >
              <ul className="footer body gold hoverWhite">
                <li>{data.address1}</li>
                <li>{data.address2}</li>
              </ul>
            </a>
            <a
              href={`tel:${data.tel}`}
              className="footer body gold hoverWhite tel"
            >
              {data.tel}
            </a>
          </InfoBlock>
          <InfoBlock>
            <h3 className="footer body gold uppercase"> {data.hoursHeading}</h3>

            <ul className="footer body gold">
              {/* <li>Coming Soon</li> */}
              <li>{data.weekdays}</li>
              <li>{data.weekends}</li>
            </ul>
          </InfoBlock>
        </Info>
      </Body>
      <Map src={data.map} alt={data.mapDescription} />
      <Disclaimer className="footer disclaimer">
        This is not an offering for sale as any such offering can only be made
        by way of disclosure statement. Square footages are based on preliminary
        measurements and are approximate. Renderings are conceptual only and may
        differ from the final product. The developer reserves the right to make
        modifications to building design, specifications and floor plans.
        E&amp;O.E
      </Disclaimer>
    </Root>
  );
}

Project.propTypes = {
  isProject: PropTypes.bool,
};

const Root = styled.div`
  display: ${(props) => (props.isProject ? 'block' : 'none')};
  background-color: black;
  position: relative;
  ${vw('padding-top', 40, 60, 68)}
  ${vw('padding-bottom', 40, 40, 50)}
  .tel {
    display: block;
    ${vw('margin-top', 10, 10, 10)}
    ${vw('margin-bottom', 32, 32, 0)}
  }
`;

const Body = styled.div`
  ${vw('padding-left', 16, 30, 60)}
  ${vw('padding-right', 16, 30, 60)}
  ${vw('margin-bottom', 32, 58, 40)}
  h2 {
    ${vw('width', 270, 384)}
    ${vw('margin-bottom', 32, 32, 24)}
    &.global {
      ${vw('font-size', 18, 18)};
    }
  }
`;

const Info = styled.div`
  @media ${media.desktop} {
    display: flex;
  }
`;

const InfoBlock = styled.div`
  &:not(:first-of-type) {
    ${vw('margin-left', 0, 0, 117)}
  }
  h3 {
    ${vw('margin-bottom', 14, 14)}
  }
  ul {
    list-style: none;
    &:first-of-type {
      /* ${vw('margin-bottom', 0, 0, 0)} */
    }
    li:first-of-type {
      ${vw('margin-bottom', 10, 10)}
    }
  }
`;

const Map = styled.img`
  margin: 0 auto;
  ${vw('width', 300, 300)};
  ${vw('height', 190, 190)};
  ${vw('margin-bottom', 32, 0)}
  @media ${media.tablet} {
    position: absolute;
    top: ${vwTablet(116)};
    right: ${vwTablet(30)};
  }
  @media ${media.desktop} {
    top: ${vwDesktop(60)};
    right: ${vwDesktop(60)};
  }
`;

const Disclaimer = styled.span`
  display: block;
  ${vw('width', 288, 708, 940)}
  ${vw('margin-left', 16, 30, 60)}
  ${vw('margin-right', 16, 30, 0)}
`;
