import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

const Building = (props) => {
  const { image, buildingSvg, activeLevel } = props;
  return (
    <Root>
      <img src={image} alt="Building" />
      <Svg
        width={buildingSvg.width}
        height={buildingSvg.height}
        viewBox={buildingSvg.viewBox}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {buildingSvg.levels.map((item, index) => (
          <G
            key={index}
            active={index === activeLevel}
            style={{ opacity: activeLevel === index ? 1 : 0 }}
          >
            {item}
          </G>
        ))}
      </Svg>
    </Root>
  );
};

Building.propTypes = {
  image: PropTypes.string,
  buildingSvg: PropTypes.object,
  activeLevel: PropTypes.number,
};

const Root = styled.div`
  position: relative;
  width: ${vwMobile(130)};
  img {
    width: 100%;
    display: block;
  }
  @media ${media.tablet} {
    width: ${vwTablet(200)};
  }
  @media ${media.desktop} {
    width: ${vwDesktop(200)};
  }
`;

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
`;

const G = styled.g`
  transition: 0.3s ease;
`;

export default Building;
