import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

import PostCard from './components/PostCard';
import FiltersMobile from './components/FiltersMobile';

import { getRequest, getRequestCategories } from 'src/utils/request';

import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

export default function Updates() {
  const [loggedIn, setLoggedIn] = useState((localStorage.getItem('token') !== null));
  const [ content, setContent ] = useState(null);
  const [ categories, setCategories ] = useState([]);
  const [ filterList, setFilterList ] = useState([]);
  const [ activeFilter , setActiveFilter ] = useState([]);
  const [ activeFilterName, setActiveFilterName ] = useState('All');
  const [ isEmpty, setIsEmpty ] = useState(false);
  const [ isTransitioning, setIsTransitioning ] = useState(false);
  
  useEffect(() => {
    if (loggedIn) requestContent();
  },[])

  const requestContent = async () => {
    const reqResult = await getRequest('one-shaughnessy');
    const reqCategoriesResult = await getRequestCategories();
    await setContent(reqResult);
    await setCategories(reqCategoriesResult);
  }

  const toggleFilterList = (categoryId) => {
    const newFilter = [categoryId];

    if (!isTransitioning) {
      setIsTransitioning(true);
      setActiveFilter(newFilter);
      setTimeout(() => {
        setFilterList(newFilter);
        setIsTransitioning(false);
      }, 500)
    }
    for (let i=0; i < content.length; i++) {
      if (content[i].categories.length !== 0 && newFilter.length !== 0) {
        if (content[i].categories[0].toString() === categoryId) {
          setIsEmpty(false);
          break;
        } else {
          setIsEmpty(true);
        }
      } else {
        setIsEmpty(true);
      }
    }
  }

  const toggleAllFilterList = () => {
    setIsEmpty(false);
    if (!isTransitioning) {
      setIsTransitioning(true);
      setActiveFilter([]);
      setTimeout(() => {
        setFilterList([]);
        setIsTransitioning(false);
      }, 500)
    }
  }

  const handleFilterChange = (categoryId, categoryName) => {
    const newFilter = [];
    if (categoryId !== 0) {
      newFilter.push(categoryId.toString());
    }

    if (!isTransitioning) {
      setIsTransitioning(true);
      setActiveFilter(newFilter);
      setActiveFilterName(categoryName);
      setTimeout(() => {
        setFilterList(newFilter);
        setIsTransitioning(false);
      }, 500)
    }

    for (let i=0; i < content.length; i++) {
      if (content[i].categories.length !== 0 && newFilter.length !== 0) {
        if (content[i].categories[0].toString() === categoryId) {
          setIsEmpty(false);
          break;
        }
      } else {
        setIsEmpty(true);
      }
    }
  }

  return (
    <>
    {
    !loggedIn ? 
      <Redirect to='/membership' /> :
      <Root>
        <h1 className='corporate black'>Project Updates</h1>

        <FiltersMobile categories={categories} handleFilterChange={handleFilterChange} activeFilterName={activeFilterName} />
        
        <FiltersTabletDesktop>
          <FilterButton 
            className='button white uppercase'
            isActive={activeFilter.length === 0}
            onClick={toggleAllFilterList}
          >
            All
          </FilterButton>
          {categories !== null && categories.map((category, i) => (
            category.name.toLowerCase() !== 'uncategorized' &&
            <FilterButton 
              className='button white uppercase'
              isActive={activeFilter.includes(category.id.toString())}
              onClick={() => toggleFilterList(category.id.toString())}
            >
              {category.name}
            </FilterButton>
          ))}
        </FiltersTabletDesktop>
        <Section style={{ opacity: isTransitioning ? 0 : 1 }}>
          {(categories !== null && content !== null) && content.map((post, i) => (
            (filterList.includes((post.categories.length !== 0) && post.categories[0].toString()) || filterList.length === 0) &&
            <PostCard
              data={post}
              key={i}
              style={{ opacity: isTransitioning ? 0 : 1 }}
            />
          ))}

          {
            isEmpty &&
            <Empty style={{ opacity: isTransitioning ? 0 : 1 }}>
              <p className='body2 black'>There are currently no new posts in this category. Please check back soon for updates.</p>
            </Empty>
          }
        </Section>
      </Root>
    }
    </>
  )
}

const Root = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.white};
  ${vw('min-height', 494, 662, 598)}
  ${vw('padding-top', 40, 80, 100)}
  ${vw('padding-right', 16, 60)}
  ${vw('padding-bottom', 40, 80, 100)}
  ${vw('padding-left', 16, 60)}
  h1 {
    ${vw('margin-bottom', 40, 40, 60)}
  }
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: ${vwMobile(288)};
  grid-gap: ${vwMobile(40)} 0;
  transition-duration: 0.5s;
  @media ${media.tablet} {    
    grid-template-columns: ${`repeat(2, ${vwTablet(312)})`};
    grid-gap: ${vwTablet(60)} ${vwTablet(24)};
  }
  @media ${media.desktop} {

    grid-template-columns: ${`repeat(3, ${vwDesktop(414)})`};
    grid-gap: ${vwDesktop(60)} ${vwDesktop(39)};
  }
`;



const FiltersTabletDesktop = styled.div`
  display: none;
  @media ${media.tablet} {
    display: block;
    margin-bottom: ${vwTablet(60)};
  }

  @media ${media.desktop} {
    position: absolute;
    top: ${vwDesktop(134)};
    right: ${vwDesktop(60)};
    margin-bottom: 0;
  }
`;

const FilterButton = styled.button`
  cursor: pointer;
  background-color: ${props => props.isActive ? props.theme.color.gold : props.theme.color.white};
  color: ${props => props.isActive ? props.theme.color.white : props.theme.color.gold};
  border: 1px solid ${({ theme }) => theme.color.gold};
  transition: all 0.3s ease;
  ${vw('padding-top', 8)}
  ${vw('padding-right', 10)}
  ${vw('padding-bottom', 8)}
  ${vw('padding-left', 10)}
  &:not(:first-of-type) {
    ${vw('margin-left', 8)}
  }
`;

const Empty = styled.div`
  position: relative;
  text-align: center;
  background-color: ${({ theme }) => theme.color.lightGrey};
  ${vw('width', 288, 648, 1320)};
  ${vw('height', 226, 292, 266)};
  p {
    width: ${vwMobile(248)};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media ${media.tablet} {
    p {
      width: ${vwTablet(528)};
    }
  }
  @media ${media.desktop} {
    p {
      width: auto;
    }
  }
`;